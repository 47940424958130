import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Box, Divider, Grid, Paper, Stack } from "@mui/material";
import { SelectForm } from "../atoms/SelectForm";
import { HiddenInput } from "../atoms/HiddenInput";
import { isMobile } from "react-device-detect";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitBuildingPlanningInformation, useInitBuildingPlanningInformationDetail } from "../../utils/CustomHook";
import { onBlurCommonAction, saveChildForPropertyEdit } from "../../utils/PropertyEdit";

export const BuildingPlanningInformation = React.memo(function buildingPlanningInformation({
  property,
  initialized,
  buildingplanninginformation,
  buildingplanninginformationdetail_set,
  setOriginPropertyData,
  toggleViewport,
  formPaperVariant,
  formPaperElevation,
  ...props
}) {
  const [isLoading, setIsLoading] = React.useState(false)
  const loadingComplete = React.useCallback(() => {
    setIsLoading(false)
  }, [])

  const [modalBuildingPlanningInformationFormIndex, setModalBuildingPlanningInformationFormIndex] = React.useState(undefined)
  const [isOpenHeader, setIsOpenHeader] = React.useState(false)


  const [choices, setChoices] = React.useState({});
  const [propertyId, setPropertyId] = React.useState(null)

  const [isFocused, setFocusInputName] = useFocusInput()
  const handleFocus = React.useCallback((name) => {
    setFocusInputName(name)
  }, [])

  const {
    control,
    formState: { errors },
    getValues,
    methods,
    setError,
    clearErrors,
    setValue,
    trigger,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange",
  });

  const [buildingPlanningInformationDetailFields] = useInitBuildingPlanningInformationDetail(buildingplanninginformationdetail_set, setValue, isFocused, control, trigger)
  useInitBuildingPlanningInformation(buildingplanninginformation, setValue, isFocused)

  React.useEffect(() => {
    setPropertyId(property.id)
  }, [property])

  React.useEffect(() => {
    setChoices(props.choices);
  }, [props.choices]);

  React.useEffect(() => {
    if (!(buildingplanninginformation)) {
      return;
    }

    initialized();
  }, [buildingplanninginformation]);

  const saveCommonParams = React.useMemo(() => [
    propertyId, clearErrors, setOriginPropertyData, setError
  ], [propertyId, clearErrors, setOriginPropertyData, setError])

  const onBlurForBuildingPlanningInformation = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "buildingplanninginformation",
    ]
    saveChildForPropertyEdit(e, errors, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const onBlurForBuildingPlanningInformationDetail = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    saveBuildingPlanningInformationDetail(e, errors)
  }, [propertyId]);

  const saveBuildingPlanningInformationDetail = React.useCallback((e, errors = {}) => {
    const saveChildKeys = [
      "property", "buildingplanninginformationdetail_set", null
    ]
    saveChildForPropertyEdit(e, errors, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId])

  const rules = React.useMemo(() => {
    const data = {
      buildingplanninginformation: {
        neighborhood_demand: {
          onBlur: onBlurForBuildingPlanningInformation,
        },
        other_demand: {
          onBlur: onBlurForBuildingPlanningInformation,
        },
      },
      buildingplanninginformationdetail_set: {
        building_no: {
          onBlur: onBlurForBuildingPlanningInformationDetail,
        },
        section: {
          onBlur: onBlurForBuildingPlanningInformationDetail,
        },
        sales_style: {
          onBlur: onBlurForBuildingPlanningInformationDetail,
        },
        building_m2_demand: {
          onBlur: onBlurForBuildingPlanningInformationDetail,
        },
        floor_number: {
          onBlur: onBlurForBuildingPlanningInformationDetail,
        },
        room_layout: {
          onBlur: onBlurForBuildingPlanningInformationDetail,
        },
        dive: {
          onBlur: onBlurForBuildingPlanningInformationDetail,
        },
        garage: {
          onBlur: onBlurForBuildingPlanningInformationDetail,
        },
        depth_high_basic: {
          onBlur: onBlurForBuildingPlanningInformationDetail,
        },
        floor_heating: {
          onBlur: onBlurForBuildingPlanningInformationDetail,
        },
        masu_construction: {
          onBlur: onBlurForBuildingPlanningInformationDetail,
        },
        station_payment_burden: {
          onBlur: onBlurForBuildingPlanningInformationDetail,
        },
      },
    }
    return data
  }, [onBlurForBuildingPlanningInformation, onBlurForBuildingPlanningInformationDetail]);

  const labels = React.useMemo(() => ({
    neighborhood_demand: "近隣要望",
    other_demand: "その他要望",
    building_no: "号棟",
    section: "区画",
    sales_style: "販売形態",
    building_m2_demand: "建物㎡要望",
    floor_number: "階",
    room_layout: "間取り",
    dive: "潜り",
    garage: "車庫",
    depth_high_basic: "深・高基礎",
    floor_heating: "床暖房",
    masu_construction: "升工事",
    station_payment_burden: "局納金負担",
  }), [])

  const propertyEditFormDialogCloseAction = React.useCallback(() => {
    toggleViewport()
  }, [])
  const formAreaClickedAction = React.useCallback((additionalProcess) => {
    if (!isMobile) {
      return
    }
    if (additionalProcess) {
      additionalProcess()
    }
    setIsLoading(true)
    toggleViewport()
  }, [])

  const commonInputProps = React.useMemo(() => ({
    control: control,
    onFocus: handleFocus,
  }), [control, handleFocus])

  const pageTitle = "建物企画情報"

  return (
    <PropertyEditForms
      title={
        <>
          {pageTitle}
        </>
      }
    >
      <LoadingProgress open={isLoading} useCircular={false} />
      <Box sx={{ overflowX: "scroll", pt: 3 }}>
        <Box sx={{ minWidth: "1000px", pb: 2 }}>
          <FormProvider {...methods}>
            <PropertyEditFormDialog
              isOpen={modalBuildingPlanningInformationFormIndex !== undefined}
              handleClose={() => {
                setModalBuildingPlanningInformationFormIndex(undefined)
                propertyEditFormDialogCloseAction()
              }}
              openedAction={loadingComplete}
              title={
                <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
                  {pageTitle}
                </Stack>
              }
              content={
                <BuildingPlanningInformationDetailForm
                  commonInputProps={commonInputProps}
                  labels={labels}
                  rules={rules}
                  index={modalBuildingPlanningInformationFormIndex}
                  choices={choices}
                  disabled={props.disabled}
                  setValue={setValue}
                  getValues={getValues}
                  isModal={true}
                />
              }
            />
            <PropertyEditFormDialog
              isOpen={isOpenHeader}
              handleClose={() => {
                setIsOpenHeader(false)
                propertyEditFormDialogCloseAction()
              }}
              openedAction={loadingComplete}
              title={pageTitle}
              content={
                <BuildingPlanningInformationForm
                  commonInputProps={commonInputProps}
                  rules={rules}
                  labels={labels}
                  disabled={props.disabled}
                  isModal={true}
                />
              }
            />
            <form>
              <Grid container direction="row" spacing={1}>
                {buildingPlanningInformationDetailFields.map((item, index) => {
                  return (
                    <Grid item xs={12} key={item.id}>
                      <Stack spacing={1}>
                        <Paper
                          elevation={formPaperElevation}
                          variant={formPaperVariant}
                          onClick={() => formAreaClickedAction(() => setModalBuildingPlanningInformationFormIndex(index))}
                        >
                          <BuildingPlanningInformationDetailForm
                            commonInputProps={commonInputProps}
                            rules={rules}
                            labels={labels}
                            index={index}
                            choices={choices}
                            disabled={props.disabled}
                            setValue={setValue}
                            getValues={getValues}
                            isModal={false}
                          />
                        </Paper>
                        <Divider />
                      </Stack>
                    </Grid>
                  )
                })}
                <Grid item xs={12}>
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenHeader(true))}
                  >
                    <BuildingPlanningInformationForm
                      commonInputProps={commonInputProps}
                      rules={rules}
                      labels={labels}
                      disabled={props.disabled}
                      isModal={false}
                    />
                  </Paper>
                </Grid>

              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Box >
    </PropertyEditForms >
  );
});

BuildingPlanningInformation.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
  buildingplanninginformation: PropTypes.object.isRequired,
  buildingplanninginformationdetail_set: PropTypes.array.isRequired,
};

BuildingPlanningInformation.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const BuildingPlanningInformationDetailForm = React.memo(function buildingPlanningInformationDetailForm(props) {
  const { commonInputProps, labels, rules, index, choices, setValue, isModal } = props
  return (
    <Grid container direction="row" spacing={1} columns={12}>
      {index !== undefined &&
        <>
          <HiddenInput
            {...commonInputProps}
            name={`buildingplanninginformationdetail_set.${index}.id`}
          />
          <Grid item xs={isModal ? 4 : 1}>
            <TextField
              {...commonInputProps}
              name={`buildingplanninginformationdetail_set.${index}.building_no`}
              label={labels.building_no}
              isLabelInside={!isModal}
              rules={rules.buildingplanninginformationdetail_set.building_no}
              disabled={true}
            />
          </Grid>
          <Grid item xs={isModal ? 4 : 1}>
            <TextField
              {...commonInputProps}
              name={`buildingplanninginformationdetail_set.${index}.section`}
              label={labels.section}
              isLabelInside={!isModal}
              rules={rules.buildingplanninginformationdetail_set.section}
              disabled={true}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`buildingplanninginformationdetail_set.${index}.sales_style`}
              rules={rules.buildingplanninginformationdetail_set.sales_style}
              label={labels.sales_style}
              isLabelInside={!isModal}
              choices={choices?.buildingplanninginformationdetail_sales_style}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              name={`buildingplanninginformationdetail_set.${index}.building_m2_demand`}
              rules={rules.buildingplanninginformationdetail_set.building_m2_demand}
              label={labels.building_m2_demand}
              isLabelInside={!isModal}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`buildingplanninginformationdetail_set.${index}.floor_number`}
              rules={rules.buildingplanninginformationdetail_set.floor_number}
              label={labels.floor_number}
              isLabelInside={!isModal}
              choices={choices?.floor_number}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`buildingplanninginformationdetail_set.${index}.room_layout`}
              rules={rules.buildingplanninginformationdetail_set.room_layout}
              label={labels.room_layout}
              isLabelInside={!isModal}
              choices={choices?.room_layout}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`buildingplanninginformationdetail_set.${index}.dive`}
              rules={rules.buildingplanninginformationdetail_set.dive}
              label={labels.dive}
              isLabelInside={!isModal}
              choices={choices?.buildingplanninginformationdetail_dive}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`buildingplanninginformationdetail_set.${index}.garage`}
              rules={rules.buildingplanninginformationdetail_set.garage}
              label={labels.garage}
              isLabelInside={!isModal}
              choices={choices?.buildingplanninginformationdetail_garage}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`buildingplanninginformationdetail_set.${index}.depth_high_basic`}
              rules={rules.buildingplanninginformationdetail_set.depth_high_basic}
              label={labels.depth_high_basic}
              isLabelInside={!isModal}
              choices={choices?.depth_high_basic}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`buildingplanninginformationdetail_set.${index}.floor_heating`}
              rules={rules.buildingplanninginformationdetail_set.floor_heating}
              label={labels.floor_heating}
              isLabelInside={!isModal}
              choices={choices?.floor_heating}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`buildingplanninginformationdetail_set.${index}.masu_construction`}
              rules={rules.buildingplanninginformationdetail_set.masu_construction}
              label={labels.masu_construction}
              isLabelInside={!isModal}
              choices={choices?.masu_construction}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`buildingplanninginformationdetail_set.${index}.station_payment_burden`}
              rules={rules.buildingplanninginformationdetail_set.station_payment_burden}
              label={labels.station_payment_burden}
              isLabelInside={!isModal}
              choices={choices?.station_payment_burden}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
        </>
      }
    </Grid>
  )
})
BuildingPlanningInformationDetailForm.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  index: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

const BuildingPlanningInformationForm = React.memo(function buildingPlanningInformationForm(props) {
  const { commonInputProps, isModal, rules, labels } = props
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label={labels.neighborhood_demand}
          name={`buildingplanninginformation.neighborhood_demand`}
          rules={rules.buildingplanninginformation.neighborhood_demand}
          disabled={props.disabled}
          multiline
          rows={isModal ? 20 : 5}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label={labels.other_demand}
          name={`buildingplanninginformation.other_demand`}
          rules={rules.buildingplanninginformation.other_demand}
          disabled={props.disabled}
          multiline
          rows={isModal ? 20 : 5}
        />
      </Grid>
    </Grid>
  )
})
BuildingPlanningInformationForm.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
}
