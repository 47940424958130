import { Container as MUIContainer } from "@mui/system";
import PropTypes from "prop-types";

export const Container = ({ ...props }) => {
  return (
    <MUIContainer {...props}>
      {props.children}
    </MUIContainer>
    
  );
};


Container.defaultProps = {
  maxWidth: "xl",
  sx: {},
}

Container.propTypes = {
  children: PropTypes.any,
}