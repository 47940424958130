import * as React from "react";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import { Dialog } from "../molecules/Dialog";
import { Snackbar } from "../atoms/Snackbar";
import * as validationRules from "../../utils/ValidationRules";
import { useForm } from "react-hook-form";
import { RadioGroup } from "../atoms/RadioGruop";
import { FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { Button } from "../atoms/Button";
import { copyProperty } from "../../api/Properties";
export const CopyPropertyDialog = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const {
    control,
    handleSubmit,
    reset,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
  });
  const handleClose = () => {
    reset()
    props.handleClose();
  };

  const submitAction = (data) => {
    setIsSubmitting(true);
    const originId = props.originProperty.id
    data.id = originId
    data.grade = Number(data.grade)
    copyProperty(data)
      .then((response) => {
        props.dataReload(response.data)
        setMessage("作成しました");
        setSeverity("success");
        handleClose();
        reset()
      })
      .catch(() => {
        setMessage("作成に失敗しました");
        setSeverity("error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }
  const rules = {
    grade: {
      ...validationRules.required(),
    }
  }

  const getAreaCodeAndGradeGroup = () => {

    const group = props.areaCodeAndGradeGroup.find((item) => {
      if (item.areacode.value == props.originProperty?.area_code) {
        return item.grades
      }
    })
    return group
  }

  return (
    <>
      <Snackbar
        closeAction={() => {
          setMessage("");
        }}
        message={message}
        severity={severity}
      />
      <Dialog
        isOpen={isOpen}
        title={`[${props.originProperty?.name}] のコピーを作成`}
        handleClose={handleClose}
        content={
          <RadioGroup
            control={control}
            label={
              <Stack direction="row">
                <Typography variant="subtitle1" sx={{color: "red.main"}} component="span">＊</Typography>
                <Typography variant="subtitle1" component="span">PS/LSを選んでください：</Typography>
              </Stack>
            }
            name="grade"
            rules={rules.grade}
            
          >

            <Stack direction={isMobile ? "row" : "column"} spacing={isMobile ? 5 : 0}>
              {getAreaCodeAndGradeGroup()?.grades?.map((grade, gradeIndex) => {
                return (
                  <FormControlLabel
                    value={grade?.value}
                    label={grade?.label}
                    control={<Radio />}
                    key={gradeIndex}
                  />
                );
              })}
            </Stack>
          </RadioGroup>
        }
        actions={
          <>
            <Button color={isMobile ? "gray" : "white"} onClick={handleClose} disabled={isSubmitting} fullSize={isMobile}>
              キャンセル
            </Button>
            <Button color="green" onClick={handleSubmit(submitAction)} disabled={isSubmitting} fullSize={isMobile}>
              作成
            </Button>
          </>
        }
      />
    </>
  );
};

CopyPropertyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  originProperty: PropTypes.object.isRequired,
  areaCodeAndGradeGroup: PropTypes.array.isRequired,
  dataReload: PropTypes.func
};

CopyPropertyDialog.defaultProps = {
  isOpen: false,
  originProperty: {},
  areaCodeAndGradeGroup: [],
  dataReload: () => { }
};
