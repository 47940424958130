import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { Grid, InputAdornment, Paper, Typography } from "@mui/material";
import { TextField } from "../atoms/TextField";
import { SelectForm } from "../atoms/SelectForm";
import { isMobile } from "react-device-detect";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { DialogSubContent } from "../molecules/DialogSubContent";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitContractTerm, useInitJudicialScrivener, useInitTransmissionMatter } from "../../utils/CustomHook";
import { onBlurCommonAction, saveChildForPropertyEdit } from "../../utils/PropertyEdit";

export const TransmissionMatterForms = React.memo(
  function transmissionMatterForms({
    property,
    initialized,
    transmissionmatter,
    judicialscrivener,
    contractterm,
    setOriginPropertyData,
    toggleViewport,
    formPaperVariant,
    formPaperElevation,
    ...props
  }) {
    const [isLoading, setIsLoading] = React.useState(false)
    const loadingComplete = React.useCallback(() => {
      setIsLoading(false)
    }, [])

    const [isOpenTransmissionMatterForm, setIsOpenTransmissionMatterForm] = React.useState(false)
    const {
      control,
      formState: { errors },
      setValue,
      methods,
      setError,
      clearErrors,
    } = useForm({
      shouldFocusError: false,
      criteriaMode: "all",
      defaultValues: "",
      mode: "onChange",
    });

    const [choices, setChoices] = React.useState({});
    const [propertyId, setPropertyId] = React.useState(null)

    const [isFocused, setFocusInputName] = useFocusInput()
    const handleFocus = React.useCallback((name) => {
      setFocusInputName(name)
    }, [])

    useInitJudicialScrivener(judicialscrivener, setValue, isFocused)
    useInitContractTerm(contractterm, setValue, isFocused)
    useInitTransmissionMatter(transmissionmatter, setValue, isFocused)

    React.useEffect(() => {
      setPropertyId(property.id)
    }, [property])

    React.useEffect(() => {
      setChoices(props.choices);
    }, [props.choices]);

    React.useEffect(() => {
      if (!(judicialscrivener && transmissionmatter && contractterm)) {
        return;
      }

      initialized();
    }, [transmissionmatter, judicialscrivener, contractterm]);

    const saveCommonParams = React.useMemo(() => [
      errors, propertyId, clearErrors, setOriginPropertyData, setError
    ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

    const onBlurForTransmissionMatter = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "transmissionmatter",
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
    }, [propertyId]);

    const onBlurForJudicialScrivener = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "judicialscrivener",
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
    }, [propertyId]);

    const onBlurForContractTerm = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "contractterm",
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
    }, [propertyId]);

    const rules = React.useMemo(() => ({
      contractterm: {
        extradition_condition: {
          onBlur: onBlurForContractTerm,
        },
        surveying_condition: {
          onBlur: onBlurForContractTerm,
        },
      },
      transmissionmatter: {
        measurement_office_name: {
          onBlur: onBlurForTransmissionMatter,
        },
        is_grace_period_for_handover: {
          onBlur: onBlurForTransmissionMatter,
        },
        grace_period_for_handover: {
          onBlur: onBlurForTransmissionMatter,
        },
        life_line_estimate_company: {
          onBlur: onBlurForTransmissionMatter,
        },
        construction_estimate_company: {
          onBlur: onBlurForTransmissionMatter,
        },
        retaining_wall_estimate_company: {
          onBlur: onBlurForTransmissionMatter,
        },
        surplus_soils_removal_estimate_company: {
          onBlur: onBlurForTransmissionMatter,
        },
        cut_off_estimate_company: {
          onBlur: onBlurForTransmissionMatter,
        },
        garage_position_estimate_company: {
          onBlur: onBlurForTransmissionMatter,
        },
        boarding_estimate_company: {
          onBlur: onBlurForTransmissionMatter,
        },
        sign_estimate_company: {
          onBlur: onBlurForTransmissionMatter,
        },
        measurement_cost_arrangement: {
          onBlur: onBlurForTransmissionMatter,
        },
        dismantling_estimate_supported: {
          onBlur: onBlurForTransmissionMatter,
        },
        life_line_estimate_supported: {
          onBlur: onBlurForTransmissionMatter,
        },
        construction_estimate_supported: {
          onBlur: onBlurForTransmissionMatter,
        },
        retaining_wall_estimate_supported: {
          onBlur: onBlurForTransmissionMatter,
        },
        surplus_soils_removal_estimate_supported: {
          onBlur: onBlurForTransmissionMatter,
        },
        cut_off_estimate_supported: {
          onBlur: onBlurForTransmissionMatter,
        },
        garage_position_estimate_supported: {
          onBlur: onBlurForTransmissionMatter,
        },
        boarding_estimate_supported: {
          onBlur: onBlurForTransmissionMatter,
        },
        sign_estimate_supported: {
          onBlur: onBlurForTransmissionMatter,
        },
      },
      judicialscrivener: {
        name: {
          onBlur: onBlurForJudicialScrivener,
        },
        registration_specification: {
          onBlur: onBlurForJudicialScrivener,
        },
      },
    }), [onBlurForContractTerm, onBlurForTransmissionMatter, onBlurForJudicialScrivener]);

    const propertyEditFormDialogCloseAction = React.useCallback(() => {
      toggleViewport()
    }, [])
    const formAreaClickedAction = React.useCallback((additionalProcess) => {
      if (!isMobile) {
        return
      }
      if (additionalProcess) {
        additionalProcess()
      }
      setIsLoading(true)
      toggleViewport()
    }, [])

    const commonInputProps = React.useMemo(() => ({
      control: control,
      onFocus: handleFocus,
    }), [control, handleFocus])

    return (
      <PropertyEditForms title="伝達事項">
        <LoadingProgress open={isLoading} useCircular={false} />
        <FormProvider {...methods}>
          <PropertyEditFormDialog
            isOpen={isOpenTransmissionMatterForm}
            handleClose={() => {
              setIsOpenTransmissionMatterForm(false)
              propertyEditFormDialogCloseAction()
            }}
            title="伝達事項"
            openedAction={loadingComplete}
            contentSx={{ px: 0, pt: 0 }}
            content={
              <TransmissionMatterForm
                commonInputProps={commonInputProps}
                rules={rules}
                choices={choices}
                disabled={props.disabled}
                setValue={setValue}
                isModal={true}
              />
            }
          />
          <form>
            <Paper
              elevation={formPaperElevation}
              variant={formPaperVariant}
              onClick={() => formAreaClickedAction(() => setIsOpenTransmissionMatterForm(true))}
            >
              <TransmissionMatterForm
                commonInputProps={commonInputProps}
                rules={rules}
                choices={choices}
                disabled={props.disabled}
                setValue={setValue}
                isModal={false}
              />
            </Paper>
          </form>
        </FormProvider>
      </PropertyEditForms>
    );
  }
);

TransmissionMatterForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  transmissionmatter: PropTypes.object.isRequired,
  judicialscrivener: PropTypes.object.isRequired,
  contractterm: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

TransmissionMatterForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const TransmissionMatterForm = React.memo(function transmissionMatterForm(props) {

  const { commonInputProps, rules, choices, isModal, setValue} = props

  const forms1 = [
    {
      title: "1. 引渡し条件",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "1. 引渡し条件"}
            name="contractterm.extradition_condition"
            rules={rules.contractterm.extradition_condition}
            choices={choices.extradition_condition}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
      </Grid>
    },
    {
      title: "2. 測量",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "2. 測量"}
            name="contractterm.surveying_condition"
            rules={rules.contractterm.surveying_condition}
            choices={choices.surveying_condition}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            {...commonInputProps}
            name="transmissionmatter.measurement_office_name"
            labelPosition="left"
            label="事務所名"
            rules={rules.transmissionmatter.measurement_office_name}
            disabled={props.disabled}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectForm
            {...commonInputProps}
            name="transmissionmatter.measurement_cost_arrangement"
            labelPosition="left"
            label="費用/手配"
            rules={
              rules.transmissionmatter.measurement_cost_arrangement
            }
            choices={choices?.cost_arrangement}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
      </Grid>
    },
  ]

  const forms2 = [
    {
      title: "3. 引渡猶予期間",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={6}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "3. 引渡猶予期間"}
            name="transmissionmatter.is_grace_period_for_handover"
            rules={
              rules.transmissionmatter.is_grace_period_for_handover
            }
            choices={choices?.availability}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...commonInputProps}
            name="transmissionmatter.grace_period_for_handover"
            type="number"
            rules={rules.transmissionmatter.grace_period_for_handover}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">日間</InputAdornment>
              ),
            }}
            disabled={props.disabled}
          />
        </Grid>
      </Grid>
    },
    {
      title: "4. 所有権移転登記",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "4. 所有権移転登記"}
            name="judicialscrivener.registration_specification"
            rules={rules.judicialscrivener.registration_specification}
            choices={choices?.registration_specification}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...commonInputProps}
            name="judicialscrivener.name"
            rules={rules.judicialscrivener.name}
            disabled={props.disabled}
          />
        </Grid>
      </Grid>
    },
    {
      title: "5. 解体",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={isModal ? 12 : 4}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "5. 解体"}
            name="transmissionmatter.dismantling_estimate_supported"
            rules={
              rules.transmissionmatter.dismantling_estimate_supported
            }
            choices={choices?.estimate}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 8}>
          <SelectForm
            {...commonInputProps}
            labelPosition="left"
            label="見積会社"
            name="transmissionmatter.dismantling_estimate_company"
            rules={rules.transmissionmatter.grace_period_for_handover}
            choices={choices?.dismantling_estimate_companies}
            disabled={props.disabled}
            setValue={setValue}
            freeSolo
          />
        </Grid>
      </Grid>
    },
  ]

  const forms3 = [
    {
      title: "6. ライフライン",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={isModal ? 12 : 4}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "6. ライフライン"}
            name="transmissionmatter.life_line_estimate_supported"
            rules={
              rules.transmissionmatter.life_line_estimate_supported
            }
            choices={choices?.estimate}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 8}>
          <SelectForm
            {...commonInputProps}
            name="transmissionmatter.life_line_estimate_company"
            labelPosition="left"
            label="見積会社"
            rules={
              rules.transmissionmatter.life_line_estimate_company
            }
            choices={choices?.life_line_estimate_companies}
            disabled={props.disabled}
            setValue={setValue}
            freeSolo
          />
        </Grid>
      </Grid>
    },
    {
      title: "7. 造成",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={isModal ? 12 : 4}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "7. 造成"}
            name="transmissionmatter.construction_estimate_supported"
            rules={
              rules.transmissionmatter.construction_estimate_supported
            }
            choices={choices?.estimate}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 8}>
          <SelectForm
            {...commonInputProps}
            name="transmissionmatter.construction_estimate_company"
            labelPosition="left"
            label="見積会社"
            rules={
              rules.transmissionmatter.construction_estimate_company
            }
            choices={choices?.construction_estimate_companies}
            disabled={props.disabled}
            setValue={setValue}
            freeSolo
          />
        </Grid>
      </Grid>
    },
    {
      title: "8. 擁壁",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={isModal ? 12 : 4}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "8. 擁壁"}
            name="transmissionmatter.retaining_wall_estimate_supported"
            rules={
              rules.transmissionmatter
                .retaining_wall_estimate_supported
            }
            choices={choices?.estimate}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 8}>
          <TextField
            {...commonInputProps}
            name="transmissionmatter.retaining_wall_estimate_company"
            labelPosition="left"
            label="見積会社"
            rules={
              rules.transmissionmatter.retaining_wall_estimate_company
            }
            disabled={props.disabled}
          />
        </Grid>
      </Grid>
    },
    {
      title: "9. 残土処理",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={isModal ? 12 : 4}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "9. 残土処理"}
            name="transmissionmatter.surplus_soils_removal_estimate_supported"
            rules={
              rules.transmissionmatter
                .surplus_soils_removal_estimate_supported
            }
            choices={choices?.estimate}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 8}>
          <TextField
            {...commonInputProps}
            name="transmissionmatter.surplus_soils_removal_estimate_company"
            labelPosition="left"
            label="見積会社"
            rules={
              rules.transmissionmatter
                .surplus_soils_removal_estimate_company
            }
            disabled={props.disabled}
          />
        </Grid>
      </Grid>
    },
  ]

  const forms4 = [
    {
      title: "10. 切下等",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={isModal ? 12 : 4}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "10. 切下等"}
            name="transmissionmatter.cut_off_estimate_supported"
            rules={
              rules.transmissionmatter.cut_off_estimate_supported
            }
            choices={choices?.estimate}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 8}>
          <TextField
            {...commonInputProps}
            name="transmissionmatter.cut_off_estimate_company"
            labelPosition="left"
            label="見積会社"
            rules={rules.transmissionmatter.cut_off_estimate_company}
            disabled={props.disabled}
          />
        </Grid>
      </Grid>
    },
    {
      title: "11. 車庫位置",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={isModal ? 12 : 4}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "11. 車庫位置"}
            name="transmissionmatter.garage_position_estimate_supported"
            rules={
              rules.transmissionmatter
                .garage_position_estimate_supported
            }
            choices={choices?.confirmation}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 8}>
          <TextField
            {...commonInputProps}
            name="transmissionmatter.garage_position_estimate_company"
            labelPosition="left"
            label="聴取相手"
            rules={
              rules.transmissionmatter
                .garage_position_estimate_company
            }
            disabled={props.disabled}
          />
        </Grid>
      </Grid>
    },
    {
      title: "12. 乗入れ",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={isModal ? 12 : 4}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "12. 乗入れ"}
            name="transmissionmatter.boarding_estimate_supported"
            rules={
              rules.transmissionmatter.boarding_estimate_supported
            }
            choices={choices?.confirmation}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 8}>
          <TextField
            {...commonInputProps}
            labelPosition="left"
            label="聴取相手"
            name="transmissionmatter.boarding_estimate_company"
            rules={rules.transmissionmatter.boarding_estimate_company}
            disabled={props.disabled}
          />
        </Grid>
      </Grid>
    },
    {
      title: "13. 標識",
      inputs: <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={isModal ? 12 : 4}>
          <SelectForm
            {...commonInputProps}
            label={!isModal && "13. 標識"}
            name="transmissionmatter.sign_estimate_supported"
            rules={rules.transmissionmatter.sign_estimate_supported}
            choices={choices?.confirmation}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 8}>
          <TextField
            {...commonInputProps}
            labelPosition="left"
            label="聴取相手"
            name="transmissionmatter.sign_estimate_company"
            rules={rules.transmissionmatter.sign_estimate_company}
            disabled={props.disabled}
          />
        </Grid>
      </Grid>
    },
  ]

  const forms = [
    forms1,
    forms2,
    forms3,
    forms4,
  ]
  return (
    <Grid container direction="row" spacing={1}>
      {
        forms.map((forms, index) => {
          return (
            <Grid item xs={12} lg={3} key={index} container direction="row" spacing={1}>
              {forms.map((item, itemIndex) => {
                return (
                  <Grid item xs={12} key={itemIndex}>
                    {isModal
                      ?
                        <DialogSubContent
                          title={
                            <Typography>
                              {item.title}
                            </Typography>
                          }
                          content={item.inputs}
                        />
                      :
                        item.inputs
                    }
                  </Grid>
                )
              })}
            </Grid>
          )
        })
      }

    </Grid>
  )
})
TransmissionMatterForm.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
}