import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

export const CostFormsHeader = (props) => {

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{justifyContent: "center"}}>
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12} container direction="row" spacing={1}>
        <Grid item xs={3}>
          <Typography sx={{textDecoration: 'underline', justifyContent: "center"}}>
            項目
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={{textDecoration: 'underline', justifyContent: "center"}}>
            金額
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={{textDecoration: 'underline', justifyContent: "center"}}>
            単価
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={{textDecoration: 'underline', justifyContent: "center"}}>
            数量
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

CostFormsHeader.propTypes = {
  title: PropTypes.string.isRequired,
}