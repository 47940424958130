import * as React from "react";
import PropTypes from "prop-types";
import { Button } from "../atoms/Button";
import { DeleteIcon } from "../atoms/DeleteIcon";
import { AddIcon } from "../atoms/AddIcon";
import { CreatePropertyDialog } from "./CreatePropertyDialog";
import { DeletePropertyDialog } from "./DeletePropertyDialog";
import { Grid, Stack, Typography } from "@mui/material";
import { SearchIcon } from "../atoms/SearchIcon";
import { isBrowser, isMobile } from "react-device-detect";
import { PaginationDialog } from "../molecules/PaginationDialog";
import { PropertyTableSearchForms } from "../molecules/PropertyTableSearchForms";
import { PropertyTableSearchDialog } from "../molecules/PropertyTableSearchDialog";
import { TableToolbar } from "../molecules/TableToolbar";

export const MOBILE_TOOLBAR_TYPE = {
  DEFAULT: 1,
  DELETE_CLICKED: 2,
}

export const PropertyTableToolbar = (props) => {
  const [isOpenCreatePropertyDialog, setOpenCreatePropertyDialog] = React.useState(false);
  const [isOpenDeletePropertyDialog, setOpenDeletePropertyDialog] = React.useState(false);
  const [isOpenPaginationDialog, setOpenPaginationDialog] = React.useState(false);
  const [isOpenPropertyTableSearchDialog, setOpenPropertyTableSearchDialog] = React.useState(false);

  const {page, rowsPerPage, lastPage, handleChangePage, rowsPerPageOptions, handleChangeRowsPerPage, itemCount, mobileDeleteIconClickedAction, searchWords, mobileToolbarType} = props
  const isSearched = Object.keys(searchWords).length !== 0

  return (
    <>
      <TableToolbar title="物件一覧">
        {isMobile &&
          <>
            <Grid container direction="row" justifyContent="space-between" alignItems="stretch" spacing={0} sx={{minHeight: "40px"}}>
              {mobileToolbarType == MOBILE_TOOLBAR_TYPE.DEFAULT &&
                <>
                  <Grid item xs={2}>
                    <Button
                      sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, backgroundColor: "green.main", minWidth: "30px" }}
                      variant="text"
                      fullSize={true}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenCreatePropertyDialog(true);
                      }}
                    >
                      <AddIcon sx={{display: "flex"}} color="white" />
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      sx={{ borderRadius: 0, backgroundColor: "pink.main", minWidth: "30px" }}
                      variant="text"
                      fullSize={true}
                      onClick={(e) => {
                        e.stopPropagation();
                        mobileDeleteIconClickedAction()
                      }}
                    >
                      <DeleteIcon sx={{display: "flex"}} color="white" />
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullSize={true}
                      sx={{borderRadius: 0}}
                      onClick={() => {
                        setOpenPaginationDialog(true)
                      }}
                      variant="text"
                    >
                      <Stack sx={{ color: "gray2.main", width: "100%" }}>
                        <Typography sx={{ justifyContent: "center" }}>
                          {page * rowsPerPage - (rowsPerPage - 1)}~{page * rowsPerPage > itemCount ? itemCount : page * rowsPerPage}件
                        </Typography>
                        <Typography fontSize="0.7rem" sx={{ justifyContent: "center" }}>
                          (全 {itemCount}件)
                        </Typography>
                      </Stack>
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0,borderLeft: "1px solid #d3d4dd", backgroundColor: isSearched ? "blue.main" : "gray.main", minWidth: "30px" }}
                      variant="text"
                      fullSize={true}
                      onClick={() => {
                        setOpenPropertyTableSearchDialog(true)
                      }}
                    >
                  <SearchIcon sx={{display: "flex"}} color={isSearched ? "white" : "gray2"} />
                    </Button>
                  </Grid>
                </>
              }
              {mobileToolbarType == MOBILE_TOOLBAR_TYPE.DELETE_CLICKED &&
                <Grid item xs={12}>
                  <Button
                    color="pink"
                    fullSize={true}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenDeletePropertyDialog(true);
                    }}
                  >
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                      <DeleteIcon color="white"/>
                      <Typography>
                        チェックした物件を削除
                      </Typography>
                    </Stack>
                  </Button>
                </Grid>
              }
            </Grid>
            <PaginationDialog
              isOpen={isOpenPaginationDialog}
              page={page}
              rowsPerPage={rowsPerPage}
              lastPage={lastPage}
              rowsPerPageOptions={rowsPerPageOptions}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleClose={() => setOpenPaginationDialog(false)}
            />
            <PropertyTableSearchDialog
              choices={props.choices}
              nowSearchWords={searchWords}
              isOpen={isOpenPropertyTableSearchDialog}
              handleClose={() => setOpenPropertyTableSearchDialog(false)}
              doSearch={props.doSearch}
            />
          </>
        }

        {isBrowser &&
          <>
            <PropertyTableSearchForms
              direction="row"
              choices={props.choices}
              doSearch={props.doSearch}
            />
            <Button
              color="green"
              startIcon={<AddIcon color="white"/>}
              onClick={() => {
                setOpenCreatePropertyDialog(true);
              }}
            >
              <Typography>
                新規作成
              </Typography>
            </Button>
            <Button
              color="pink"
              startIcon={<DeleteIcon color="white" />}
              onClick={() => {
                setOpenDeletePropertyDialog(true);
              }}
            >
              <Typography>
                一括削除
              </Typography>
            </Button>
          </>
        }
      </TableToolbar>
      <CreatePropertyDialog
        choices={props.choices}
        isOpen={isOpenCreatePropertyDialog}
        handleClose={() => setOpenCreatePropertyDialog(false)}
        dataReload={props.dataReload}
      />
      <DeletePropertyDialog
        isOpen={isOpenDeletePropertyDialog}
        handleClose={() => setOpenDeletePropertyDialog(false)}
        dataReload={props.dataReload}
        targetIds={props.tableRowCheckedList}
      />
    </>
  );
};

PropertyTableToolbar.propTypes = {
  choices: PropTypes.object.isRequired,
  dataReload: PropTypes.func,
  doSearch: PropTypes.func.isRequired,
  tableRowCheckedList: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  itemCount: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  mobileDeleteIconClickedAction: PropTypes.func.isRequired,
  searchWords: PropTypes.object.isRequired,
  mobileToolbarType: PropTypes.number.isRequired,
}