import PropTypes from "prop-types";
import { TextField } from "./TextField";
import { styled } from '@mui/material/styles';
import { Box, FormLabel, IconButton, Link, Stack } from "@mui/material";
import { DeleteButton } from "./DeleteButton";
import { Upload } from "@mui/icons-material";
import { DonwloadButton } from "./DownloadButton";
import { Tooltip } from "./Tooltip";

export const FileForm = (props) => {
  const { watch, control, name, color, downloadOnClick, rules, deleteOnClick, label, disabled, accept, uploadTitle, downloadTitle, deleteTitle, isSubmitting } = props;
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const fileName = typeof (watch) === "string" ? watch : ""

  return (
    <>
      <FormLabel>
        {label}
      </FormLabel>
      <Stack direction="row" spacing={1} alignItems="center">
        <Tooltip title={uploadTitle ?? ""}>
          <IconButton
            sx={{
              minWidth: "40px",
              px: 0,
              border: "1.2px solid",
              borderRadius: 2,
            }}
            color={color}
            component="label"
            disabled={disabled || isSubmitting}
          >

            <VisuallyHiddenInput
              type="file"
              accept={accept}
              disabled={disabled || isSubmitting}
              {...rules}
            />
            <Upload />
          </IconButton>
        </Tooltip>
        <DonwloadButton
          onClick={downloadOnClick}
          title={downloadTitle}
          disabled={!watch || isSubmitting}
        />
        <DeleteButton
          onClick={deleteOnClick}
          title={deleteTitle}
          disabled={!watch || isSubmitting}
        />
        <Box component="p" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
          {URL.canParse(fileName) ? <Link target="_blank" href={fileName}>{fileName}</Link> : fileName}
        </Box>
        <TextField
          hidden
          name={name}
          control={control}
        />
      </Stack>
    </>
  );
};

FileForm.defaultProps = {
  sx: {},
  color: "blue",
  uploadTitle: "アップロード",
};

FileForm.propTypes = {
  control: PropTypes.any.isRequired,
  name: PropTypes.string,
  rules: PropTypes.object,
  deleteOnClick: PropTypes.func,
  downloadOnClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  color: PropTypes.string,
  uploadTitle: PropTypes.string,
  downloadTitle: PropTypes.string,
  deleteTitle: PropTypes.string,
  watch: PropTypes.any,
  isSubmitting: PropTypes.bool.isRequired,
};
