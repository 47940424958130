import React from "react";
import PropTypes from "prop-types";
import { TextField } from "../atoms/TextField";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { DatePicker } from "../atoms/DatePicker";
import { DialogSubContent } from "./DialogSubContent";

export const BusinessProcessForm = (props) => {
  const { control, onFocus, disabled, isModal, labels } = props;

  const Inputs = () => (
    <Grid container direction="row" spacing={1} columns={10}>
      <Grid item xs={isModal ? 10 : 2}>
        <TextField
          disabled={true}
          control={control}
          name={props.standardTextFieldName}
          label={isModal && labels.standard}
          onFocus={onFocus}
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">日間</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 5 : 4}>
        <DatePicker
          control={control}
          name={props.startAtName}
          label={isModal && labels.start}
          onFocus={onFocus}
          rules={props.startAtRules}
          defaultValue={null}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={isModal ? 5 : 4}>
        <DatePicker
          control={control}
          name={props.endAtName}
          rules={props.endAtRules}
          onFocus={onFocus}
          label={isModal && labels.end}
          defaultValue={null}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
  return (
    <>
      {
        isModal
        ?
          <DialogSubContent
            title={props.title}
            content={<Inputs/>}
          />
        :
          <Grid container direction="row" spacing={1}>
            <Grid item xs={2}>
              <Typography>{props.title}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Inputs/>
            </Grid>
          </Grid>
      }
    </>
  );
};
BusinessProcessForm.propTypes = {
  standardTextFieldName: PropTypes.string.isRequired,
  startAtName: PropTypes.string.isRequired,
  startAtRules: PropTypes.object.isRequired,
  endAtName: PropTypes.string.isRequired,
  endAtRules: PropTypes.object.isRequired,
  title: PropTypes.string,
  control: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  onFocus: PropTypes.func.isRequired,
};

BusinessProcessForm.defaultProps = {
  standardTextFieldName: {},
  title: "",
};
