import PropTypes from "prop-types";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

export const FileIcon = (props) => {
  return (
    <InsertDriveFileOutlinedIcon
      {...props}
    />
  );
};

FileIcon.defaultProps = {
  color: "white"
};

FileIcon.propTypes = {
  color: PropTypes.string,
}