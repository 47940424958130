import _ from "lodash";
import React from "react";
import { ApiErrorHandler } from "../../api/ApiErrorHandler";
import { getProperty as getPropertyApi } from "../../api/Properties";
import { useParams } from "react-router-dom";
import { PropertyEditLayout } from "../templates/PropertyEditLayout";
import { BusinessBalanceForms } from "../organisms/BusinessBalanceForms";
import { PropertyOverviewForms } from "../organisms/PropertyOverviewForms";
import { SalesPlanForms } from "../organisms/SalesPlanForms";
import { CostForms } from "../organisms/CostForms";
import { TransmissionMatterForms } from "../organisms/TransmissionMatterForms";
import { LesseeStatusForms } from "../organisms/LesseeStatusForms";
import { Grid } from "@mui/material";
import { BusinessProcessForms } from "../organisms/BusinessProcessForms";
import { PrivateRoadAgreementForms } from "../organisms/PrivateRoadAgreementForms";
import { AnnouncementMatterOtherForms } from "../organisms/AnnouncementMatterOtherForms";
import { defaultViewport, getPageByStatus, mobileViewport, PAGES } from "../../utils/Constants";
import { ContractContactForms } from "../organisms/ContractContactForms";
import { ContractAmountForms } from "../organisms/ContractAmountForms";
import { ContractTermForms } from "../organisms/ContractTermForms";
import { OutsourcingReportForms } from "../organisms/OutsourcingReportForms";
import { SettlementConfirmationForms } from "../organisms/SettlementConfirmationForms";
import { PlanningRequestBasicInfoForms } from "../organisms/PlanningRequestBasicInfoForms";
import { SettlementContactForms } from "../organisms/SettlementContactForms";
import PropTypes from "prop-types";
import { isPropertyEditable, hasPlanningRequestPermission } from "../../utils/UserUtil";
import { isMobile } from "react-device-detect";
import { BuildingPlanningInformation } from "../organisms/BuildingPlanningInformationForms";
import { LimitOnLawsAndRegulationsForms } from "../organisms/LimitOnLawsAndRegulationsForms";
import { ValidAreaArrangementForms } from "../organisms/ValidAreaArrangementForms";
import { LifeLineGarbageWalkwayEtcForms } from "../organisms/LifeLineGarbageWalkwayEtcForms";
import { RoadRelationshipForms } from "../organisms/RoadRelationshipForms";
import { SurroundingEnvironmentForms } from "../organisms/SurroundingEnvironmentForms";
import { FieldConstructionForms } from "../organisms/FieldConstructionForms";

export const PropertyEdit = () => {
  const params = useParams();
  const [user, setUser] = React.useState({});
  const [choices, setChoices] = React.useState({});
  const [loadingOpen, setLoadingOpen] = React.useState(true);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [originProperty, setOriginProperty] = React.useState({});

  const [nowPage, setNowPage] = React.useState();

  const [isDisabled, setIsDisabled] = React.useState(true);
  const [hasPRPermission, setHasPlanningRequestPermission] = React.useState(false);

  const useToggleViewport = (initialData) => {
    const [viewport, setViewport] = React.useState(initialData);

    const toggleViewport = React.useCallback(() => {
      setViewport((prevState) => {
        if (!isMobile) {
          return
        }
        const newViewport = prevState === mobileViewport ? defaultViewport : mobileViewport
        return newViewport
      });
    }, []);

    return [viewport, toggleViewport];
  };
  const [viewport, toggleViewport] = useToggleViewport(isMobile ? mobileViewport : defaultViewport)

  const formPaperVariant = isMobile ? "inputTargets" : undefined
  const formPaperElevation = isMobile ? undefined : 0

  React.useEffect(() => {
    const disabled = !isPropertyEditable(originProperty)
    setIsDisabled(disabled)
    const planningRequestPermission = hasPlanningRequestPermission(originProperty)
    setHasPlanningRequestPermission(planningRequestPermission)
  }, [originProperty]);

  const getProperty = (successCallBack, withPlanningRequest = false) => {
    getPropertyApi(params.id, { "with_planning_request": withPlanningRequest })
      .then((response) => {
        if (response.data.user && !_.isEqual(response.data.user, user)) {
          setUser(response.data.user);
        }
        const newProperty = response.data.results;
        setOriginPropertyData(newProperty);

        if (response.data.choices && !_.isEqual(response.data.choices, choices)) {
          setChoices(response.data.choices);
        }
        successCallBack(newProperty);
      })
      .catch((error) => {
        setSnackbarMessage("物件の取得に失敗しました。");
        ApiErrorHandler(error);
      });
  };

  const setOriginPropertyData = React.useCallback((newProperty) => {
    if (newProperty && !_.isEqual(newProperty, originProperty)) {
      setOriginProperty(newProperty);
    }
  }, []);

  React.useEffect(() => {
    getProperty((newProperty) => setNowPageByStatus(newProperty), true);
  }, []);

  const setNowPageByStatus = (newProperty) => {
    const page = getPageByStatus(newProperty.status ?? null)
    setNowPage(page)
  }

  const initialized = React.useCallback((isInitialized) => {
    setLoadingOpen(isInitialized);
  }, []);

  const pageChangedAction = React.useCallback((newPage) => {
    if (newPage !== nowPage) {
      setLoadingOpen(true);
    }
    const withPlanningRequest = newPage === PAGES.PLANNING_REQUEST
    getProperty(() => setNowPage(newPage), withPlanningRequest);
  }, [nowPage]);

  const apiErrorAction = React.useCallback((message) => {
    setSnackbarMessage(message);
  }, []);

  const clearSnackbarMessage = React.useCallback(() => {
    setSnackbarMessage("");
  }, []);

  const getPages = React.useMemo(() => {
    const pages = [
      {
        value: "検討情報",
        isNowpage: nowPage === PAGES.CONSIDERATION_INFORMATION,
        props: {
          onClick: () => {
            if (nowPage !== PAGES.CONSIDERATION_INFORMATION) {
              pageChangedAction(PAGES.CONSIDERATION_INFORMATION);
            }
          },
        },
      },
      {
        value: "契約情報",
        isNowpage: nowPage === PAGES.CONTRACT_INFORMATION,
        props: {
          onClick: () => {
            if (nowPage !== PAGES.CONTRACT_INFORMATION) {
              pageChangedAction(PAGES.CONTRACT_INFORMATION);
            }
          },
        },
      },
      {
        value: "決済情報",
        isNowpage: nowPage === PAGES.SETTLEMENT_INFORMATION,
        props: {
          onClick: () => {
            if (nowPage !== PAGES.SETTLEMENT_INFORMATION) {
              pageChangedAction(PAGES.SETTLEMENT_INFORMATION);
            }
          },
        },
      },
    ];

    const planningRequestPage = {
      value: "企画依頼",
      isNowpage: nowPage === PAGES.PLANNING_REQUEST,
      props: {
        onClick: () => {
          if (nowPage !== PAGES.PLANNING_REQUEST) {
            pageChangedAction(PAGES.PLANNING_REQUEST);
          }
        },
      },
    }

    const latestPage = getPageByStatus(originProperty.status)
    const newPages = pages.slice(0, latestPage)
    if (hasPlanningRequestPermission(originProperty)) {
      newPages.push(planningRequestPage)
    }
    return newPages
  }, [nowPage, originProperty]);

  const formsProps = React.useMemo(() => ({
    originProperty: originProperty,
    choices: choices,
    setOriginPropertyData: setOriginPropertyData,
    initialized: initialized,
    isDisabled: isDisabled,
    toggleViewport: toggleViewport,
    formPaperVariant: formPaperVariant,
    formPaperElevation: formPaperElevation,
  }), [originProperty, choices, setOriginPropertyData, initialized, isDisabled, toggleViewport, formPaperVariant, formPaperElevation])
  return (
    <PropertyEditLayout
      pageTitle="物件編集"
      user={user}
      loadingOpen={loadingOpen}
      property={originProperty}
      choices={choices}
      pages={getPages}
      pageChangedAction={pageChangedAction}
      snackbarMessage={snackbarMessage}
      snackbarCloseAction={clearSnackbarMessage}
      apiErrorAction={apiErrorAction}
      isEdit={!isDisabled}
      toggleViewport={toggleViewport}
      viewport={viewport}
    >
      <Grid container direction="row" spacing={1}>
        {nowPage === PAGES.CONSIDERATION_INFORMATION && (
          <UnderConsiderationForms
            {...formsProps}
          />
        )}
        {nowPage === PAGES.CONTRACT_INFORMATION && (
          <ContractForms
            {...formsProps}
          />
        )}
        {nowPage === PAGES.SETTLEMENT_INFORMATION && (
          <SettlementEndForms
            {...formsProps}
          />
        )}
        {nowPage === PAGES.PLANNING_REQUEST && (
          <PlanningRequestForms
            {...{ ...formsProps, isDisabled: !hasPRPermission }}
          />
        )}
      </Grid>
    </PropertyEditLayout>
  );
};

const UnderConsiderationForms = (props) => {
  const { originProperty, choices, setOriginPropertyData, initialized, isDisabled, toggleViewport, formPaperVariant, formPaperElevation } = props;

  const [property, setProperty] = React.useState({});
  const [announcementmatterother, setAnnouncementMatterOther] = React.useState(
    {}
  );
  const [businessbalance, setBusinessBalance] = React.useState({});
  const [businessprocess, setBusinessProcess] = React.useState({});
  const [costbuildingrelatedcost, setCostBuildingRelatedCost] = React.useState(
    {}
  );
  const [costlandrelatedcost, setCostLandRelatedCost] = React.useState({});
  const [costsalescost, setCostSalesCost] = React.useState({});
  const [contractterm, setContractTerm] = React.useState({});
  const [judicialscrivener, setJudicialScrivener] = React.useState({});
  const [lesseestatus, setLesseeStatus] = React.useState({});
  const [lesseestatusdetail_set, setLesseeStatusDetailSet] = React.useState([]);
  const [privateroadagreement_set, setPrivateRoadAgreementSet] = React.useState([]);
  const [propertyoverview, setPropertyOverview] = React.useState({});
  const [salesplan, setSalesPlan] = React.useState({});
  const [transmissionmatter, setTransmissionMatter] = React.useState({});
  const [companypayeebroker, setCompanyPayeeBroker] = React.useState({});

  const [underConsiderationInitialized, setUnderConsiderationInitialized] =
    React.useReducer((state, newState) => ({ ...state, ...newState }), {
      businessBalanceForms: false,
      businessProcessForms: false,
      propertyOverviewForms: false,
      salesPlanForms: false,
      costForms: false,
      transmissionMatterForms: false,
      lesseeStatusForms: false,
      privateRoadAgreementForms: false,
      announcementMatterOtherForms: false,
    });

  React.useEffect(() => {
    setPropertyData(originProperty);
  }, [originProperty]);

  React.useEffect(() => {
    const notInitialized = Object.keys(underConsiderationInitialized).filter(
      (key) => underConsiderationInitialized[key] === false
    );
    initialized(notInitialized.length !== 0);
  }, [underConsiderationInitialized]);

  const setPropertyData = (newProperty) => {
    const newPropertyData = createPropertyDataForChild(newProperty)
    if (newPropertyData && !_.isEqual(newPropertyData, property)) {
      setProperty(newPropertyData);
    }
    if (
      newProperty.announcementmatterother &&
      !_.isEqual(newProperty.announcementmatterother, announcementmatterother)
    ) {
      setAnnouncementMatterOther(newProperty.announcementmatterother);
    }
    if (
      newProperty.businessbalance &&
      !_.isEqual(newProperty.businessbalance, businessbalance)
    ) {
      setBusinessBalance(newProperty.businessbalance);
    }
    if (
      newProperty.businessprocess &&
      !_.isEqual(newProperty.businessprocess, businessprocess)
    ) {
      setBusinessProcess(newProperty.businessprocess);
    }
    if (
      newProperty.costbuildingrelatedcost &&
      !_.isEqual(newProperty.costbuildingrelatedcost, costbuildingrelatedcost)
    ) {
      setCostBuildingRelatedCost(newProperty.costbuildingrelatedcost);
    }
    if (
      newProperty.costlandrelatedcost &&
      !_.isEqual(newProperty.costlandrelatedcost, costlandrelatedcost)
    ) {
      setCostLandRelatedCost(newProperty.costlandrelatedcost);
    }
    if (
      newProperty.costsalescost &&
      !_.isEqual(newProperty.costsalescost, costsalescost)
    ) {
      setCostSalesCost(newProperty.costsalescost);
    }
    if (
      newProperty.contractterm &&
      !_.isEqual(newProperty.contractterm, contractterm)
    ) {
      setContractTerm(newProperty.contractterm);
    }
    if (
      newProperty.judicialscrivener &&
      !_.isEqual(newProperty.judicialscrivener, judicialscrivener)
    ) {
      setJudicialScrivener(newProperty.judicialscrivener);
    }
    if (
      newProperty.lesseestatus &&
      !_.isEqual(newProperty.lesseestatus, lesseestatus)
    ) {
      setLesseeStatus(newProperty.lesseestatus);
    }
    if (
      newProperty.lesseestatusdetail_set &&
      !_.isEqual(newProperty.lesseestatusdetail_set, lesseestatusdetail_set)
    ) {
      setLesseeStatusDetailSet(newProperty.lesseestatusdetail_set);
    }
    if (
      newProperty.privateroadagreement_set &&
      !_.isEqual(newProperty.privateroadagreement_set, privateroadagreement_set)
    ) {
      setPrivateRoadAgreementSet(newProperty.privateroadagreement_set);
    }
    if (
      newProperty.propertyoverview &&
      !_.isEqual(newProperty.propertyoverview, propertyoverview)
    ) {
      setPropertyOverview(newProperty.propertyoverview);
    }
    if (
      newProperty.salesplan &&
      !_.isEqual(newProperty.salesplan, salesplan)
    ) {
      setSalesPlan(newProperty.salesplan);
    }
    if (
      newProperty.transmissionmatter &&
      !_.isEqual(newProperty.transmissionmatter, transmissionmatter)
    ) {
      setTransmissionMatter(newProperty.transmissionmatter);
    }
    if (
      newProperty.companypayeebroker &&
      !_.isEqual(newProperty.companypayeebroker, companypayeebroker)
    ) {
      setCompanyPayeeBroker(newProperty.companypayeebroker);
    }
  };

  const businessBalanceFormsInitialized = React.useCallback(() => {
    setUnderConsiderationInitialized({ businessBalanceForms: true });
  }, []);
  const propertyOverviewFormsInitialized = React.useCallback(() => {
    setUnderConsiderationInitialized({ propertyOverviewForms: true });
  }, []);
  const salesPlanFormsInitialized = React.useCallback(() => {
    setUnderConsiderationInitialized({ salesPlanForms: true });
  }, []);
  const costFormsInitialized = React.useCallback(() => {
    setUnderConsiderationInitialized({ costForms: true });
  }, []);
  const transmissionMatterFormsInitialized = React.useCallback(() => {
    setUnderConsiderationInitialized({ transmissionMatterForms: true });
  }, []);
  const lesseeStatusFormsInitialized = React.useCallback(() => {
    setUnderConsiderationInitialized({ lesseeStatusForms: true });
  }, []);
  const businessProcessFormsInitialized = React.useCallback(() => {
    setUnderConsiderationInitialized({ businessProcessForms: true });
  }, []);
  const privateRoadAgreementFormsInitialized = React.useCallback(() => {
    setUnderConsiderationInitialized({ privateRoadAgreementForms: true });
  }, []);
  const announcementMatterOtherFormsInitialized = React.useCallback(() => {
    setUnderConsiderationInitialized({ announcementMatterOtherForms: true });
  }, []);

  const commonFormsProps = React.useMemo(() => ({
    property: property,
    choices: choices,
    setOriginPropertyData: setOriginPropertyData,
    disabled: isDisabled,
    toggleViewport: toggleViewport,
    formPaperVariant: formPaperVariant,
    formPaperElevation: formPaperElevation,
  }), [property, choices, setOriginPropertyData, isDisabled, toggleViewport, formPaperVariant, formPaperElevation])

  return (
    <>
      <Grid item xs={12}>
        <BusinessBalanceForms
          initialized={businessBalanceFormsInitialized}
          businessbalance={businessbalance}
          companypayeebroker={companypayeebroker}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <PropertyOverviewForms
          initialized={propertyOverviewFormsInitialized}
          propertyoverview={propertyoverview}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <SalesPlanForms
          initialized={salesPlanFormsInitialized}
          salesplan={salesplan}
          specificationArea={businessbalance.specification_area}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <CostForms
          initialized={costFormsInitialized}
          costlandrelatedcost={costlandrelatedcost}
          costbuildingrelatedcost={costbuildingrelatedcost}
          businessbalance={businessbalance}
          costsalescost={costsalescost}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <TransmissionMatterForms
          initialized={transmissionMatterFormsInitialized}
          transmissionmatter={transmissionmatter}
          judicialscrivener={judicialscrivener}
          contractterm={contractterm}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <LesseeStatusForms
          initialized={lesseeStatusFormsInitialized}
          lesseestatus={lesseestatus}
          lesseestatusdetail_set={lesseestatusdetail_set}
          {...commonFormsProps}
        />
      </Grid>

      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12} lg={6}>
          <BusinessProcessForms
            initialized={businessProcessFormsInitialized}
            businessprocess={businessprocess}
            {...commonFormsProps}
          />
        </Grid>
        <Grid item xs={12} lg={6} container spacing={1} direction="column">
          <Grid item xs={12} lg={5}>
            <PrivateRoadAgreementForms
              initialized={privateRoadAgreementFormsInitialized}
              contractterm={contractterm}
              privateroadagreement_set={privateroadagreement_set}
              {...commonFormsProps}
            />
          </Grid>
          <Grid item xs={12} lg={7}>
            <AnnouncementMatterOtherForms
              initialized={announcementMatterOtherFormsInitialized}
              announcementmatterother={announcementmatterother}
              {...commonFormsProps}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

UnderConsiderationForms.propTypes = {
  originProperty: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  initialized: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

const ContractForms = (props) => {
  const { originProperty, choices, setOriginPropertyData, initialized, isDisabled, toggleViewport, formPaperVariant, formPaperElevation } = props;

  const [property, setProperty] = React.useState({});
  const [companypayeebroker, setCompanyPayeeBroker] = React.useState({});
  const [contractamount, setContractAmount] = React.useState({});
  const [contractcontact, setContractContact] = React.useState({});
  const [contractcontactlandowner_set, setContractContactLandownerSet] =
    React.useState([]);
  const [contractcontactseller_set, setContractContactSeller] = React.useState(
    []
  );
  const [contractterm, setContractTerm] = React.useState({});
  const [judicialscrivener, setJudicialScrivener] = React.useState({});
  const [otherbroker, setOtherBroker] = React.useState({});
  const [outsourcingcompany, setOutsourcingCompany] = React.useState({});
  const [outsourcingreport, setOutsourcingReport] = React.useState({});

  const [contractInitialized, setContractFormInitialized] = React.useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      contractContactForms: false,
      contractAmountForms: false,
      contractContractTermForms: false,
      outsourcingReportForms: false,
    }
  );

  React.useEffect(() => {
    setPropertyData(originProperty);
  }, [originProperty]);

  React.useEffect(() => {
    const notInitialized = Object.keys(contractInitialized).filter(
      (key) => contractInitialized[key] === false
    );
    initialized(notInitialized.length !== 0);
  }, [contractInitialized]);

  const setPropertyData = (newProperty) => {
    const newPropertyData = createPropertyDataForChild(newProperty)
    if (newPropertyData && !_.isEqual(newPropertyData, property)) {
      setProperty(newPropertyData);
    }
    if (
      newProperty.companypayeebroker &&
      !_.isEqual(newProperty.companypayeebroker, companypayeebroker)
    ) {
      setCompanyPayeeBroker(newProperty.companypayeebroker);
    }
    if (
      newProperty.contractamount &&
      !_.isEqual(newProperty.contractamount, contractamount)
    ) {
      setContractAmount(newProperty.contractamount);
    }
    if (
      newProperty.contractcontact &&
      !_.isEqual(newProperty.contractcontact, contractcontact)
    ) {
      setContractContact(newProperty.contractcontact);
    }
    if (
      newProperty.contractcontactlandowner_set &&
      !_.isEqual(
        newProperty.contractcontactlandowner_set,
        contractcontactlandowner_set
      )
    ) {
      setContractContactLandownerSet(newProperty.contractcontactlandowner_set);
    }
    if (
      newProperty.contractcontactseller_set &&
      !_.isEqual(
        newProperty.contractcontactseller_set,
        contractcontactseller_set
      )
    ) {
      setContractContactSeller(newProperty.contractcontactseller_set);
    }
    if (
      newProperty.contractterm &&
      !_.isEqual(newProperty.contractterm, contractterm)
    ) {
      setContractTerm(newProperty.contractterm);
    }
    if (
      newProperty.judicialscrivener &&
      !_.isEqual(newProperty.judicialscrivener, judicialscrivener)
    ) {
      setJudicialScrivener(newProperty.judicialscrivener);
    }
    if (
      newProperty.otherbroker &&
      !_.isEqual(newProperty.otherbroker, otherbroker)
    ) {
      setOtherBroker(newProperty.otherbroker);
    }
    if (
      newProperty.outsourcingcompany &&
      !_.isEqual(newProperty.outsourcingcompany, outsourcingcompany)
    ) {
      setOutsourcingCompany(newProperty.outsourcingcompany);
    }
    if (
      newProperty.outsourcingreport &&
      !_.isEqual(newProperty.outsourcingreport, outsourcingreport)
    ) {
      setOutsourcingReport(newProperty.outsourcingreport);
    }
  };

  const contractContactFormsInitialized = React.useCallback(() => {
    setContractFormInitialized({ contractContactForms: true });
  }, []);
  const contractAmountFormsInitialized = React.useCallback(() => {
    setContractFormInitialized({ contractAmountForms: true });
  }, []);
  const contractTermFormsInitialized = React.useCallback(() => {
    setContractFormInitialized({ contractContractTermForms: true });
  }, []);
  const outsourcingReportFormsInitialized = React.useCallback(() => {
    setContractFormInitialized({ outsourcingReportForms: true });
  }, []);

  const commonFormsProps = React.useMemo(() => ({
    property: property,
    choices: choices,
    setOriginPropertyData: setOriginPropertyData,
    disabled: isDisabled,
    toggleViewport: toggleViewport,
    formPaperVariant: formPaperVariant,
    formPaperElevation: formPaperElevation,
  }), [property, choices, setOriginPropertyData, isDisabled, toggleViewport, formPaperVariant, formPaperElevation])

  return (
    <>
      <Grid item xs={12}>
        <ContractContactForms
          initialized={contractContactFormsInitialized}
          contractcontact={contractcontact}
          contractcontactseller_set={contractcontactseller_set}
          contractcontactlandowner_set={contractcontactlandowner_set}
          companypayeebroker={companypayeebroker}
          otherbroker={otherbroker}
          outsourcingcompany={outsourcingcompany}
          judicialscrivener={judicialscrivener}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <ContractAmountForms
          initialized={contractAmountFormsInitialized}
          contractamount={contractamount}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <ContractTermForms
          initialized={contractTermFormsInitialized}
          contractterm={contractterm}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <OutsourcingReportForms
          initialized={outsourcingReportFormsInitialized}
          outsourcingreport={outsourcingreport}
          {...commonFormsProps}
        />
      </Grid>
    </>
  );
};

ContractForms.propTypes = {
  originProperty: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  initialized: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

const SettlementEndForms = (props) => {
  const { originProperty, choices, setOriginPropertyData, initialized, isDisabled, toggleViewport, formPaperVariant, formPaperElevation } = props;

  const [property, setProperty] = React.useState({});
  const [companypayeebroker, setCompanyPayeeBroker] = React.useState({});
  const [contractcontactlandowner_set, setContractContactLandownerSet] =
    React.useState([]);
  const [contractcontactseller_set, setContractContactSeller] = React.useState(
    []
  );
  const [contractterm, setContractTerm] = React.useState({});
  const [judicialscrivener, setJudicialScrivener] = React.useState({});
  const [outsourcingcompany, setOutsourcingCompany] = React.useState({});
  const [propertyoverview, setPropertyOverview] = React.useState({});
  const [settlementconfirmation, setSettlementConfirmation] = React.useState(
    {}
  );
  const [settlementcontact, setSettlementContact] = React.useState({});
  const [privateroadagreement_set, setPrivateRoadAgreementSet] = React.useState([]);

  const [settlementEndInitialized, setSettlementEndInitialized] =
    React.useReducer((state, newState) => ({ ...state, ...newState }), {
      settlementConfirmationForms: false,
      settlementContactForms: false,
    });

  React.useEffect(() => {
    setPropertyData(originProperty);
  }, [originProperty]);

  React.useEffect(() => {
    const notInitialized = Object.keys(settlementEndInitialized).filter(
      (key) => settlementEndInitialized[key] === false
    );
    initialized(notInitialized.length !== 0);
  }, [settlementEndInitialized]);

  const setPropertyData = (newProperty) => {
    const newPropertyData = createPropertyDataForChild(newProperty)
    if (newPropertyData && !_.isEqual(newPropertyData, property)) {
      setProperty(newPropertyData);
    }
    if (
      newProperty.companypayeebroker &&
      !_.isEqual(newProperty.companypayeebroker, companypayeebroker)
    ) {
      setCompanyPayeeBroker(newProperty.companypayeebroker);
    }
    if (
      newProperty.contractcontactlandowner_set &&
      !_.isEqual(
        newProperty.contractcontactlandowner_set,
        contractcontactlandowner_set
      )
    ) {
      setContractContactLandownerSet(newProperty.contractcontactlandowner_set);
    }
    if (
      newProperty.contractcontactseller_set &&
      !_.isEqual(
        newProperty.contractcontactseller_set,
        contractcontactseller_set
      )
    ) {
      setContractContactSeller(newProperty.contractcontactseller_set);
    }
    if (
      newProperty.contractterm &&
      !_.isEqual(newProperty.contractterm, contractterm)
    ) {
      setContractTerm(newProperty.contractterm);
    }
    if (
      newProperty.judicialscrivener &&
      !_.isEqual(newProperty.judicialscrivener, judicialscrivener)
    ) {
      setJudicialScrivener(newProperty.judicialscrivener);
    }
    if (
      newProperty.outsourcingcompany &&
      !_.isEqual(newProperty.outsourcingcompany, outsourcingcompany)
    ) {
      setOutsourcingCompany(newProperty.outsourcingcompany);
    }
    if (
      newProperty.propertyoverview &&
      !_.isEqual(newProperty.propertyoverview, propertyoverview)
    ) {
      setPropertyOverview(newProperty.propertyoverview);
    }
    if (
      newProperty.settlementconfirmation &&
      !_.isEqual(newProperty.settlementconfirmation, settlementconfirmation)
    ) {
      setSettlementConfirmation(newProperty.settlementconfirmation);
    }
    if (
      newProperty.settlementcontact &&
      !_.isEqual(newProperty.settlementcontact, settlementcontact)
    ) {
      setSettlementContact(newProperty.settlementcontact);
    }
    if (
      newProperty.privateroadagreement_set &&
      !_.isEqual(newProperty.privateroadagreement_set, privateroadagreement_set)
    ) {
      setPrivateRoadAgreementSet(newProperty.privateroadagreement_set);
    }
  };

  const settlementConfirmationFormsInitialized = React.useCallback(() => {
    setSettlementEndInitialized({ settlementConfirmationForms: true });
  }, []);
  const settlementContactFormsInitialized = React.useCallback(() => {
    setSettlementEndInitialized({ settlementContactForms: true });
  }, []);

  const commonFormsProps = React.useMemo(() => ({
    property: property,
    choices: choices,
    setOriginPropertyData: setOriginPropertyData,
    disabled: isDisabled,
    toggleViewport: toggleViewport,
    formPaperVariant: formPaperVariant,
    formPaperElevation: formPaperElevation,
  }), [property, choices, setOriginPropertyData, isDisabled, toggleViewport, formPaperVariant, formPaperElevation])

  return (
    <>
      <Grid item xs={12}>
        <SettlementConfirmationForms
          initialized={settlementConfirmationFormsInitialized}
          settlementconfirmation={settlementconfirmation}
          contractterm={contractterm}
          privateroadagreement_set={privateroadagreement_set}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <SettlementContactForms
          initialized={settlementContactFormsInitialized}
          companypayeebroker={companypayeebroker}
          outsourcingcompany={outsourcingcompany}
          judicialscrivener={judicialscrivener}
          contractcontactseller_set={contractcontactseller_set}
          contractcontactlandowner_set={contractcontactlandowner_set}
          settlementcontact={settlementcontact}
          propertyoverview={propertyoverview}
          contractterm={contractterm}
          {...commonFormsProps}
        />
      </Grid>
    </>
  );
};

SettlementEndForms.propTypes = {
  originProperty: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  initialized: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

const PlanningRequestForms = (props) => {
  const { originProperty, choices, setOriginPropertyData, initialized, isDisabled, toggleViewport, formPaperVariant, formPaperElevation } = props;

  const [property, setProperty] = React.useState({});
  const [planningrequest, setPlanningRequest] = React.useState({});
  const [buildingplanninginformation, setBuildingPlanningInformation] = React.useState({});
  const [buildingplanninginformationdetail_set, setBuildingPlanningInformationDetailSet] = React.useState([]);
  const [limitonlawsandregulations, setLimitOnLawsAndRegulations] = React.useState({});
  const [validareaarrangement, setValidAreaArrangement] = React.useState({});
  const [lifelinegarbagewalkwayetc, setLifeLineGarbageWalkwayEtc] = React.useState({});
  const [roadrelationship, setRoadRelationship] = React.useState({});
  const [surroundingenvironment, setSurroundingEnvironment] = React.useState({});
  const [fieldconstruction, setFieldConstruction] = React.useState({});
  const [businessprocess, setBusinessProcess] = React.useState({});

  const [planningRequestInitialized, setPlanningRequestInitialized] =
    React.useReducer((state, newState) => ({ ...state, ...newState }), {
      planningRequestBasicInfoForms: false,
      buildingPlanningInformationForms: false,
      limitOnLawsAndRegulationsForms: false,
      validAreaArrangementForms: false,
      lifeLineGarbageWalkwayEtcForms: false,
      roadRelationshipForms: false,
      surroundingEnvironmentForms: false,
      fieldConstructionForms: false,
    });

  React.useEffect(() => {
    setPropertyData(originProperty);
  }, [originProperty]);

  React.useEffect(() => {
    const notInitialized = Object.keys(planningRequestInitialized).filter(
      (key) => planningRequestInitialized[key] === false
    );
    initialized(notInitialized.length !== 0);
  }, [planningRequestInitialized]);

  const setPropertyData = (newProperty) => {
    const newPropertyData = createPropertyDataForChild(newProperty)
    if (newPropertyData && !_.isEqual(newPropertyData, property)) {
      setProperty(newPropertyData);
    }
    if (
      newProperty.planningrequest &&
      !_.isEqual(newProperty.planningrequest, planningrequest)
    ) {
      setPlanningRequest(newProperty.planningrequest);
    }
    if (
      newProperty.buildingplanninginformation &&
      !_.isEqual(newProperty.buildingplanninginformation, buildingplanninginformation)
    ) {
      setBuildingPlanningInformation(newProperty.buildingplanninginformation);
    }
    if (
      newProperty.buildingplanninginformationdetail_set &&
      !_.isEqual(newProperty.buildingplanninginformationdetail_set, buildingplanninginformationdetail_set)
    ) {
      setBuildingPlanningInformationDetailSet(newProperty.buildingplanninginformationdetail_set);
    }
    if (
      newProperty.limitonlawsandregulations &&
      !_.isEqual(newProperty.limitonlawsandregulations, limitonlawsandregulations)
    ) {
      setLimitOnLawsAndRegulations(newProperty.limitonlawsandregulations);
    }
    if (
      newProperty.validareaarrangement &&
      !_.isEqual(newProperty.validareaarrangement, validareaarrangement)
    ) {
      setValidAreaArrangement(newProperty.validareaarrangement);
    }
    if (
      newProperty.lifelinegarbagewalkwayetc &&
      !_.isEqual(newProperty.lifelinegarbagewalkwayetc, lifelinegarbagewalkwayetc)
    ) {
      setLifeLineGarbageWalkwayEtc(newProperty.lifelinegarbagewalkwayetc);
    }
    if (
      newProperty.roadrelationship &&
      !_.isEqual(newProperty.roadrelationship, roadrelationship)
    ) {
      setRoadRelationship(newProperty.roadrelationship);
    }
    if (
      newProperty.surroundingenvironment &&
      !_.isEqual(newProperty.surroundingenvironment, surroundingenvironment)
    ) {
      setSurroundingEnvironment(newProperty.surroundingenvironment);
    }
    if (
      newProperty.fieldconstruction &&
      !_.isEqual(newProperty.fieldconstruction, fieldconstruction)
    ) {
      setFieldConstruction(newProperty.fieldconstruction);
    }
    if (
      newProperty.businessprocess &&
      !_.isEqual(newProperty.businessprocess, businessprocess)
    ) {
      setBusinessProcess(newProperty.businessprocess);
    }
  };

  const planningRequestBasicInfoFormsInitialized = React.useCallback(() => {
    setPlanningRequestInitialized({ planningRequestBasicInfoForms: true });
  }, []);

  const buildingPlanningInformationInitialized = React.useCallback(() => {
    setPlanningRequestInitialized({ buildingPlanningInformationForms: true });
  }, []);

  const limitOnLawsAndRegulationsInitialized = React.useCallback(() => {
    setPlanningRequestInitialized({ limitOnLawsAndRegulationsForms: true });
  }, []);

  const validAreaArrangementInitialized = React.useCallback(() => {
    setPlanningRequestInitialized({ validAreaArrangementForms: true });
  }, []);

  const lifeLineGarbageWalkwayEtcInitialized = React.useCallback(() => {
    setPlanningRequestInitialized({ lifeLineGarbageWalkwayEtcForms: true });
  }, []);

  const roadRelationshipInitialized = React.useCallback(() => {
    setPlanningRequestInitialized({ roadRelationshipForms: true });
  }, []);

  const surroundingEnvironmentInitialized = React.useCallback(() => {
    setPlanningRequestInitialized({ surroundingEnvironmentForms: true });
  }, []);

  const fieldConstructionInitialized = React.useCallback(() => {
    setPlanningRequestInitialized({ fieldConstructionForms: true });
  }, []);

  const commonFormsProps = React.useMemo(() => ({
    property: property,
    choices: choices,
    setOriginPropertyData: setOriginPropertyData,
    disabled: isDisabled,
    toggleViewport: toggleViewport,
    formPaperVariant: formPaperVariant,
    formPaperElevation: formPaperElevation,
  }), [property, choices, setOriginPropertyData, isDisabled, toggleViewport, formPaperVariant, formPaperElevation])
  return (
    <>
      <Grid item xs={12}>
        <PlanningRequestBasicInfoForms
          initialized={planningRequestBasicInfoFormsInitialized}
          planningrequest={planningrequest}
          businessprocess={businessprocess}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <BuildingPlanningInformation
          initialized={buildingPlanningInformationInitialized}
          buildingplanninginformation={buildingplanninginformation}
          buildingplanninginformationdetail_set={buildingplanninginformationdetail_set}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <LimitOnLawsAndRegulationsForms
          initialized={limitOnLawsAndRegulationsInitialized}
          limitonlawsandregulations={limitonlawsandregulations}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <ValidAreaArrangementForms
          initialized={validAreaArrangementInitialized}
          validareaarrangement={validareaarrangement}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <LifeLineGarbageWalkwayEtcForms
          initialized={lifeLineGarbageWalkwayEtcInitialized}
          lifelinegarbagewalkwayetc={lifelinegarbagewalkwayetc}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <RoadRelationshipForms
          initialized={roadRelationshipInitialized}
          roadrelationship={roadrelationship}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <SurroundingEnvironmentForms
          initialized={surroundingEnvironmentInitialized}
          surroundingenvironment={surroundingenvironment}
          {...commonFormsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <FieldConstructionForms
          initialized={fieldConstructionInitialized}
          fieldconstruction={fieldconstruction}
          {...commonFormsProps}
        />
      </Grid>
    </>
  );
};

PlanningRequestForms.propTypes = {
  originProperty: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  initialized: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};


const createPropertyDataForChild = (newProperty) => {
  const newPropertyData = {
    area_code: newProperty.area_code,
    area_code_display: newProperty.area_code_display,
    created_at: newProperty.created_at,
    created_user: newProperty.created_user,
    owner_user: newProperty.owner_user,
    owner_user_name: newProperty.owner_user_name,
    propertyshare_set: newProperty.propertyshare_set,
    editing_user: newProperty.editing_user,
    grade: newProperty.grade,
    grade_display: newProperty.grade_display,
    id: newProperty.id,
    name: newProperty.name,
    status: newProperty.status,
    MAX_ROW_CONTRACT_CONTACT_SELLER: newProperty.MAX_ROW_CONTRACT_CONTACT_SELLER,
    MAX_ROW_CONTRACT_CONTACT_LANDOWNER: newProperty.MAX_ROW_CONTRACT_CONTACT_LANDOWNER,
    MAX_ROW_PRIVATE_ROAD_AGREEMENT: newProperty.MAX_ROW_PRIVATE_ROAD_AGREEMENT,
    is_areacode_capital_area: newProperty.is_areacode_capital_area,
    is_areacode_nagoya: newProperty.is_areacode_nagoya,
    is_areacode_fukuoka: newProperty.is_areacode_fukuoka,
    is_areacode_kansai: newProperty.is_areacode_kansai,
    is_areacode_gunma: newProperty.is_areacode_gunma,
    is_grade_ls: newProperty.is_grade_ls,
    is_grade_ps: newProperty.is_grade_ps,
    is_grade_ls_plus: newProperty.is_grade_ls_plus,
    is_editable: newProperty.is_editable,
  };
  return newPropertyData
}