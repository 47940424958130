import PropTypes from "prop-types";
import { DeleteIcon } from "./DeleteIcon";
import { IconButton } from "@mui/material";
import { Tooltip } from "./Tooltip";

export const DeleteButton = (props) => {
  const { title, sx, color, disabled, onClick, ...buttonProps } = props;
  return (
    <Tooltip title={disabled ? "" : (title ?? "")}>
      <IconButton
        sx={{
          minWidth: "40px",
          px: 0,
          border: "1.2px solid",
          borderRadius: 2,
          ...sx,
        }}
        color={color}
        onClick={disabled ? null : onClick}
        disabled={disabled}
        {...buttonProps}
      >
        <DeleteIcon color={disabled ? "inherit" : color} />
      </IconButton>
    </Tooltip>
  );
};

DeleteButton.defaultProps = {
  sx: {},
  color: "darkRed",
  title: "削除",
  disabled: false
};

DeleteButton.propTypes = {
  sx: PropTypes.object,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};
