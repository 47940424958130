import { Box, Stack, Typography } from "@mui/material";
import { LoginLayout } from "../templates/LoginLayout";
import React from "react";
import { FrontendUrls } from "../Urls";
import { backendLogin } from "../../api/Auth";
import { Snackbar } from "../atoms/Snackbar";
import { LogoIcon } from "../atoms/LogoIcon";

export const Login = () => {
  const [errorMessage, setErrorMessage] = React.useState("");

  const clientId = process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_OAUTH2_CLIENT_ID

  React.useLayoutEffect(() => {
    window.handleCredentialResponse = handleCredentialResponse;
  }, []);

  const handleCredentialResponse = (params) => {
    doLogin(params)
  };

  const doLogin = (params) => {
    backendLogin(params)
      .then(() => {
        window.open(FrontendUrls.properties, "_self");
      })
      .catch(() => {
        setErrorMessage("認証エラーが発生しました。");
      });
  }

  return (
    <LoginLayout
      pageTitle="ログイン"
    >
      <Snackbar
        closeAction={() => {
          setErrorMessage("");
        }}
        message={errorMessage}
        severity={"error"}
      />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ minHeight: "100vh", my: 0, py: 0 }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <LogoIcon sx={{ height: "100%", width: "50px" }} />
          <Typography component="span" variant="h3">物件計画書</Typography>
        </Stack>
        <Box
          id="g_id_onload"
          data-client_id={clientId}
          data-callback="handleCredentialResponse"
          data-use_fedcm_for_prompt="true"
        />
        <Box
          className="g_id_signin"
          data-type="standard"
          data-size="large"
          data-theme="filled_blue"
          data-text="signin"
          data-shape="rectangular"
          data-logo_alignment="left"
          data-width="220"
        />
      </Stack>
    </LoginLayout>
  );
};
