import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import {ReactComponent as icon} from "../../assets/images/HamburgerMenu.svg";

export const HamburgerMenuIcon = (props) => {
  return (
    <SvgIcon
      component={icon}
      viewBox="0 0 16 12"
      {...props}
    />
  );
};

HamburgerMenuIcon.defaultProps = {
  color: "gray2"
};

HamburgerMenuIcon.propTypes = {
  color: PropTypes.string,
}