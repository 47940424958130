import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from "@mui/material";
import PropTypes from "prop-types";

export const Breadcrumbs = (props) => {
  return (
    <MUIBreadcrumbs separator={props.separator}>
      {props.items.map((item, index) => {
        if (item.isNowpage) {
          return (
            <Typography  key={index} variant="h5" color="primary" sx={{ textDecoration: 'underline', textUnderlineOffset: "0.4em" }}>
              {item.value}
            </Typography>
          )
        } else {
          return (
            <Link href="#" color="inherit" underline="none" key={index}>
              <Typography variant="h5" color="inherit" {...item.props}>
                {item.value}
              </Typography>
            </Link>
          )
        }
      })}
    </MUIBreadcrumbs>
  )
};

Breadcrumbs.defaultProps = {
  separator: "",
  items: [],
};

Breadcrumbs.propTypes = {
  children: PropTypes.any,
  separator: PropTypes.string,
  items: PropTypes.array.isRequired,
}