import * as React from "react";
import PropTypes from "prop-types";

import Alert from "@mui/material/Alert";
import MUISnackbar from "@mui/material/Snackbar";

export const Snackbar = (props) => {
  const [state] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const [message, setMessage] = React.useState("");
  const severity = props.severity;

  React.useEffect(() => {
    if (props.message) {
      setMessage(props.message);
    }
  }, [props.message]);

  const { vertical, horizontal } = state;

  const closeAction = props.closeAction ? props.closeAction : () => { };
  const handleClose = () => {
    closeAction();
  };

  return (
    <div>
      <MUISnackbar
        anchorOrigin={{ vertical, horizontal }}
        open={props.message !== ""}
        onClose={handleClose}
        key={vertical + horizontal}
        autoHideDuration={props.autoHideDuration}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </MUISnackbar>
    </div>
  );
};

Snackbar.propTypes = {
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  closeAction: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number,
};

Snackbar.defaultProps = {
  severity: "error",
  autoHideDuration: 5000,
};
