import React from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Grid, Paper } from "@mui/material";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { isMobile } from "react-device-detect";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useFieldConstruction } from "../../utils/CustomHook";
import { onBlurCommonAction, saveChildForPropertyEdit } from "../../utils/PropertyEdit";
import { SelectForm } from "../atoms/SelectForm";
import { PropertyEditForms } from "../molecules/PropertyEditForms";


export const FieldConstructionForms = React.memo(
  function fieldConstructionForms({
    property,
    initialized,
    fieldconstruction,
    setOriginPropertyData,
    toggleViewport,
    formPaperVariant,
    formPaperElevation,
    ...props
  }) {

    const [choices, setChoices] = React.useState({});
    React.useEffect(() => {
      setChoices(props.choices);
    }, [props.choices]);

    const [isLoading, setIsLoading] = React.useState(false)
    const loadingComplete = React.useCallback(() => {
      setIsLoading(false)
    }, [])

    const [isOpenModal, setIsOpenModal] = React.useState(false)
    const [propertyId, setPropertyId] = React.useState(null)

    const [isFocused, setFocusInputName] = useFocusInput()
    const handleFocus = React.useCallback((name) => {
      setFocusInputName(name)
    }, [])

    const {
      control,
      formState: { errors },
      setValue,
      methods,
      setError,
      clearErrors,
    } = useForm({
      shouldFocusError: false,
      criteriaMode: "all",
      defaultValues: "",
      mode: "onChange",
    });

    useFieldConstruction(fieldconstruction, setValue, isFocused)

    React.useEffect(() => {
      setPropertyId(property.id)
    }, [property])

    React.useEffect(() => {
      if (!fieldconstruction) {
        return;
      }
      initialized();
    }, [fieldconstruction]);

    const saveCommonParams = React.useMemo(() => [
      errors, propertyId, clearErrors, setOriginPropertyData, setError
    ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

    const onBlurForFieldConstruction = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "fieldconstruction",
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
    }, [propertyId]);

    const rules = React.useMemo(() => ({
      fieldconstruction: {
        little_transportation: {
          onBlur: onBlurForFieldConstruction,
        },
        hand_carrying: {
          onBlur: onBlurForFieldConstruction,
        },
        recession_maintenance: {
          onBlur: onBlurForFieldConstruction,
        },
        earth_retaining_in_area: {
          onBlur: onBlurForFieldConstruction,
        },
        u_shaped_gutter_lid_maintenance: {
          onBlur: onBlurForFieldConstruction,
        },
        kbm_risk_of_loss: {
          onBlur: onBlurForFieldConstruction,
        },
        field_water_faucet_existence: {
          onBlur: onBlurForFieldConstruction,
        },
        sewage_trout_recession_existence: {
          onBlur: onBlurForFieldConstruction,
        },
        memo: {
          onBlur: onBlurForFieldConstruction,
        },
      },
    }), [onBlurForFieldConstruction]);

    const propertyEditFormDialogCloseAction = React.useCallback(() => {
      toggleViewport()
    }, [])

    const formAreaClickedAction = React.useCallback((additionalProcess) => {
      if (!isMobile) {
        return
      }
      if (additionalProcess) {
        additionalProcess()
      }
      setIsLoading(true)
      toggleViewport()
    }, [])

    const commonInputProps = React.useMemo(() => ({
      control: control,
      onFocus: handleFocus,
    }), [control, handleFocus])

    const pageTitle = "現場工事"
    return (
      <PropertyEditForms title={pageTitle} >
        <LoadingProgress open={isLoading} useCircular={false} />
        <FormProvider {...methods}>
          <PropertyEditFormDialog
            isOpen={isOpenModal}
            handleClose={() => {
              setIsOpenModal(false)
              propertyEditFormDialogCloseAction()
            }}
            title={pageTitle}
            openedAction={loadingComplete}
            content={
              <Forms
                commonInputProps={commonInputProps}
                rules={rules}
                isModal={true}
                disabled={props.disabled}
                setValue={setValue}
                choices={choices}
              />
            }
          />
          <form>
            <Paper
              elevation={formPaperElevation}
              variant={formPaperVariant}
              onClick={() => formAreaClickedAction(() => setIsOpenModal(true))}
            >
              <Forms
                commonInputProps={commonInputProps}
                rules={rules}
                isModal={false}
                disabled={props.disabled}
                setValue={setValue}
                choices={choices}
              />
            </Paper>
          </form>
        </FormProvider>
      </PropertyEditForms>
    );
  }
);

FieldConstructionForms.propTypes = {
  property: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  fieldconstruction: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
  choices: PropTypes.object.isRequired,
};

FieldConstructionForms.defaultProps = {
  property: {},
  disabled: false,
};


const Forms = React.memo(function forms(props) {
  const { commonInputProps, rules, isModal, disabled, choices, setValue } = props

  const columns = 30
  const formList = React.useMemo(() => [
    {
      label: "小運搬",
      property_name: "little_transportation",
      pcWidth: 6,
      choices: choices.planning_request_availability,
    },
    {
      label: "手運び",
      property_name: "hand_carrying",
      pcWidth: 6,
      choices: choices.planning_request_availability,
    },
    {
      label: "後退整備",
      property_name: "recession_maintenance",
      pcWidth: 6,
      choices: choices.recession_maintenance,
    },
    {
      label: "区画内土留",
      property_name: "earth_retaining_in_area",
      pcWidth: 6,
      choices: choices.ohd_availability,
    },
    {
      label: "U字溝フタ整備",
      property_name: "u_shaped_gutter_lid_maintenance",
      pcWidth: 6,
      choices: choices.ohd_availability,
    },
    {
      label: "KBM亡失リスク",
      property_name: "kbm_risk_of_loss",
      pcWidth: 6,
      choices: choices.planning_request_availability,
    },
    {
      label: "現場水栓有無",
      property_name: "field_water_faucet_existence",
      pcWidth: 6,
      choices: choices.field_water_faucet_existence,
    },
    {
      label: "下水マス後退有無",
      property_name: "sewage_trout_recession_existence",
      pcWidth: 6,
      choices: choices.sewage_trout_recession_existence,
    },
  ], [choices])

  return (
    <Grid container direction="row" spacing={1} columns={columns} alignItems="flex-end">
      {formList.map((item, index) => (
        <Grid key={index} item xs={isModal ? columns : item.pcWidth}>
          <SelectForm
            {...commonInputProps}
            name={`fieldconstruction.${item.property_name}`}
            rules={rules.fieldconstruction[item.property_name]}
            label={item.label}
            choices={item.choices}
            disabled={disabled}
            setValue={setValue}
          />
        </Grid>
      ))}

      <Grid item xs={columns}>
        <TextField
          {...commonInputProps}
          name={"fieldconstruction.memo"}
          rules={rules.fieldconstruction.memo}
          label="メモ"
          multiline
          rows={isModal ? 20 : 5}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
})

Forms.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
}