import { SvgIcon } from "@mui/material";
import {ReactComponent as icon} from "../../assets/images/Logo.svg";

export const LogoIcon = (props) => {
  return (
    <SvgIcon
      component={icon}
      viewBox="0 0 53 45"
      {...props}
    />
  );
};
