import React from "react";
import PropTypes from "prop-types";
import { Toolbar } from "../atoms/Toolbar";
import { PropertyEditStatus } from "../molecules/PropertyEditStatus";
import { PropertyEditMenus } from "../molecules/PropertyEditMenus";

export const PropertyEditToolbar = (props) => {
  const property = props.property;
  const choices = props.choices;
  const pageChangedAction = props.pageChangedAction;
  const apiErrorAction = props.apiErrorAction;


  return (
    <Toolbar>
      <PropertyEditStatus
        property={property}
        choices={choices}
        pageChangedAction={pageChangedAction}
        apiErrorAction={apiErrorAction}
      />
      <PropertyEditMenus
        property={property}
        choices={choices}
      />
    </Toolbar>
  );
};

PropertyEditToolbar.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  pageChangedAction: PropTypes.func,
  apiErrorAction: PropTypes.func,
};

PropertyEditToolbar.defaultProps = {
  property: {},
  choices: {},
  pageChangedAction: () => {},
  apiErrorAction: () => {},
};

