import React from "react";
import PropTypes from "prop-types";
import { Button } from "../atoms/Button";
import { Stack } from "@mui/material";
import { PrinterIcon } from "../atoms/PrinterIcon";
import { MailIcon } from "../atoms/MailIcon";
import { ShareIcon } from "../atoms/ShareIcon";
import { FileIcon } from "../atoms/FileIcon";
import { hasPropertyEditPermission } from "../../utils/UserUtil";
import { PdfDialog } from "../organisms/PdfDialog";
import { PrintDialog } from "../organisms/PrintDialog";
import { ExcelDialog } from "../organisms/ExcelDialog";
import { PropertyShareDialog } from "../organisms/PropertyShareDialog";
import { isBrowser, isMobile } from "react-device-detect";
import { ButtonAndMenu } from "./ButtonAndMenu";
import { HamburgerMenuIcon } from "../atoms/HamburgerMenuIcon";

export const PropertyEditMenus = (props) => {
  const property = props.property;
  const choices = props.choices;

  const [openPdfDialog, setOpenPdfDialog] = React.useState(false);
  const [openPrintDialog, setOpenPrintDialog] = React.useState(false);
  const [openPlanningRequestPrintDialog, setOpenPlanningRequestPrintDialog] = React.useState(false);
  const [openExcelDialog, setOpenExcelDialog] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);

  // 共有可否
  const is_share = hasPropertyEditPermission(property);

  const menus = React.useMemo(() => {
    const data = []
    if (is_share) {
      data.push({
        key: "share",
        label: "共有",
        startIcon: <ShareIcon />,
        color: "blue",
        onClick: () => {
          setOpenShare(true)
          props.toggleViewport()
        },
      })
    }
    data.push(
      {
        key: "mail",
        label: "メールにPDF送信",
        startIcon: <MailIcon />,
        color: "brown",
        onClick: () => {
          setOpenPdfDialog(true);
          props.toggleViewport()
        },
      },
      {
        key: "print",
        label: "印刷",
        startIcon: <PrinterIcon />,
        color: "emeraldGreen",
        onClick: () => {
          setOpenPrintDialog(true);
          props.toggleViewport()
        },
      },
    )
    if (Array.isArray(choices.export_pdf_sheet_names_for_planning_request_drive) && choices.export_pdf_sheet_names_for_planning_request_drive.length) {
      data.push(
        {
          key: "print_planning_request_drive",
          label: "企画依頼書印刷",
          startIcon: <PrinterIcon />,
          color: "emeraldGreen",
          onClick: () => {
            setOpenPlanningRequestPrintDialog(true);
            props.toggleViewport()
          },
        }
      )
    }
    data.push(
      {
        key: "excel",
        label: "Excel出力",
        startIcon: <FileIcon />,
        color: "green",
        onClick: () => {
          setOpenExcelDialog(true);
          props.toggleViewport()
        },
      }
    )
    return data
  }, [props.toggleViewport, choices, is_share])

  return (
    <>
      {isMobile &&
        <ButtonAndMenu
          buttonName={<HamburgerMenuIcon />}
          menus={menus}
        />
      }

      {isBrowser &&
        <Stack spacing={2} direction="row" justifyContent={"flex-end"} sx={{ flexGrow: 1 }}>
          {menus.map((item) => {
            return (
              <Button
                key={item.key}
                startIcon={item.startIcon}
                color={item.color}
                onClick={item.onClick}
              >
                {item.label}
              </Button>
            )

          })}
        </Stack>
      }

      <PropertyShareDialog
        open={openShare}
        property_id={property.id ?? 0}
        propertyShare={property.propertyshare_set ?? []}
        choices={choices.property_share ?? {}}
        onClose={() => {
          setOpenShare(false)
          props.toggleViewport()
        }}
      />
      <PdfDialog
        open={openPdfDialog}
        property_id={property.id ?? 0}
        causeCanNotSelectSheet={property.cause_can_not_create_pdf}
        choices={choices.export_pdf_sheet_names ?? []}
        onClose={() => {
          setOpenPdfDialog(false)
          props.toggleViewport()
        }}
      />
      <PrintDialog
        open={openPrintDialog}
        property_id={property.id ?? 0}
        causeCanNotSelectSheet={property.cause_can_not_create_pdf}
        choices={choices.export_pdf_sheet_names ?? []}
        onClose={() => {
          setOpenPrintDialog(false)
          props.toggleViewport()
        }}
      />
      <PrintDialog
        open={openPlanningRequestPrintDialog}
        property_id={property.id ?? 0}
        choices={choices.export_pdf_sheet_names_for_planning_request_drive ?? []}
        onClose={() => {
          setOpenPlanningRequestPrintDialog(false)
          props.toggleViewport()
        }}
      />
      <ExcelDialog
        open={openExcelDialog}
        property_id={property.id ?? 0}
        causeCanNotSelectSheet={property.cause_can_not_create_excel}
        choices={choices.export_excel_sheet_names ?? []}
        onClose={() => {
          setOpenExcelDialog(false)
          props.toggleViewport()
        }}
      />
    </>
  )
}

PropertyEditMenus.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  toggleViewport: PropTypes.func,
};

PropertyEditMenus.defaultProps = {
  toggleViewport: () => { },
};
