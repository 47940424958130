import * as React from "react";
import PropTypes from "prop-types";
import * as validationRules from "../../utils/ValidationRules";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "../atoms/TableCell";
import { EnhancedTableHead } from "../molecules/EnhancedTableHead";
import { IconButton, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { HiddenInput } from "../atoms/HiddenInput";
import { CheckBox } from "../atoms/CheckBox";
import { TablePageChanger } from "../molecules/TablePageChanger";
import { TableRowsPerPageChanger } from "../molecules/TableRowsPerPageChanger";

export default function EnhancedTable(props) {
  const headCells = props.headCells ? props.headCells : [];
  const dense = props.dense;

  const [selected] = React.useState([]);
  const { rows, rowsPerPage, order, orderBy } = props;
  const page = props.page;
  const firstPage = 1;

  const {
    control,
    setValue,
    getValues,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange"
  });

  const {
    fields: rowFields,
    replace: rowReplace,
  } = useFieldArray({ control, name: "row" });

  React.useEffect(() => {
    const data = []
    rows.forEach((item) => {
      data.push({
        id: item.id,
        checked: false,
      })
    })
    if (rowFields.length > 0) {
      data.forEach((item, index) => {
        setValue(`row.${index}`, item)
      })
    } else {
      rowReplace(data);
    }
  }, [rows])

  const handleRequestSort = (event, newOrderBy, newOrder) => {
    props.handleRequestSort(newOrderBy, newOrder);
  };

  const handleSelectAllClick = (event) => {
    rowFields.forEach((item, index) => {
      setValue(`row.${index}.checked`, event.target.value)
    })
    handleClick()
  };
  
  const handleClick = () => {
    const rowValues = getValues("row")
    const ids = rowValues.flatMap((item) => item.checked ? [item.id] : [])
    props.checkBoxClickedAction(ids)
  }

  const Pagination = () => {
    return (
      <>
        {props.usePagination &&
          <Stack direction="row" alignItems="center" sx={{ overflow: "auto", borderRight: "1px solid #dedfe6", borderLeft: "1px solid #dedfe6", borderBottom: "1px solid #dedfe6" }}>
            <Stack direction="row" sx={{ height: "100%", px: 2, py: 1 }}>
              <Typography>
                {page * rowsPerPage - (rowsPerPage - 1)}~{page * rowsPerPage > props.itemCount ? props.itemCount : page * rowsPerPage}件 (全 {props.itemCount}件)
              </Typography>
            </Stack>

            <TableRowsPerPageChanger
              rowsPerPage={rowsPerPage}
              sx={{ height: "100%", borderLeft: "1px solid #dedfe6", px: 2, py: 1 }}
              rowsPerPageOptions={props.rowsPerPageOptions}
              handleChangeRowsPerPage={props.handleChangeRowsPerPage}
            />
            <TablePageChanger
              page={page}
              firstPage={firstPage}
              lastPage={props.lastPage}
              handleChangePage={props.handleChangePage}
              sx={{ height: "100%", flexGrow: 1, justifyContent: "right", px: 2, py: 1 }}
            />
          </Stack>
        }
      </>
    )
  }

  const isSelected = (key) => selected.indexOf(key) !== -1;

  const rules = {
    page: {
      ...validationRules.min(firstPage),
      ...validationRules.max(props.lastPage),
    },
    row: {
      onChange: handleClick,
    },
  }

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            control={control}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            tableButtons={props.tableButtons}
            includeCheckBox={props.includeCheckBox}
          />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${row.id}`;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{background: row.color ?? "inherit"}}
                >
                  {props.includeCheckBox &&
                    <TableCell padding="checkbox">
                      <HiddenInput
                        control={control}
                        name={`row.${index}.id`}
                      />
                      <CheckBox
                        control={control}
                        rules={rules.row}
                        name={`row.${index}.checked`}
                        color="primary"
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                  }
                  {headCells.map((headCell) => {
                    const createLink = () => {
                      let link = headCell.link
                      headCell.link_params.forEach((param) => {
                        link = link.replace(`:${param}`, row[param])
                      })
                      return link
                    }
                    return (
                      <TableCell key={`${row.id}_${headCell.id}`}>
                        {
                          headCell?.link
                            ? <Link to={createLink()}>
                              {row[headCell.id]}
                            </Link>
                            : <Typography noWrap>{row[headCell.id]}</Typography> 
                        }
                      </TableCell>
                    );
                  })}
                  {props.tableButtons.map((item, index) => {
                    return (
                      <TableCell align="center" key={index} padding="none">
                        <IconButton onClick={() => { item.onClick(row) }}>
                          {item.buttonChildren}
                        </IconButton>
                      </TableCell>
                    )
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination/>
    </>
  );
}

EnhancedTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  dense: PropTypes.bool,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  tableButtons: PropTypes.array,
  itemCount: PropTypes.number.isRequired,
  checkBoxClickedAction: PropTypes.func,
  includeCheckBox: PropTypes.bool,
  usePagination: PropTypes.bool,
};

EnhancedTable.defaultProps = {
  tableButtons: [],
  checkBoxClickedAction: () => { },
  usePagination: true,
};