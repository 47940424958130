import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Grid, InputAdornment, Paper, Stack, Typography } from "@mui/material";
import { DatePicker } from "../atoms/DatePicker";
import { Container } from "../atoms/Container";
import { NoNameTextField } from "../atoms/NoNameTextField";
import { SelectForm } from "../atoms/SelectForm";
import { SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE } from "../../utils/Constants";
import { HiddenInput } from "../atoms/HiddenInput";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { isMobile } from "react-device-detect";
import { DialogSubContent } from "../molecules/DialogSubContent";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitPrivateRoadAgreement, useInitSettlementConfirmation, useInitSettlementConfirmationContractTerm } from "../../utils/CustomHook";
import { onBlurCommonAction, saveChildForPropertyEdit } from "../../utils/PropertyEdit";

export const SettlementConfirmationForms = React.memo(
  function settlementConfirmationForms({
    property,
    initialized,
    settlementconfirmation,
    setOriginPropertyData,
    privateroadagreement_set,
    contractterm,
    toggleViewport,
    formPaperVariant,
    formPaperElevation,
    ...props
  }) {
    const [isLoading, setIsLoading] = React.useState(false)
    const loadingComplete = React.useCallback(() => {
      setIsLoading(false)
    }, [])

    const [isOpenSettlementConfirmationForm, setIsOpenSettlementConfirmationForm] = React.useState(false)
    const [isOpenConditionOnContractForm, setIsOpenConditionOnContractForm] = React.useState(false)

    const [choices, setChoices] = React.useState({});
    const [propertyId, setPropertyId] = React.useState(null)

    const [isFocused, setFocusInputName] = useFocusInput()
    const handleFocus = React.useCallback((name) => {
      setFocusInputName(name)
    }, [])

    React.useEffect(() => {
      setPropertyId(property.id)
    }, [property])

    React.useEffect(() => {
      setChoices(props.choices);
    }, [props.choices]);

    const {
      control,
      formState: { errors },
      setValue,
      methods,
      setError,
      getValues,
      clearErrors,
      trigger,
    } = useForm({
      shouldFocusError: false,
      criteriaMode: "all",
      defaultValues: "",
      mode: "onChange",
    });

    useInitSettlementConfirmation(settlementconfirmation, setValue, isFocused)
    useInitSettlementConfirmationContractTerm(settlementconfirmation.settlementconfirmationcontractterm_set, setValue, isFocused)

    const [privateRoadAgreementFields] = useInitPrivateRoadAgreement(privateroadagreement_set, setValue, isFocused, control, trigger)

    React.useEffect(() => {
      if (!(settlementconfirmation && privateroadagreement_set)) {
        return;
      }

      initialized();
    }, [settlementconfirmation, privateroadagreement_set]);

    const saveCommonParams = React.useMemo(() => [
      errors, propertyId, clearErrors, setOriginPropertyData, setError
    ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

    const onBlurForPrivateRoadAgreement = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "privateroadagreement_set", null
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
    }, [propertyId]);

    const onBlurForSettlementConfirmationContractTerm = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "settlement_confirmation", "settlementconfirmation", "settlementconfirmationcontractterm_set"
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
    }, [propertyId]);

    const onBlurForSettlementConfirmation = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "settlementconfirmation"
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
    }, [propertyId]);

    const onBlurForBusinessProcess = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "businessprocess"
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
    }, [propertyId]);

    const rules = React.useMemo(() => ({
      businessprocess: {
        contract_at: {
          onBlur: onBlurForBusinessProcess,
        },
        settlement_at: {
          onBlur: onBlurForBusinessProcess,
        },
      },
      settlementconfirmation: {
        announcement_date: {
          onBlur: onBlurForSettlementConfirmation,
        },
        settlementconfirmationcontractterm_set: {
          status: {
            onBlur: onBlurForSettlementConfirmationContractTerm,
          },
          note: {
            onBlur: onBlurForSettlementConfirmationContractTerm,
          },
          person_confirmation: {
            onBlur: onBlurForSettlementConfirmationContractTerm,
          },
          mgr_confirmation: {
            onBlur: onBlurForSettlementConfirmationContractTerm,
          },
          planning_department_confirmation: {
            onBlur: onBlurForSettlementConfirmationContractTerm,
          },
          acquisition_document_confirmation: {
            onBlur: onBlurForSettlementConfirmationContractTerm,
          },
          public_and_private_boundary_confirmation_count: {
            onBlur: onBlurForSettlementConfirmationContractTerm,
          },
          private_and_private_boundary_confirmation_count: {
            onBlur: onBlurForSettlementConfirmationContractTerm,
          },
          traffic_excavation_agreement_form_count: {
            onBlur: onBlurForSettlementConfirmationContractTerm,
          },
          aw_change_report: {
            onBlur: onBlurForSettlementConfirmationContractTerm,
          },
          agreement_form_count: {
            onBlur: onBlurForSettlementConfirmationContractTerm,
          },
        },
      },
      privateroadagreement_set: {
        target: {
          onBlur: onBlurForPrivateRoadAgreement,
        },
      }
    }), [onBlurForSettlementConfirmation, onBlurForSettlementConfirmationContractTerm, onBlurForPrivateRoadAgreement, onBlurForBusinessProcess]);

    const propertyEditFormDialogCloseAction = React.useCallback(() => {
      toggleViewport()
    }, [])

    const formAreaClickedAction = React.useCallback((additionalProcess) => {
      if (!isMobile) {
        return
      }
      if (additionalProcess) {
        additionalProcess()
      }
      setIsLoading(true)
      toggleViewport()
    }, [])

    const commonInputProps = React.useMemo(() => ({
      control: control,
      onFocus: handleFocus,
    }), [control, handleFocus])

    return (
      <PropertyEditForms title="決済確認">
        <LoadingProgress open={isLoading} useCircular={false} />
        <FormProvider {...methods}>
          <PropertyEditFormDialog
            isOpen={isOpenSettlementConfirmationForm}
            handleClose={() => {
              setIsOpenSettlementConfirmationForm(false)
              propertyEditFormDialogCloseAction()
            }}
            title="決済確認"
            openedAction={loadingComplete}
            content={
              <SettlementConfirmationForm
                commonInputProps={commonInputProps}
                rules={rules}
                disabled={props.disabled}
                isModal
              />
            }
          />
          <PropertyEditFormDialog
            isOpen={isOpenConditionOnContractForm}
            handleClose={() => {
              setIsOpenConditionOnContractForm(false)
              propertyEditFormDialogCloseAction()
            }}
            title="契約時条件"
            openedAction={loadingComplete}
            contentSx={{ px: 0, pt: 0 }}
            content={
              <ConditionOnContractForm
                commonInputProps={commonInputProps}
                rules={rules}
                disabled={props.disabled}
                isModal={true}
                choices={choices}
                setValue={setValue}
                contractterm={contractterm}
                privateRoadAgreementFields={privateRoadAgreementFields}
                settlementconfirmationcontractterm_set={settlementconfirmation.settlementconfirmationcontractterm_set ?? []}
              />
            }
          />
          <form>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <Paper
                  elevation={formPaperElevation}
                  variant={formPaperVariant}
                  onClick={() => formAreaClickedAction(() => setIsOpenSettlementConfirmationForm(true))}
                >
                  <SettlementConfirmationForm
                    commonInputProps={commonInputProps}
                    rules={rules}
                    disabled={props.disabled}
                    isModal={false}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Container></Container>
                <Paper
                  elevation={formPaperElevation}
                  variant={formPaperVariant}
                  onClick={() => formAreaClickedAction(() => setIsOpenConditionOnContractForm(true))}
                >
                  <ConditionOnContractForm
                    commonInputProps={commonInputProps}
                    rules={rules}
                    disabled={props.disabled}
                    isModal={false}
                    choices={choices}
                    setValue={setValue}
                    contractterm={contractterm}
                    privateRoadAgreementFields={privateRoadAgreementFields}
                    settlementconfirmationcontractterm_set={settlementconfirmation.settlementconfirmationcontractterm_set ?? []}
                  />
                </Paper>

              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </PropertyEditForms>
    );
  }
);

SettlementConfirmationForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  settlementconfirmation: PropTypes.object.isRequired,
  contractterm: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  privateroadagreement_set: PropTypes.array.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

SettlementConfirmationForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const ContractTermTemplate = React.memo(function contractTermTemplate(props) {
  const {
    labelAfter,
    isLabelNest,
    statusChildren,
    type,
    rules,
    commonInputProps,
    choices,
    setValue,
    labels,
    isNoteDisabled,
    isModal,
    title,
    isNotInputs,
  } = props;

  const statusYesNoChoiceTypes = React.useMemo(() => ([
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_OWNER_ALL_MEMBERS_EXISTS,
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_EXISTS_THIRD_PARTY_INHERITED_MESSAGE,
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_EXISTS_DATE_DESCRIBED,
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_PRIOR_NARROW_DISCUSSED,
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_AREA_IF_MATCHES,
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_OWNER_ALL_MEMBERS_EXISTS,
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_EXISTS_THIRD_PARTY_INHERITED_MESSAGE,
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_EXISTS_DATE_DESCRIBED,
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.CONTRACT_WHEN_FROM_CONDITION_CHANGE_EXISTS,
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.CLEARING_WHEN_MORTGAGE_ERASURE_UNNECESSARY_IS,
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.HAS_ISSUED_TRANSFER_AGREEMENT_FORM,
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.IS_SIGNING_LEASE_CONTRACT,
    SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.IS_HEARING_SHEET_CREATING_DONE,
  ]), [])
  const statusChoices = statusYesNoChoiceTypes.includes(type) ? choices.contract_term_yes_no : choices.contract_term_achievements

  const Inputs = () => {
    return (
      isNotInputs
      ?
        <NoNameTextField />
      :
      <Grid container direction="row" spacing={1}>
        <HiddenInput
          {...commonInputProps}
          name={`settlementconfirmation.settlementconfirmationcontractterm_set.${type}.id`}
        />
        <HiddenInput
          {...commonInputProps}
          name={`settlementconfirmation.settlementconfirmationcontractterm_set.${type}.type`}
          defaultValue={String(type)}
        />
        <Grid item xs={isModal ? 12 : 6} container direction="row" spacing={1}>
          <Grid item xs={isModal ? 12 : 3}>
            <SelectForm
              {...commonInputProps}
              name={`settlementconfirmation.settlementconfirmationcontractterm_set.${type}.status`}
              rules={
                rules?.settlementconfirmation
                  ?.settlementconfirmationcontractterm_set.status
              }
              choices={statusChoices}
              disabled={props.disabled}
              setValue={setValue}
              label={isModal && labels.conditionAchievementStatus}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 9} container direction="row" spacing={1}>
            {isModal &&
              <Grid item xs={12}>
                <Typography>{labels.note}</Typography>
              </Grid>
            }
            {statusChildren
            ?
              statusChildren
            :
              statusYesNoChoiceTypes.includes(type)
              ?
                <>
                  {isModal && <Grid item xs={1}/>}
                  <Grid item xs={isModal ? 3 : 4}>
                    <Typography>NOの場合 →</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      {...commonInputProps}
                      name={`settlementconfirmation.settlementconfirmationcontractterm_set.${type}.note`}
                      rules={rules?.settlementconfirmation?.settlementconfirmationcontractterm_set.note}
                      disabled={isNoteDisabled || props.disabled}
                    />
                  </Grid>
                </>
              :
                <Grid item xs={12}>
                  <TextField
                    {...commonInputProps}
                    name={`settlementconfirmation.settlementconfirmationcontractterm_set.${type}.note`}
                    rules={rules?.settlementconfirmation?.settlementconfirmationcontractterm_set.note}
                    disabled={props.disabled}
                  />
                </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={isModal ? 12 : 6} container direction="row" spacing={1}>
          <Grid item xs={isModal ? 12 : 3}>
            <TextField
              {...commonInputProps}
              name={`settlementconfirmation.settlementconfirmationcontractterm_set.${type}.person_confirmation`}
              rules={
                rules?.settlementconfirmation
                  ?.settlementconfirmationcontractterm_set.person_confirmation
              }
              disabled={props.disabled}
              label={isModal && labels.personConfirmation}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 3}>
            <TextField
              {...commonInputProps}
              name={`settlementconfirmation.settlementconfirmationcontractterm_set.${type}.mgr_confirmation`}
              rules={
                rules?.settlementconfirmation
                  ?.settlementconfirmationcontractterm_set.mgr_confirmation
              }
              disabled={props.disabled}
              label={isModal && labels.mgrConfirmation}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 3}>
            <TextField
              {...commonInputProps}
              name={`settlementconfirmation.settlementconfirmationcontractterm_set.${type}.planning_department_confirmation`}
              rules={
                rules?.settlementconfirmation
                  ?.settlementconfirmationcontractterm_set
                  .planning_department_confirmation
              }
              disabled={props.disabled}
              label={isModal && labels.planningDepartmentConfirmation}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 3}>
            <SelectForm
              {...commonInputProps}
              name={`settlementconfirmation.settlementconfirmationcontractterm_set.${type}.acquisition_document_confirmation`}
              rules={
                rules?.settlementconfirmation
                  ?.settlementconfirmationcontractterm_set
                  .acquisition_document_confirmation
              }
              choices={choices?.contract_term_acquisition_document_confirmation}
              disabled={props.disabled}
              setValue={setValue}
              label={isModal && labels.acquisitionDocumentConfirmation}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return (
    <>
      {isModal
        ?
          <DialogSubContent
            title={title}
            content={
              <Grid spacing={1} container>
                <Grid item xs={12}>
                  {labelAfter && labelAfter}
                </Grid>
                <Grid item xs={12}>
                  <Inputs />
                </Grid>
              </Grid>
            }
          />
        :
        <>
          <Grid item xs={4} container direction="row" spacing={1}>
            {isLabelNest && <Grid item xs={1}/>}
            <Grid item xs>
              <NoNameTextField
                value={title}
              />
            </Grid>
            {labelAfter &&
              <Grid item xs={8}>
                {labelAfter}
              </Grid>
            }
          </Grid>
          <Grid item xs={8}>
            <Inputs/>
          </Grid>
        </>
      }
    </>
  );
});

ContractTermTemplate.propTypes = {
  isNotInputs: PropTypes.bool,
  isLabelNest: PropTypes.bool,
  labelAfter: PropTypes.any,
  statusChildren: PropTypes.any,
  type: PropTypes.number,
  rules: PropTypes.object.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  labels: PropTypes.object,
  title: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  isNoteDisabled: PropTypes.bool,
  isModal: PropTypes.bool.isRequired,
};

ContractTermTemplate.defaultProps = {
  disabled: false,
  labels: {},
  isNotInputs: false,
};

const SettlementConfirmationForm = React.memo(function settlementConfirmationForm(props) {
  const {commonInputProps, rules, isModal} = props
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={isModal ? 12 : 3}>
        <DatePicker
          {...commonInputProps}
          label="契約日"
          name="settlementconfirmation.contract_at"
          rules={rules.businessprocess.contract_at}
          disabled={props.disabled}
          defaultValue={null}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 3}>
        <DatePicker
          {...commonInputProps}
          label="決済日"
          name="settlementconfirmation.settlement_at"
          rules={rules.businessprocess.settlement_at}
          disabled={props.disabled}
          defaultValue={null}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 3}>
        <DatePicker
          {...commonInputProps}
          label="発表日"
          name="settlementconfirmation.announcement_date"
          rules={rules.settlementconfirmation.announcement_date}
          defaultValue={null}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 3}>
        <TextField
          {...commonInputProps}
          label="仲介業者"
          name="settlementconfirmation.company_payee_broker_name"
          disabled={true}
        />
      </Grid>
    </Grid>
  )
})
SettlementConfirmationForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
}

const ConditionOnContractForm = React.memo(function conditionOnContractForm(props) {
  const { commonInputProps, rules, isModal, choices, setValue, contractterm, privateRoadAgreementFields, settlementconfirmationcontractterm_set
} = props

  const labels = React.useMemo(() => ({
    conditionOnContract: "契約時条件",
    conditionAchievementStatus: "条件成就状況",
    note: "備考",
    personConfirmation: "担当確認",
    mgrConfirmation: "MGR確認",
    planningDepartmentConfirmation: "企画課確認",
    acquisitionDocumentConfirmation: "決済当日取得書類",
  }), [])

  const statusYes = 4;
  const statusNo = 5;
  const contractTermTemplateCommonProps = React.useMemo(() => ({
    commonInputProps: commonInputProps,
    setValue: setValue,
    rules: rules,
    choices: choices,
    disabled: props.disabled,
    labels: labels,
    isModal: isModal,
  }), [setValue, rules, commonInputProps, choices, props.disabled,labels, isModal])

  const getTypePerData = React.useCallback((type) => {
    return settlementconfirmationcontractterm_set?.find(obj => obj.type === type)
  }, [settlementconfirmationcontractterm_set])

  const statusChildrenForFixedMeasurementDrawingIssue = React.useMemo(() => {
    return (
      <>
        <Grid item xs={4}>
          <Typography>境界確認書通数 →</Typography>
        </Grid>
        <Grid item xs={8} container direction={isModal ? "column" : "row"} spacing={1}>
          <Grid item xs={6}>
            <TextField
              {...commonInputProps}
              name={`settlementconfirmation.settlementconfirmationcontractterm_set.${SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE}.public_and_private_boundary_confirmation_count`}
              rules={
                rules?.settlementconfirmation
                  ?.settlementconfirmationcontractterm_set
                  .public_and_private_boundary_confirmation_count
              }
              type={"number"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    官民
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    通
                  </InputAdornment>
                ),
              }}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...commonInputProps}
              name={`settlementconfirmation.settlementconfirmationcontractterm_set.${SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE}.private_and_private_boundary_confirmation_count`}
              rules={
                rules?.settlementconfirmation
                  ?.settlementconfirmationcontractterm_set
                  .private_and_private_boundary_confirmation_count
              }
              type={"number"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    民民
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    通
                  </InputAdornment>
                ),
              }}
              disabled={props.disabled}
            />
          </Grid>
        </Grid>
      </>
    )
  }, [isModal, commonInputProps, rules, props.disabled])

  const statusChildrenForFixedMeasurementDrawingIssueAreaIfMatches = React.useMemo(() => {
    return (
      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          name={`settlementconfirmation.settlementconfirmationcontractterm_set.${SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_AREA_IF_MATCHES}.note`}
          rules={
            rules?.settlementconfirmation
              ?.settlementconfirmationcontractterm_set.note
          }
          disabled={props.disabled}
        />
      </Grid>
    )
  }, [commonInputProps, rules, props.disabled])

  const labelAfterForPrivateRoadAgreementDocument = React.useMemo(() => {
    return (
      <NoNameTextField
        value={contractterm.private_road_approval_form_display ?? ""}
      />
    )
  }, [contractterm])

  const statusChildrenForPrivateRoadAgreementDocument = React.useMemo(() => {
    return (
      <>
        <Grid item xs={4}>
          <Typography>私道通行掘削承諾書 →</Typography>
        </Grid>
        <Grid item xs={8} container direction="row" spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name={`settlementconfirmation.settlementconfirmationcontractterm_set.${SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT}.traffic_excavation_agreement_form_count`}
              rules={
                rules?.settlementconfirmation
                  ?.settlementconfirmationcontractterm_set
                  .traffic_excavation_agreement_form_count
              }
              type={"number"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    通
                  </InputAdornment>
                ),
              }}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <TextField
              {...commonInputProps}
              name={`settlementconfirmation.settlementconfirmationcontractterm_set.${SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT}.note`}
              rules={
                rules?.settlementconfirmation
                  ?.settlementconfirmationcontractterm_set.note
              }
              disabled={props.disabled}
            />
          </Grid>
        </Grid>
      </>
    )
  }, [isModal, commonInputProps, rules, props.disabled])

  const labelAfterForPlotCorrectionRegistration = React.useMemo(() => {
    return (
      <NoNameTextField
        value={
          contractterm.plot_correction_registration_display ??
          ""
        }
      />
    )
  }, [contractterm])

  const labelAfterForRegistrationOfSubdivision = React.useMemo(() => {
    return (
      <NoNameTextField
        value={
          contractterm.registration_of_subdivision_display ??
          ""
        }
      />
    )
  }, [contractterm])

  const labelAfterForPassingCriteria = React.useMemo(() => {
    return (
      <NoNameTextField
        value={
          contractterm.extradition_condition_display ?? ""
        }
      />
    )
  }, [contractterm])

  const labelAfterForDismantlingBeforeClearing = React.useMemo(() => {
    return (
      <NoNameTextField
        value={
          contractterm.dismantling_before_settlement_display ??
          ""
        }
      />
    )
  }, [contractterm])

  const labelAfterForOtherCharacterTrade = React.useMemo(() => {
    return (
      <NoNameTextField
        value={
          contractterm.buying_and_selling_by_others_display ??
          ""
        }
      />
    )
  }, [contractterm])

  const labelAfterForLandCategoryChangeRegistration = React.useMemo(() => {
    return (
      <NoNameTextField
        value={
          contractterm.land_category_change_registration_display ??
          ""
        }
      />
    )
  }, [contractterm])

  const labelAfterForContractStyle = React.useMemo(() => {
    return (
      <NoNameTextField
        value={contractterm.contract_type_display ?? ""}
      />
    )
  }, [contractterm])

  const labelAfterForPlanCriteria = React.useMemo(() => {
    return (
      <NoNameTextField
        value={contractterm.plan_condition_display ?? ""}
      />
    )
  }, [contractterm])

  const labelAfterForValidAreaCriteria = React.useMemo(() => {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item xs={8}>
          <NoNameTextField
            value={
              contractterm.effective_area_condition_display ??
              ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <NoNameTextField
            value={contractterm.effective_area ?? ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  ㎡
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    )
  }, [contractterm])

  const labelAfterForFrontageAllocationCriteria = React.useMemo(() => {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item xs={8}>
          <NoNameTextField
            value={
              contractterm.frontage_security_condition_display ??
              ""
            }
          />
        </Grid>
        <Grid item xs={4}>
          <NoNameTextField
            value={contractterm.frontage_security_area ?? ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  ㎡
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    )
  }, [contractterm])

  const labelAfterForDevelopmentPositionSpecifiedConstruction = React.useMemo(() => {
    return (
      <NoNameTextField
        value={
          contractterm.development_position_designation_creation_display ??
          ""
        }
      />
    )
  }, [contractterm])

  const labelAfterForAbolitionNotification = React.useMemo(() => {
    return (
      <NoNameTextField
        value={
          contractterm.abolition_notification_display ?? ""
        }
      />
    )
  }, [contractterm])

  const labelAfterForProductiveGreen = React.useMemo(() => {
    return (
      <NoNameTextField
        value={contractterm.productive_green_display ?? ""}
      />
    )
  }, [contractterm])

  const labelAfterForViolationOfCivilLaw = React.useMemo(() => {
    return (
      <NoNameTextField
        value={
          contractterm.violation_of_civil_law_display ?? ""
        }
      />
    )
  }, [contractterm])

  const labelAfterForBuildingConfirmation = React.useMemo(() => {
    return (
      <NoNameTextField
        value={
          contractterm.building_confirmation_display ?? ""
        }
      />
    )
  }, [contractterm])

  const statusChildrenForContractWhenFromConditionChangeExists = React.useMemo(() => {
    return (
      <Grid item xs={12} container direction="row">
        <Grid item xs={12} container direction="row">
          {isModal && <Grid item xs={1}/>}
          <Grid item xs={isModal ? 2 : 3}>
            <Typography>YESの場合 →</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              inputProps={isModal ? {} : { sx: { maxHeight: "8px" } }}
              {...commonInputProps}
              name={`settlementconfirmation.settlementconfirmationcontractterm_set.${SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.CONTRACT_WHEN_FROM_CONDITION_CHANGE_EXISTS}.note`}
              rules={
                rules?.settlementconfirmation
                  ?.settlementconfirmationcontractterm_set.note
              }
              disabled={
                getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.CONTRACT_WHEN_FROM_CONDITION_CHANGE_EXISTS)?.status !== statusYes || props.disabled
              }
            />
          </Grid>
        </Grid>

        {isModal && <Grid item xs={4}/> }
        <Grid item xs={isModal ? 8 : 12} container direction="row" spacing={1} >
          <Grid item xs={isModal ? 12 : 7}>
            <SelectForm
              labelPosition="left"
              label="AW変更報告書"
              sx={isModal ? {} : { maxHeight: "25px" }}
              {...commonInputProps}
              name={`settlementconfirmation.settlementconfirmationcontractterm_set.${SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.CONTRACT_WHEN_FROM_CONDITION_CHANGE_EXISTS}.aw_change_report`}
              rules={
                rules?.settlementconfirmation
                  ?.settlementconfirmationcontractterm_set
                  .aw_change_report
              }
              choices={
                choices?.contract_term_aw_change_report
              }
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 5}>
            <TextField
              labelPosition="left"
              label="合意書"
              inputProps={isModal ? {} : { sx: { maxHeight: "8px" } }}
              {...commonInputProps}
              name={`settlementconfirmation.settlementconfirmationcontractterm_set.${SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.CONTRACT_WHEN_FROM_CONDITION_CHANGE_EXISTS}.agreement_form_count`}
              type="number"
              rules={
                rules?.settlementconfirmation
                  ?.settlementconfirmationcontractterm_set
                  .agreement_form_count
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    通
                  </InputAdornment>
                ),
              }}
              disabled={props.disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }, [isModal, commonInputProps, rules, choices, props.disabled, getTypePerData, setValue])

  return (
    <Grid container direction="row" spacing={1}>
      {!isModal &&
        <Grid item xs={12} container direction="row" spacing={1}>
          <Grid item xs={4}>
            <Typography variant="subtitle1">{labels.conditionOnContract}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography align="center">{labels.conditionAchievementStatus}</Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={1}>
            <Typography align="center">{labels.note}</Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={1}>
            <Typography align="center">{labels.personConfirmation}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography align="center">{labels.mgrConfirmation}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography align="center">{labels.planningDepartmentConfirmation}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography align="center">{labels.acquisitionDocumentConfirmation}</Typography>
          </Grid>
        </Grid>
      }

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        isNotInputs
        title={`(1) ${contractterm.trade_form_display ?? ""}`}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE
        }
        title={`(2) ${contractterm.surveying_condition_display ?? "" }`}
        statusChildren={statusChildrenForFixedMeasurementDrawingIssue}
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_OWNER_ALL_MEMBERS_EXISTS
        }
        title="(L) 所有者全員からあるか"
        isLabelNest
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_OWNER_ALL_MEMBERS_EXISTS)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_EXISTS_THIRD_PARTY_INHERITED_MESSAGE
        }
        title="(L) 第三者承継文言があるか"
        isLabelNest
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_EXISTS_THIRD_PARTY_INHERITED_MESSAGE)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_EXISTS_DATE_DESCRIBED
        }
        title="(L) 日付記載はあるか"
        isLabelNest
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_EXISTS_DATE_DESCRIBED)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_PRIOR_NARROW_DISCUSSED
        }
        title="(L) 所有者全員からあるか事前狭隘協議"
        isLabelNest
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_PRIOR_NARROW_DISCUSSED)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_AREA_IF_MATCHES
        }
        title="(L) 境界確認書添付図面と高低真北測量図の面積 ・辺長は完全一致するか"
        isLabelNest
        statusChildren={ statusChildrenForFixedMeasurementDrawingIssueAreaIfMatches}
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FIXED_MEASUREMENT_DRAWING_ISSUE_AREA_IF_MATCHES)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.TRANSBOUNDARY_DECONTAMINATION
        }
        title={`(3) ${contractterm.border_transgression_display ?? ""}`}
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.TRANSBOUNDARY_DECONTAMINATION)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        isNotInputs
        title={`(4) ${contractterm.contract_non_conformity_liability_display ??  "" }`}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        isNotInputs
        title={`(5) ${contractterm.contract_display ?? ""}`}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT
        }
        title="(6) 私道承諾書"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT)?.status !== statusNo}
        labelAfter={labelAfterForPrivateRoadAgreementDocument}
        statusChildren={statusChildrenForPrivateRoadAgreementDocument}
      />

      <Grid item xs={12} container direction="row" spacing={1} alignItems="center">
        <Grid item xs={2} />
        <Grid item xs={1}>
          <Typography>
            対象 →
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="row" spacing={2} columns={10}>
            {privateRoadAgreementFields.map((item, index) => {
              return (
                <Grid item xs={isModal ? 10 : 2} key={index}>
                  <HiddenInput
                    {...commonInputProps}
                    name={`privateroadagreement_set.${index}.id`}
                  />
                  <Stack>
                    <TextField
                      inputProps={{ sx: { maxHeight: "8px" } }}
                      {...commonInputProps}
                      name={`privateroadagreement_set.${index}.target_brush`}
                      disabled={true}
                    />
                    <SelectForm
                      sx={{ maxHeight: "25px" }}
                      {...commonInputProps}
                      name={`privateroadagreement_set.${index}.target`}
                      rules={rules.privateroadagreement_set.target}
                      choices={choices.private_road_agreement_target}
                      disabled={props.disabled}
                      setValue={setValue}
                    />
                  </Stack>
                </Grid>
              )
            })}

          </Grid>
        </Grid>
        <Grid item xs={isModal ? 12 : 1}>
          <HiddenInput
            {...commonInputProps}
            name={`settlementconfirmation.settlementconfirmationcontractterm_set.${SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_TARGET}.id`}
          />
          <HiddenInput
            {...commonInputProps}
            name={`settlementconfirmation.settlementconfirmationcontractterm_set.${SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_TARGET}.type`}
            defaultValue={String(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_TARGET)}
          />
          <SelectForm
            {...commonInputProps}
            name={`settlementconfirmation.settlementconfirmationcontractterm_set.${SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_TARGET}.acquisition_document_confirmation`}
            rules={
              rules?.settlementconfirmation
                ?.settlementconfirmationcontractterm_set
                .acquisition_document_confirmation
            }
            choices={choices.contract_term_acquisition_document_confirmation}
            disabled={props.disabled}
            setValue={setValue}
            label={isModal && labels.acquisitionDocumentConfirmation}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_OWNER_ALL_MEMBERS_EXISTS
        }
        title="(L) 所有者全員からあるか"
        isLabelNest
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_OWNER_ALL_MEMBERS_EXISTS)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_EXISTS_THIRD_PARTY_INHERITED_MESSAGE
        }
        title="(L) 第三者承継文言があるか"
        isLabelNest
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_EXISTS_THIRD_PARTY_INHERITED_MESSAGE)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_EXISTS_DATE_DESCRIBED
        }
        title="(L) 日付記載はあるか"
        isLabelNest
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRIVATE_ROAD_AGREEMENT_DOCUMENT_EXISTS_DATE_DESCRIBED)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PLOT_CORRECTION_REGISTRATION
        }
        title="(7) 地積更正登記"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PLOT_CORRECTION_REGISTRATION)?.status !== statusNo}
        labelAfter={labelAfterForPlotCorrectionRegistration}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.REGISTRATION_OF_SUBDIVISION
        }
        title="(8) 分筆登記"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.REGISTRATION_OF_SUBDIVISION)?.status !== statusNo}
        labelAfter={labelAfterForRegistrationOfSubdivision}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PASSING_CRITERIA
        }
        title="(9) 引渡条件"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PASSING_CRITERIA)?.status !== statusNo}
        labelAfter={labelAfterForPassingCriteria}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.DISMANTLING_BEFORE_CLEARING
        }
        title="(10) 決済前解体"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.DISMANTLING_BEFORE_CLEARING)?.status !== statusNo}
        labelAfter={labelAfterForDismantlingBeforeClearing}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.OTHER_CHARACTER_TRADE
        }
        title="(11) 他人物売買"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.OTHER_CHARACTER_TRADE)?.status !== statusNo}
        labelAfter={labelAfterForOtherCharacterTrade}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.LAND_CATEGORY_CHANGE_REGISTRATION
        }
        title="(12) 地目変更登記"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.LAND_CATEGORY_CHANGE_REGISTRATION)?.status !== statusNo}
        labelAfter={labelAfterForLandCategoryChangeRegistration}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.CONTRACT_STYLE
        }
        title="(13) 契約形態"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.CONTRACT_STYLE)?.status !== statusNo}
        labelAfter={labelAfterForContractStyle}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PLAN_CRITERIA
        }
        title="(14) プラン条件"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PLAN_CRITERIA)?.status !== statusNo}
        labelAfter={labelAfterForPlanCriteria}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.VALID_AREA_CRITERIA
        }
        title="(15) 有効面積条件"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.VALID_AREA_CRITERIA)?.status !== statusNo}
        labelAfter={labelAfterForValidAreaCriteria}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FRONTAGE_ALLOCATION_CRITERIA
        }
        title="(16) 間口確保条件"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.FRONTAGE_ALLOCATION_CRITERIA)?.status !== statusNo}
        labelAfter={labelAfterForFrontageAllocationCriteria}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.DEVELOPMENT_POSITION_SPECIFIED_CONSTRUCTION
        }
        title="(17) 開発・位置指定・造成"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.DEVELOPMENT_POSITION_SPECIFIED_CONSTRUCTION)?.status !== statusNo}
        labelAfter={labelAfterForDevelopmentPositionSpecifiedConstruction}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.ABOLITION_NOTIFICATION
        }
        title="(18) 廃止届"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.ABOLITION_NOTIFICATION)?.status !== statusNo}
        labelAfter={labelAfterForAbolitionNotification}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRODUCTIVE_GREEN
        }
        title="(19) 生産緑地"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.PRODUCTIVE_GREEN)?.status !== statusNo}
        labelAfter={labelAfterForProductiveGreen}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.VIOLATION_OF_CIVIL_LAW
        }
        title="(20) 民法破り"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.VIOLATION_OF_CIVIL_LAW)?.status !== statusNo}
        labelAfter={labelAfterForViolationOfCivilLaw}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.BUILDING_CONFIRMATION
        }
        title="(21) 建築確認"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.BUILDING_CONFIRMATION)?.status !== statusNo}
        labelAfter={labelAfterForBuildingConfirmation}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.NOTE1}
        title={`(22) ${contractterm.note1 ?? ""}`}
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.NOTE1)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.NOTE2}
        title={`(23) ${contractterm.note2 ?? ""}`}
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.NOTE2)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.CONTRACT_WHEN_FROM_CONDITION_CHANGE_EXISTS
        }
        title="(24) 契約時からの条件変更があるか"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.CONTRACT_WHEN_FROM_CONDITION_CHANGE_EXISTS)?.status !== statusYes}
        statusChildren={statusChildrenForContractWhenFromConditionChangeExists}
      />
      <Grid item xs={12} />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.CLEARING_WHEN_MORTGAGE_ERASURE_UNNECESSARY_IS
        }
        title="(25) 決済時に抵当権抹消が不要か"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.CLEARING_WHEN_MORTGAGE_ERASURE_UNNECESSARY_IS)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.HAS_ISSUED_TRANSFER_AGREEMENT_FORM
        }
        title="(借) 譲渡承諾書交付されているか"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.HAS_ISSUED_TRANSFER_AGREEMENT_FORM)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.IS_SIGNING_LEASE_CONTRACT
        }
        title="(借) 賃貸借契約書締結しているか"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.IS_SIGNING_LEASE_CONTRACT)?.status !== statusNo}
      />

      <ContractTermTemplate
        {...contractTermTemplateCommonProps}
        type={
          SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.IS_HEARING_SHEET_CREATING_DONE
        }
        title="(借) ヒアリングシート作成は済んでいるか"
        isNoteDisabled={getTypePerData(SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE.IS_HEARING_SHEET_CREATING_DONE)?.status !== statusNo}
      />
    </Grid>
  )
})

ConditionOnContractForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
  contractterm: PropTypes.object.isRequired,
  privateRoadAgreementFields: PropTypes.array.isRequired,
  settlementconfirmationcontractterm_set: PropTypes.array.isRequired,
}