import { DialogActions as MUIDialogActions } from "@mui/material";
import PropTypes from "prop-types";

export const DialogActions = ({ sx, ...props }) => {
  if (!sx?.bgcolor) {
    sx.bgcolor = "gray.main";
  }

  return <MUIDialogActions sx={sx} {...props} />;
};

DialogActions.defaultProps = {
  sx: {},
};

DialogActions.propTypes = {
  sx: PropTypes.object
}