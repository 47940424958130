import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Formulas } from "./pages/Admin/Formulas";
import { FormulasByKey } from "./pages/Admin/FormulasByKey";
import { PropertyShare } from "./pages/Admin/PropertyShare";
import { Login } from "./pages/Login";
import { Properties } from "./pages/Properties";
import { PropertyEdit } from "./pages/PropertyEdit";
import { UserSettings } from "./pages/UserSettings";
import { FrontendUrls } from "./Urls";

export const AppRouter = () => {
  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route path={FrontendUrls.login} element={<Login />} />
        <Route path={FrontendUrls.properties} element={<Properties />} />
        <Route path={FrontendUrls.propertyEdit} element={<PropertyEdit />} />
        <Route path={FrontendUrls.userSettings} element={<UserSettings />} />
        <Route path={FrontendUrls.adminFormulas} element={<Formulas />} />
        <Route path={FrontendUrls.adminFormulasByKey} element={<FormulasByKey />} />
        <Route path={FrontendUrls.adminPropertyShare} element={<PropertyShare />} />
      </Routes>
    </BrowserRouter>
  );
};
