import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import {ReactComponent as icon} from "../../assets/images/Add.svg";

export const AddIcon = (props) => {
  return (
    <SvgIcon
      component={icon}
      viewBox="0 0 17 16"
      {...props}
    />
  );
};

AddIcon.defaultProps = {
  color: "green"
};

AddIcon.propTypes = {
  color: PropTypes.string,
}