import { Grid, InputAdornment, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { toCircled } from "../../utils/NumberUtils";
import { isPropertyEditable } from "../../utils/UserUtil";
import { AddButton } from "../atoms/AddButton";
import { DeleteButton } from "../atoms/DeleteButton";
import { FormControl } from "../atoms/FormControl";
import { FormLabel } from "../atoms/FormLabel";
import { HiddenInput } from "../atoms/HiddenInput";
import { TextField } from "../atoms/TextField";
import React from "react";
import { DialogSubContent } from "./DialogSubContent";
import { Container } from "../atoms/Container";

export const CostOtherForm = (props) => {
  const {
    addForm,
    removeForm,
    fields,
    tableName,
    parentTableName,
    rules,
    startNum,
    commonInputProps,
    property,
    MAX_ROW,
    isModal,
  } = props;
  const isDisabled = !isPropertyEditable(property);


  const getForms = () => {

    const forms = fields.map((item, index) => (
      <Grid container spacing={1} direction="row" key={index}>
        <Grid item xs={isModal ? 12 : 4}>
          <TextField
            {...commonInputProps}
            name={`${parentTableName}.${tableName}.${index}.cost`}
            rules={rules?.[parentTableName]?.[tableName]?.cost}
            type="number"
            disabled={isDisabled}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>
        {isModal === false &&
          <Grid item xs={1}>
            <Typography>内容</Typography>
          </Grid>
        }
        <Grid item xs={isModal ? 12 : 7}>
          <TextField
            {...commonInputProps}
            name={`${parentTableName}.${tableName}.${index}.content`}
            rules={rules?.[parentTableName]?.[tableName]?.content}
            disabled={isDisabled}
            placeholder={isModal ? "内容" : undefined}
          />
        </Grid>
      </Grid>
    ))
    return forms
  }

  const getTitle = () => {
    return (
      <Stack direction="row">
        <FormLabel>
          <Typography variant="subtitle1">その他</Typography>
        </FormLabel>
        <AddButton onClick={addForm} disabled={isDisabled || MAX_ROW <= fields.length} />
      </Stack>
    )
  }
  return (
    <FormControl>
      {isModal
      ?
        <Container sx={{pt: 3}}>
          {getTitle()}
        </Container>
      : getTitle()
      }
      {isModal
        ?
          <>
            {getForms().map((item, index) => {
              return (
                <DialogSubContent
                  key={index}
                  title={
                    <Stack direction="row" spacing={2}>
                      <Typography>({startNum + index})</Typography>
                      <Typography>
                        その他{toCircled(index + 1)}
                      </Typography>
                    </Stack>
                  }
                  content={
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={10}>
                        <HiddenInput
                          {...commonInputProps}
                          name={`${parentTableName}.${tableName}.${index}.id`}
                        />
                        {item}
                      </Grid>
                      <Grid item xs={2}>
                        <DeleteButton
                          onClick={() => removeForm(index)}
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>
                  }
                />
              )
            })}
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", mt: 1 }}
              onClick={!(isDisabled || MAX_ROW <= fields.length) ? addForm : undefined}
            >
              <Typography sx={{ color: (isDisabled || MAX_ROW <= fields.length) ? "gray.main" : "darkGreen.main" }}>
                追加
              </Typography>
              <AddButton disabled={isDisabled || MAX_ROW <= fields.length} sx={{ ml: 1 }} />
            </Stack>
          </>
        :
        <>
          {getForms().map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Grid container spacing={1} direction="row" key={index}>
                  <Grid item xs={3} container spacing={1} direction="row">
                    <HiddenInput
                      {...commonInputProps}
                      name={`${parentTableName}.${tableName}.${index}.id`}
                    />
                    <Grid item xs={4}>
                      <Typography>({startNum + index})</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        その他{toCircled(index + 1)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <DeleteButton
                        onClick={() => removeForm(index)}
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    {item}
                  </Grid>
                </Grid>
              </React.Fragment>
            )
          })}
        </>
      }
    </FormControl>
  );
};

CostOtherForm.propTypes = {
  isModal: PropTypes.bool.isRequired,
  addForm: PropTypes.func.isRequired,
  removeForm: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  tableName: PropTypes.string.isRequired,
  parentTableName: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  startNum: PropTypes.number,
  commonInputProps: PropTypes.any.isRequired,
  property: PropTypes.object.isRequired,
  MAX_ROW: PropTypes.number.isRequired,
};
CostOtherForm.defaultProps = {
  addForm: () => { },
  removeForm: () => { },
  fields: [],
  rules: {},
  startNum: 0,
};
