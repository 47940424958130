import * as React from "react";
import { useFieldArray } from "react-hook-form";
import { CONTRACT_AMOUNT_DETAIL_TYPE } from "./Constants";
import { getNested } from "./ObjectUtil";
import { getCompanyPayeeBrokerNameChoices as getCompanyPayeeBrokerNameChoicesApi } from "./PropertyEdit";

export const useFocusInput = () => {
  const [focusInputName, setFocusInputName] = React.useState("")
  const isFocused = React.useCallback((name) => {
    return name === focusInputName
  }, [focusInputName])
  return [isFocused, setFocusInputName]
}

const SimpleInit = (obj, setValue, isFocused, baseName = "", errors = undefined) => {
  Object.keys(obj).map((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      return
    }
    const name = [baseName, key].join(".")
    if (errors && getNested(errors, name.split("."))) {
      return
    }
    if (!isFocused(name)) {
      if (Object.keys(obj.values_or_default ?? {}).includes(key)) {
        setValue(name, obj.values_or_default[key], { shouldValidate: true });
      } else {
        setValue(name, obj[key], { shouldValidate: true });
      }
    }
  });
}

const SimpleArrayInit = (fields, data, setValue, isFocused, fieldRemove, fieldReplace, baseName = "") => {
  if (fields.length) {
    data.forEach((item, index) => {
      Object.keys(item).forEach((key) => {
        const name = [baseName, index, key].join(".")
        if (!isFocused(name)) {
          setValue(name, item[key], { shouldValidate: true })
        }
      })
    })
    fields.forEach((item, index) => {
      if (index >= data.length) {
        fieldRemove(index)
      }
    })
  } else {
    fieldReplace(data)
  }
}

export const useInitBusinessBalance = (businessbalance, setValue, isFocused, errors) => {
  React.useEffect(() => {
    if (!businessbalance) {
      return
    }
    Object.keys(businessbalance).map((key) => {
      if (typeof businessbalance[key] === "object" && businessbalance[key] !== null) {
        return
      }
      if (errors.businessbalance?.[key]) {
        return
      }
      const name = `businessbalance.${key}`
      if (isFocused(name)) {
        return
      }
      if (key === "annualized_rate_percent" || key === "business_profit_rate") {
        const value = businessbalance[key] ?? 0
        setValue(name, value * 100, { shouldValidate: true });
      } else {
        setValue(name, businessbalance[key], { shouldValidate: true });
      }
    });
  }, [businessbalance])
}

export const useInitCompanyPayeeBroker = (companypayeebroker, setValue, isFocused, errors) => {
  React.useEffect(() => {
    if (!companypayeebroker) {
      return
    }
    SimpleInit(companypayeebroker, setValue, isFocused, "companypayeebroker", errors)
  }, [companypayeebroker])
}

export const useInitTradeTargetLotNumber = (businessbalancetradetargetlotnumber_set, setValue, isFocused, control, trigger) => {
  const {
    fields: tradeTargetLotNumberFields,
    append: tradeTargetLotNumberAppend,
    replace: tradeTargetLotNumberReplace,
    remove: tradeTargetLotNumberRemove,
  } = useFieldArray({
    control,
    name: "businessbalance.businessbalancetradetargetlotnumber_set",
  });

  React.useEffect(() => {
    if (!businessbalancetradetargetlotnumber_set) {
      return
    }
    SimpleArrayInit(tradeTargetLotNumberFields, businessbalancetradetargetlotnumber_set, setValue, isFocused, tradeTargetLotNumberRemove, tradeTargetLotNumberReplace, "businessbalance.businessbalancetradetargetlotnumber_set")
  }, [businessbalancetradetargetlotnumber_set])

  React.useEffect(() => {
    trigger("businessbalance.businessbalancetradetargetlotnumber_set")
  }, [tradeTargetLotNumberFields])
  return [tradeTargetLotNumberFields, tradeTargetLotNumberAppend, tradeTargetLotNumberRemove]
}

export const useInitPrivateRoad = (businessbalanceprivateroad_set, setValue, isFocused, control, trigger) => {
  const {
    fields: privateRoadFields,
    append: privateRoadAppend,
    replace: privateRoadReplace,
    remove: privateRoadRemove,
  } = useFieldArray({
    control,
    name: "businessbalance.businessbalanceprivateroad_set",
  });

  React.useEffect(() => {
    if (!businessbalanceprivateroad_set) {
      return
    }
    SimpleArrayInit(privateRoadFields, businessbalanceprivateroad_set, setValue, isFocused, privateRoadRemove, privateRoadReplace, "businessbalance.businessbalanceprivateroad_set")
  }, [businessbalanceprivateroad_set])

  React.useEffect(() => {
    trigger("businessbalance.businessbalanceprivateroad_set")
  }, [privateRoadFields])
  return [privateRoadFields, privateRoadAppend, privateRoadRemove]
}

export const useInitRoadsideLandPricesRatio = (roadside_land_prices_ratio_target_year, businessbalanceroadsidelandpricesratio_set, setValue, isFocused, control, trigger) => {
  const {
    fields: roadsideLandPricesRatioFields,
    replace: roadsideLandPricesRatioReplace,
    remove: roadsideLandPricesRatioRemove,
  } = useFieldArray({
    control,
    name: "businessbalance.businessbalanceroadsidelandpricesratio_set",
  });

  React.useEffect(() => {
    if (!roadside_land_prices_ratio_target_year || !businessbalanceroadsidelandpricesratio_set) {
      return
    }

    const options = { year: 'numeric' };
    const businessbalanceroadsidelandpricesratioData = []
    roadside_land_prices_ratio_target_year.forEach((year) => {
      businessbalanceroadsidelandpricesratioData.push({
        year: year,
        displayYear: new Intl.DateTimeFormat('ja-JP-u-ca-japanese', options).format(new Date().setFullYear(year)),
      })
    })

    businessbalanceroadsidelandpricesratio_set?.forEach((item) => {
      businessbalanceroadsidelandpricesratioData.forEach((initialData, index) => {
        if (initialData.year != item.year) {
          return
        }
        businessbalanceroadsidelandpricesratioData[index] = {
          ...item,
          ...initialData,
        }
      })
    })

    SimpleArrayInit(roadsideLandPricesRatioFields, businessbalanceroadsidelandpricesratioData, setValue, isFocused, roadsideLandPricesRatioRemove, roadsideLandPricesRatioReplace, "businessbalance.businessbalanceroadsidelandpricesratio_set")
  }, [roadside_land_prices_ratio_target_year, businessbalanceroadsidelandpricesratio_set])

  React.useEffect(() => {
    trigger("businessbalance.businessbalanceroadsidelandpricesratio_set")
  }, [roadsideLandPricesRatioFields])
  return roadsideLandPricesRatioFields
}

export const useInitSpecialFactor = (businessbalancespecialfactor_set, setValue, isFocused, control, rules, trigger) => {
  const {
    fields: specialFactorFields,
    replace: specialFactorReplace,
    remove: specialFactorRemove,
  } =
    useFieldArray({
      control,
      name: "businessbalance.businessbalancespecialfactor_set",
    });
  React.useEffect(() => {
    if (!businessbalancespecialfactor_set) {
      return
    }
    let specialFactorData = [];
    businessbalancespecialfactor_set?.forEach((item) => {
      specialFactorData.push(item);
    });

    const specialFactorCount = 3;
    const defaultValue = {}
    Object.keys(rules).forEach((key) => {
      defaultValue[key] = null
    })
    for (let i = specialFactorData.length; i < specialFactorCount; i++) {
      specialFactorData.push(defaultValue);
    }

    SimpleArrayInit(specialFactorFields, specialFactorData, setValue, isFocused, specialFactorRemove, specialFactorReplace, "businessbalance.businessbalancespecialfactor_set")
  }, [businessbalancespecialfactor_set])

  React.useEffect(() => {
    trigger("businessbalance.businessbalancespecialfactor_set")
  }, [specialFactorFields])
  return specialFactorFields
}

export const useInitPropertyOverview = (propertyoverview, setValue, isFocused) => {
  React.useEffect(() => {
    if (!propertyoverview) {
      return
    }
    SimpleInit(propertyoverview, setValue, isFocused, "propertyoverview")
  }, [propertyoverview])
}

export const useInitPropertyOverviewTraffic = (propertyoverviewtraffic_set, setValue, isFocused, control, trigger) => {
  const {
    fields: propertyOverviewTrafficFields,
    append: propertyOverviewTrafficAppend,
    replace: propertyOverviewTrafficReplace,
    remove: propertyOverviewTrafficRemove,
  } = useFieldArray({
    control,
    name: "propertyoverview.propertyoverviewtraffic_set",
  });

  React.useEffect(() => {
    if (!propertyoverviewtraffic_set) {
      return
    }
    SimpleArrayInit(propertyOverviewTrafficFields, propertyoverviewtraffic_set, setValue, isFocused, propertyOverviewTrafficRemove, propertyOverviewTrafficReplace, "propertyoverview.propertyoverviewtraffic_set")
  }, [propertyoverviewtraffic_set])

  React.useEffect(() => {
    trigger("propertyoverview.propertyoverviewtraffic_set")
  }, [propertyOverviewTrafficFields])
  return [propertyOverviewTrafficFields, propertyOverviewTrafficAppend, propertyOverviewTrafficRemove]
}

export const useInitPropertyOverviewUrbanPlan = (propertyoverviewurbanplan_set, setValue, isFocused, control, rules, trigger) => {
  const {
    fields: propertyOverviewUrbanPlanFields,
    replace: propertyOverviewUrbanPlanReplace,
    remove: propertyOverviewUrbanPlanRemove,
  } = useFieldArray({
    control,
    name: "propertyoverview.propertyoverviewurbanplan_set",
  });

  React.useEffect(() => {
    if (!propertyoverviewurbanplan_set) {
      return
    }

    let propertyoverviewurbanplanData = [];
    if (!Array.isArray(propertyoverviewurbanplan_set)) {
      return
    }

    propertyoverviewurbanplan_set.forEach((item) => {
      propertyoverviewurbanplanData.push(item);
    });

    const urbanPlanCount = 2;

    const defaultValue = {}
    Object.keys(rules).forEach((key) => {
      defaultValue[key] = null
    })
    for (let i = propertyoverviewurbanplanData.length; i < urbanPlanCount; i++) {
      propertyoverviewurbanplanData.push(defaultValue);
    }

    SimpleArrayInit(propertyOverviewUrbanPlanFields, propertyoverviewurbanplanData, setValue, isFocused, propertyOverviewUrbanPlanRemove, propertyOverviewUrbanPlanReplace, "propertyoverview.propertyoverviewurbanplan_set")
  }, [propertyoverviewurbanplan_set])

  React.useEffect(() => {
    trigger("propertyoverview.propertyoverviewurbanplan_set")
  }, [propertyOverviewUrbanPlanFields])
  return propertyOverviewUrbanPlanFields
}

export const useInitPropertyOverviewAdjacentRoad = (propertyoverviewadjacentroad_set, setValue, isFocused, control, trigger) => {
  const {
    fields: propertyOverviewAdjacentRoadFields,
    append: propertyOverviewAdjacentRoadAppend,
    replace: propertyOverviewAdjacentRoadReplace,
    remove: propertyOverviewAdjacentRoadRemove,
  } = useFieldArray({
    control,
    name: "propertyoverview.propertyoverviewadjacentroad_set",
  });


  React.useEffect(() => {
    if (!propertyoverviewadjacentroad_set) {
      return
    }
    SimpleArrayInit(propertyOverviewAdjacentRoadFields, propertyoverviewadjacentroad_set, setValue, isFocused, propertyOverviewAdjacentRoadRemove, propertyOverviewAdjacentRoadReplace, "propertyoverview.propertyoverviewadjacentroad_set")
  }, [propertyoverviewadjacentroad_set])

  React.useEffect(() => {
    trigger("propertyoverview.propertyoverviewadjacentroad_set")
  }, [propertyOverviewAdjacentRoadFields])
  return [propertyOverviewAdjacentRoadFields, propertyOverviewAdjacentRoadAppend, propertyOverviewAdjacentRoadRemove]
}

export const useInitSalesPlanDetail = (salesplandetail_set, setValue, isFocused, control, trigger) => {
  const {
    fields: salesPlanDetailFields,
    replace: salesPlanDetailReplace,
    append: salesPlanDetailAppend,
    remove: salesPlanDetailRemove,
  } = useFieldArray({ control, name: "salesplan.salesplandetail_set" });

  React.useEffect(() => {
    if (!salesplandetail_set) {
      return
    }
    SimpleArrayInit(salesPlanDetailFields, salesplandetail_set, setValue, isFocused, salesPlanDetailRemove, salesPlanDetailReplace, "salesplan.salesplandetail_set")
  }, [salesplandetail_set])

  React.useEffect(() => {
    trigger("salesplan.salesplandetail_set")
  }, [salesPlanDetailFields])
  return [salesPlanDetailFields, salesPlanDetailAppend, salesPlanDetailRemove]
}

export const useInitSalesPlan = (salesplan, setValue, isFocused) => {
  React.useEffect(() => {
    if (!salesplan) {
      return
    }
    SimpleInit(salesplan, setValue, isFocused, "salesplan")
  }, [salesplan])
}

export const useInitBuildingPlanningInformationDetail = (buildingplanninginformationdetail_set, setValue, isFocused, control, trigger) => {
  const {
    fields: buildingPlanningInformationDetailFields,
    replace: buildingPlanningInformationDetailReplace,
    append: buildingPlanningInformationDetailAppend,
    remove: buildingPlanningInformationDetailRemove,
  } = useFieldArray({ control, name: "buildingplanninginformationdetail_set" });

  React.useEffect(() => {
    if (!buildingplanninginformationdetail_set) {
      return
    }
    SimpleArrayInit(buildingPlanningInformationDetailFields, buildingplanninginformationdetail_set, setValue, isFocused, buildingPlanningInformationDetailRemove, buildingPlanningInformationDetailReplace, "buildingplanninginformationdetail_set")
  }, [buildingplanninginformationdetail_set])

  React.useEffect(() => {
    trigger("buildingplanninginformationdetail_set")
  }, [buildingPlanningInformationDetailFields])
  return [buildingPlanningInformationDetailFields, buildingPlanningInformationDetailAppend, buildingPlanningInformationDetailRemove]
}

export const useInitBuildingPlanningInformation = (buildingplanninginformation, setValue, isFocused) => {
  React.useEffect(() => {
    if (!buildingplanninginformation) {
      return
    }
    SimpleInit(buildingplanninginformation, setValue, isFocused, "buildingplanninginformation")
  }, [buildingplanninginformation])
}

export const useInitMstBusinessBalanceArea = (mstbusinessbalancearea, setValue, isFocused) => {
  React.useEffect(() => {
    if (!mstbusinessbalancearea) {
      return
    }
    SimpleInit(mstbusinessbalancearea, setValue, isFocused, "mstbusinessbalancearea")
  }, [mstbusinessbalancearea])
}

export const useInitCostLandRelatedCost = (costlandrelatedcost, setValue, isFocused) => {
  React.useEffect(() => {
    if (!costlandrelatedcost) {
      return
    }
    SimpleInit(costlandrelatedcost, setValue, isFocused, "costlandrelatedcost")
  }, [costlandrelatedcost])
}

export const useInitCostLandRelatedCostLandAcquisitionCost = (costlandrelatedcostlandacquisitioncost_set, setValue, isFocused, control, trigger) => {

  const {
    fields: costLandRelatedCostLandAcquisitionCostFields,
    replace: costLandRelatedCostLandAcquisitionCostReplace,
    remove: costLandRelatedCostLandAcquisitionCostRemove,
  } = useFieldArray({
    control,
    name: "costlandrelatedcost.costlandrelatedcostlandacquisitioncost_set",
  });

  React.useEffect(() => {
    let data = [];
    costlandrelatedcostlandacquisitioncost_set?.forEach(
      (item) => {
        data.push({
          id: item.id,
          land_acquisition_cost: item.values_or_default.land_acquisition_cost,
        });
      }
    );

    const limit = 2;
    for (let i = data.length; i < limit; i++) {
      data.push({
        id: "",
        land_acquisition_cost: "",
      });
    }

    SimpleArrayInit(costLandRelatedCostLandAcquisitionCostFields, data, setValue, isFocused, costLandRelatedCostLandAcquisitionCostRemove, costLandRelatedCostLandAcquisitionCostReplace, "costlandrelatedcost.costlandrelatedcostlandacquisitioncost_set")
  }, [costlandrelatedcostlandacquisitioncost_set])

  React.useEffect(() => {
    trigger("costlandrelatedcost.costlandrelatedcostlandacquisitioncost_set")
  }, [costLandRelatedCostLandAcquisitionCostFields])
  return costLandRelatedCostLandAcquisitionCostFields
}

export const useInitCostLandRelatedCostOther = (costlandrelatedcostother_set, setValue, isFocused, control, trigger) => {
  const {
    fields: costLandRelatedCostOtherFields,
    append: costLandRelatedCostOtherAppend,
    remove: costLandRelatedCostOtherRemove,
    replace: costLandRelatedCostOtherReplace,
  } = useFieldArray({
    control,
    name: "costlandrelatedcost.costlandrelatedcostother_set",
  });

  React.useEffect(() => {
    if (!costlandrelatedcostother_set) {
      return
    }
    SimpleArrayInit(costLandRelatedCostOtherFields, costlandrelatedcostother_set, setValue, isFocused, costLandRelatedCostOtherRemove, costLandRelatedCostOtherReplace, "costlandrelatedcost.costlandrelatedcostother_set")
  }, [costlandrelatedcostother_set])

  React.useEffect(() => {
    trigger("costlandrelatedcost.costlandrelatedcostother_set")
  }, [costLandRelatedCostOtherFields])
  return [costLandRelatedCostOtherFields, costLandRelatedCostOtherAppend, costLandRelatedCostOtherRemove]
}

export const useInitCostBuildingRelatedCost = (costbuildingrelatedcost, setValue, isFocused, control, trigger) => {
  const {
    fields: basicBuildingsRelatedFields,
    replace: basicBuildingsRelatedReplace,
  } = useFieldArray({
    control,
    name: "costbuildingrelatedcost.basic_buildings_related",
  });
  const {
    fields: waterworksBureauPaymentFields,
    replace: waterworksBureauPaymentReplace,
  } = useFieldArray({
    control,
    name: "costbuildingrelatedcost.waterworks_bureau_payment",
  });

  React.useEffect(() => {
    if (!costbuildingrelatedcost) {
      return
    }

    if (costbuildingrelatedcost.waterworks_bureau_payment) {
      waterworksBureauPaymentReplace(costbuildingrelatedcost.waterworks_bureau_payment)
    }
    if (costbuildingrelatedcost.basic_buildings_related) {
      basicBuildingsRelatedReplace(costbuildingrelatedcost.basic_buildings_related)
    }
    SimpleInit(costbuildingrelatedcost, setValue, isFocused, "costbuildingrelatedcost")
  }, [costbuildingrelatedcost])

  React.useEffect(() => {
    trigger("costbuildingrelatedcost.basic_buildings_related")
  }, [basicBuildingsRelatedFields])

  React.useEffect(() => {
    trigger("costbuildingrelatedcost.waterworks_bureau_payment")
  }, [waterworksBureauPaymentFields])
  return [basicBuildingsRelatedFields, waterworksBureauPaymentFields]
}

export const useInitCostBuildingRelatedCostOther = (costbuildingrelatedcostother_set, setValue, isFocused, control, trigger) => {
  const {
    fields: costBuildingRelatedCostOtherFields,
    append: costBuildingRelatedCostOtherAppend,
    remove: costBuildingRelatedCostOtherRemove,
    replace: costBuildingRelatedCostOtherReplace,
  } = useFieldArray({
    control,
    name: "costbuildingrelatedcost.costbuildingrelatedcostother_set",
  });

  React.useEffect(() => {
    if (!costbuildingrelatedcostother_set) {
      return
    }
    SimpleArrayInit(costBuildingRelatedCostOtherFields, costbuildingrelatedcostother_set, setValue, isFocused, costBuildingRelatedCostOtherRemove, costBuildingRelatedCostOtherReplace, "costbuildingrelatedcost.costbuildingrelatedcostother_set")
  }, [costbuildingrelatedcostother_set])

  React.useEffect(() => {
    trigger("costbuildingrelatedcost.costbuildingrelatedcostother_set")
  }, [costBuildingRelatedCostOtherFields])
  return [costBuildingRelatedCostOtherFields, costBuildingRelatedCostOtherAppend, costBuildingRelatedCostOtherRemove]
}

export const useInitCostSalesCost = (costsalescost, setValue, isFocused) => {
  React.useEffect(() => {
    if (!costsalescost) {
      return
    }
    SimpleInit(costsalescost, setValue, isFocused, "costsalescost")
  }, [costsalescost])
}
export const useInitContractTerm = (contractterm, setValue, isFocused) => {
  React.useEffect(() => {
    if (!contractterm) {
      return
    }
    SimpleInit(contractterm, setValue, isFocused, "contractterm")
  }, [contractterm])
}

export const useInitJudicialScrivener = (judicialscrivener, setValue, isFocused) => {
  React.useEffect(() => {
    if (!judicialscrivener) {
      return
    }
    SimpleInit(judicialscrivener, setValue, isFocused, "judicialscrivener")
  }, [judicialscrivener])
}

export const useInitTransmissionMatter = (transmissionmatter, setValue, isFocused) => {
  React.useEffect(() => {
    if (!transmissionmatter) {
      return
    }
    SimpleInit(transmissionmatter, setValue, isFocused, "transmissionmatter")
  }, [transmissionmatter])
}

export const useInitLesseeStatusDetail = (lesseestatusdetail_set, setValue, isFocused, control, trigger) => {
  const {
    fields: lesseeStatusFields,
    append: lesseeStatusAppend,
    replace: lesseeStatusReplace,
    remove: lesseeStatusRemove,
  } = useFieldArray({ control, name: "lesseestatus.lesseestatusdetail_set" });

  React.useEffect(() => {
    if (!lesseestatusdetail_set) {
      return
    }
    SimpleArrayInit(lesseeStatusFields, lesseestatusdetail_set, setValue, isFocused, lesseeStatusRemove, lesseeStatusReplace, "lesseestatus.lesseestatusdetail_set")
  }, [lesseestatusdetail_set])

  React.useEffect(() => {
    trigger("lesseestatus.lesseestatusdetail_set")
  }, [lesseeStatusFields])
  return [lesseeStatusFields, lesseeStatusAppend, lesseeStatusRemove]
}

export const useInitLesseeStatus = (lesseestatus, setValue, isFocused, errors) => {
  React.useEffect(() => {
    if (!lesseestatus) {
      return
    }
    SimpleInit(lesseestatus, setValue, isFocused, "lesseestatus", errors)
  }, [lesseestatus])
}

export const useInitBusinessProcess = (businessprocess, setValue, isFocused) => {
  React.useEffect(() => {
    if (!businessprocess) {
      return
    }
    SimpleInit(businessprocess, setValue, isFocused, "businessprocess")
  }, [businessprocess])
}

export const useInitPrivateRoadAgreement = (privateroadagreement_set, setValue, isFocused, control, trigger) => {
  const {
    fields: privateRoadAgreementFields,
    append: privateRoadAgreementAppend,
    replace: privateRoadAgreementReplace,
    remove: privateRoadAgreementRemove,
  } = useFieldArray({ control, name: "privateroadagreement_set" });

  React.useEffect(() => {
    if (!privateroadagreement_set) {
      return
    }
    SimpleArrayInit(privateRoadAgreementFields, privateroadagreement_set, setValue, isFocused, privateRoadAgreementRemove, privateRoadAgreementReplace, "privateroadagreement_set")
  }, [privateroadagreement_set])

  React.useEffect(() => {
    trigger("privateroadagreement_set")
  }, [privateRoadAgreementFields])
  return [privateRoadAgreementFields, privateRoadAgreementAppend, privateRoadAgreementRemove]
}

export const useInitAnnouncementMatterOther = (announcementmatterother, setValue, isFocused) => {
  React.useEffect(() => {
    if (!announcementmatterother) {
      return
    }
    SimpleInit(announcementmatterother, setValue, isFocused, "announcementmatterother")
  }, [announcementmatterother])
}

export const useInitContractContact = (contractcontact, setValue, isFocused) => {
  React.useEffect(() => {
    if (!contractcontact) {
      return
    }
    SimpleInit(contractcontact, setValue, isFocused, "contractcontact")
  }, [contractcontact])
}

export const useInitContractContactSeller = (contractcontactseller_set, setValue, isFocused, control, trigger) => {

  const {
    fields: contractContactSellerFields,
    append: contractContactSellerAppend,
    replace: contractContactSellerReplace,
    remove: contractContactSellerRemove,
  } = useFieldArray({ control, name: "contractcontactseller_set" });

  React.useEffect(() => {
    if (!contractcontactseller_set) {
      return
    }
    SimpleArrayInit(contractContactSellerFields, contractcontactseller_set, setValue, isFocused, contractContactSellerRemove, contractContactSellerReplace, "contractcontactseller_set")
  }, [contractcontactseller_set])

  React.useEffect(() => {
    trigger("contractcontactseller_set")
  }, [contractContactSellerFields])
  return [contractContactSellerFields, contractContactSellerAppend, contractContactSellerRemove]
}

export const useInitContractContactLandowner = (contractcontactlandowner_set, setValue, isFocused, control, trigger) => {
  const {
    fields: contractContactLandownerFields,
    append: contractContactLandownerAppend,
    replace: contractContactLandownerReplace,
    remove: contractContactLandownerRemove,
  } = useFieldArray({ control, name: "contractcontactlandowner_set" });

  React.useEffect(() => {
    if (!contractcontactlandowner_set) {
      return
    }
    SimpleArrayInit(contractContactLandownerFields, contractcontactlandowner_set, setValue, isFocused, contractContactLandownerRemove, contractContactLandownerReplace, "contractcontactlandowner_set")
  }, [contractcontactlandowner_set])

  React.useEffect(() => {
    trigger("contractcontactlandowner_set")
  }, [contractContactLandownerFields])
  return [contractContactLandownerFields, contractContactLandownerAppend, contractContactLandownerRemove]
}

export const useInitOtherBroker = (otherbroker, setValue, isFocused) => {
  React.useEffect(() => {
    if (!otherbroker) {
      return
    }
    SimpleInit(otherbroker, setValue, isFocused, "otherbroker")
  }, [otherbroker])
}

export const useInitOutsourcingCompany = (outsourcingcompany, setValue, isFocused) => {
  React.useEffect(() => {
    if (!outsourcingcompany) {
      return
    }
    SimpleInit(outsourcingcompany, setValue, isFocused, "outsourcingcompany")
  }, [outsourcingcompany])
}

export const useInitContractAmount = (contractamount, setValue, isFocused) => {
  React.useEffect(() => {
    if (!contractamount) {
      return
    }
    SimpleInit(contractamount, setValue, isFocused, "contractamount")
  }, [contractamount])
}

export const useInitContractAmountDetail = (contractamountdetail_set, control, trigger) => {
  const {
    fields: contractAmountDetailFields,
    append: contractAmountDetailAppend,
    replace: contractAmountDetailReplace,
    remove: contractAmountDetailRemove,
  } = useFieldArray({
    control,
    name: "contractamount.contractamountdetail_set",
  });

  React.useEffect(() => {
    if (!contractamountdetail_set) {
      return
    }
    const data = contractamountdetail_set ?? [];
    const createInitialData = (type, count) => {
      const initData = contractamountdetail_set.filter(
        (item) => item.type === type
      );
      for (let i = initData.length; i < count; i++) {
        data.push({
          id: "",
          type: type,
          amount: "",
          payment_methods: "",
          note: "",
          payment_deadline: "",
        });
      }
    };

    //DBにデータが存在するもので、手付金と仲介手数料のもの
    const temp_constractamountdetail = contractamountdetail_set.filter(
      (item) => (item.type === 1 || item.type === 2)
    );
    if (temp_constractamountdetail.length <= 5){
      for (let i = temp_constractamountdetail.length; i < 5; i++) {
        data.push({
          id: "",
          type: 1,
          amount: "",
          payment_methods: "",
          note: "",
          payment_deadline: "",
        });
      }
    }

    createInitialData(CONTRACT_AMOUNT_DETAIL_TYPE.REMAINING_PRICE, 1);
    createInitialData(
      CONTRACT_AMOUNT_DETAIL_TYPE.REMAINING_PRICE_INTERMEDIARY_CHARGE,
      1
    );
    createInitialData(CONTRACT_AMOUNT_DETAIL_TYPE.BUSINESS_CONSIGNMENT_FEE, 1);
    createInitialData(CONTRACT_AMOUNT_DETAIL_TYPE.RESERVATION_MONEY, 1);


    const contractAmountDetailData = data.flatMap((item, i) => ({ ...item, index: i }))
    contractAmountDetailData.map((item, index) => {
      Object.keys(item).forEach((key) => {
        if (Object.keys(item.values_or_default ?? {}).includes(key)) {
          contractAmountDetailData[index][key] = item.values_or_default[key]
        }
      })
    });

    contractAmountDetailReplace(contractAmountDetailData);
  }, [contractamountdetail_set])

  React.useEffect(() => {
    trigger("contractamount.contractamountdetail_set")
  }, [contractAmountDetailFields])
  return [contractAmountDetailFields, contractAmountDetailAppend, contractAmountDetailRemove]
}

export const useInitOutsourcingReport = (outsourcingreport, setValue, isFocused) => {
  React.useEffect(() => {
    if (!outsourcingreport) {
      return
    }
    SimpleInit(outsourcingreport, setValue, isFocused, "outsourcingreport")
  }, [outsourcingreport])
}

export const useInitOutsourcingReportPaymentInformation = (outsourcingreportpaymentinformation_set, setValue, isFocused, control, trigger) => {
  const {
    fields: outsourcingReportPaymentInformationFields,
    replace: outsourcingReportPaymentInformationReplace,
    remove: outsourcingReportPaymentInformationRemove,
  } = useFieldArray({
    control,
    name: "outsourcingreport.outsourcingreportpaymentinformation_set",
  });
  React.useEffect(() => {
    if (!outsourcingreportpaymentinformation_set) {
      return
    }
    let data = [];
    outsourcingreportpaymentinformation_set.forEach(
      (item) => {
        data.push(item);
      }
    );

    const count = 2;
    for (let i = data.length; i < count; i++) {
      data.push({
        id: "",
        date_of_payment: "",
        payment_amount: "",
      });
    }

    SimpleArrayInit(outsourcingReportPaymentInformationFields, data, setValue, isFocused, outsourcingReportPaymentInformationRemove, outsourcingReportPaymentInformationReplace, "outsourcingreport.outsourcingreportpaymentinformation_set")
  }, [outsourcingreportpaymentinformation_set])

  React.useEffect(() => {
    trigger("outsourcingreport.outsourcingreportpaymentinformation_set")
  }, [outsourcingReportPaymentInformationFields])
  return outsourcingReportPaymentInformationFields
}

export const useInitSettlementConfirmation = (settlementconfirmation, setValue, isFocused) => {
  React.useEffect(() => {
    if (!settlementconfirmation) {
      return
    }
    SimpleInit(settlementconfirmation, setValue, isFocused, "settlementconfirmation")
  }, [settlementconfirmation])
}

export const useInitSettlementConfirmationContractTerm = (settlementconfirmationcontractterm_set, setValue, isFocused) => {
  React.useEffect(() => {
    if (!settlementconfirmationcontractterm_set) {
      return
    }
    settlementconfirmationcontractterm_set.map((item) => {
      const type = item.type;
      const baseName = `settlementconfirmation.settlementconfirmationcontractterm_set.${type}`
      SimpleInit(item, setValue, isFocused, baseName)
    });
  }, [settlementconfirmationcontractterm_set])
}

export const usePlanningRequest = (planningrequest, setValue, isFocused) => {
  React.useEffect(() => {
    if (!planningrequest) {
      return
    }
    SimpleInit(planningrequest, setValue, isFocused, "planningrequest")
  }, [planningrequest])
}

export const useLimitOnLawsAndRegulations = (limitonlawsandregulations, setValue, isFocused) => {
  React.useEffect(() => {
    if (!limitonlawsandregulations) {
      return
    }
    SimpleInit(limitonlawsandregulations, setValue, isFocused, "limitonlawsandregulations")
  }, [limitonlawsandregulations])
}

export const useValidAreaArrangement = (validareaarrangement, setValue, isFocused) => {
  React.useEffect(() => {
    if (!validareaarrangement) {
      return
    }
    SimpleInit(validareaarrangement, setValue, isFocused, "validareaarrangement")
  }, [validareaarrangement])
}

export const useLifeLineGarbageWalkwayEtc = (lifelinegarbagewalkwayetc, setValue, isFocused) => {
  React.useEffect(() => {
    if (!lifelinegarbagewalkwayetc) {
      return
    }
    SimpleInit(lifelinegarbagewalkwayetc, setValue, isFocused, "lifelinegarbagewalkwayetc")
  }, [lifelinegarbagewalkwayetc])
}

export const useRoadRelationship = (roadrelationship, setValue, isFocused) => {
  React.useEffect(() => {
    if (!roadrelationship) {
      return
    }
    SimpleInit(roadrelationship, setValue, isFocused, "roadrelationship")
  }, [roadrelationship])
}

export const useSurroundingEnvironment = (surroundingenvironment, setValue, isFocused) => {
  React.useEffect(() => {
    if (!surroundingenvironment) {
      return
    }
    SimpleInit(surroundingenvironment, setValue, isFocused, "surroundingenvironment")
  }, [surroundingenvironment])
}

export const useFieldConstruction = (fieldconstruction, setValue, isFocused) => {
  React.useEffect(() => {
    if (!fieldconstruction) {
      return
    }
    SimpleInit(fieldconstruction, setValue, isFocused, "fieldconstruction")
  }, [fieldconstruction])
}

export const useCompanyPayeeBrokerName = () => {
  const getCompanyPayeeBrokerNameChoices = React.useCallback((company_payee_broker_name) => {
    setCompanyPayeeBrokerNameIsLoading(true)
    setCompanyPayeeBrokerNameChoices([])
    const success = (response) => {
      setCompanyPayeeBrokerNameChoices(response.data.choices)
    }
    const finallyCallBack = () => {
      setCompanyPayeeBrokerNameIsLoading(false)
    }
    getCompanyPayeeBrokerNameChoicesApi({ company_payee_broker_name: company_payee_broker_name }, success, null, finallyCallBack)
  })
  const [companyPayeeBrokerNameChoices, setCompanyPayeeBrokerNameChoices] = React.useState([])
  const [companyPayeeBrokerNameIsLoading, setCompanyPayeeBrokerNameIsLoading] = React.useState()

  return [getCompanyPayeeBrokerNameChoices, companyPayeeBrokerNameChoices, companyPayeeBrokerNameIsLoading]
}