import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { ButtonAndMenu } from "../molecules/ButtonAndMenu";
import { backendLogout } from "../../api/Auth";
import { FrontendUrls } from "../Urls";
import { Snackbar } from "../atoms/Snackbar";
import { UserContext } from "../../Context";
import { LogoIcon } from "../atoms/LogoIcon";
import { Link, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import { HamburgerMenuIcon } from "../atoms/HamburgerMenuIcon";
import { PropertyEditInfo } from "./PropertyEditInfo";

export const Header = React.memo(function header(props) {
  const { cost_price, gross_profit_per_building } = props
  const [errorMessage, setErrorMessage] = React.useState("");
  const user = React.useContext(UserContext);

  const menus = React.useMemo(() => {
    const result = [
      {
        label: "ユーザー設定",
        onClick: () => {
          window.open(FrontendUrls.userSettings, "_self");
        },
      },
    ]
    if (user.is_accessible_admin) {
      result.push(
        {
          isDivider: true,
          label: "管理画面"
        },
        {
          label: "計算式一覧",
          onClick: () => {
            window.open(FrontendUrls.adminFormulas, "_self");
          },
        },
        {
          label: "物件一括共有",
          onClick: () => {
            window.open(FrontendUrls.adminPropertyShare, "_self");
          },
        },
      )
    }
    result.push(
      {
        isDivider: true,
      },
      {
        label: "ログアウト",
        onClick: () => {
          backendLogout()
            .then(() => {
              window.open(FrontendUrls.login, "_self");
            })
            .catch(() => {
              setErrorMessage("ログアウトに失敗しました。");
            });
        },
      },
    )
    return result
  }, [user])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Snackbar
        closeAction={() => {
          setErrorMessage("");
        }}
        message={errorMessage}
        severity={"error"}
      />
      <AppBar color="white">
        <Toolbar>
          <Link color="black" href={FrontendUrls.properties}  underline="none">
            <LogoIcon sx={{height: "100%", width: "50px"}} />
            <Typography sx={{fontSize: 30}} component="span">物件計画書</Typography>
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          {props.showEditInfo &&
            <PropertyEditInfo
              cost_price={cost_price}
              gross_profit_per_building={gross_profit_per_building}
            />
          }
          <ButtonAndMenu
            buttonName={isMobile ? <HamburgerMenuIcon /> : (user?.username ?? "")}
            menus={menus}
            sx={{ml: "auto"}}
            useDivider={false}
          />
        </Toolbar>
      </AppBar>
      <Toolbar/>
    </Box>
  );
});

Header.propTypes = {
  cost_price: PropTypes.number,
  gross_profit_per_building: PropTypes.number,
  showEditInfo: PropTypes.bool,
};
Header.defaultProps = {
  showEditInfo: false,
};