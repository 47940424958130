import * as React from "react";
import PropTypes from "prop-types";
import {
  Grid, Stack,
} from "@mui/material";
import * as validationRules from "../../utils/ValidationRules";
import { SelectForm } from "../atoms/SelectForm";
import { DeleteButton } from "../atoms/DeleteButton";
import { HiddenInput } from "../atoms/HiddenInput";

export const PropertyShareInputs = (props) => {
  const { control, index, handleRemove, choices, setValue } =
    props;
  const rules = {
    shared_user: {
      ...validationRules.required(),
    },
    permission: {
      ...validationRules.required(),
    },
  };
  return (
    <Stack direction="row" spacing={1}>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <HiddenInput
          control={control}
          name={`share.${index}.id`}
        />
        <Grid item xs={6}>
          <SelectForm
            control={control}
            name={`share.${index}.shared_user`}
            rules={rules.shared_user}
            choices={choices.users}
            setValue={setValue}
            disableClearable={true}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectForm
            control={control}
            name={`share.${index}.permission`}
            rules={rules.permission}
            choices={choices.permissions}
            setValue={setValue}
            disableClearable={true}
          />
        </Grid>
      </Grid>
      <DeleteButton onClick={handleRemove} />
    </Stack>
  );
};

PropertyShareInputs.propTypes = {
  register: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  control: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
}