import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Grid, InputAdornment, Paper, Stack, Typography } from "@mui/material";
import { HiddenInput } from "../atoms/HiddenInput";
import { SelectForm } from "../atoms/SelectForm";
import { CONTRACT_AMOUNT_DETAIL_TYPE } from "../../utils/Constants";
import { DeleteButton } from "../atoms/DeleteButton";
import { AddButton } from "../atoms/AddButton";
import { toCircled } from "../../utils/NumberUtils";
import { isMobile } from "react-device-detect";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { DialogSubContent } from "../molecules/DialogSubContent";
import _ from "lodash";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitContractAmount, useInitContractAmountDetail } from "../../utils/CustomHook";
import { onBlurCommonAction, removeChild, saveChildForPropertyEdit } from "../../utils/PropertyEdit";

export const ContractAmountForms = React.memo(function contractAmountForms({
  property,
  initialized,
  setOriginPropertyData,
  contractamount,
  toggleViewport,
  formPaperVariant,
  formPaperElevation,
  ...props
}) {
  const [isLoading, setIsLoading] = React.useState(false)
  const loadingComplete = React.useCallback(() => {
    setIsLoading(false)
  }, [])

  const [isOpenDenominationOnContractModal, setIsOpenDenominationOnContractModal] = React.useState(false)
  const [isOpenIntermediateMoneyModal, setIsOpenIntermediateMoneyModal] = React.useState(false)
  const [isOpenRemainingPriceModal, setIsOpenRemainingPriceModal] = React.useState(false)
  const [isOpenReservationMoneyModal, setIsOpenReservationMoneyModal] = React.useState(false)
  const [isOpenOtherModal, setIsOpenOtherModal] = React.useState(false)

  const [choices, setChoices] = React.useState({});
  const [propertyId, setPropertyId] = React.useState(null)

  const [denominationOnContractData, setDenominationOnContractData] = React.useState([])
  const [remainingPriceData, setRemainingPriceData] = React.useState([])
  const [reservationMoneyData, setReservationMoneyData] = React.useState([])

  const [isFocused, setFocusInputName] = useFocusInput()
  const handleFocus = React.useCallback((name) => {
    setFocusInputName(name)
  }, [])

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    methods,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange",
  });

  useInitContractAmount(contractamount, setValue, isFocused)
  const [contractAmountDetailFields, contractAmountDetailAppend, contractAmountDetailRemove] = useInitContractAmountDetail(contractamount.contractamountdetail_set, control, trigger)

  React.useEffect(() => {
    if (!contractamount) {
      return;
    }
    initialized();
  }, [contractamount]);

  React.useEffect(() => {
    console.log("----------contractAmountDetailFields----------")
    console.log(contractAmountDetailFields)
    console.log("----------contractAmountDetailFields----------")


    var newDenominationOnContractData = []
    // contractAmountDetailFields.filter((item) => item.type == CONTRACT_AMOUNT_DETAIL_TYPE.EARNEST)
    //   .sort((a, b) => a.display_order < b.display_order ? -1 : 1)
    //   .forEach((item, index) => {
    //     const circleNum = toCircled(index + 1);
    //     newDenominationOnContractData.push({
    //       item: item,
    //       title: `手付金${circleNum}`,
    //       amountName: undefined,
    //     })
    //   })

    // contractAmountDetailFields.filter((item) => item.type == CONTRACT_AMOUNT_DETAIL_TYPE.INTERMEDIARY_CHARGE_ON_CONTRACT)
    //   .forEach((item) => {
    //     newDenominationOnContractData.push({
    //       item: item,
    //       title: "仲介手数料",
    //       amountName: undefined,
    //     })
    //   })

    // contractAmountDetailFields.filter((item) => item.type == CONTRACT_AMOUNT_DETAIL_TYPE.REVENUE_STAMP_CASH_ON_HAND_SENTENCE)
    //   .forEach((item) => {
    //     newDenominationOnContractData.push({
    //       item: item,
    //       title: "印紙(現金文)",
    //       amountName: undefined,
    //     })
    //   })
    contractAmountDetailFields.filter((item) => item.type == CONTRACT_AMOUNT_DETAIL_TYPE.REVENUE_STAMP_CASH_ON_HAND_SENTENCE ||
                                                item.type == CONTRACT_AMOUNT_DETAIL_TYPE.EARNEST ||
                                                item.type == CONTRACT_AMOUNT_DETAIL_TYPE.INTERMEDIARY_CHARGE_ON_CONTRACT)
      .forEach((item) => {
        newDenominationOnContractData.push({
              item: item,
              amountName: undefined,
        })
      })
    if (newDenominationOnContractData && newDenominationOnContractData !== [] && !_.isEqual(newDenominationOnContractData, denominationOnContractData)) {
      console.log(newDenominationOnContractData)
      setDenominationOnContractData(newDenominationOnContractData)
    }

    var newRemainingPriceData = []
    contractAmountDetailFields.filter((item) => item.type == CONTRACT_AMOUNT_DETAIL_TYPE.REMAINING_PRICE)
      .forEach((item) => {
        newRemainingPriceData.push({
          item: item,
          title: "残代金",
          amountName: "contractamount.remaining_price_amount",
        })
      })
    contractAmountDetailFields.filter((item) => item.type == CONTRACT_AMOUNT_DETAIL_TYPE.REMAINING_PRICE_INTERMEDIARY_CHARGE)
      .forEach((item) => {
        newRemainingPriceData.push({
          item: item,
          title: "仲介手数料",
          amountName: "contractamount.remaining_price_intermediary_charge_amount",
        })
      })
    contractAmountDetailFields.filter((item) => item.type == CONTRACT_AMOUNT_DETAIL_TYPE.BUSINESS_CONSIGNMENT_FEE)
      .forEach((item) => {
        newRemainingPriceData.push({
          item: item,
          title: "業務委託料",
          amountName: "contractamount.business_consignment_fee_amount",
        })
      })
    if (newRemainingPriceData && newRemainingPriceData !== [] && !_.isEqual(newRemainingPriceData, remainingPriceData)) {
      setRemainingPriceData(newRemainingPriceData)
    }

    var newReservationMoneyData = []
    contractAmountDetailFields.filter((item) => item.type == CONTRACT_AMOUNT_DETAIL_TYPE.RESERVATION_MONEY)
      .forEach((item) => {
        newReservationMoneyData.push({
          item: item,
          title: "留保金",
          amountName: undefined,
        })
      })
    if (newReservationMoneyData && newReservationMoneyData !== [] && !_.isEqual(newReservationMoneyData, reservationMoneyData)) {
      setReservationMoneyData(newReservationMoneyData)
    }
  }, [contractAmountDetailFields]);

  React.useEffect(() => {
    setChoices(props.choices);
  }, [props.choices]);

  React.useEffect(() => {
    setPropertyId(property.id)
  }, [property])

  const saveCommonParams = React.useMemo(() => [
    errors, propertyId, clearErrors, setOriginPropertyData, setError
  ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

  const removeChildCommonParams = React.useMemo(() => [
    propertyId, getValues, setOriginPropertyData,
  ], [propertyId, getValues, setOriginPropertyData])

  const onBlurForContractAmount = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "contractamount",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const createContractAmountDetail = () => {
    const index = contractAmountDetailFields.reduce((a, b) => a.index > b.index ? a.index : b.index) + 1
    const data = {
      type: CONTRACT_AMOUNT_DETAIL_TYPE.INTERMEDIATE_MONEY,
      index: index,
    };
    contractAmountDetailAppend(data);
  };
  ////金種の詳細を編集した際に発火するトリガー
  const onBlurForContractAmountDetail = React.useCallback((e) => {
    console.log("onBlurForContractAmountDetail内")
    console.log(setFocusInputName)
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "contract_amount", "contractamount", "contractamountdetail_set"
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId]);

  const handleRemoveForContractAmountDetail = React.useCallback((index) => {
    removeChild(...removeChildCommonParams, contractAmountDetailRemove, index, "contractamount", "contractamountdetail_set")
  }, [propertyId]);

  const rules = React.useMemo(() => ({
    contractamount: {
      revenue_stamp_burden_classification: {
        onBlur: onBlurForContractAmount,
      },
      revenue_stamp_our_burden: {
        onBlur: onBlurForContractAmount,
      },
      revenue_stamp_substance_bringing: {
        onBlur: onBlurForContractAmount,
      },
      revenue_stamp_bringing_amount: {
        onBlur: onBlurForContractAmount,
      },
      revenue_stamp_expenses_reimbursement: {
        onBlur: onBlurForContractAmount,
      },
      tax_withholding: {
        onBlur: onBlurForContractAmount,
      },
      prepaid_collection_amount: {
        onBlur: onBlurForContractAmount,
      },
      remaining_price_collection_amount: {
        onBlur: onBlurForContractAmount,
      },
      total_collection_amount: {
        onBlur: onBlurForContractAmount,
      },
      contractamountdetail_set: {   /////契約時金種の詳細を編集するための項目
        type : {
          onBlur: onBlurForContractAmountDetail,
        },
        amount: {
          onBlur: onBlurForContractAmountDetail,
        },
        payment_methods: {
          onBlur: onBlurForContractAmountDetail,
        },
        note: {
          onBlur: onBlurForContractAmountDetail,
        },
        payment_deadline: {
          onBlur: onBlurForContractAmountDetail,
        },
      },
    },
  }), [onBlurForContractAmount, onBlurForContractAmountDetail]);

  const contractAmountDetailLabels = React.useMemo(() => ({
    amount: "金額",
    payment_method: "金種",
    note: "備考",
    payment_deadline: "支払期日",
  }), [])

  const isAddIntermediateMoney = props.disabled || contractamount.MAX_ROW_CONTRACT_AMOUNT_DETAIL_INTERMEDIATE_MONEY <= contractAmountDetailFields.filter((item) => item.type == CONTRACT_AMOUNT_DETAIL_TYPE.INTERMEDIATE_MONEY).length
  const AddIntermediateMoneyButton = () => (
    <AddButton
      onClick={createContractAmountDetail}
      disabled={isAddIntermediateMoney}
    />
  )

  const getIntermediateMoneyData = () => {
    var data = []
    contractAmountDetailFields.filter((item) => item.type == CONTRACT_AMOUNT_DETAIL_TYPE.INTERMEDIATE_MONEY)
      .forEach((item, index) => {
        const circleNum = toCircled(index + 1);
        data.push({
          item: item,
          title: `中間金${circleNum}`,
          amountName: undefined,
        })
      })

    return data
  }

  const propertyEditFormDialogCloseAction = React.useCallback(() => {
    toggleViewport()
  }, [])

  const formAreaClickedAction = React.useCallback((additionalProcess) => {
    if (!isMobile) {
      return
    }
    if (additionalProcess) {
      additionalProcess()
    }
    setIsLoading(true)
    toggleViewport()
  }, [])

  const commonInputProps = React.useMemo(() => ({
    control: control,
    onFocus: handleFocus,
  }), [control, handleFocus])

  return (
    <PropertyEditForms title="契約連絡表 - 金額">
      <LoadingProgress open={isLoading} useCircular={false} />
      <FormProvider {...methods}>
        <PropertyEditFormDialog
          isOpen={isOpenDenominationOnContractModal}
          handleClose={() => {
            setIsOpenDenominationOnContractModal(false)
            propertyEditFormDialogCloseAction()
          }}
          title="契約時金種"
          openedAction={loadingComplete}
          contentSx={{ px: 0, pt: 0 }}
          content={
            <CostForm
              commonInputProps={commonInputProps}
              choices={choices}
              rules={rules}
              setValue={setValue}
              labels={contractAmountDetailLabels}
              disabled={props.disabled}
              data={denominationOnContractData}
              isModal={false}
              isContractAmount={true}
              totalName="contractamount.total_denomination_on_contract"
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenIntermediateMoneyModal}
          handleClose={() => {
            setIsOpenIntermediateMoneyModal(false)
            propertyEditFormDialogCloseAction()
          }}
          title={
            <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
              中間金
              <AddIntermediateMoneyButton/>
            </Stack>
          }
          openedAction={loadingComplete}
          contentSx={{ px: 0, pt: 0 }}
          content={
            <>
              <CostForm
                commonInputProps={commonInputProps}
                choices={choices}
                rules={rules}
                labels={contractAmountDetailLabels}
                setValue={setValue}
                handleRemoveForContractAmountDetail={handleRemoveForContractAmountDetail}
                disabled={props.disabled}
                isModal={true}
                data={getIntermediateMoneyData()}
                totalName="contractamount.total_intermediate_money"
              />
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", mt: 1 }}
                onClick={!(isAddIntermediateMoney) ? createContractAmountDetail : undefined}
              >
                <Typography sx={{ color: (isAddIntermediateMoney) ? "gray.main" : "darkGreen.main" }}>
                  追加
                </Typography>
                <AddButton disabled={isAddIntermediateMoney} sx={{ ml: 1 }} />
              </Stack>
            </>
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenRemainingPriceModal}
          handleClose={() => {
            setIsOpenRemainingPriceModal(false)
            propertyEditFormDialogCloseAction()
          }}
          title={"残代金"}
          contentSx={{ px: 0, pt: 0 }}
          openedAction={loadingComplete}
          content={
            <CostForm
              commonInputProps={commonInputProps}
              choices={choices}
              rules={rules}
              labels={contractAmountDetailLabels}
              setValue={setValue}
              disabled={props.disabled}
              isModal={true}
              data={remainingPriceData}
              totalName="contractamount.total_remaining_price_amount"
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenReservationMoneyModal}
          handleClose={() => {
            setIsOpenReservationMoneyModal(false)
            propertyEditFormDialogCloseAction()
          }}
          title={"残金留保"}
          openedAction={loadingComplete}
          contentSx={{ px: 0, pt: 0 }}
          content={
            <CostForm
              commonInputProps={commonInputProps}
              choices={choices}
              rules={rules}
              labels={contractAmountDetailLabels}
              setValue={setValue}
              disabled={props.disabled}
              isModal={true}
              data={reservationMoneyData}
              totalName="contractamount.total_amount"
              totalLabel="合計"
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenOtherModal}
          handleClose={() => {
            setIsOpenOtherModal(false)
            propertyEditFormDialogCloseAction()
          }}
          title={"その他"}
          openedAction={loadingComplete}
          content={
            <OtherForm
              commonInputProps={commonInputProps}
              choices={choices}
              rules={rules}
              setValue={setValue}
              disabled={props.disabled}
            />
          }
        />

        <form>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12}>
                  <ContractAmountDetailHeader
                    title="契約時金種"
                    amount={contractAmountDetailLabels.amount}
                    payment_method={contractAmountDetailLabels.payment_method}
                    note={contractAmountDetailLabels.note}
                  />
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenDenominationOnContractModal(true))}
                  >
                    <CostForm
                      commonInputProps={commonInputProps}
                      choices={choices}
                      rules={rules}
                      setValue={setValue}
                      labels={contractAmountDetailLabels}
                      disabled={props.disabled}
                      data={denominationOnContractData}
                      isContractAmount={true} //契約時金種かどうか
                      isModal={false}
                      totalName="contractamount.total_denomination_on_contract"
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <ContractAmountDetailHeader
                    title={
                      <>
                        中間金
                        <AddIntermediateMoneyButton/>
                      </>
                    }
                    note={contractAmountDetailLabels.payment_deadline}
                  />
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenIntermediateMoneyModal(true))}
                  >
                    <CostForm
                      commonInputProps={commonInputProps}
                      choices={choices}
                      rules={rules}
                      labels={contractAmountDetailLabels}
                      setValue={setValue}
                      handleRemoveForContractAmountDetail={handleRemoveForContractAmountDetail}
                      disabled={props.disabled}
                      isModal={false}
                      data={getIntermediateMoneyData()}
                      totalName="contractamount.total_intermediate_money"
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <ContractAmountDetailHeader
                    title="残代金"
                    amount=""
                    payment_method=""
                    note=""
                  />
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenRemainingPriceModal(true))}
                  >
                    <CostForm
                      commonInputProps={commonInputProps}
                      choices={choices}
                      rules={rules}
                      labels={contractAmountDetailLabels}
                      setValue={setValue}
                      disabled={props.disabled}
                      isModal={false}
                      data={remainingPriceData}
                      totalName="contractamount.total_remaining_price_amount"
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <ContractAmountDetailHeader
                    title="残金留保"
                    amount=""
                    payment_method=""
                    note=""
                  />
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenReservationMoneyModal(true))}
                  >
                    <CostForm
                      commonInputProps={commonInputProps}
                      choices={choices}
                      rules={rules}
                      labels={contractAmountDetailLabels}
                      setValue={setValue}
                      disabled={props.disabled}
                      isModal={false}
                      data={reservationMoneyData}
                      totalName="contractamount.total_amount"
                      totalLabel="合計"
                    />
                  </Paper>
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenOtherModal(true))}
              >
                <OtherForm
                  commonInputProps={commonInputProps}
                  choices={choices}
                  rules={rules}
                  setValue={setValue}
                  disabled={props.disabled}
                />
              </Paper>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </PropertyEditForms>
  );
});

ContractAmountForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  contractamount: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

ContractAmountForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const ContractAmountDetailHeader = (props) => {
  const { title, amount, payment_method, note } = props;
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12} md={2}>
        <Typography variant="subtitle1">{title}</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        {amount}
      </Grid>
      <Grid item xs={12} md={3}>
        {payment_method}
      </Grid>
      <Grid item xs={12} md={4}>
        {note}
      </Grid>
    </Grid>
  );
};

ContractAmountDetailHeader.propTypes = {
  title: PropTypes.any,
  amount: PropTypes.string,
  payment_method: PropTypes.string,
  note: PropTypes.string,
};
ContractAmountDetailHeader.defaultProps = {
  title: "",
  amount: "",
  payment_method: "",
  note: "",
};

///契約時金種タイトルを「手付金」「仲介手数料」の選択形式にするためのコンポ―ネント  
const ContractAmountDetailFormOnContract = (props) => {
  const {item, amountName, commonInputProps, rules, choices, setValue, isModal, labels} = props;
  const index = item.index;
  const type = item.type;

  const Inputs = () => (
    <>
      <Grid item xs={isModal ? 12 : 3}>
        <HiddenInput
          {...commonInputProps}
          name={`contractamount.contractamountdetail_set.${index}.id`}
        />
        <HiddenInput
          {...commonInputProps}
          name={`contractamount.contractamountdetail_set.${index}.type`}
        />
        <TextField
          {...commonInputProps}
          name={
            amountName ??
            `contractamount.contractamountdetail_set.${index}.amount`
          }
          label={isModal && labels.amount}
          rules={rules.contractamount.contractamountdetail_set.amount}
          type="number"
          disabled={
            type === CONTRACT_AMOUNT_DETAIL_TYPE.REMAINING_PRICE ||
            type ===
            CONTRACT_AMOUNT_DETAIL_TYPE.REMAINING_PRICE_INTERMEDIARY_CHARGE ||
            type === CONTRACT_AMOUNT_DETAIL_TYPE.BUSINESS_CONSIGNMENT_FEE ||
            props.disabled
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 3}>
        <SelectForm
          {...commonInputProps}
          label={isModal && labels.payment_method}
          name={`contractamount.contractamountdetail_set.${index}.payment_methods`}
          rules={rules.contractamount.contractamountdetail_set.payment_methods}
          choices={choices?.contract_amount_detail_payment_methods}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 4}>
        <TextField
          {...commonInputProps}
          label={isModal && labels.note}
          name={`contractamount.contractamountdetail_set.${index}.note`}
          rules={rules.contractamount.contractamountdetail_set.note}
          disabled={props.disabled}
        />
      </Grid>
    </>
  )

  return (
    <>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} md={2}>
            <SelectForm
              {...commonInputProps}
              label=""
              name={`contractamount.contractamountdetail_set.${index}.type`}
              rules={
                rules.contractamount.contractamountdetail_set.type
              }
              choices={choices?.contract_amount_type}
              disabled={false}
              setValue={setValue}
            />
            </Grid>
          <Inputs/>
        </Grid>
    </>
  )
}

ContractAmountDetailFormOnContract.propTypes = {
  item: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  amountName: PropTypes.string,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  handleRemoveForContractAmountDetail: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
};
ContractAmountDetailFormOnContract.defaultProps = {
  disabled: false,
};


//手付金・仲介手数料以外は埋め込まれているテキストを使用する
const ContractAmountDetailForm = (props) => {
  const {
    item,
    title,
    amountName,
    commonInputProps,
    rules,
    choices,
    handleRemoveForContractAmountDetail,
    setValue,
    isModal,
    labels,
  } = props;
  const index = item.index;
  const type = item.type;
  
  const isIntermediateMoney = type === CONTRACT_AMOUNT_DETAIL_TYPE.INTERMEDIATE_MONEY

  const Inputs = () => (
    <>
      <Grid item xs={isModal ? 12 : 3}>
        <HiddenInput
          {...commonInputProps}
          name={`contractamount.contractamountdetail_set.${index}.id`}
        />
        <HiddenInput
          {...commonInputProps}
          name={`contractamount.contractamountdetail_set.${index}.type`}
        />
        <TextField
          {...commonInputProps}
          name={
            amountName ??
            `contractamount.contractamountdetail_set.${index}.amount`
          }
          label={isModal && labels.amount}
          rules={rules.contractamount.contractamountdetail_set.amount}
          type="number"
          disabled={
            type === CONTRACT_AMOUNT_DETAIL_TYPE.REMAINING_PRICE ||
            type ===
            CONTRACT_AMOUNT_DETAIL_TYPE.REMAINING_PRICE_INTERMEDIARY_CHARGE ||
            type === CONTRACT_AMOUNT_DETAIL_TYPE.BUSINESS_CONSIGNMENT_FEE ||
            props.disabled
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 3}>
        <SelectForm
          {...commonInputProps}
          label={isModal && labels.payment_method}
          name={`contractamount.contractamountdetail_set.${index}.payment_methods`}
          rules={rules.contractamount.contractamountdetail_set.payment_methods}
          choices={choices?.contract_amount_detail_payment_methods}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      {isIntermediateMoney ? (
        <>
          <Grid item xs={12} md={3}>
            <TextField
              {...commonInputProps}
              name={`contractamount.contractamountdetail_set.${index}.payment_deadline`}
              rules={
                rules.contractamount.contractamountdetail_set.payment_deadline
              }
              disabled={props.disabled}
              label={isModal && labels.payment_deadline}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={isModal ? 12 : 4}>
          <TextField
            {...commonInputProps}
            label={isModal && labels.note}
            name={`contractamount.contractamountdetail_set.${index}.note`}
            rules={rules.contractamount.contractamountdetail_set.note}
            disabled={props.disabled}
          />
        </Grid>
      )}
    </>
  )
  const DetailDeleteButton = () => (
    <Grid item xs={1}>
      <DeleteButton
        onClick={() => handleRemoveForContractAmountDetail && handleRemoveForContractAmountDetail(index)}
        disabled={props.disabled}
      />
    </Grid>
  )
  return (
    <>
      {isModal
      ?
        <DialogSubContent
          title={title}
          content={
            <Grid container direction="row" spacing={1} alignItems="center">
              {isIntermediateMoney
                ?
                  <>
                    <Grid item xs={11}  container direction="row" spacing={1}>
                      <Inputs/>
                    </Grid>
                    <DetailDeleteButton/>
                  </>
                :
                  <Inputs/>
              }
            </Grid>
          }
        />
      :
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} md={2}>
            <Typography>{title}</Typography>
          </Grid>
          <Inputs/>
          {isIntermediateMoney && <DetailDeleteButton/>}
        </Grid>
      }
    </>
  );
};

ContractAmountDetailForm.propTypes = {
  item: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  amountName: PropTypes.string,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  handleRemoveForContractAmountDetail: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
};
ContractAmountDetailForm.defaultProps = {
  disabled: false,
};

const CostForm = React.memo(function costForm(props) {

  const {totalLabel, commonInputProps, choices, rules, setValue, isModal, labels, data, totalName, handleRemoveForContractAmountDetail, isContractAmount} = props

  console.log("===data===")
  console.log(data)
  console.log("============")

  return (
    <Grid container direction="row" spacing={1}>
      {data.map((item) => (
        <Grid item xs={12} key={item.item.id}>
          {
            isContractAmount ? 
            (
              <ContractAmountDetailFormOnContract 
                  commonInputProps={commonInputProps}
                  rules={rules}
                  choices={choices}
                  item={item.item}
                  title={item.title}
                  disabled={props.disabled}
                  amountName={item.amountName}
                  setValue={setValue}
                  isModal={isModal}
                  labels={labels}
                  handleRemoveForContractAmountDetail={ handleRemoveForContractAmountDetail }
              /> 
            ) : 
            (
              <ContractAmountDetailForm
                  commonInputProps={commonInputProps}
                  rules={rules}
                  choices={choices}
                  item={item.item}
                  title={item.title}
                  disabled={props.disabled}
                  amountName={item.amountName}
                  setValue={setValue}
                  isModal={isModal}
                  labels={labels}
                  handleRemoveForContractAmountDetail={ handleRemoveForContractAmountDetail }
              />
            )
          }
        </Grid>
      ))}

      {isModal
      ?
        <Grid item xs={12}>
          <DialogSubContent
            title={totalLabel}
            content={
              <TextField
                disabled={true}
                {...commonInputProps}
                name={totalName}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">￥</InputAdornment>
                  ),
                }}
              />
            }
          />
        </Grid>
      :
        <>
          <Grid item xs={2}>
            <Typography align="right">{totalLabel}：</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={true}
              {...commonInputProps}
              name={totalName}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={7} />
        </>
      }
    </Grid>
  )
})
CostForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  totalName: PropTypes.string.isRequired,
  totalLabel: PropTypes.string,
  handleRemoveForContractAmountDetail: PropTypes.func,
  isContractAmount: PropTypes.bool
};
CostForm.defaultProps = {
  totalLabel: "小計",
  handleRemoveForContractAmountDetail: () => { },
}

const OtherForm = React.memo(function otherForm(props) {
  const {commonInputProps, choices, rules, setValue} = props
  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={12}>
        <SelectForm
          {...commonInputProps}
          label="1. 印紙負担区分"
          name="contractamount.revenue_stamp_burden_classification"
          rules={
            rules.contractamount.revenue_stamp_burden_classification
          }
          choices={choices?.revenue_stamp_burden_classification}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label="2. 印紙当社負担額"
          name="contractamount.revenue_stamp_our_burden"
          type="number"
          rules={rules.contractamount.revenue_stamp_our_burden}
          disabled={props.disabled}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectForm
          {...commonInputProps}
          label="3. 印紙現物持参"
          name="contractamount.revenue_stamp_substance_bringing"
          rules={
            rules.contractamount.revenue_stamp_substance_bringing
          }
          choices={choices?.availability}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label="4. 印紙現物持参額"
          name="contractamount.revenue_stamp_bringing_amount"
          type="number"
          rules={rules.contractamount.revenue_stamp_bringing_amount}
          disabled={props.disabled}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectForm
          {...commonInputProps}
          label="5. 印紙経費精算"
          name="contractamount.revenue_stamp_expenses_reimbursement"
          rules={
            rules.contractamount.revenue_stamp_expenses_reimbursement
          }
          choices={choices?.necessary}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectForm
          {...commonInputProps}
          label="6. 源泉徴収 (海外居住者がいる場合10.21％を源泉徴収)"
          name="contractamount.tax_withholding"
          rules={rules.contractamount.tax_withholding}
          choices={choices?.availability}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label="手付徴収額"
          name="contractamount.prepaid_collection_amount"
          type="number"
          rules={rules.contractamount.prepaid_collection_amount}
          disabled={props.disabled}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label="残代金徴収額"
          name="contractamount.remaining_price_collection_amount"
          type="number"
          rules={
            rules.contractamount.remaining_price_collection_amount
          }
          disabled={props.disabled}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label="合計徴収額"
          name="contractamount.total_collection_amount"
          type="number"
          rules={rules.contractamount.total_collection_amount}
          disabled={props.disabled}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
})

OtherForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};