import React from "react";
import PropTypes from "prop-types";
import { confirmFormula, createFormula, execFormula, getAdminFormulasByKey } from "../../../api/Admin";
import { ApiErrorHandler } from "../../../api/ApiErrorHandler";
import { AdminLayout } from "../../templates/AdminLayout";
import { useParams } from "react-router-dom";
import { Card, Grid, Stack, Typography } from "@mui/material";
import { Toolbar } from "../../atoms/Toolbar";
import { Button } from "../../atoms/Button";
import { AddIcon } from "../../atoms/AddIcon";
import { Dialog } from "../../molecules/Dialog";
import { TextField } from "../../atoms/TextField";
import { useForm } from "react-hook-form";
import * as validationRules from "../../../utils/ValidationRules";
import { DatePicker } from "../../atoms/DatePicker";
import { SelectForm } from "../../atoms/SelectForm";
import { Snackbar } from "../../atoms/Snackbar";

export const FormulasByKey = () => {
  const {key} = useParams();
  const [user, setUser] = React.useState({});
  const [choices, setChoices] = React.useState({});
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [isOpenCreateFormulaDialog, setIsOpenCreateFormulaDialog] = React.useState(false);
  const [createFormulaDefaultValues, setCreateFormulaDefaultValues] = React.useState({});
  const [formulas, setFormulas] = React.useState([]); 
  const [latestFormulas, setLatestFormulas] = React.useState([]);
  const [forceUseEffect, setForceUseEffect] = React.useState(0);

  const handleCloseCreateFormulaDialog = () => {
    setIsOpenCreateFormulaDialog(false)
  }

  const callUseEffect = React.useCallback(() => {
    setForceUseEffect(forceUseEffect + 1);
  }, [forceUseEffect]);

  /**
   * 適用開始日降順、エリア昇順(nullは最後)、グレード昇順(nullは最後)
   * @param {array} formulas
   * @returns {array}
   */
  const sortFormulasToPriorityOrder = (formulas) => {
    const sortedFormulas = Object.values(formulas).sort((a, b) => {
      if (a.applicable_start_at !== b.applicable_start_at) {
        if (a.applicable_start_at > b.applicable_start_at) {
          return -1
        }
        if (a.applicable_start_at < b.applicable_start_at) {
          return 1
        }
      }
      if (a.area_code !== b.area_code) {
        if (a.area_code === null && b.area_code !== null) {
          return 1
        }
        if (a.area_code !== null && b.area_code === null) {
          return -1
        }
        if (a.area_code > b.area_code) {
          return 1
        }
        if (a.area_code < b.area_code) {
          return -1
        }
      }
      if (a.grade !== b.grade) {
        if (a.grade === null && b.grade !== null) {
          return 1
        }
        if (a.grade !== null && b.grade === null) {
          return -1
        }
        return a.grade - b.grade
      }
      return 0
    })
    return sortedFormulas
  }

  React.useEffect(() => {
    setLoadingOpen(true);
    getAdminFormulasByKey(key)
      .then((response) => {
        setUser(response.data.user);
        setFormulas(response.data.results.formulas)
        setChoices(response.data.choices)
        const sortedLatestFormulas = sortFormulasToPriorityOrder(response.data.results.latest_formulas)
        setLatestFormulas(sortedLatestFormulas ?? [])
      })
      .catch((error) => {
        ApiErrorHandler(error);
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  }, [forceUseEffect]);

  const applicableStartAtFormat = (applicable_start_at) => {
    const applicableStartAt = new Date(applicable_start_at)
    const formattedApplicableStartAt = `${applicableStartAt.getFullYear()}年${applicableStartAt.getMonth() + 1}月${applicableStartAt.getDate()}日 ${applicableStartAt.getHours()}時${applicableStartAt.getMinutes()}分`
    return formattedApplicableStartAt
  }

  return (
    <AdminLayout
      pageTitle="計算式一覧"
      user={user}
      loadingOpen={loadingOpen}
    >
      <Stack spacing={1}>
        <Toolbar>
          <Stack spacing={1} justifyContent="flex-start">
            <Typography variant="h5">
              {key}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row" justifyContent="flex-end" sx={{flexGrow: 1}}>
            <Button
              color="green"
              startIcon={<AddIcon color="white"/>}
              onClick={() => {
                setCreateFormulaDefaultValues({})
                setIsOpenCreateFormulaDialog(true)
              }}
            >
              <Typography>
                追加
              </Typography>
            </Button>
          </Stack>
        </Toolbar>
        <Stack spacing={1}>
          <Typography variant="subtitle1">
            地域、LS/PS毎の最新の計算式（左から優先度の高い順）
          </Typography>
          <Stack spacing={0.5} direction="row">
            {latestFormulas.map((item) => {
              return (
                <Button
                  color="emeraldGreen"
                  key={item.id}
                  onClick={() => { window.location.href = `#link_${item.id}` }}
                >
                  {item.area_code_display ?? "全て"}({item.grade_display ?? "全て"})
                </Button>
              )
            })}
          </Stack>
          {formulas.map((item) => {
            return (
              <Card key={item.id} sx={{ p: 1 }} id={`link_${item.id}`}>
                <Grid container spacing={1} alignItems="strech">
                  <Grid item xs={10} container direction="row" spacing={1} alignItems="flex-start">
                    <Grid item xs={12} container direction="row" spacing={1} alignItems="flex-start">
                      <Grid item xs={4}>
                        <Typography>
                          名前
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography>
                          {item.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container direction="row" spacing={1} alignItems="flex-start">
                      <Grid item xs={4}>
                        <Typography>
                          説明
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography sx={{whiteSpace: "pre-wrap"}}>
                          {item.description}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container direction="row" spacing={1} alignItems="flex-start">
                      <Grid item xs={4}>
                        <Typography>
                          適用開始日
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography>
                          {applicableStartAtFormat(item.applicable_start_at)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container direction="row" spacing={1} alignItems="flex-start">
                      <Grid item xs={4}>
                        <Typography>
                          式
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography sx={{whiteSpace: "pre-wrap"}}>
                          {item.formula}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container direction="row" spacing={1} alignItems="flex-start">
                      <Grid item xs={4}>
                        <Typography>
                          エリアコード
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography>
                          {item.area_code_display ?? "全て"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container direction="row" spacing={1} alignItems="flex-start">
                      <Grid item xs={4}>
                        <Typography>
                          グレード
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography>
                          {item.grade_display ?? "全て"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Stack direction="row" justifyContent="flex-end" sx={{flexGrow: 1}}>
                      <Button
                        color="green"
                        startIcon={<AddIcon color="white"/>}
                        onClick={() => {
                          setCreateFormulaDefaultValues({
                            name: item.name,
                            description: item.description,
                            area_code: item.area_code,
                            grade: item.grade,
                            formula: item.formula,
                          })
                          setIsOpenCreateFormulaDialog(true)
                        }}
                      >
                        <Typography>
                          コピーして追加
                        </Typography>
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            )
          })}
        </Stack>
      </Stack>
      <CreateFormulaDialog
        isOpen={isOpenCreateFormulaDialog}
        handleClose={handleCloseCreateFormulaDialog}
        choices={choices}
        formulaKey={key}
        createdAction={callUseEffect}
        defaultValues={createFormulaDefaultValues}
      />
    </AdminLayout>
  );
};

const CreateFormulaDialog = (props) => {
  const {defaultValues, choices, isOpen, handleClose, formulaKey, createdAction} = props
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [variables, setVariables] = React.useState([]);
  const [isRegistrationChecked, setIsRegistrationChecked] = React.useState(false);
  const [isFormulaChecked, setIsFormulaChecked] = React.useState(false);

  React.useEffect(() => {
    reset()
    setVariables([])
    setIsRegistrationChecked(false)
    setIsFormulaChecked(false)
    setIsSubmitting(false)
    setValue("key", formulaKey)
  }, [isOpen])

  const {
    control,
    setValue,
    handleSubmit,
    setError,
    reset
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
  });

  const setErrroByApi = (error) => {
    const errorData = error.response.data
    Object.keys(errorData).forEach((key) => {
      setError(key, {type: "api", message: errorData[key].join("")})
    })
  }

  const confirmAction = (data) => {
    setIsSubmitting(true);
    confirmFormula(data)
      .then((response) => {
        setVariables(response.data.variables)
        setIsRegistrationChecked(true)
      })
      .catch((error) => {
        setErrroByApi(error)
        setMessage("エラーが発生しました");
        setSeverity("error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const execFormulaAction = (data) => {
    setIsSubmitting(true);
    execFormula(data)
      .then((response) => {
        setIsFormulaChecked(true)
        setValue("result", response.data.result)
      })
      .catch((error) => {
        setErrroByApi(error)
        setMessage("エラーが発生しました");
        setSeverity("error");
        setValue("result", null)
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const createAction = (data) => {
    setIsSubmitting(true);
    createFormula(data)
      .then(() => {
        handleClose()
        createdAction()
      })
      .catch((error) => {
        setErrroByApi(error)
        setMessage("エラーが発生しました");
        setSeverity("error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <>
      <Snackbar
        closeAction={() => {
          setMessage("");
        }}
        message={message}
        severity={severity}
      />
      <Dialog
        isOpen={isOpen}
        title={
          <>
            <AddIcon color="black" sx={{mr: 1}} />
            追加
          </>
        }
        content={
          <Stack spacing={1}>
            <TextField
              control={control}
              label="キー"
              name="key"
              disabled={true}
            />
            <TextField
              control={control}
              label="名前"
              name="name"
              rules={{
                ...validationRules.required(),
              }}
              defaultValue={defaultValues.name}
            />
            <DatePicker
              control={control}
              label="適用開始日時"
              name="applicable_start_at"
              rules={{
                ...validationRules.required(),
              }}
              type="datetime-local"
              defaultValue={null}
            />
            <TextField
              control={control}
              label="説明"
              name="description"
              rules={{
                ...validationRules.required(),
              }}
              multiline
              rows={2}
              defaultValue={defaultValues.description}
            />
            <SelectForm
              control={control}
              label="エリアコード"
              name="area_code"
              choices={choices.area_code}
              setValue={setValue}
              defaultValue={defaultValues.area_code}
              disableClearable={true}
            />
            <SelectForm
              control={control}
              label="グレード"
              name="grade"
              choices={choices.grade}
              setValue={setValue}
              defaultValue={defaultValues.grade}
              disableClearable={true}
            />
            <TextField
              control={control}
              label="計算式"
              name="formula"
              rules={{
                ...validationRules.required(),
                onChange: () => {
                  setIsRegistrationChecked(false)
                  setIsFormulaChecked(false)
                  setVariables([])
                  setValue("result", null)
                }
              }}
              multiline
              rows={5}
              defaultValue={defaultValues.formula}
            />
            {variables.map((variable, index) => (
              <TextField
                key={index}
                control={control}
                label={variable}
                name={`formula_variables.${variable}`}
                rules={{
                  ...validationRules.required(),
                  onChange: () => {
                    setIsFormulaChecked(false)
                  }
                }}
              />
            ))}
            {isRegistrationChecked &&
              <TextField
                control={control}
                label="結果"
                name="result"
                disabled
              />
            }
          </Stack>
        }
        actions={
          <>
            <Button color="white" onClick={handleClose} disabled={isSubmitting}>
              キャンセル
            </Button>
            <Button color="green" onClick={handleSubmit(confirmAction)} disabled={isSubmitting}>
              確認
            </Button>
            <Button color="green" onClick={handleSubmit(execFormulaAction)} disabled={isSubmitting || !isRegistrationChecked}>
              計算式のテスト
            </Button>
            <Button color="green" onClick={handleSubmit(createAction)} disabled={isSubmitting || !(isRegistrationChecked && isFormulaChecked)}>
              登録
            </Button>
          </>
        }
        handleClose={handleClose}
      />
    </>
  )
}
CreateFormulaDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  formulaKey: PropTypes.string.isRequired,
  createdAction: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
};