import React from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Grid, Paper } from "@mui/material";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { isMobile } from "react-device-detect";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useValidAreaArrangement } from "../../utils/CustomHook";
import { onBlurCommonAction, saveChildForPropertyEdit } from "../../utils/PropertyEdit";
import { SelectForm } from "../atoms/SelectForm";
import { PropertyEditForms } from "../molecules/PropertyEditForms";

export const ValidAreaArrangementForms = React.memo(
  function validAreaArrangementForms({
    property,
    initialized,
    validareaarrangement,
    setOriginPropertyData,
    toggleViewport,
    formPaperVariant,
    formPaperElevation,
    ...props
  }) {

    const [choices, setChoices] = React.useState({});
    React.useEffect(() => {
      setChoices(props.choices);
    }, [props.choices]);

    const [isLoading, setIsLoading] = React.useState(false)
    const loadingComplete = React.useCallback(() => {
      setIsLoading(false)
    }, [])

    const [isOpenModal, setIsOpenModal] = React.useState(false)
    const [propertyId, setPropertyId] = React.useState(null)

    const [isFocused, setFocusInputName] = useFocusInput()
    const handleFocus = React.useCallback((name) => {
      setFocusInputName(name)
    }, [])

    const {
      control,
      formState: { errors },
      setValue,
      methods,
      setError,
      clearErrors,
    } = useForm({
      shouldFocusError: false,
      criteriaMode: "all",
      defaultValues: "",
      mode: "onChange",
    });

    useValidAreaArrangement(validareaarrangement, setValue, isFocused)

    React.useEffect(() => {
      setPropertyId(property.id)
    }, [property])

    React.useEffect(() => {
      if (!validareaarrangement) {
        return;
      }
      initialized();
    }, [validareaarrangement]);

    const saveCommonParams = React.useMemo(() => [
      errors, propertyId, clearErrors, setOriginPropertyData, setError
    ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

    const onBlurForValidAreaArrangement = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "validareaarrangement",
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
    }, [propertyId]);

    const rules = React.useMemo(() => ({
      validareaarrangement: {
        m2_regulation: {
          onBlur: onBlurForValidAreaArrangement,
        },
        m2_regulation_area: {
          onBlur: onBlurForValidAreaArrangement,
        },
        area_exclusion: {
          onBlur: onBlurForValidAreaArrangement,
        },
        area_exclusion_text: {
          onBlur: onBlurForValidAreaArrangement,
        },
        l_shaped_side_channel: {
          onBlur: onBlurForValidAreaArrangement,
        },
        setback_finish: {
          onBlur: onBlurForValidAreaArrangement,
        },
        corner_cutting: {
          onBlur: onBlurForValidAreaArrangement,
        },
        building_coverage_ratio_relaxation: {
          onBlur: onBlurForValidAreaArrangement,
        },
        usage_border: {
          onBlur: onBlurForValidAreaArrangement,
        },
        wall_position_limit: {
          onBlur: onBlurForValidAreaArrangement,
        },
        wall_position_limit_area: {
          onBlur: onBlurForValidAreaArrangement,
        },
        building_arrangement: {
          onBlur: onBlurForValidAreaArrangement,
        },
        memo: {
          onBlur: onBlurForValidAreaArrangement,
        },
      },
    }), [onBlurForValidAreaArrangement]);

    const propertyEditFormDialogCloseAction = React.useCallback(() => {
      toggleViewport()
    }, [])

    const formAreaClickedAction = React.useCallback((additionalProcess) => {
      if (!isMobile) {
        return
      }
      if (additionalProcess) {
        additionalProcess()
      }
      setIsLoading(true)
      toggleViewport()
    }, [])

    const commonInputProps = React.useMemo(() => ({
      control: control,
      onFocus: handleFocus,
    }), [control, handleFocus])

    const pageTitle = "有効面積・配置"
    return (
      <PropertyEditForms title={pageTitle} >
        <LoadingProgress open={isLoading} useCircular={false} />
        <FormProvider {...methods}>
          <PropertyEditFormDialog
            isOpen={isOpenModal}
            handleClose={() => {
              setIsOpenModal(false)
              propertyEditFormDialogCloseAction()
            }}
            title={pageTitle}
            openedAction={loadingComplete}
            content={
              <Forms
                commonInputProps={commonInputProps}
                rules={rules}
                isModal={true}
                disabled={props.disabled}
                setValue={setValue}
                choices={choices}
              />
            }
          />
          <form>
            <Paper
              elevation={formPaperElevation}
              variant={formPaperVariant}
              onClick={() => formAreaClickedAction(() => setIsOpenModal(true))}
            >
              <Forms
                commonInputProps={commonInputProps}
                rules={rules}
                isModal={false}
                disabled={props.disabled}
                setValue={setValue}
                choices={choices}
              />
            </Paper>
          </form>
        </FormProvider>
      </PropertyEditForms>
    );
  }
);

ValidAreaArrangementForms.propTypes = {
  property: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  validareaarrangement: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
  choices: PropTypes.object.isRequired,
};

ValidAreaArrangementForms.defaultProps = {
  property: {},
  disabled: false,
};


const Forms = React.memo(function forms(props) {
  const { commonInputProps, rules, isModal, disabled, choices, setValue } = props

  const columns = 30
  const formTypeText = "text"
  const formTypeSelect = "select"
  const formList = React.useMemo(() => [
    {
      formType: formTypeSelect,
      label: "㎡規制",
      property_name: "m2_regulation",
      pcWidth: 6,
      choices: choices.planning_request_availability,
    },
    {
      formType: formTypeText,
      property_name: "m2_regulation_area",
      pcWidth: 6,
      type: "float"
    },
    {
      formType: formTypeSelect,
      label: "面積除外",
      property_name: "area_exclusion",
      pcWidth: 6,
      choices: choices.planning_request_availability,
    },
    {
      formType: formTypeText,
      property_name: "area_exclusion_text",
      pcWidth: 6,
    },
    {
      formType: null,
      pcWidth: 6,
    },
    {
      formType: formTypeSelect,
      label: "L型側溝",
      property_name: "l_shaped_side_channel",
      pcWidth: 6,
      choices: choices.lshaped_side_channel,
    },
    {
      formType: formTypeSelect,
      label: "セットバック仕上",
      property_name: "setback_finish",
      pcWidth: 6,
      choices: choices.setback_finish,
    },
    {
      formType: formTypeSelect,
      label: "隅切り",
      property_name: "corner_cutting",
      pcWidth: 6,
      choices: choices.corner_cutting,
    },
    {
      formType: formTypeSelect,
      label: "建蔽率緩和",
      property_name: "building_coverage_ratio_relaxation",
      pcWidth: 6,
      choices: choices.building_coverage_ratio_relaxation,
    },
    {
      formType: formTypeSelect,
      label: "用途境",
      property_name: "usage_border",
      pcWidth: 6,
      choices: choices.planning_request_availability,
    },
    {
      formType: formTypeSelect,
      label: "壁面位置制限",
      property_name: "wall_position_limit",
      pcWidth: 6,
      choices: choices.planning_request_availability,
    },
    {
      formType: formTypeText,
      property_name: "wall_position_limit_area",
      pcWidth: 6,
      type: "float",
    },
    {
      formType: formTypeSelect,
      label: "建物配置",
      property_name: "building_arrangement",
      pcWidth: 6,
      choices: choices.building_arrangement,
    },
  ], [choices])

  return (
    <Grid container direction="row" spacing={1} columns={columns} alignItems="flex-end">
      {formList.map((item, index) => (
        <Grid key={index} item xs={isModal ? columns : item.pcWidth}>
          {item.formType === formTypeText &&
            <TextField
              {...commonInputProps}
              name={`validareaarrangement.${item.property_name}`}
              rules={rules.validareaarrangement[item.property_name]}
              label={item.label}
              disabled={disabled}
              type={item.type}
            />
          }
          {item.formType === formTypeSelect &&
            <SelectForm
              {...commonInputProps}
              name={`validareaarrangement.${item.property_name}`}
              rules={rules.validareaarrangement[item.property_name]}
              label={item.label}
              choices={item.choices}
              disabled={disabled}
              setValue={setValue}
            />
          }
        </Grid>
      ))}

      <Grid item xs={columns}>
        <TextField
          {...commonInputProps}
          name={"validareaarrangement.memo"}
          rules={rules.validareaarrangement.memo}
          label="メモ"
          multiline
          rows={isModal ? 20 : 5}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
})

Forms.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
}