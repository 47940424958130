import MUITableCell from "@mui/material/TableCell";
import PropTypes from "prop-types";

const TableCell = (props) => {
  return <MUITableCell {...props}>{props.children}</MUITableCell>;
};

export default TableCell;

TableCell.propTypes = {
  padding: PropTypes.any,
  children: PropTypes.any
}