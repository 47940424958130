import * as React from "react";
import PropTypes from "prop-types";
import { Button } from "../atoms/Button";
import MenuItem from "@mui/material/MenuItem";
import { Box, ClickAwayListener, Divider, Paper, Popper } from "@mui/material";

export const ButtonAndMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const menus = props.menus;

  return (
    <Box sx={{...props.sx}}>
      <ClickAwayListener onClickAway={handleClose} touchEvent={false}>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <Button
            variant={props.variant}
            color={props.color}
            sx={{...props.buttonSx}}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={props.endIcon}
            startIcon={props.startIcon}
          >
            {props.buttonName}
          </Button>
          <Popper id={open ? 'simple-popper' : undefined} open={open} anchorEl={anchorEl} sx={{zIndex: 1500, maxHeight: 500}}>
            <Paper sx={{py: 1}} elevation={8}>
            {menus.map((menu, index) => {
              return (
                <React.Fragment key={index}>
                  {(props.useDivider && index !== 0) && <Divider/>}
                  {menu.isDivider ?
                    <>
                      <Divider>
                        {menu.label}
                      </Divider>
                    </>
                    :
                    <>
                      <MenuItem
                        key={index}
                        disabled={menu.disabled}
                        onClick={() => {
                          handleClose()
                          menu.onClick()
                        }}
                      >
                        {menu.label}
                      </MenuItem>
                    </>
                  }
                </React.Fragment>
              );
            })}
            </Paper>
          </Popper>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

ButtonAndMenu.propTypes = {
  menus: PropTypes.array.isRequired,
  endIcon: PropTypes.element,
  startIcon: PropTypes.element,
  buttonName: PropTypes.any,
  buttonSx: PropTypes.object,
  sx: PropTypes.object,
  variant: PropTypes.string,
  color: PropTypes.string,
  useDivider: PropTypes.bool,
}
ButtonAndMenu.defaultProps = {
  menus: [],
  variant: "text",
  color: "black",
  useDivider: true,
}