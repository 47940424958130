import React from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Grid, Paper, Stack } from "@mui/material";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { isMobile } from "react-device-detect";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, usePlanningRequest, useInitBusinessProcess } from "../../utils/CustomHook";
import { onBlurCommonAction, saveChildForPropertyEdit, saveBlockRelatedDiagram, applyEstimate, saveProperty } from "../../utils/PropertyEdit";
import { SelectForm } from "../atoms/SelectForm";
import { DatePicker } from "../atoms/DatePicker";
import { FileForm } from "../atoms/FileForm";
import { downloadBlockRelatedDiagram } from "../../api/Properties";
import { pdfDownload } from "../../utils/FileUtil";
import { Button } from "../atoms/Button";
import LinkIcon from '@mui/icons-material/Link';
import { Snackbar } from "../atoms/Snackbar";
import * as validationRules from "../../utils/ValidationRules";
import { ConstructionCooperationDialog } from "./ConstructionCooperationDialog";

export const PlanningRequestBasicInfoForms = React.memo(
  function planningRequestBasicInfoForms({
    property,
    initialized,
    planningrequest,
    businessprocess,
    setOriginPropertyData,
    toggleViewport,
    formPaperVariant,
    formPaperElevation,
    ...props
  }) {
    const [choices, setChoices] = React.useState({});
    React.useEffect(() => {
      setChoices(props.choices);
    }, [props.choices]);

    const [isLoading, setIsLoading] = React.useState(false)
    const loadingComplete = React.useCallback(() => {
      setIsLoading(false)
    }, [])

    const [isOpenModal, setIsOpenModal] = React.useState(false)
    const [propertyId, setPropertyId] = React.useState(null)
    const [isGetEstimateDisabled, setIsGetEstimateDisabled] = React.useState(false)
    const [isSubmittingBlockRelatedDiagram, setIsSubmittingBlockRelatedDiagram] = React.useState(false)
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("error");

    const [isFocused, setFocusInputName] = useFocusInput()
    const handleFocus = React.useCallback((name) => {
      setFocusInputName(name)
    }, [])

    const {
      control,
      formState: { errors },
      setValue,
      methods,
      setError,
      clearErrors,
      watch,
    } = useForm({
      shouldFocusError: false,
      criteriaMode: "all",
      defaultValues: "",
      mode: "onChange",
    });


    useInitBusinessProcess(businessprocess, setValue, isFocused)

    const watchBlockRelatedDiagramGoogleDriveId = watch("planningrequest.block_related_diagram_google_drive_url")
    usePlanningRequest(planningrequest, setValue, isFocused)

    React.useEffect(() => {
      if (!Object.keys(property).length || !Object.keys(businessprocess).length) {
        return
      }
      setPropertyId(property.id)
      if (!isFocused("name")) {
        setValue("name", property.name, { shouldValidate: true });
      }
      initialized();
    }, [property, planningrequest, businessprocess]);

    const saveCommonParams = React.useMemo(() => [
      errors, propertyId, clearErrors, setOriginPropertyData, setError
    ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

    const onBlurForProperty = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      saveProperty(e, ...saveCommonParams)
    }, [propertyId]);

    const onBlurForBusinessProcess = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "businessprocess",
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
    }, [propertyId]);

    const onBlurForPlanningRequest = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "planningrequest",
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
    }, [propertyId]);

    const onChangeForPlanningRequestBlockRelatedDiagram = React.useCallback((e) => {
      setIsSubmittingBlockRelatedDiagram(true)
      onBlurCommonAction(setFocusInputName)
      if (e.target.files.length !== 1) {
        return
      }
      const file = e.target.files[0]
      saveBlockRelatedDiagram(file, ...saveCommonParams, () => { setIsSubmittingBlockRelatedDiagram(false) })
    }, [propertyId]);

    const deleteForPlanningRequestBlockRelatedDiagram = React.useCallback(() => {
      setIsSubmittingBlockRelatedDiagram(true)
      onBlurCommonAction(setFocusInputName)
      saveBlockRelatedDiagram("", ...saveCommonParams, () => { setIsSubmittingBlockRelatedDiagram(false) })
    }, [propertyId]);

    const downloadForPlanningRequestBlockRelatedDiagram = React.useCallback(() => {
      setIsSubmittingBlockRelatedDiagram(true)
      downloadBlockRelatedDiagram(propertyId)
        .then((response) => {
          pdfDownload(response)
        })
        .finally(() => {
          setIsSubmittingBlockRelatedDiagram(false)
        });
    }, [propertyId]);

    const rules = React.useMemo(() => ({
      name: {
        onBlur: onBlurForProperty,
      },
      businessprocess: {
        settlement_at: {
          onBlur: onBlurForBusinessProcess,
        },
      },
      planningrequest: {
        property_no: {
          onBlur: onBlurForPlanningRequest,
        },
        site_responsible: {
          onBlur: onBlurForPlanningRequest,
        },
        planning_responsible: {
          onBlur: onBlurForPlanningRequest,
        },
        building_specification: {
          onBlur: onBlurForPlanningRequest,
        },
        sales_style: {
          onBlur: onBlurForPlanningRequest,
        },
        area: {
          onBlur: onBlurForPlanningRequest,
        },
        design_op: {
          onBlur: onBlurForPlanningRequest,
        },
        passing_criteria: {
          onBlur: onBlurForPlanningRequest,
        },
        purchase_clearing_expected_date: {
          onBlur: onBlurForPlanningRequest,
        },
        announcement_expected_date: {
          onBlur: onBlurForPlanningRequest,
        },
        measurement_office: {
          onBlur: onBlurForPlanningRequest,
        },
        measurement_office_responsible_person_name: {
          onBlur: onBlurForPlanningRequest,
        },
        measurement_office_phone_number: {
          onBlur: onBlurForPlanningRequest,
        },
        measurement_office_email: {
          onBlur: onBlurForPlanningRequest,
          ...validationRules.isEmail(),
        },
        block_related_diagram_google_drive_url: {
          onChange: onChangeForPlanningRequestBlockRelatedDiagram,
        },
        other: {
          onBlur: onBlurForPlanningRequest,
        },
      },
    }), [onBlurForPlanningRequest, onBlurForProperty, onBlurForBusinessProcess]);

    const propertyEditFormDialogCloseAction = React.useCallback(() => {
      toggleViewport()
    }, [])

    const formAreaClickedAction = React.useCallback((additionalProcess) => {
      if (!isMobile) {
        return
      }
      if (additionalProcess) {
        additionalProcess()
      }
      setIsLoading(true)
      toggleViewport()
    }, [])

    const setSnackBarForApiSuccess = React.useCallback(() => {
      setMessage("成功しました");
      setSeverity("success");
    }, [])
    const setSnackBarForApiError = React.useCallback(() => {
      setMessage("失敗しました");
      setSeverity("error");
    }, [])

    const onClickGetEstimate = React.useCallback(() => {
      setIsGetEstimateDisabled(true)
      const finallyCallBack = () => { setIsGetEstimateDisabled(false) }
      applyEstimate(propertyId, setOriginPropertyData, setSnackBarForApiSuccess, setSnackBarForApiError, finallyCallBack)
    }, [propertyId, setOriginPropertyData])

    const commonInputProps = React.useMemo(() => ({
      control: control,
      onFocus: handleFocus,
    }), [control, handleFocus])

    return (
      <>
        <Snackbar
          closeAction={() => {
            setMessage("");
          }}
          message={message}
          severity={severity}
        />
        <LoadingProgress open={isLoading} useCircular={false} />
        <FormProvider {...methods}>
          <PropertyEditFormDialog
            isOpen={isOpenModal}
            handleClose={() => {
              setIsOpenModal(false)
              propertyEditFormDialogCloseAction()
            }}
            title=""
            openedAction={loadingComplete}
            content={
              <Forms
                commonInputProps={commonInputProps}
                rules={rules}
                isModal={true}
                disabled={props.disabled}
                setValue={setValue}
                choices={choices}
                deleteForPlanningRequestBlockRelatedDiagram={deleteForPlanningRequestBlockRelatedDiagram}
                downloadForPlanningRequestBlockRelatedDiagram={downloadForPlanningRequestBlockRelatedDiagram}
                watchBlockRelatedDiagramGoogleDriveId={watchBlockRelatedDiagramGoogleDriveId}
                onClickGetEstimate={onClickGetEstimate}
                isGetEstimateDisabled={isGetEstimateDisabled}
                isSubmittingBlockRelatedDiagram={isSubmittingBlockRelatedDiagram}
                propertyId={propertyId ?? 0}
              />
            }
          />
          <form>
            <Paper
              elevation={formPaperElevation}
              variant={formPaperVariant}
              onClick={() => formAreaClickedAction(() => setIsOpenModal(true))}
            >
              <Forms
                commonInputProps={commonInputProps}
                rules={rules}
                isModal={false}
                disabled={props.disabled}
                setValue={setValue}
                choices={choices}
                deleteForPlanningRequestBlockRelatedDiagram={deleteForPlanningRequestBlockRelatedDiagram}
                downloadForPlanningRequestBlockRelatedDiagram={downloadForPlanningRequestBlockRelatedDiagram}
                watchBlockRelatedDiagramGoogleDriveId={watchBlockRelatedDiagramGoogleDriveId}
                onClickGetEstimate={onClickGetEstimate}
                isGetEstimateDisabled={isGetEstimateDisabled}
                isSubmittingBlockRelatedDiagram={isSubmittingBlockRelatedDiagram}
                propertyId={propertyId ?? 0}
              />
            </Paper>
          </form>
        </FormProvider>
      </>
    );
  }
);

PlanningRequestBasicInfoForms.propTypes = {
  property: PropTypes.object.isRequired,
  businessprocess: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  planningrequest: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
  choices: PropTypes.object.isRequired,
};

PlanningRequestBasicInfoForms.defaultProps = {
  property: {},
  businessprocess: {},
  disabled: false,
};


const Forms = React.memo(function forms(props) {
  const {
    commonInputProps,
    rules,
    isModal,
    disabled,
    choices,
    setValue,
    deleteForPlanningRequestBlockRelatedDiagram,
    downloadForPlanningRequestBlockRelatedDiagram,
    watchBlockRelatedDiagramGoogleDriveId,
    onClickGetEstimate,
    isGetEstimateDisabled,
    isSubmittingBlockRelatedDiagram,
    propertyId,
  } = props

  const [openConstructionCooperationDialog, setOpenConstructionCooperationDialog] = React.useState(false);

  const columns = 30
  const formTypeText = "text"
  const formTypeSelect = "select"
  const formTypeDate = "date"
  const formList = React.useMemo(() => [
    {
      formType: formTypeText,
      label: "物件番号",
      property_name: "planningrequest.property_no",
      pcWidth: 6,
      rules: rules.planningrequest.property_no,
    },
    {
      formType: formTypeText,
      label: "物件名",
      property_name: "name",
      pcWidth: 12,
      rules: rules.name,
    },
    {
      formType: formTypeText,
      label: "用地担当",
      property_name: "planningrequest.site_responsible",
      pcWidth: 6,
      rules: rules.planningrequest.site_responsible,
    },
    {
      formType: formTypeText,
      label: "企画担当",
      property_name: "planningrequest.planning_responsible",
      pcWidth: 6,
      rules: rules.planningrequest.planning_responsible,
    },
    {
      formType: formTypeSelect,
      label: "建物仕様",
      property_name: "planningrequest.building_specification",
      pcWidth: 6,
      choices: choices.building_specification,
      rules: rules.planningrequest.building_specification,
    },
    {
      formType: formTypeSelect,
      label: "販売形態",
      property_name: "planningrequest.sales_style",
      pcWidth: 6,
      choices: choices.sales_style,
      rules: rules.planningrequest.sales_style,
    },
    {
      formType: formTypeSelect,
      label: "エリア",
      property_name: "planningrequest.area",
      pcWidth: 6,
      choices: choices.specification_area,
      rules: rules.planningrequest.area,
      disabled: true,
    },
    {
      formType: formTypeSelect,
      label: "デザインOP",
      property_name: "planningrequest.design_op",
      pcWidth: 6,
      choices: choices.design_op,
      rules: rules.planningrequest.design_op,
    },
    {
      formType: formTypeSelect,
      label: "引渡条件",
      property_name: "planningrequest.passing_criteria",
      pcWidth: 6,
      choices: choices.passing_criteria,
      rules: rules.planningrequest.passing_criteria,
    },
    {
      formType: formTypeDate,
      label: "仕入決済予定日",
      property_name: "businessprocess.settlement_at",
      pcWidth: 6,
      rules: rules.businessprocess.settlement_at,
    },
    {
      formType: formTypeDate,
      label: "発表予定日",
      property_name: "planningrequest.announcement_expected_date",
      pcWidth: 6,
      rules: rules.planningrequest.announcement_expected_date,
    },
    {
      formType: null,
      pcWidth: 18,
    },
    {
      formType: formTypeText,
      label: "測量事務所",
      property_name: "planningrequest.measurement_office",
      pcWidth: 10,
      rules: rules.planningrequest.measurement_office,
    },
    {
      formType: formTypeText,
      label: "測量事務所（担当者名）",
      property_name: "planningrequest.measurement_office_responsible_person_name",
      pcWidth: 5,
      rules: rules.planningrequest.measurement_office_responsible_person_name,
    },
    {
      formType: formTypeText,
      label: "測量事務所（電話番号）",
      property_name: "planningrequest.measurement_office_phone_number",
      pcWidth: 5,
      rules: rules.planningrequest.measurement_office_phone_number,
    },
    {
      formType: formTypeText,
      label: "測量事務所（メールアドレス）",
      property_name: "planningrequest.measurement_office_email",
      pcWidth: 10,
      rules: rules.planningrequest.measurement_office_email,
    },
  ], [choices, rules])

  const onClickConstructionCooperation = React.useCallback(() => {
    setOpenConstructionCooperationDialog(true)
  }, [])

  return (
    <>
      <Stack spacing={2} direction="row" justifyContent="flex-end" sx={{ pb: 3 }}>
        <Button startIcon={<LinkIcon />} color="purple" onClick={onClickGetEstimate} disabled={isGetEstimateDisabled} isLoading={isGetEstimateDisabled}>
          積算取得
        </Button>
        <Button startIcon={<LinkIcon />} color="bluePurple" onClick={onClickConstructionCooperation}>
          建設連携
        </Button>
      </Stack>
      <Grid container direction="row" spacing={1} columns={columns} >
        {formList.map((item, index) => (
          <Grid key={index} item xs={isModal ? columns : item.pcWidth}>
            {item.formType === formTypeText &&
              <TextField
                {...commonInputProps}
                name={item.property_name}
                rules={item.rules}
                label={item.label}
                disabled={disabled || item.disabled}
                type={item.type}
              />
            }
            {item.formType === formTypeSelect &&
              <SelectForm
                {...commonInputProps}
                name={item.property_name}
                rules={item.rules}
                label={item.label}
                choices={item.choices}
                disabled={disabled || item.disabled}
                setValue={setValue}
              />
            }
            {item.formType === formTypeDate &&
              <DatePicker
                {...commonInputProps}
                name={item.property_name}
                rules={item.rules}
                label={item.label}
                disabled={disabled || item.disabled}
                defaultValue={null}
              />
            }
          </Grid>
        ))}

        <Grid item xs={columns}>
          <FileForm
            accept=".pdf"
            label="ブロック関係図"
            rules={rules.planningrequest.block_related_diagram_google_drive_url}
            deleteOnClick={deleteForPlanningRequestBlockRelatedDiagram}
            downloadOnClick={downloadForPlanningRequestBlockRelatedDiagram}
            control={commonInputProps.control}
            name="planningrequest.block_related_diagram_google_drive_url"
            watch={watchBlockRelatedDiagramGoogleDriveId}
            isSubmitting={isSubmittingBlockRelatedDiagram}
          />
        </Grid>
        <Grid item xs={columns}>
          <TextField
            {...commonInputProps}
            name={"planningrequest.other"}
            rules={rules.planningrequest.other}
            label="その他"
            multiline
            rows={isModal ? 20 : 5}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <ConstructionCooperationDialog
        open={openConstructionCooperationDialog}
        onClose={() => {
          setOpenConstructionCooperationDialog(false)
        }}
        property_id={propertyId}
      />
    </>
  )
})

Forms.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  deleteForPlanningRequestBlockRelatedDiagram: PropTypes.func.isRequired,
  downloadForPlanningRequestBlockRelatedDiagram: PropTypes.func.isRequired,
  watchBlockRelatedDiagramGoogleDriveId: PropTypes.any,
  onClickGetEstimate: PropTypes.func.isRequired,
  isGetEstimateDisabled: PropTypes.bool.isRequired,
  isSubmittingBlockRelatedDiagram: PropTypes.bool.isRequired,
  propertyId: PropTypes.number.isRequired,
}