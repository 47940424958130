import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { FormLabel } from "../atoms/FormLabel";
import { TextField } from "../atoms/TextField";
import { Grid, InputAdornment, Paper, Stack, Typography } from "@mui/material";
import { AddButton } from "../atoms/AddButton";
import { SelectForm } from "../atoms/SelectForm";
import { DeleteButton } from "../atoms/DeleteButton";
import { HiddenInput } from "../atoms/HiddenInput";
import { isMobile } from "react-device-detect";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitLesseeStatus, useInitLesseeStatusDetail } from "../../utils/CustomHook";
import { onBlurCommonAction, removeChild, saveChildForPropertyEdit } from "../../utils/PropertyEdit";

export const LesseeStatusForms = React.memo(function lesseeStatusForms({
  property,
  initialized,
  lesseestatusdetail_set,
  lesseestatus,
  setOriginPropertyData,
  toggleViewport,
  formPaperVariant,
  formPaperElevation,
  ...props
}) {
  const [isLoading, setIsLoading] = React.useState(false)
  const loadingComplete = React.useCallback(() => {
    setIsLoading(false)
  }, [])

  const [modalLesseeStatusFormIndex, setModalLesseeStatusFormIndex] = React.useState(undefined)
  const [isOpenTotal, setIsOpenTotal] = React.useState(false)

  const [choices, setChoices] = React.useState({});
  const [propertyId, setPropertyId] = React.useState(null)

  const [isFocused, setFocusInputName] = useFocusInput()
  const handleFocus = React.useCallback((name) => {
    setFocusInputName(name)
  }, [])

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    methods,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange",
  });

  const [lesseeStatusFields, lesseeStatusAppend, lesseeStatusRemove] = useInitLesseeStatusDetail(lesseestatus.lesseestatusdetail_set, setValue, isFocused, control, trigger)
  useInitLesseeStatus(lesseestatus, setValue, isFocused, errors)

  React.useEffect(() => {
    setPropertyId(property.id)
  }, [property])

  React.useEffect(() => {
    setChoices(props.choices);
  }, [props.choices]);

  React.useEffect(() => {
    if (!lesseestatus) {
      return;
    }
    initialized();
  }, [lesseestatusdetail_set, lesseestatus]);

  const createLesseeStatus = (e) => {
    e.stopPropagation();
    lesseeStatusAppend();
    if (modalLesseeStatusFormIndex !== undefined) {
      setModalLesseeStatusFormIndex(undefined)
      toggleViewport()
    }
  };

  const saveCommonParams = React.useMemo(() => [
    errors, propertyId, clearErrors, setOriginPropertyData, setError
  ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

  const removeChildCommonParams = React.useMemo(() => [
    propertyId, getValues, setOriginPropertyData,
  ], [propertyId, getValues, setOriginPropertyData])

  const onBlurForLesseeStatus = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "lesseestatus",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const onBlurForLesseeStatusDetail = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "lessee_status", "lesseestatus", "lesseestatusdetail_set"
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId]);

  const handleRemoveForLesseeStatus = React.useCallback((index) => {
    removeChild(...removeChildCommonParams, lesseeStatusRemove, index, "lesseestatus", "lesseestatusdetail_set", "lessee_status")
    if (modalLesseeStatusFormIndex !== undefined) {
      setModalLesseeStatusFormIndex(undefined)
      toggleViewport()
    }
  }, [propertyId]);

  const rules = React.useMemo(() => ({
    lesseestatus: {
      eviction_expected_period: {
        onBlur: onBlurForLesseeStatus,
      },
      lesseestatusdetail_set: {
        room_number: {
          onBlur: onBlurForLesseeStatusDetail,
        },
        usage: {
          onBlur: onBlurForLesseeStatusDetail,
        },
        room_layout: {
          onBlur: onBlurForLesseeStatusDetail,
        },
        area: {
          onBlur: onBlurForLesseeStatusDetail,
        },
        rent: {
          onBlur: onBlurForLesseeStatusDetail,
        },
        common_service_fee: {
          onBlur: onBlurForLesseeStatusDetail,
        },
        lessee_holder: {
          onBlur: onBlurForLesseeStatusDetail,
        },
        status: {
          onBlur: onBlurForLesseeStatusDetail,
        },
        antisocial_check: {
          onBlur: onBlurForLesseeStatusDetail,
        },
      },
    }
  }), [onBlurForLesseeStatus, onBlurForLesseeStatusDetail]);

  const AddLesseeStatusButton = () => {
    return (
      <AddButton onClick={createLesseeStatus} disabled={props.disabled || lesseestatus.MAX_ROW_LESSEE_STATUS <= lesseeStatusFields.length} />
    )
  }

  const labels = React.useMemo(() => ({
    room_number: "部屋番号",
    status: "状況",
    usage: "用途",
    room_layout: "間取り",
    area: "面積㎡",
    area_tsubo: "面積坪",
    rent: "家賃",
    tsubo_unit_price: "坪単価",
    common_service_fee: "共益費",
    total: "合計",
    lessee_holder: "賃借人名義",
    antisocial_check: "反社チェック",
  }), [])

  const propertyEditFormDialogCloseAction = React.useCallback(() => {
    toggleViewport()
  }, [])
  const formAreaClickedAction = React.useCallback((additionalProcess) => {
    if (!isMobile) {
      return
    }
    if (additionalProcess) {
      additionalProcess()
    }
    setIsLoading(true)
    toggleViewport()
  }, [])

  const commonInputProps = React.useMemo(() => ({
    control: control,
    onFocus: handleFocus,
  }), [control, handleFocus])

  return (
    <PropertyEditForms
      title={
        <>
          賃借人状況
          <AddLesseeStatusButton/>
        </>
      }
    >
      <LoadingProgress open={isLoading} useCircular={false} />
      <FormProvider {...methods}>
        <PropertyEditFormDialog
          isOpen={modalLesseeStatusFormIndex !== undefined}
          handleClose={() => {
            setModalLesseeStatusFormIndex(undefined)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title={
            <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
              賃借人状況
              <AddLesseeStatusButton/>
            </Stack>
          }
          content={
            <LesseeStatusForm
              commonInputProps={commonInputProps}
              rules={rules}
              labels={labels}
              index={modalLesseeStatusFormIndex}
              choices={choices}
              disabled={props.disabled}
              setValue={setValue}
              handleRemoveForLesseeStatus={handleRemoveForLesseeStatus}
              isModal={true}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenTotal}
          handleClose={() => {
            setIsOpenTotal(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="賃借人状況 合計"
          content={
            <TotalLesseeStatusForm
              commonInputProps={commonInputProps}
              rules={rules}
              labels={labels}
              disabled={props.disabled}
              isModal={true}
            />
          }
        />
        <form>
          <Grid container direction="row" spacing={1} columns={26}>
            <Grid item xs={2}>
              <FormLabel>{labels.room_number}</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{labels.status}</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{labels.usage}</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{labels.room_layout}</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{labels.area}</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{labels.area_tsubo}</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{labels.rent}</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{labels.tsubo_unit_price}</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{labels.common_service_fee}</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{labels.total}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <FormLabel>{labels.lessee_holder}</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{labels.antisocial_check}</FormLabel>
            </Grid>
            <Grid item xs={1} />

            {lesseeStatusFields.map((item, index) => (
              <Grid item xs={26} key={item.id}>
                <Paper
                  elevation={formPaperElevation}
                  variant={formPaperVariant}
                  onClick={() => formAreaClickedAction(() => setModalLesseeStatusFormIndex(index))}
                >
                  <LesseeStatusForm
                    commonInputProps={commonInputProps}
                    rules={rules}
                    labels={labels}
                    index={index}
                    choices={choices}
                    disabled={props.disabled}
                    setValue={setValue}
                    handleRemoveForLesseeStatus={handleRemoveForLesseeStatus}
                    isModal={false}
                  />
                </Paper>
              </Grid>
            ))}

            <Grid item xs={26}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenTotal(true))}
              >
                <TotalLesseeStatusForm
                  commonInputProps={commonInputProps}
                  rules={rules}
                  labels={labels}
                  disabled={props.disabled}
                  isModal={false}
                />
              </Paper>
            </Grid>


          </Grid>
        </form>
      </FormProvider>
    </PropertyEditForms>
  );
});

LesseeStatusForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  lesseestatusdetail_set: PropTypes.array.isRequired,
  lesseestatus: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

LesseeStatusForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const LesseeStatusForm = React.memo(function lesseeStatusForm(props) {

  const { commonInputProps, labels, rules, index, choices, setValue, handleRemoveForLesseeStatus, isModal} = props
  return (
    <Grid container direction="row" spacing={1} columns={isModal ? 12 : 26}>
      {index !== undefined &&
        <>
          <HiddenInput
            {...commonInputProps}
            name={`lesseestatus.lesseestatusdetail_set.${index}.id`}
          />
          <Grid item xs={isModal ? 6 : 2}>
            <TextField
              {...commonInputProps}
              name={`lesseestatus.lesseestatusdetail_set.${index}.room_number`}
              label={isModal && labels.room_number}
              rules={rules.lesseestatus.lesseestatusdetail_set.room_number}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 2}>
            <SelectForm
              {...commonInputProps}
              name={`lesseestatus.lesseestatusdetail_set.${index}.status`}
              rules={rules.lesseestatus.lesseestatusdetail_set.status}
              label={isModal && labels.status}
              choices={choices?.lessee_status}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 2}>
            <TextField
              {...commonInputProps}
              name={`lesseestatus.lesseestatusdetail_set.${index}.usage`}
              label={isModal && labels.usage}
              rules={rules.lesseestatus.lesseestatusdetail_set.usage}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 2}>
            <TextField
              {...commonInputProps}
              name={`lesseestatus.lesseestatusdetail_set.${index}.room_layout`}
              label={isModal && labels.room_layout}
              rules={rules.lesseestatus.lesseestatusdetail_set.room_layout}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 2}>
            <TextField
              {...commonInputProps}
              name={`lesseestatus.lesseestatusdetail_set.${index}.area`}
              type="float"
              label={isModal && labels.area}
              rules={rules.lesseestatus.lesseestatusdetail_set.area}
              disabled={props.disabled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">㎡</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 2}>
            <TextField
              {...commonInputProps}
              name={`lesseestatus.lesseestatusdetail_set.${index}.area_tsubo`}
              type="float"
              label={isModal && labels.area_tsubo}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">坪</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 2}>
            <TextField
              {...commonInputProps}
              name={`lesseestatus.lesseestatusdetail_set.${index}.rent`}
              label={isModal && labels.rent}
              type="number"
              rules={rules.lesseestatus.lesseestatusdetail_set.rent}
              disabled={props.disabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 2}>
            <TextField
              {...commonInputProps}
              name={`lesseestatus.lesseestatusdetail_set.${index}.tsubo_unit_price`}
              label={isModal && labels.tsubo_unit_price}
              type="float"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 2}>
            <TextField
              {...commonInputProps}
              name={`lesseestatus.lesseestatusdetail_set.${index}.common_service_fee`}
              type="number"
              label={isModal && labels.common_service_fee}
              rules={rules.lesseestatus.lesseestatusdetail_set.common_service_fee}
              disabled={props.disabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 2}>
            <TextField
              {...commonInputProps}
              name={`lesseestatus.lesseestatusdetail_set.${index}.total`}
              type="number"
              label={isModal && labels.total}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 3}>
            <TextField
              {...commonInputProps}
              name={`lesseestatus.lesseestatusdetail_set.${index}.lessee_holder`}
              rules={rules.lesseestatus.lesseestatusdetail_set.lessee_holder}
              label={isModal && labels.lessee_holder}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 2}>
            <SelectForm
              {...commonInputProps}
              name={`lesseestatus.lesseestatusdetail_set.${index}.antisocial_check`}
              label={isModal && labels.antisocial_check}
              rules={rules.lesseestatus.lesseestatusdetail_set.antisocial_check}
              choices={choices?.trading_confirmation}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 1} sx={isModal ? { textAlign: "center" } : {}}>
            <DeleteButton
              onClick={() => handleRemoveForLesseeStatus(index)}
              disabled={props.disabled}
            />
          </Grid>
        </>
      }
    </Grid>
  )
})
LesseeStatusForm.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  index: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  handleRemoveForLesseeStatus: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

const TotalLesseeStatusForm = React.memo(function totalLesseeStatus(props) {

  const {commonInputProps, isModal, labels, rules} = props
  return (
    <Grid container direction="row" spacing={1} columns={isModal ? 12 : 26}>
      {!isModal &&
        <Grid item xs={2}>
          <Typography sx={{ justifyContent: "right" }}>合計：</Typography>
        </Grid>
      }
      <Grid item xs={isModal ? 6 : 2}>
        <TextField
          {...commonInputProps}
          label={isModal && "合計"}
          name="lesseestatus.total_count"
          type="number"
          disabled={true}
        />
      </Grid>
      {!isModal && <Grid item xs={4} />}
      <Grid item xs={isModal ? 6 : 2}>
        <TextField
          {...commonInputProps}
          name="lesseestatus.total_area"
          type="float"
          label={isModal && labels.area}
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">㎡</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 6 : 2}>
        <TextField
          {...commonInputProps}
          name="lesseestatus.total_area_tsubo"
          label={isModal && labels.area_tsubo}
          type="float"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">坪</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 6 : 2}>
        <TextField
          {...commonInputProps}
          name="lesseestatus.total_rent"
          label={isModal && labels.rent}
          type="number"
          disabled={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 6 : 2}>
        <TextField
          {...commonInputProps}
          name="lesseestatus.total_tsubo_unit_price"
          label={isModal && labels.tsubo_unit_price}
          type="float"
          disabled={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 6 : 2}>
        <TextField
          {...commonInputProps}
          name="lesseestatus.total_common_service_fee"
          label={isModal && labels.common_service_fee}
          type="number"
          disabled={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 6 : 2}>
        <TextField
          {...commonInputProps}
          name="lesseestatus.total"
          label={isModal && labels.total}
          type="number"
          disabled={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
      {!isModal && <Grid item xs={6} /> }
      {!isModal &&
        <Grid item xs={2}>
          <Typography sx={{ justifyContent: "right" }}>賃借中：</Typography>
        </Grid>
      }

      <Grid item xs={isModal ? 6 : 2}>
        <TextField
          {...commonInputProps}
          name="lesseestatus.total_in_rental_count"
          label={isModal && "賃借中"}
          type="number"
          disabled={true}
        />
      </Grid>
      {!isModal && <Grid item xs={16} />}
      {!isModal &&
        <Grid item xs={3}>
          <Typography sx={{ justifyContent: "right" }}>立退予想期間：</Typography>
        </Grid>
      }
      <Grid item xs={isModal ? 6 : 2}>
        <TextField
          {...commonInputProps}
          name="lesseestatus.eviction_expected_period"
          disabled={props.disabled}
          label={isModal && "立退予想期間"}
          type="number"
          rules={rules.lesseestatus.eviction_expected_period}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">ヶ月</InputAdornment>
            ),
          }}
        />
      </Grid>
      {!isModal && <Grid item xs={1} />}

      {!isModal && <Grid item xs={20} />}
      {!isModal &&
        <Grid item xs={3}>
          <Typography sx={{ justifyContent: "right" }}>立退き費用：</Typography>
        </Grid>
      }
      <Grid item xs={isModal ? 6 : 2}>
        <TextField
          {...commonInputProps}
          name="lesseestatus.moving_out_cost"
          type="number"
          disabled={true}
          label={isModal && "立退き費用"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
})
TotalLesseeStatusForm.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
}