import { Stack } from "@mui/material";
import { Dialog } from "./Dialog";
import PropTypes from "prop-types";
import * as React from "react";
import { TablePageChanger } from "./TablePageChanger";
import { TableRowsPerPageChanger } from "./TableRowsPerPageChanger";
import { Button } from "../atoms/Button";
import { isMobile } from "react-device-detect";

export const PaginationDialog = (props) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const {page, lastPage, handleChangePage, rowsPerPage, rowsPerPageOptions, handleChangeRowsPerPage, handleClose} = props
  const firstPage = 1

  React.useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <>
      <Dialog
        fullScreen={false}
        isOpen={isOpen}
        content={
          <Stack spacing={2} alignItems="center">
            <TablePageChanger
              page={page}
              firstPage={firstPage}
              lastPage={lastPage}
              handleChangePage={handleChangePage}
            />
            <TableRowsPerPageChanger
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              useSelectBox={true}
            />
          </Stack>
        }
        actions={
          <Button color="white" fullSize={isMobile} onClick={handleClose}>
            結果を表示
          </Button>
        }
      />
    </>
  )
}

PaginationDialog.propTypes = {
  isOpen: PropTypes.bool,
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  sx: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

PaginationDialog.defaultProps = {
  isOpen: false,
}