import { CircularProgress } from "@mui/material";
import MuiButton from "@mui/material/Button";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

export const Button = ({ sx, fullSize, roundedCorners, ...props }) => {

  const {progressColor, isLoading, ...buttonProps} = props

  let buttonSx = {}
  if (fullSize) {
    const fullSizeSx = {m: 0, p: 0, width: "100%", height: "100%"}
    buttonSx = fullSizeSx
  }
  if (roundedCorners) {
    const roundedCornersSx = {borderRadius: "100px"}
    buttonSx = {...roundedCornersSx, ...buttonSx}
  }
  buttonSx = {...buttonSx, ...sx}
  return (
    <MuiButton sx={buttonSx} {...buttonProps}>
      <Box sx={{opacity: isLoading ? 0 : 1}}>
        {props.children}
      </Box>
      {isLoading &&
        <CircularProgress
          size={24}
          sx={{
            color: progressColor,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      }
    </MuiButton>
  );
};

Button.defaultProps = {
  variant: "contained",
  color: "primary",
  sx: {},
  fullSize: false,
  roundedCorners: false,
  isLoading: false,
  progressColor: "#000"
};

Button.propTypes = {
  sx: PropTypes.object,
  isLoading: PropTypes.bool,
  children: PropTypes.any,
  fullSize: PropTypes.bool,
  roundedCorners: PropTypes.bool,
  progressColor: PropTypes.string,
}