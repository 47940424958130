export const FrontendUrls = {
  login: "/",
  properties: "/properties/",
  propertyEdit: "/properties/edit/:id",
  userSettings: "/user/settings/",

  adminFormulas: "/admin/formulas/",
  adminFormulasByKey: "/admin/formulas/:key/",
  adminPropertyShare: "/admin/property/share/",
};
