import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import {ReactComponent as icon} from "../../assets/images/RightArrow.svg";

export const LeftArrowIcon = (props) => {
  return (
    <SvgIcon
      component={icon}
      {...props}
      viewBox="0 0 11 17"
      sx={{ transform: "scale(-1, 1)", ...props.sx}}
    />
  );
};


LeftArrowIcon.defaultProps = {
  color: "black" 
};

LeftArrowIcon.propTypes = {
  color: PropTypes.string,
  sx: PropTypes.object,
}