
function fileDownload(response, mimeType, defaultFileName) {
  const contentDisposition = response.headers['content-disposition'];
  let fileName = defaultFileName;

  if (contentDisposition) {
    const matches = contentDisposition.match(/filename\*=(UTF-8'')?([^;\r\n]*)/i);
    if (matches && matches[2]) {
      const encodedFileName = matches[2];
      fileName = decodeURIComponent(encodedFileName);
    } else {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch && fileNameMatch[1]) {
        fileName = fileNameMatch[1];
      }
    }
  }
  const file = new Blob([response.data], { type: mimeType });
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(file);
  downloadLink.download = fileName;
  downloadLink.click();
}
export function excelDownload(response) {
  fileDownload(response, "application/xlsx", "filename.xlsx")
}

export function pdfDownload(response) {
  const file = new Blob([response.data], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}