import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";

export const CustomHelmet = (props) => {
  let scripts = [];
  for (let i in props.scripts) {
    scripts.push(<script key={i} src={props.scripts[i]}></script>);
  }

  return (
    <Helmet>
      <title>{props.pageTitle}</title>
      {props.viewport && <meta name="viewport" content={props.viewport} />}
      {scripts}
    </Helmet>
  );
};

CustomHelmet.propTypes = {
  scripts: PropTypes.array,
  pageTitle: PropTypes.string.isRequired,
  viewport: PropTypes.string,
}