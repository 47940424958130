import * as React from 'react'
import PropTypes from "prop-types";
import { TextField } from './TextField';

export const DatePicker = (props) => {
  const {defaultValue, type, ...datePickerProps} = props
  let textFieldDefaultValue = undefined
  if (defaultValue) {
    const defaultValueYear = defaultValue.getFullYear()
    const defaultValueMonth = ("0" + (defaultValue.getMonth() + 1)).slice(-2)
    const defaultValueDate = ("0" + defaultValue.getDate()).slice(-2)
    const defaultValueHours = defaultValue.getHours()
    const defaultValueMinutes = defaultValue.getMinutes()
    if (type === "date") {
      textFieldDefaultValue = `${defaultValueYear}-${defaultValueMonth}-${defaultValueDate}`
    } else if (type === "time") {
      textFieldDefaultValue = `${defaultValueHours}:${defaultValueMinutes}`
    } else if (type === "datetime-local") {
      textFieldDefaultValue = `${defaultValueYear}-${defaultValueMonth}-${defaultValueDate}T${defaultValueHours}:${defaultValueMinutes}`
    }
  }
  return (
    <TextField
      {...datePickerProps}
      type={type}
      defaultValue={textFieldDefaultValue}
    />
  )
}


DatePicker.propTypes = {
  defaultValue: PropTypes.instanceOf(Date),
  type: PropTypes.oneOf(["date", "time","datetime-local"]),
};
DatePicker.defaultProps = {
  defaultValue: new Date(),
  type: "date",
};