import * as React from "react";
import PropTypes from "prop-types";
import { Dialog } from "../molecules/Dialog";
import { Button } from "../atoms/Button";
import { Snackbar } from "../atoms/Snackbar";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Box,
  FormGroup,
  FormHelperText,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import { CheckBox } from "../atoms/CheckBox";
import { isMobile } from "react-device-detect";

export const SelectSheetDialog = ({ choices, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    const data = [];
    if (!choices) {
      return
    }
    choices.forEach((item) => {
      data.push({
        "checked": false,
        "value": item.value,
        "label": item.label,
      })
    })
    sheetReplace(data);
  }, [open]);

  const {
    control,
    handleSubmit,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
  });

  const {
    fields: sheetFields,
    replace: sheetReplace,
  } = useFieldArray({ control, name: "sheet" });

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onClose = () => {
    props.onClose();
  };
  const submitAction = (data) => {
    const sheet_names = data.sheet.flatMap((item) => item.checked ? [item.value] : [])
    if (sheet_names.length === 0) {
      setMessage("1件以上チェックしてください");
      setSeverity("error");
      return
    }

    setIsSubmitting(true);
    props.api(props.property_id, { sheet: sheet_names })
      .then((response) => {
        setMessage("成功しました");
        setSeverity("success");
        props.apiSuccessAction(response)
        onClose();
      })
      .catch(() => {
        setMessage("失敗しました");
        setSeverity("error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Portal>
        <Snackbar
          closeAction={() => {
            setMessage("");
          }}
          message={message}
          severity={severity}
        />
      </Portal>
      <Dialog
        isOpen={open}
        title={props.title}
        handleClose={onClose}
        content={
          <>
            <Box>
              <Typography variant="subtitle1">
                {props.description}
              </Typography>
            </Box>

            <Box sx={{ pl: 1 }}>
              <FormGroup>
                <Stack>
                  {sheetFields.map(
                    (item, index) => {
                      const errors = props.causeCanNotSelectSheet[item.value] ?? []
                      return (
                        <React.Fragment key={index}>
                          <CheckBox
                            control={control}
                            name={`sheet.${index}.checked`}
                            label={item.label}
                            labelPlacement="end"
                            disabled={isSubmitting || errors.length > 0}
                          />
                          {errors.length > 0 &&
                            <Stack sx={{ mb: 1 }}>
                              {errors.map((item, errorIndex) => (
                                <FormHelperText error key={errorIndex} >
                                  ・{item}
                                </FormHelperText>
                              ))}
                            </Stack>
                          }
                        </React.Fragment>
                      );
                    }
                  )}
                </Stack>
              </FormGroup>
            </Box>
          </>
        }
        actions={
          <>
            <Button color={isMobile ? "gray" : "white"} onClick={onClose} disabled={isSubmitting} fullSize={isMobile}>
              キャンセル
            </Button>
            <Button
              color={props.submitButtonColor}
              type="submit"
              onClick={handleSubmit(submitAction)}
              disabled={isSubmitting}
              fullSize={isMobile}
              isLoading={isSubmitting}
            >
              {props.submitButtonTitle}
            </Button>
          </>
        }
      />
    </>
  );
};

SelectSheetDialog.propTypes = {
  choices: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
  property_id: PropTypes.number.isRequired,
  submitButtonTitle: PropTypes.string.isRequired,
  submitButtonColor: PropTypes.string.isRequired,
  api: PropTypes.func,
  apiSuccessAction: PropTypes.func,
  description: PropTypes.any.isRequired,
  causeCanNotSelectSheet: PropTypes.object,
};

SelectSheetDialog.defaultProps = {
  api: () => { },
  apiSuccessAction: () => { },
  causeCanNotSelectSheet: {},
}