import { Stack, Typography } from "@mui/material";
import { sendEmailWithPdf } from "../../api/Properties";
import PropTypes from "prop-types";
import { MailIcon } from "../atoms/MailIcon";
import { SelectSheetDialog } from "./SelectSheetDialog";

export const PdfDialog = (props) => {
  return (
    <SelectSheetDialog
      open={props.open}
      title={
        <>
          <MailIcon color="black" sx={{ mr: 1 }} />
          メールにPDFを送信
        </>
      }
      submitButtonTitle={"送信"}
      submitButtonColor={"brown"}
      property_id={props.property_id ?? 0}
      causeCanNotSelectSheet={props.causeCanNotSelectSheet}
      choices={props.choices ?? []}
      onClose={props.onClose}
      api={sendEmailWithPdf}
      description={
        <Stack direction="row" sx={{mb: 1}}>
          <Typography variant="subtitle1" sx={{color: "red.main"}} component="span">＊</Typography>
          <Typography variant="subtitle1" component="span">送信したいシートを選んでください：</Typography>
        </Stack>
      }
    />
  );
};

PdfDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  property_id: PropTypes.number.isRequired,
  choices: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  causeCanNotSelectSheet: PropTypes.object,
};

PdfDialog.defaultProps = {
  causeCanNotSelectSheet: {},
};