import * as React from "react";
import PropTypes from "prop-types";
import { ResetButton } from "../atoms/ResetButton";

export const PropertyColumnResetButton = (props) => {
  const {onClick} = props
  return (
    <ResetButton
      title="元の計算式を反映"
      onClick={onClick}
    />
  );
};

PropertyColumnResetButton.propTypes = {
  onClick: PropTypes.func,
}