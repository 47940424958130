import { Backdrop, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

export const LoadingProgress = (props) => {
  const defaultSx = {
    color: "#fff",
    zIndex: (theme) => theme.zIndex.drawer + 1,
    backgroundColor: "rgb(0, 0, 0, 0.9)",
  }
  const backdropSx = {...defaultSx, ...props.sx}
  return (
    <Backdrop open={props.open} sx={backdropSx}>
      {props.useCircular && <CircularProgress color="inherit" />}
    </Backdrop>
  );
};

LoadingProgress.propTypes = {
  open: PropTypes.bool,
  sx: PropTypes.object,
  useCircular: PropTypes.bool,
};

LoadingProgress.defaultProps = {
  useCircular: true,
  open: false,
  sx: {},
};
