import * as React from "react";
import PropTypes from "prop-types";
import { Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";

export const PropertyEditInfo = React.memo(function propertyEditInfo(props) {
  const data = [
    {
      label: "仕入値",
      value: Number.isFinite(props.cost_price) ? ("¥" + props.cost_price.toLocaleString()) : "¥0",
    },
    {
      label: "1棟粗利",
      value: Number.isFinite(props.gross_profit_per_building) ? ("¥" + _.round(props.gross_profit_per_building, 0).toLocaleString()) : "¥0",
    },
  ]

  return (
    <Stack
      direction="row"
      spacing={1}
      divider={<Divider orientation="vertical" flexItem />}
      sx={{ mr: 2 }}
    >
      {data.map((item, index) => ( 
        <Stack alignItems="left" key={index}>
          <Typography variant="subtitle1" fontSize="0.7rem">
            {item.label}
          </Typography>
          <Typography fontSize="1.5rem">
            {item.value}
          </Typography>
        </Stack>
       ))}
    </Stack>
  );
});

PropertyEditInfo.propTypes = {
  cost_price: PropTypes.number,
  gross_profit_per_building: PropTypes.number,
};

PropertyEditInfo.defaultProps = {
  cost_price: 0,
  gross_profit_per_building: 0,
};