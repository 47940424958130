import * as React from "react";
import PropTypes from "prop-types";
import { TextField as MUITextField } from "@mui/material";
import { FormLabel } from "@mui/material";

export const NoNameTextField = (props) => {
  const { label, ...textFiledProps } = props
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <MUITextField
        {...textFiledProps}
      />
    </>
  )
};

NoNameTextField.defaultProps = {
  fullWidth: true,
  size: "small",
  disabled: true,
  multiline: true,
};
NoNameTextField.propTypes = {
  label: PropTypes.any,
};
