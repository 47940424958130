import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { FormControl } from "../atoms/FormControl";
import { FormLabel } from "../atoms/FormLabel";
import { Divider, Grid, InputAdornment, Paper, Stack, Typography } from "@mui/material";
import { AddButton } from "../atoms/AddButton";
import { SelectForm } from "../atoms/SelectForm";
import { DeleteButton } from "../atoms/DeleteButton";
import { HiddenInput } from "../atoms/HiddenInput";
import { toAlphabet } from "../../utils/NumberUtils";
import { isMobile } from "react-device-detect";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { DialogSubContent } from "../molecules/DialogSubContent";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitPropertyOverview, useInitPropertyOverviewAdjacentRoad, useInitPropertyOverviewTraffic, useInitPropertyOverviewUrbanPlan } from "../../utils/CustomHook";
import { onBlurCommonAction, removeChild, saveChildForPropertyEdit } from "../../utils/PropertyEdit";

export const PropertyOverviewForms = React.memo(function propertyOverviewForms({
  property,
  initialized,
  propertyoverview,
  setOriginPropertyData,
  toggleViewport,
  formPaperVariant,
  formPaperElevation,
  ...props
}) {
  const [choices, setChoices] = React.useState({});
  const loadingComplete = React.useCallback(() => {
    setIsLoading(false)
  }, [])

  const [isLoading, setIsLoading] = React.useState(false)
  const [isOpenTrafficForm, setIsOpenTrafficForm] = React.useState(false)
  const [isOpenSchoolDistrictForm, setIsOpenSchoolDistrictForm] = React.useState(false)
  const [isOpenLandForm, setIsOpenLandForm] = React.useState(false)
  const [isOpenBuildingsForm, setIsOpenBuildingsForm] = React.useState(false)
  const [isOpenOtherForm, setIsOpenOtherForm] = React.useState(false)
  const [isOpenUrbanPlanForm, setIsOpenUrbanPlanForm] = React.useState(false)
  const [isOpenSaleForm, setIsOpenSaleForm] = React.useState(false)
  const [isOpenContractCountForm, setIsOpenContractCountForm] = React.useState(false)
  const [isOpenCurrentStateForm, setIsOpenCurrentStateForm] = React.useState(false)

  const [propertyId, setPropertyId] = React.useState()

  const [isFocused, setFocusInputName] = useFocusInput()
  const handleFocus = React.useCallback((name) => {
    setFocusInputName(name)
  }, [])

  React.useEffect(() => {
    setPropertyId(property.id)
  }, [property])

  React.useEffect(() => {
    setChoices(props.choices);
  }, [props.choices]);

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    methods,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange",
  });

  useInitPropertyOverview(propertyoverview, setValue, isFocused)
  const [propertyOverviewTrafficFields, propertyOverviewTrafficAppend, propertyOverviewTrafficRemove] = useInitPropertyOverviewTraffic(propertyoverview.propertyoverviewtraffic_set, setValue, isFocused, control, trigger)
  const [propertyOverviewAdjacentRoadFields, propertyOverviewAdjacentRoadAppend, propertyOverviewAdjacentRoadRemove] = useInitPropertyOverviewAdjacentRoad(propertyoverview.propertyoverviewadjacentroad_set, setValue, isFocused, control, trigger)

  React.useEffect(() => {
    if (!propertyoverview) {
      return
    }
    initialized();
  }, [propertyoverview]);

  const saveCommonParams = React.useMemo(() => [
    errors, propertyId, clearErrors, setOriginPropertyData, setError
  ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

  const removeChildCommonParams = React.useMemo(() => [
    propertyId, getValues, setOriginPropertyData,
  ], [propertyId, getValues, setOriginPropertyData])

  const onBlurForPropertyOverview = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "propertyoverview",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const createPropertyOverviewTraffic = () => {
    propertyOverviewTrafficAppend();
  };

  const onBlurForOverviewUrbanPlan = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property_overview", "propertyoverview", "propertyoverviewurbanplan_set"
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId]);

  const handleRemoveForPropertyOverviewTraffic = React.useCallback((index) => {
    removeChild(...removeChildCommonParams, propertyOverviewTrafficRemove, index, "propertyoverview", "propertyoverviewtraffic_set")
  }, [propertyId]);

  const onBlurForOverviewTraffic = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property_overview", "propertyoverview", "propertyoverviewtraffic_set"
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId]);

  const createPropertyOverviewAdjacentRoad = () => {
    propertyOverviewAdjacentRoadAppend();
  };

  const onBlurForOverviewAdjacentRoad = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property_overview", "propertyoverview", "propertyoverviewadjacentroad_set"
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId]);

  const handleRemoveForPropertyOverviewAdjacentRoad = React.useCallback((index) => {
    removeChild(...removeChildCommonParams, propertyOverviewAdjacentRoadRemove, index, "propertyoverview", "propertyoverviewadjacentroad_set")
  }, [propertyId]);

  const rules = React.useMemo(() => ({
    propertyoverview: {
      primary_school: {
        onBlur: onBlurForPropertyOverview,
      },
      junior_high_school: {
        onBlur: onBlurForPropertyOverview,
      },
      registry_area: {
        onBlur: onBlurForPropertyOverview,
      },
      liquidation_standard_area: {
        onBlur: onBlurForPropertyOverview,
      },
      building_enabled_area: {
        onBlur: onBlurForPropertyOverview,
      },
      building_area: {
        onBlur: onBlurForPropertyOverview,
      },
      contract_count: {
        onBlur: onBlurForPropertyOverview,
      },
      building_structure: {
        onBlur: onBlurForPropertyOverview,
      },
      underground: {
        onBlur: onBlurForPropertyOverview,
      },
      registration: {
        onBlur: onBlurForPropertyOverview,
      },
      buried_cultural_property_holding_area: {
        onBlur: onBlurForPropertyOverview,
      },
      district_planning: {
        onBlur: onBlurForPropertyOverview,
      },
      in_city_planning_facility: {
        onBlur: onBlurForPropertyOverview,
      },
      designated_workshops_and_factories: {
        onBlur: onBlurForPropertyOverview,
      },
      public_expansion_law: {
        onBlur: onBlurForPropertyOverview,
      },
      insolvency: {
        onBlur: onBlurForPropertyOverview,
      },
      sale_reason: {
        onBlur: onBlurForPropertyOverview,
      },
      sale_form: {
        onBlur: onBlurForPropertyOverview,
      },
      current_state: {
        onBlur: onBlurForPropertyOverview,
      },
      propertyoverviewtraffic_set: {
        station: {
          onBlur: onBlurForOverviewTraffic,
        },
        travel_time_min: {
          onBlur: onBlurForOverviewTraffic,
        },
        means_of_transportation: {
          onBlur: onBlurForOverviewTraffic,
        },
      },
      propertyoverviewadjacentroad_set: {
        direction: {
          onBlur: onBlurForOverviewAdjacentRoad,
        },
        width: {
          onBlur: onBlurForOverviewAdjacentRoad,
        },
        category: {
          onBlur: onBlurForOverviewAdjacentRoad,
        },
      },
      propertyoverviewurbanplan_set: {
        zoning_district: {
          onBlur: onBlurForOverviewUrbanPlan,
        },
        building_coverage_ratio: {
          onBlur: onBlurForOverviewUrbanPlan,
        },
        floor_area_ratio: {
          onBlur: onBlurForOverviewUrbanPlan,
        },
        allowance_floor_area_ratio: {
          onBlur: onBlurForOverviewUrbanPlan,
        },
        height_district: {
          onBlur: onBlurForOverviewUrbanPlan,
        },
        fireproof_specified_category: {
          onBlur: onBlurForOverviewUrbanPlan,
        },
        direction: {
          onBlur: onBlurForOverviewUrbanPlan,
        },
        minimum_site_area: {
          onBlur: onBlurForOverviewUrbanPlan,
        },
      },
    },
  }), [onBlurForPropertyOverview, onBlurForOverviewTraffic, onBlurForOverviewAdjacentRoad, onBlurForOverviewUrbanPlan]);

  const propertyOverviewUrbanPlanFields = useInitPropertyOverviewUrbanPlan(propertyoverview.propertyoverviewurbanplan_set, setValue, isFocused, control, rules.propertyoverview.propertyoverviewurbanplan_set, trigger)

  const propertyEditFormDialogCloseAction = React.useCallback(() => {
    toggleViewport()
  }, [])
  const formAreaClickedAction = React.useCallback((additionalProcess) => {
    if (!isMobile) {
      return
    }
    if (additionalProcess) {
      additionalProcess()
    }
    setIsLoading(true)
    toggleViewport()
  }, [])

  const commonInputProps = React.useMemo(() => ({
    control: control,
    onFocus: handleFocus,
  }), [control, handleFocus])

  return (
    <PropertyEditForms title="物件概要">
      <LoadingProgress open={isLoading} useCircular={false} />
      <FormProvider {...methods}>
        <PropertyEditFormDialog
          isOpen={isOpenTrafficForm}
          handleClose={() => {
            setIsOpenTrafficForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title={
            <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
              交通
              <AddButton
                onClick={createPropertyOverviewTraffic}
                disabled={props.disabled || propertyoverview.MAX_ROW_PROPERTY_OVERVIEW_TRAFFIC <= propertyOverviewTrafficFields.length}
              />
            </Stack>
          }
          content={
            <>
              <TrafficForm
                commonInputProps={commonInputProps}
                rules={rules}
                choices={choices}
                errors={errors}
                handleRemoveForPropertyOverviewTraffic={handleRemoveForPropertyOverviewTraffic}
                propertyOverviewTrafficFields={propertyOverviewTrafficFields}
                isModal={true}
                disabled={props.disabled}
                setValue={setValue}
              />
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", mt: 1 }}
                onClick={!(props.disabled || propertyoverview.MAX_ROW_PROPERTY_OVERVIEW_TRAFFIC <= propertyOverviewTrafficFields.length) ? createPropertyOverviewTraffic : undefined}
              >
                <Typography sx={{ color: (props.disabled || propertyoverview.MAX_ROW_PROPERTY_OVERVIEW_TRAFFIC <= propertyOverviewTrafficFields.length) ? "gray.main" : "darkGreen.main" }}>
                  追加
                </Typography>
                <AddButton disabled={props.disabled || propertyoverview.MAX_ROW_PROPERTY_OVERVIEW_TRAFFIC <= propertyOverviewTrafficFields.length} sx={{ ml: 1 }} />
              </Stack>
            </>
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenSchoolDistrictForm}
          handleClose={() => {
            setIsOpenSchoolDistrictForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="学区"
          content={
            <SchoolDistrictForm
              commonInputProps={commonInputProps}
              rules={rules}
              disabled={props.disabled}
              isModal={true}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenLandForm}
          handleClose={() => {
            setIsOpenLandForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="土地"
          content={
            <LandForm
              commonInputProps={commonInputProps}
              rules={rules}
              disabled={props.disabled}
              isModal={true}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenBuildingsForm}
          handleClose={() => {
            setIsOpenBuildingsForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="建物"
          content={
            <BuildingsForm
              commonInputProps={commonInputProps}
              rules={rules}
              disabled={props.disabled}
              choices={choices}
              setValue={setValue}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenOtherForm}
          handleClose={() => {
            setIsOpenOtherForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="その他"
          content={
            <OtherForm
              commonInputProps={commonInputProps}
              rules={rules}
              choices={choices}
              disabled={props.disabled}
              setValue={setValue}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenUrbanPlanForm}
          handleClose={() => {
            setIsOpenUrbanPlanForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="都市計画"
          contentSx={{ p: 0 }}
          content={
            <UrbanPlanForm
              commonInputProps={commonInputProps}
              rules={rules}
              choices={choices}
              propertyOverviewUrbanPlanFields={propertyOverviewUrbanPlanFields}
              propertyOverviewAdjacentRoadFields={propertyOverviewAdjacentRoadFields}
              createPropertyOverviewAdjacentRoad={createPropertyOverviewAdjacentRoad}
              handleRemoveForPropertyOverviewAdjacentRoad={handleRemoveForPropertyOverviewAdjacentRoad}
              errors={errors}
              isModal={true}
              disabled={props.disabled}
              MAX_ROW_PROPERTY_OVERVIEW_ADJACENT_ROAD={propertyoverview.MAX_ROW_PROPERTY_OVERVIEW_ADJACENT_ROAD ?? 0}
              setValue={setValue}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenSaleForm}
          handleClose={() => {
            setIsOpenSaleForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="売却"
          content={
            <SaleForm
              commonInputProps={commonInputProps}
              rules={rules}
              choices={choices}
              disabled={props.disabled}
              setValue={setValue}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenContractCountForm}
          handleClose={() => {
            setIsOpenContractCountForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="契約本数"
          content={
            <ContractCountForm
              commonInputProps={commonInputProps}
              rules={rules}
              disabled={props.disabled}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenCurrentStateForm}
          handleClose={() => {
            setIsOpenCurrentStateForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="現況"
          content={
            <CurrentStateForm
              commonInputProps={commonInputProps}
              rules={rules}
              disabled={props.disabled}
              choices={choices}
              setValue={setValue}
            />
          }
        />
        <form>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} lg={4}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenTrafficForm(true))}
                  >
                    <Stack direction="row">
                      <FormLabel>
                        <Typography variant="subtitle1">交通</Typography>
                      </FormLabel>
                      <AddButton
                        onClick={createPropertyOverviewTraffic}
                        disabled={props.disabled || propertyoverview.MAX_ROW_PROPERTY_OVERVIEW_TRAFFIC <= propertyOverviewTrafficFields.length}
                      />
                    </Stack>
                    <TrafficForm
                      commonInputProps={commonInputProps}
                      rules={rules}
                      choices={choices}
                      errors={errors}
                      handleRemoveForPropertyOverviewTraffic={handleRemoveForPropertyOverviewTraffic}
                      propertyOverviewTrafficFields={propertyOverviewTrafficFields}
                      isModal={false}
                      disabled={props.disabled}
                      setValue={setValue}
                    />
                  </Paper>

                </Grid>

                <Grid item xs={12}>
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenSchoolDistrictForm(true))}
                  >
                    <Grid container direction="row" spacing={1} alignItems="center">
                      <Grid item xs={1}>
                        <Typography noWrap variant="subtitle1">学区</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <SchoolDistrictForm
                          commonInputProps={commonInputProps}
                          rules={rules}
                          disabled={props.disabled}
                          isModal={false}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenLandForm(true))}
              >
                <Typography variant="subtitle1">土地</Typography>
                <LandForm
                  commonInputProps={commonInputProps}
                  rules={rules}
                  disabled={props.disabled}
                  isModal={false}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} lg={2}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenBuildingsForm(true))}
              >
                <Typography variant="subtitle1">建物</Typography>
                <BuildingsForm
                  commonInputProps={commonInputProps}
                  rules={rules}
                  choices={choices}
                  disabled={props.disabled}
                  setValue={setValue}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenOtherForm(true))}
              >
                <Typography variant="subtitle1">その他</Typography>
                <OtherForm
                  commonInputProps={commonInputProps}
                  rules={rules}
                  choices={choices}
                  disabled={props.disabled}
                  setValue={setValue}
                />
              </Paper>

            </Grid>

            <Grid item xs={12}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenUrbanPlanForm(true))}
              >
                <Typography variant="subtitle1">都市計画</Typography>
                <UrbanPlanForm
                  commonInputProps={commonInputProps}
                  rules={rules}
                  choices={choices}
                  propertyOverviewUrbanPlanFields={propertyOverviewUrbanPlanFields}
                  propertyOverviewAdjacentRoadFields={propertyOverviewAdjacentRoadFields}
                  createPropertyOverviewAdjacentRoad={createPropertyOverviewAdjacentRoad}
                  handleRemoveForPropertyOverviewAdjacentRoad={handleRemoveForPropertyOverviewAdjacentRoad}
                  errors={errors}
                  isModal={false}
                  disabled={props.disabled}
                  MAX_ROW_PROPERTY_OVERVIEW_ADJACENT_ROAD={propertyoverview.MAX_ROW_PROPERTY_OVERVIEW_ADJACENT_ROAD ?? 0}
                  setValue={setValue}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenSaleForm(true))}
              >
                <SaleForm
                  commonInputProps={commonInputProps}
                  rules={rules}
                  choices={choices}
                  disabled={props.disabled}
                  setValue={setValue}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={3}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenContractCountForm(true))}
              >
                <ContractCountForm
                  commonInputProps={commonInputProps}
                  rules={rules}
                  disabled={props.disabled}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={3}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenCurrentStateForm(true))}
              >
                <CurrentStateForm
                  commonInputProps={commonInputProps}
                  rules={rules}
                  disabled={props.disabled}
                  choices={choices}
                  setValue={setValue}
                />
              </Paper>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </PropertyEditForms>
  );
});
PropertyOverviewForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  propertyoverview: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

PropertyOverviewForms.defaultProps = {
  choices: {},
  disabled: false,
};

const TrafficForm = React.memo(function trafficForm(props) {
  const { commonInputProps, rules, choices, errors, handleRemoveForPropertyOverviewTraffic, propertyOverviewTrafficFields, isModal, setValue } = props

  const data = [
    {
      input: (index) => {
        return (
          <SelectForm
            labelPosition={isModal ? "left" : "top"}
            {...commonInputProps}
            label="路線"
            name={`propertyoverview.propertyoverviewtraffic_set.${index}.means_of_transportation`}
            rules={
              rules.propertyoverview.propertyoverviewtraffic_set
                .means_of_transportation
            }
            choices={choices?.transportation}
            disabled={props.disabled}
            setValue={setValue}
          />
        )
      },
      grid: 5,
    },
    {
      input: (index) => {
        return (
          <TextField
            labelPosition={isModal ? "left" : "top"}
            {...commonInputProps}
            label="駅名"
            name={`propertyoverview.propertyoverviewtraffic_set.${index}.station`}
            rules={
              rules.propertyoverview.propertyoverviewtraffic_set
                .station
            }
            disabled={props.disabled}
          />
        )
      },
      grid: 3,
    },
    {
      input: (index) => {
        return (
          <TextField
            labelPosition={isModal ? "left" : "top"}
            {...commonInputProps}
            label="徒歩"
            name={`propertyoverview.propertyoverviewtraffic_set.${index}.travel_time_min`}
            type="number"
            rules={
              rules.propertyoverview.propertyoverviewtraffic_set
                .travel_time_min
            }
            disabled={props.disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  分
                </InputAdornment>
              ),
            }}
          />
        )
      },
      grid: 3,
    }
  ]
  return (
    <FormControl
      error={
        errors.propertyoverview?.propertyoverviewtraffic_set
          ? true
          : false
      }
    >
      {propertyOverviewTrafficFields.map((item, index) => (
        <Grid container spacing={1} key={index} alignItems={isModal ? "center" : "flex-end"}>
          <HiddenInput
            {...commonInputProps}
            name={`propertyoverview.propertyoverviewtraffic_set.${index}.id`}
          />
          {isModal
            ?
            <>
              <Grid item xs={11}>
                <Grid container spacing={1}>
                  {data.map((item, dataIndex) => {
                    return (
                      <Grid key={dataIndex} item xs={12}>
                        {item.input(index)}
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <DeleteButton
                  onClick={() =>
                    handleRemoveForPropertyOverviewTraffic(index)
                  }
                  disabled={props.disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
            :
            <>
              {data.map((item, dataIndex) => {
                return (
                  <Grid key={dataIndex} item xs={12} md={item.grid}>
                    {item.input(index)}
                  </Grid>
                )
              })}
              <Grid item xs={12} md={1}>
                <DeleteButton
                  onClick={() =>
                    handleRemoveForPropertyOverviewTraffic(index)
                  }
                  disabled={props.disabled}
                />
              </Grid>
            </>
          }
        </Grid>
      ))}
    </FormControl>
  )
})
TrafficForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleRemoveForPropertyOverviewTraffic: PropTypes.func.isRequired,
  propertyOverviewTrafficFields: PropTypes.array.isRequired,
  isModal: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
}

const SchoolDistrictForm = React.memo(function schoolDistrictForm(props) {
  const { commonInputProps, rules, isModal } = props
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={isModal ? 12 : 6}>
        <TextField
          {...commonInputProps}
          label="小学校"
          name="propertyoverview.primary_school"
          rules={rules.propertyoverview.primary_school}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 6}>
        <TextField
          {...commonInputProps}
          label="中学校"
          name="propertyoverview.junior_high_school"
          rules={rules.propertyoverview.junior_high_school}
          disabled={props.disabled}
        />
      </Grid>
    </Grid>
  )
})
SchoolDistrictForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
}

const LandForm = React.memo(function landForm(props) {

  const { commonInputProps, rules, isModal } = props
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={isModal ? 12 : 4}>
        <Typography>登記簿面積</Typography>
      </Grid>
      <Grid item xs={isModal ? 6 : 4}>
        <TextField
          {...commonInputProps}
          name="propertyoverview.registry_area"
          type="float"
          rules={rules.propertyoverview.registry_area}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">㎡</InputAdornment>
            ),
          }}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={isModal ? 6 : 4}>
        <TextField
          {...commonInputProps}
          name="propertyoverview.registry_area_tsubo"
          type="float"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">坪</InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={isModal ? 12 : 4}>
        <Typography>精算基準面積</Typography>
      </Grid>
      <Grid item xs={isModal ? 6 : 4}>
        <TextField
          {...commonInputProps}
          name="propertyoverview.liquidation_standard_area"
          type="float"
          rules={rules.propertyoverview.liquidation_standard_area}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">㎡</InputAdornment>
            ),
          }}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={isModal ? 6 : 4}>
        <TextField
          {...commonInputProps}
          name="propertyoverview.liquidation_standard_area_tsubo"
          type="float"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">坪</InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={isModal ? 12 : 4}>
        <Typography>建築有効面積</Typography>
      </Grid>
      <Grid item xs={isModal ? 6 : 4}>
        <TextField
          {...commonInputProps}
          name="propertyoverview.building_enabled_area"
          type="float"
          rules={rules.propertyoverview.building_enabled_area}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">㎡</InputAdornment>
            ),
          }}
          disabled={props.disabled}
          color="yellow"
        />
      </Grid>
      <Grid item xs={isModal ? 6 : 4}>
        <FormControl>
          <TextField
            {...commonInputProps}
            name="propertyoverview.building_enabled_area_tsubo"
            type="float"
            disabled={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">坪</InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
})

LandForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
}

const BuildingsForm = React.memo(function buildingsForm(props) {
  const { commonInputProps, rules, choices, setValue } = props
  return (
    <Grid container direction="row" spacing={1} alignItems="flex-end">
      <Grid item xs={12}>
        <SelectForm
          {...commonInputProps}
          name="propertyoverview.building_structure"
          rules={rules.propertyoverview.building_structure}
          choices={choices?.building_structures}
          disabled={props.disabled}
          setValue={setValue}
          color="yellow"
        />
      </Grid>
      <Grid item xs={6}>
        <SelectForm
          {...commonInputProps}
          label="地下"
          name="propertyoverview.underground"
          rules={rules.propertyoverview.underground}
          choices={choices?.availability}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectForm
          {...commonInputProps}
          label="登記"
          name="propertyoverview.registration"
          rules={rules.propertyoverview.registration}
          choices={choices?.availability}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...commonInputProps}
          label="面積"
          name="propertyoverview.building_area"
          type="float"
          rules={rules.propertyoverview.building_area}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">㎡</InputAdornment>
            ),
          }}
          disabled={props.disabled}
          color="yellow"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...commonInputProps}
          name="propertyoverview.building_area_tsubo"
          type="float"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">坪</InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>

  )
})
BuildingsForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
}

const OtherForm = React.memo(function otherForm(props) {
  const { commonInputProps, rules, choices, setValue } = props

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={6}>
        <Typography>埋蔵文化財包蔵地区</Typography>
      </Grid>
      <Grid item xs={6}>
        <SelectForm
          {...commonInputProps}
          name="propertyoverview.buried_cultural_property_holding_area"
          rules={
            rules.propertyoverview
              .buried_cultural_property_holding_area
          }
          choices={choices?.applicable}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography>地区計画</Typography>
      </Grid>
      <Grid item xs={6}>
        <SelectForm
          {...commonInputProps}
          name="propertyoverview.district_planning"
          rules={rules.propertyoverview.district_planning}
          choices={choices?.applicable}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography>都市計画施設内（計画道路）</Typography>
      </Grid>
      <Grid item xs={6}>
        <SelectForm
          {...commonInputProps}
          name="propertyoverview.in_city_planning_facility"
          rules={rules.propertyoverview.in_city_planning_facility}
          choices={choices?.applicable}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography>指定作業所・工場</Typography>
      </Grid>
      <Grid item xs={6}>
        <SelectForm
          {...commonInputProps}
          name="propertyoverview.designated_workshops_and_factories"
          rules={
            rules.propertyoverview.designated_workshops_and_factories
          }
          choices={choices?.applicable}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography>公拡法</Typography>
      </Grid>
      <Grid item xs={6}>
        <SelectForm
          {...commonInputProps}
          name="propertyoverview.public_expansion_law"
          rules={rules.propertyoverview.public_expansion_law}
          choices={choices?.applicable}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography>債務超過</Typography>
      </Grid>
      <Grid item xs={6}>
        <SelectForm
          {...commonInputProps}
          name="propertyoverview.insolvency"
          rules={rules.propertyoverview.insolvency}
          choices={choices?.applicable}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
    </Grid>

  )
})
OtherForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
}

const UrbanPlanForm = React.memo(function urbanPlanForm(props) {
  const { commonInputProps, rules, choices, propertyOverviewUrbanPlanFields, isModal, createPropertyOverviewAdjacentRoad, handleRemoveForPropertyOverviewAdjacentRoad, errors, propertyOverviewAdjacentRoadFields, MAX_ROW_PROPERTY_OVERVIEW_ADJACENT_ROAD, setValue } = props

  const urbanPlanData = [
    {
      input: (index) => {
        return (
          <SelectForm
            {...commonInputProps}
            name={`propertyoverview.propertyoverviewurbanplan_set.${index}.zoning_district`}
            rules={
              rules.propertyoverview.propertyoverviewurbanplan_set
                .zoning_district
            }
            choices={choices?.zoning_district}
            disabled={props.disabled}
            setValue={setValue}
          />
        )
      },
      lgGrid: 4,
      sxGrid: 12,
    },
    {
      input: (index) => {
        return (
          <SelectForm
            {...commonInputProps}
            label="建蔽率"
            name={`propertyoverview.propertyoverviewurbanplan_set.${index}.building_coverage_ratio`}
            rules={
              rules.propertyoverview.propertyoverviewurbanplan_set
                .building_coverage_ratio
            }
            choices={choices?.building_coverage_ratio}
            disabled={props.disabled}
            setValue={setValue}
          />
        )
      },
      lgGrid: 1,
      sxGrid: 6,
    },
    {
      input: (index) => {
        return (
          <SelectForm
            {...commonInputProps}
            label="容積率"
            name={`propertyoverview.propertyoverviewurbanplan_set.${index}.floor_area_ratio`}
            rules={
              rules.propertyoverview.propertyoverviewurbanplan_set
                .floor_area_ratio
            }
            choices={choices?.floor_area_ratio}
            disabled={props.disabled}
            setValue={setValue}
          />
        )
      },
      lgGrid: 1,
      sxGrid: 6,
    },
    {
      input: (index) => {
        return (
          <TextField
            {...commonInputProps}
            label="許容容積率"
            name={`propertyoverview.propertyoverviewurbanplan_set.${index}.allowance_floor_area_ratio`}
            type="number"
            rules={rules.propertyoverview.propertyoverviewurbanplan_set.allowance_floor_area_ratio}
            disabled={props.disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">％</InputAdornment>
              ),
            }}
          />
        )
      },
      lgGrid: 1,
      sxGrid: 6,
    },
    {
      input: (index) => {
        return (
          <SelectForm
            {...commonInputProps}
            label="高度地区"
            name={`propertyoverview.propertyoverviewurbanplan_set.${index}.height_district`}
            rules={
              rules.propertyoverview.propertyoverviewurbanplan_set
                .height_district
            }
            choices={choices?.height_district}
            disabled={props.disabled}
            setValue={setValue}
          />
        )
      },
      lgGrid: 1,
      sxGrid: 6,
    },
    {
      input: (index) => {
        return (
          <SelectForm
            {...commonInputProps}
            label="防火指定"
            name={`propertyoverview.propertyoverviewurbanplan_set.${index}.fireproof_specified_category`}
            rules={
              rules.propertyoverview.propertyoverviewurbanplan_set
                .fireproof_specified_category
            }
            choices={choices?.fireproof_specified_categories}
            disabled={props.disabled}
            setValue={setValue}
          />
        )
      },
      lgGrid: 1,
      sxGrid: 6,
    },
    {
      input: (index) => {
        return (
          <TextField
            {...commonInputProps}
            label="最低敷地面積"
            name={`propertyoverview.propertyoverviewurbanplan_set.${index}.minimum_site_area`}
            rules={
              rules.propertyoverview.propertyoverviewurbanplan_set
                ?.minimum_site_area
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">㎡</InputAdornment>
              ),
            }}
            type="float"
            disabled={props.disabled}
          />
        )
      },
      lgGrid: 3,
      sxGrid: 6,
    },
  ]

  const adjacentRoadData = [
    {
      input: (index) => {
        return (
          <SelectForm
            {...commonInputProps}
            name={`propertyoverview.propertyoverviewadjacentroad_set.${index}.direction`}
            rules={
              rules.propertyoverview
                .propertyoverviewadjacentroad_set.direction
            }
            choices={choices?.direction}
            disabled={props.disabled}
            setValue={setValue}
          />
        )
      },
      lgGrid: 4,
      sxGrid: 12,
    },
    {
      input: (index) => {
        return (
          <TextField
            {...commonInputProps}
            name={`propertyoverview.propertyoverviewadjacentroad_set.${index}.width`}
            type="float"
            rules={
              rules.propertyoverview
                .propertyoverviewadjacentroad_set.width
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  ｍ
                </InputAdornment>
              ),
            }}
            disabled={props.disabled}
          />
        )
      },
      lgGrid: 4,
      sxGrid: 6,
    },
    {
      input: (index) => {
        return (
          <SelectForm
            {...commonInputProps}
            name={`propertyoverview.propertyoverviewadjacentroad_set.${index}.category`}
            rules={
              rules.propertyoverview
                .propertyoverviewadjacentroad_set.category
            }
            choices={choices?.adjacent_road_category}
            disabled={props.disabled}
            setValue={setValue}
          />
        )
      },
      lgGrid: 4,
      sxGrid: 6,
    },
  ]

  const spacingSx = isModal ? { px: 3, py: 0.5 } : {}
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        {propertyOverviewUrbanPlanFields.map((item, index) => (
          <React.Fragment key={index}>
            <HiddenInput
              {...commonInputProps}
              name={`propertyoverview.propertyoverviewurbanplan_set.${index}.id`}
            />
            {isModal
              ?
              <DialogSubContent
                title={
                  <Typography>
                    （{toAlphabet(index + 1)}）
                  </Typography>
                }
                content={
                  <Grid container spacing={1} alignItems="flex-end">
                    {urbanPlanData.map((dataItem, dataIndex) => {
                      return (
                        <Grid key={dataIndex} item xs={dataItem.sxGrid} lg={dataItem.lgGrid}>
                          {dataItem.input(index)}
                        </Grid>
                      )
                    })}
                  </Grid>
                }
              />
              :
              <Grid container direction="row" spacing={1} key={index} alignItems="flex-end">
                <Grid item xs={12} lg={1} sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <Typography sx={{ minHeight: "50px", pl: 3 }}>
                    （{toAlphabet(index + 1)}）
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={11} container spacing={1} alignItems="flex-end">
                  {urbanPlanData.map((dataItem, dataIndex) => {
                    return (
                      <Grid key={dataIndex} item xs={dataItem.sxGrid} lg={dataItem.lgGrid}>
                        {dataItem.input(index)}
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            }
          </React.Fragment>
        ))}
      </Grid>
      {isModal &&
        <Grid item xs={12}>
          <Divider />
        </Grid>
      }

      <Grid item xs={12}>
        <FormControl
          error={
            errors.propertyoverview?.propertyoverviewadjacentroad_set
              ? true
              : false
          }
        >
          <Stack direction="row" sx={isModal ? { ...spacingSx, mt: 3, mb: 2 } : {}}>
            <FormLabel>
              <Typography variant="subtitle1">接道</Typography>
            </FormLabel>
            <AddButton
              onClick={createPropertyOverviewAdjacentRoad}
              disabled={props.disabled || MAX_ROW_PROPERTY_OVERVIEW_ADJACENT_ROAD <= propertyOverviewAdjacentRoadFields.length}
            />
          </Stack>
          {isModal
            ?
            <Grid container spacing={1} direction="row" sx={{ mb: 3 }}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {propertyOverviewAdjacentRoadFields.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" sx={spacingSx}>
                      <HiddenInput
                        {...commonInputProps}
                        name={`propertyoverview.propertyoverviewadjacentroad_set.${index}.id`}
                      />
                      <Grid item xs={11} container spacing={1}>
                        {adjacentRoadData.map((adjacentRoadItem, adjacentRoadIndex) => {
                          return (
                            <Grid key={adjacentRoadIndex} item xs={adjacentRoadItem.sxGrid} lg={adjacentRoadItem.lgGrid}>
                              {adjacentRoadItem.input(index)}
                            </Grid>
                          )
                        })}
                      </Grid>
                      <Grid item xs={1}>
                        <DeleteButton
                          onClick={() =>
                            handleRemoveForPropertyOverviewAdjacentRoad(index)
                          }
                          disabled={props.disabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%", mt: 1 }}
                  onClick={!(props.disabled || MAX_ROW_PROPERTY_OVERVIEW_ADJACENT_ROAD <= propertyOverviewAdjacentRoadFields.length) ? createPropertyOverviewAdjacentRoad : undefined}
                >
                  <Typography sx={{ color: (props.disabled || MAX_ROW_PROPERTY_OVERVIEW_ADJACENT_ROAD <= propertyOverviewAdjacentRoadFields.length) ? "gray.main" : "darkGreen.main" }}>
                    追加
                  </Typography>
                  <AddButton disabled={props.disabled || MAX_ROW_PROPERTY_OVERVIEW_ADJACENT_ROAD <= propertyOverviewAdjacentRoadFields.length} sx={{ ml: 1 }} />
                </Stack>
              </Grid>
            </Grid>
            :
            <Grid container spacing={2} direction="row">
              {propertyOverviewAdjacentRoadFields.map((item, index) => (
                <Grid item xs={12} md={4} key={index} container spacing={1}>
                  <HiddenInput
                    {...commonInputProps}
                    name={`propertyoverview.propertyoverviewadjacentroad_set.${index}.id`}
                  />
                  <Grid item xs={11} container spacing={1}>
                    {adjacentRoadData.map((adjacentRoadItem, adjacentRoadIndex) => {
                      return (
                        <Grid item key={adjacentRoadIndex} xs={adjacentRoadItem.sxGrid} lg={adjacentRoadItem.lgGrid}>
                          {adjacentRoadItem.input(index)}
                        </Grid>
                      )
                    })}
                  </Grid>
                  <Grid item xs={1}>
                    <DeleteButton
                      onClick={() =>
                        handleRemoveForPropertyOverviewAdjacentRoad(index)
                      }
                      disabled={props.disabled}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          }
        </FormControl>
      </Grid>
    </Grid>

  )
})
UrbanPlanForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  propertyOverviewUrbanPlanFields: PropTypes.array.isRequired,
  isModal: PropTypes.bool.isRequired,
  createPropertyOverviewAdjacentRoad: PropTypes.func.isRequired,
  handleRemoveForPropertyOverviewAdjacentRoad: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  propertyOverviewAdjacentRoadFields: PropTypes.array.isRequired,
  MAX_ROW_PROPERTY_OVERVIEW_ADJACENT_ROAD: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
}

const SaleForm = React.memo(function saleForm(props) {

  const { commonInputProps, rules, choices, setValue } = props
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={6}>
        <SelectForm
          {...commonInputProps}
          label="売却理由"
          name="propertyoverview.sale_reason"
          rules={rules.propertyoverview.sale_reason}
          choices={choices?.sale_reasons}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <SelectForm
          {...commonInputProps}
          label="売却形態"
          name="propertyoverview.sale_form"
          rules={rules.propertyoverview.sale_form}
          choices={choices?.sale_forms}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
    </Grid>
  )
})
SaleForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
}

const ContractCountForm = React.memo(function contractCountForm(props) {
  const { commonInputProps, rules } = props
  return (
    <TextField
      {...commonInputProps}
      label="契約本数"
      name="propertyoverview.contract_count"
      rules={rules.propertyoverview.contract_count}
      type="number"
      disabled={props.disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">本</InputAdornment>
        ),
      }}
      color="yellow"
    />
  )
})
ContractCountForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
}

const CurrentStateForm = React.memo(function currentStateForm(props) {

  const { commonInputProps, rules, choices, setValue } = props
  return (
    <SelectForm
      {...commonInputProps}
      label="現況"
      name="propertyoverview.current_state"
      rules={rules.propertyoverview.current_state}
      choices={choices?.current_state}
      disabled={props.disabled}
      setValue={setValue}
      color="yellow"
    />
  )
})
CurrentStateForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
}