import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from "prop-types";
import { Container } from "../atoms/Container";
import { CustomHelmetProvider } from "../organisms/CustomHelmetProvider";
import { CustomThemeProvider } from "../organisms/CustomThemeProvider";
import { Header } from "../organisms/Header";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { UserContext } from "../../Context";

export const SimpleLayout = (props) => {
  return (
    <UserContext.Provider value={props.user}>
      <CustomThemeProvider>
        <CustomHelmetProvider pageTitle={props.pageTitle}>
          <CssBaseline />
          {props.useHeader && <Header />}
          <LoadingProgress open={props.loadingOpen} />
          <Container>{props.children}</Container>
        </CustomHelmetProvider>
      </CustomThemeProvider>
    </UserContext.Provider>
  );
};

SimpleLayout.propTypes = {
  user: PropTypes.object.isRequired,
  loadingOpen: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.any,
  useHeader: PropTypes.bool,
};

SimpleLayout.defaultProps = {
  useHeader: true,
};
