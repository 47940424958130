import PropTypes from "prop-types";
import { ResetIcon } from "./ResetIcon";
import { IconButton } from "@mui/material";
import { Tooltip } from "./Tooltip";

export const ResetButton = (props) => {
  const { title, sx, disabled, onClick, color, ...buttonProps } = props;
  return (
    <Tooltip title={title}>
      <IconButton
        sx={{
          p: 0,
          opacity: 0.54,
          ...sx,
        }}
        onClick={disabled ? null : onClick}
        color={color}
        {...buttonProps}
      >
        <ResetIcon color={color}/>
      </IconButton>
    </Tooltip>
  );
};

ResetButton.defaultProps = {
  sx: {},
  color: "black",
  disabled: false,
};

ResetButton.propTypes = {
  sx: PropTypes.object,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};
