import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import {ReactComponent as icon} from "../../assets/images/Printer.svg";


export const PrinterIcon = (props) => {
  return (
    <SvgIcon
      component={icon}
      viewBox="0 0 21 21"
      {...props}
    />
  );
};

PrinterIcon.defaultProps = {
  color: "white"
};

PrinterIcon.propTypes = {
  color: PropTypes.string,
}