import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

export const TableTitle = ({ sx, ...props }) => {
  // if (!sx?.flex) {
  //   sx.flex = "1 1 100%";
  // }
  return (
    <Typography sx={sx} {...props}>
      {props.children}
    </Typography>
  );
};

TableTitle.defaultProps = {
  sx: {},
  variant: "h5",
  id: "tableTitle",
  component: "div",
};

TableTitle.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.any
}