import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { Download } from "@mui/icons-material";
import { Tooltip } from "./Tooltip";

export const DonwloadButton = (props) => {
  const { color, onClick, title, disabled } = props;

  return (
    <Tooltip title={title ?? ""}>
      <IconButton
        sx={{
          minWidth: "40px",
          px: 0,
          border: "1.2px solid",
          borderRadius: 2,
        }}
        color={color}
        component="label"
        onClick={onClick}
        disabled={disabled}
      >
        <Download />
      </IconButton>
    </Tooltip>
  );
};

DonwloadButton.defaultProps = {
  color: "blue",
  title: "ダウンロード",
  disabled: false,
};

DonwloadButton.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};
