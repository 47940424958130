import * as React from "react";
import PropTypes from "prop-types";
import { Checkbox as MuiCheckBox, FormControlLabel, FormHelperText } from "@mui/material";
import { FormControl } from "./FormControl";
import { Controller } from "react-hook-form";

export const CheckBox = (props) => {
  const { onFocus, name, control, rules, label, labelPlacement, ...checkBoxProps } = props
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
            label
            ? <FormControl error={fieldState.error ? true : false}>
                <FormControlLabel
                  control={
                    <MuiCheckBox
                      {...field}
                      checked={field.value ? true : false}
                      value={field.value ?? ""}
                      onFocus={() => {
                        if (typeof onFocus === "function") {
                          onFocus(name)
                        }
                      }}
                    />
                  }
                  {...checkBoxProps}
                  labelPlacement={labelPlacement}
                  label={label}
                />
                {fieldState.error?.message &&
                  <FormHelperText>
                    {fieldState.error?.message}
                  </FormHelperText>
                }
              </FormControl>
            : <FormControl error={fieldState.error ? true : false}>
                <MuiCheckBox
                  {...field}
                  checked={field.value ? true : false}
                  value={field.value ?? ""}
                  {...checkBoxProps}
                  onFocus={() => {
                    if (typeof onFocus === "function") {
                      onFocus(name)
                    }
                  }}
                />
                {fieldState.error?.message &&
                  <FormHelperText>
                    {fieldState.error?.message}
                  </FormHelperText>
                }
              </FormControl>
        )
      }

      }
    />
  )
};

CheckBox.propTypes = {
  control: PropTypes.any.isRequired,
  onFocus: PropTypes.func,
  rules: PropTypes.object,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  labelPlacement: PropTypes.string,
};

