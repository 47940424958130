import * as React from "react";
import PropTypes from "prop-types";
import {
  Grid, Stack,
} from "@mui/material";
import * as validationRules from "../../utils/ValidationRules";
import { SelectForm } from "../atoms/SelectForm";

export const PropertyBulkShareInputs = (props) => {
  const { control, choices, setValue } =
    props;
  const rules = {
    owner_user_id: {
      ...validationRules.required(),
    },
    share_user_id: {
      ...validationRules.required(),
    },
    permission: {
      ...validationRules.required(),
    },
  };
  return (
    <Stack direction="row" spacing={1}>
      <Grid
        container
        direction="row"
        spacing={1}
      >
        <Grid item xs={4}>
          <SelectForm
            label="作成ユーザ"
            control={control}
            name={`owner_user_id`}
            rules={rules.owner_user_id}
            choices={choices.users}
            setValue={setValue}
            disableClearable={true}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectForm
            label="共有先"
            control={control}
            name={`share_user_id`}
            rules={rules.share_user_id}
            choices={choices.users}
            setValue={setValue}
            disableClearable={true}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectForm
            label="権限"
            control={control}
            name={`permission`}
            rules={rules.permission}
            choices={choices.permissions}
            setValue={setValue}
            disableClearable={true}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

PropertyBulkShareInputs.propTypes = {
  register: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  control: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
}