import PropTypes from "prop-types";
import { Tooltip as MuiTooltip } from "@mui/material";

export const Tooltip = (props) => {
  const { title, arrow } = props;
  return (
    <MuiTooltip title={title} arrow={arrow}>
      {props.children}
    </MuiTooltip>
  );
};

Tooltip.defaultProps = {
  arrow: true,
  title: "",
};
Tooltip.propTypes = {
  title: PropTypes.string,
  arrow: PropTypes.bool,
  children: PropTypes.any,
};
