import { Stack } from "@mui/material";
import { SimpleLayout } from "../templates/SimpleLayout";
import React from "react";
import { Snackbar } from "../atoms/Snackbar";
import { Box } from "@mui/system";
import { UserSettingsForm } from "../organisms/UserSettingsForm";
import { ApiErrorHandler } from "../../api/ApiErrorHandler";
import { getUserShares } from "../../api/UserShare";
import _ from "lodash";
import { isMobile } from "react-device-detect";

export const UserSettings = () => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [user, setUser] = React.useState({});
  const [userShare, setUserShare] = React.useState([]);
  const [choices, setChoices] = React.useState({});
  const [forceUseEffect, setForceUseEffect] = React.useState(0);
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [isInitializing, setIsInitializing] = React.useState(true);
  const callUseEffect = React.useCallback(() => {
    setForceUseEffect(forceUseEffect + 1);
  }, [forceUseEffect]);

  React.useEffect(() => {
    setLoadingOpen(true);
    setIsInitializing(true);
    getUserShares()
      .then((response) => {
        setUser(response.data.user);
        if (response.data.results && !_.isEqual(response.data.results, userShare)) {
          setUserShare(response.data.results);
        }
        if (response.data.choices && !_.isEqual(response.data.choices, choices)) {
          setChoices(response.data.choices);
        }
        setIsInitializing(false);
      })
      .catch((error) => {
        ApiErrorHandler(error);
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  }, [forceUseEffect]);

  return (
    <SimpleLayout
      pageTitle="ユーザー設定"
      user={user}
      loadingOpen={loadingOpen}
      useHeader={!isMobile}
    >
      <Snackbar
        closeAction={() => {
          setErrorMessage("");
        }}
        message={errorMessage}
        severity={"error"}
      />
      <Stack
        alignItems="center"
        sx={{ minHeight: "100vh", marginTop: 15 }}
        spacing={2}
      >
        {!isMobile && <Box component="h1">ユーザー設定</Box>}
        <UserSettingsForm
          userShare={userShare}
          choices={choices}
          submittedAction={callUseEffect}
          isInitializing={isInitializing}
        />
      </Stack>
    </SimpleLayout>
  );
};
