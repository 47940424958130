import * as React from "react";
import { IconButton, Stack, Typography } from "@mui/material"
import PropTypes from "prop-types";
import { LeftArrowIcon } from "../atoms/LeftArrowIcon"
import { RightArrowIcon } from "../atoms/RightArrowIcon"
import { TextField } from "../atoms/TextField"
import * as validationRules from "../../utils/ValidationRules";
import { useForm } from "react-hook-form";

export const TablePageChanger = (props) => {

  const {page, firstPage, lastPage, handleChangePage, sx} = props

  React.useEffect(() => {
    setValue("page", page)
  }, [page])

  const {
    control,
    formState: { errors },
    setValue,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange"
  });

  const rules = {
    page: {
      ...validationRules.min(firstPage),
      ...validationRules.max(lastPage),
    },
  }
  return (
    <Stack direction="row" sx={sx} alignItems="center" spacing={1}>
      <IconButton
        sx={{ py: 0 }}
        disabled={page === firstPage}
        onClick={() => {
          if (page === firstPage) {
            return
          }
          handleChangePage(page - 1)
        }}
      >
        <LeftArrowIcon color={page === firstPage ? "gray" : undefined} />
      </IconButton>
      <Typography>
        ページ
      </Typography>
      <TextField
        type="number"
        control={control}
        fullWidth={false}
        name="page"
        rules={rules.page}
        InputProps={{ sx: { height: "21px", width: "90px", } }}
        onKeyDown={(event) => {
          const newPage = event.target.value
          if (event.key === 'Enter' && !errors.page) {
            handleChangePage(Number(newPage))
          }
        }}
      />
      <Typography>
        /
      </Typography>
      <Typography>
        {lastPage}
      </Typography>
      <IconButton
        sx={{ py: 0 }}
        disabled={page === lastPage}
        onClick={() => {
          if (page === lastPage) {
            return
          }
          handleChangePage(page + 1)
        }}
      >
        <RightArrowIcon color={page === lastPage ? "gray" : undefined} />
      </IconButton>
    </Stack>
  )
}

TablePageChanger.propTypes = {
  page: PropTypes.number.isRequired,
  firstPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  sx: PropTypes.object,
};