import * as React from "react";
import PropTypes from "prop-types";
import MUIDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import { DialogActions } from "../atoms/DialogActions";
import { isMobile } from "react-device-detect";

export const Dialog = (props) => {
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const handleClose = () => {
    props.handleClose();
  };

  const defaultActionsSx = isMobile ? {alignItems: "stretch", m: 0, p: 0, height: "50px"} : {}
  const actionsSx = {...defaultActionsSx, ...props.actionsSx}

  const defaultTitleSx = { height: "auto" }
  const titleSx = {...defaultTitleSx, ...props.titleSx}
  const {contentSx} = props

  return (
    <>
      <MUIDialog
        open={isOpen}
        onClose={props.backdropClickToClose ? handleClose : null}
        fullWidth={props.fullWidth}
        fullScreen={props.fullScreen}
        scroll={props.scroll}
      >
        {props.title &&
          <DialogTitle sx={titleSx}>
            {props.title}
          </DialogTitle>
        }

        {props.content &&
          <DialogContent
            dividers={props.dividers}
            sx={contentSx}
          >
            {props.content}
          </DialogContent>
        }

        {props.actions &&
          <DialogActions disableSpacing={props.disableSpacing} sx={actionsSx}>
            {props.actions}
          </DialogActions>
        }
      </MUIDialog>
    </>
  );
  // })
};

Dialog.propTypes = {
  scroll: PropTypes.string,
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  actions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  backdropClickToClose: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.any,
  dividers: PropTypes.bool,
  disableSpacing: PropTypes.bool,
  actionsSx: PropTypes.object,
  titleSx: PropTypes.object,
  contentSx: PropTypes.object,
};

Dialog.defaultProps = {
  scroll: "paper",
  fullWidth: true,
  fullScreen: isMobile,
  dividers: isMobile,
  disableSpacing: isMobile,
  actionsSx: {},
  titleSx: {},
  contentSx: {},
}
