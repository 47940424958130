import { SvgIcon } from "@mui/material";
import {ReactComponent as icon} from "../../assets/images/Copy.svg";

export const CopyIcon = (props) => {
  return (
    <SvgIcon
      component={icon}
      viewBox="0 0 20 20"
      {...props}
    />
  );
};

