import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import { ReactComponent as icon } from "../../assets/images/Share.svg";

export const ShareIcon = (props) => {
  return <SvgIcon component={icon} viewBox="0 0 21 21" {...props} />;
};

ShareIcon.defaultProps = {
  color: "white",
};

ShareIcon.propTypes = {
  color: PropTypes.string,
};
