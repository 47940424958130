import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from "prop-types";
import { Container } from "../atoms/Container";
import { CustomHelmetProvider } from "../organisms/CustomHelmetProvider";
import { CustomThemeProvider } from "../organisms/CustomThemeProvider";

export const LoginLayout = (props) => {
  return (
    <CustomThemeProvider>
      <CustomHelmetProvider
        pageTitle={props.pageTitle}
        scripts={["https://accounts.google.com/gsi/client"]}
      >
        <CssBaseline />
        <Container
          sx={{py: 0, my: 0, minHeight: "100vh"}}
        >
          {props.children}
        </Container>
      </CustomHelmetProvider>
    </CustomThemeProvider>
  );
};

LoginLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.any,
};
