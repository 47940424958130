import { Paper, Stack } from "@mui/material";
import { TableTitle } from "../atoms/TableTitle";
import { Toolbar } from "../atoms/Toolbar";
import PropTypes from "prop-types";
import { isBrowser, isMobile } from "react-device-detect";

export const TableToolbar = (props) => {
  return (
    <Toolbar>
      {isMobile &&
        <Paper sx={{ position: 'fixed', bottom: 10, left: 5, right: 5, backgroundColor: "gray.main", borderRadius: "5.2px" }} elevation={3}>
          {props.children}
        </Paper>
      }

      {isBrowser &&
        <>
          <Stack spacing={2} direction="row" justifyContent="flex-start">
            <TableTitle>{props.title}</TableTitle>
          </Stack>
          <Stack spacing={2} direction="row" justifyContent="flex-end" sx={{flexGrow: 1}}>
            {props.children}
          </Stack>
        </>
      }
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
}