import * as React from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material"
import { DownArrowIcon } from "../atoms/DownArrowIcon"
import { ButtonAndMenu } from "./ButtonAndMenu"
import { SelectForm } from "../atoms/SelectForm";
import { useForm } from "react-hook-form";

export const TableRowsPerPageChanger = (props) => {

  const {rowsPerPage, rowsPerPageOptions, handleChangeRowsPerPage, sx, useSelectBox } = props

  React.useEffect(() => {
    setValue("select", rowsPerPage)
  }, [rowsPerPage])


  const {
    control,
    setValue
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
  });

  const handleChangeRowsPerPageForSelectForm = (e) => {
    handleChangeRowsPerPage(e.target.value)
  }

  const rules = {
    select: {
      onBlur: handleChangeRowsPerPageForSelectForm,
    }
  }

  return (
    <>
      {
        useSelectBox
          ?
            <SelectForm
              control={control}
              name="select"
              rules={rules.select}
              choices={rowsPerPageOptions.flatMap((item) => [{value: item, label: `${item}行表示`}])}
              setValue={setValue}
            />
          :
            <Stack direction="row" sx={sx} alignItems="center">
              <Typography>
                ページごとに表示：{rowsPerPage}件
              </Typography>
              <ButtonAndMenu
                buttonSx={{ p: 0 }}
                buttonName={<DownArrowIcon sx={{ display: "flex" }} />}
                menus={rowsPerPageOptions.map((item) => {
                  return {
                    label: item,
                    onClick: () => handleChangeRowsPerPage(item)
                  }
                })}
              />
            </Stack>
      }
    </>
  )
}

TableRowsPerPageChanger.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  sx: PropTypes.object,
  useSelectBox: PropTypes.bool,
};

TableRowsPerPageChanger.defaultProps = {
  rowsPerPageOptions: [],
};
