import PropTypes from "prop-types";
import EnhancedTable from "../organisms/EnhancedTable";
import { isBrowser } from 'react-device-detect';
import { AdminLayout } from "./AdminLayout";
import { Container } from "../atoms/Container";

export const AdminListLayout = (props) => {

  return (
    <AdminLayout
      pageTitle={props.pageTitle}
      user={props.user}
      loadingOpen={props.loadingOpen}
    >
      <Container sx={{ m: 0, p: 0 }}>
        {props.tableToolbar}
        <EnhancedTable
          rows={props.rows}
          includeCheckBox={props.tableCheckBox}
          headCells={props.headCells}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          lastPage={props.lastPage}
          rowsPerPage={props.rowsPerPage}
          itemCount={props.itemCount}
          rowsPerPageOptions={props.rowsPerPageOptions}
          handleRequestSort={props.handleRequestSort}
          order={props.order}
          orderBy={props.orderBy}
          tableButtons={props.tableButtons}
          checkBoxClickedAction={props.tableCheckBoxClickedAction}
          usePagination={isBrowser}
        />
      </Container>
    </AdminLayout>
  );
};

AdminListLayout.propTypes = {
  user: PropTypes.object.isRequired,
  loadingOpen: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.any,
  selectedMenuId: PropTypes.number,
  rows: PropTypes.array.isRequired,
  tableCheckBox: PropTypes.bool,
  headCells: PropTypes.array.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  itemCount: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  tableButtons: PropTypes.array,
  tableCheckBoxClickedAction: PropTypes.func,
  tableToolbar: PropTypes.object,
};
