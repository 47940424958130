import React from "react";
import { isBrowser } from "react-device-detect";
import { ApiErrorHandler } from "../../api/ApiErrorHandler";
import { getProperties } from "../../api/Properties";
import { hasPropertyEditPermission } from "../../utils/UserUtil";
import { CopyIcon } from "../atoms/CopyIcon";
import { CopyPropertyDialog } from "../organisms/CopyPropertyDialog";
import { palette } from "../organisms/CustomThemeProvider";
import { PropertyTableToolbar, MOBILE_TOOLBAR_TYPE } from "../organisms/PropertyTableToolbar";
import { ListLayout } from "../templates/ListLayout";
import { FrontendUrls } from "../Urls";

export const Properties = () => {
  const [page, setPage] = React.useReducer((state, newState) => newState, 1);
  const [loadingOpen, setLoadingOpen] = React.useState(true);
  const [lastPage, setLastPage] = React.useState(1);
  const [rowData, setRowData] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([10]);
  const [itemCount, setItemCount] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [choices, setChoices] = React.useState({});
  const [copyDialogOpen, setCopyDialogOpen] = React.useState(false);
  const [copyOriginProperty, setCopyOriginProperty] = React.useState({});
  const [forceUseEffect, setForceUseEffect] = React.useState(0);

  const [snakcBarMessage, setSnakcBarMessage] = React.useState("");

  const [tableRowCheckedList, setTableRowCheckedList] = React.useState([]);
  const [tableCheckBox, setTableCheckBox] = React.useState(isBrowser)

  const originalHeadCells = [
    {
      id: "name",
      label: "物件名",
      link: FrontendUrls.propertyEdit,
      link_params: [
        "id",
      ],
    },
    {
      id: "area_code_display",
      label: "エリア",
    },
    {
      id: "property_city",
      label: "行政区",
    },
    {
      id: "payeebroker",
      label: "仲介",
    },
    {
      id: "status",
      label: "ステータス",
    },
    {
      id: "owner_user",
      label: "作成者",
    },
    {
      id: "updated_at",
      label: "最終更新日",
    },
  ];
  const [headCells, setHeadCells] = React.useState(originalHeadCells)

  const snackBarCloseAction = () => {
    setSnakcBarMessage("")
  }

  const [searchWords, setSearchWords] = React.useState({})
  const callUseEffect = () => {
    setForceUseEffect(forceUseEffect + 1);
  };

  React.useEffect(() => {
    setLoadingOpen(true)
    let ordering = "-id";
    if (order && orderBy) {
      if (order == "asc") {
        ordering = orderBy;
      } else if (order == "desc") {
        ordering = "-" + orderBy;
      }
    }
    const params = {
      page: page,
      page_size: rowsPerPage,
      ordering: ordering,
      ...searchWords,
    };
    getProperties(params)
      .then((response) => {
        let rowData = [];
        response.data.results.map((item) => {
          rowData.push(createRowData(item));
        });
        setRowData(rowData);
        setUser(response.data.user);
        setRowsPerPage(response.data.page_size);
        setRowsPerPageOptions(response.data.page_sizes);
        setLastPage(response.data.last_page);
        setItemCount(response.data.count);
        setChoices(response.data.choices);
        setLoadingOpen(false)
        if (!response.data.user.is_manager) {
          setHeadCells(originalHeadCells.filter((v) => v.id !== "owner_user"))
        }
      })
      .catch((error) => {
        setSnakcBarMessage("物件の取得に失敗しました")
        ApiErrorHandler(error);
      });
  }, [page, rowsPerPage, order, orderBy, forceUseEffect, searchWords]);

  const createRowData = (item) => {
    const updatedAt = new Date(item.updated_at)
    const formattedUpdatedAt = `${updatedAt.getFullYear()}年${updatedAt.getMonth() + 1}月${updatedAt.getDate()}日`
    return {
      id: item.id,
      name: item.name,
      area_code: item.area_code,
      area_code_display: `${item.area_code_display}（${item.grade_display}）`,
      property_city: item.businessbalance?.mstbusinessbalancearea?.name,
      payeebroker: item.companypayeebroker?.name,
      status: item.status_display,
      owner_user: item.owner_user.username,
      updated_at: formattedUpdatedAt,
      property: item,
      color: hasPropertyEditPermission(item) ? undefined : palette.palette.gray.main,
    }
  }

  const dataReload = () => {
    callUseEffect()
  }

  const mobileDeleteIconClickedAction = () => {
    setTableCheckBox(true)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (page_size) => {
    setRowsPerPage(page_size);
    setPage(1);
  };
  const handleRequestSort = (newOrderBy, newOrder) => {
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  };

  const tableButtons = [
    {
      buttonChildren:
        <>
          <CopyIcon />
        </>,
      onClick: (data) => {
        setCopyOriginProperty(data)
        setCopyDialogOpen(true)
      }
    },
  ]

  const doSearch = (searchObj, clear = false) => {
    setPage(1)
    if (clear) {
      setSearchWords({})
    } else {
      const newWords = { ...searchWords, ...(searchObj ?? {}) }
      Object.keys(newWords).forEach((key) => {
        if (!newWords[key] && newWords[key] !== 0) {
          delete newWords[key]
        }
      })
      setSearchWords(newWords)
    }
  }

  return (
    <ListLayout
      pageTitle="物件一覧"
      loadingOpen={loadingOpen}
      rows={rowData}
      headCells={headCells}
      tableName="物件一覧"
      user={user}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      lastPage={lastPage}
      rowsPerPage={rowsPerPage}
      itemCount={itemCount}
      rowsPerPageOptions={rowsPerPageOptions}
      handleRequestSort={handleRequestSort}
      order={order}
      orderBy={orderBy}
      snackBarCloseAction={snackBarCloseAction}
      snackBarMessage={snakcBarMessage}
      severity={"error"}
      searchWords={searchWords}
      tableCheckBoxClickedAction={(data) => setTableRowCheckedList(data)}
      tableCheckBox={tableCheckBox}
      doSearch={doSearch}
      choices={choices}
      tableToolbar={
        <PropertyTableToolbar
          page={page}
          rowsPerPage={rowsPerPage}
          lastPage={lastPage}
          handleChangePage={handleChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          itemCount={itemCount}
          mobileDeleteIconClickedAction={mobileDeleteIconClickedAction}
          mobileToolbarType={tableCheckBox ? MOBILE_TOOLBAR_TYPE.DELETE_CLICKED : MOBILE_TOOLBAR_TYPE.DEFAULT}
          choices={choices}
          dataReload={dataReload}
          tableRowCheckedList={tableRowCheckedList}
          searchWords={searchWords}
          doSearch={doSearch}
        />
      }
      tableButtons={tableButtons}
    >
      <CopyPropertyDialog
        isOpen={copyDialogOpen}
        originProperty={copyOriginProperty}
        areaCodeAndGradeGroup={choices?.area_code_and_grade_group ? choices.area_code_and_grade_group : []}
        handleClose={() => { setCopyDialogOpen(false) }}
        dataReload={dataReload}
      />
    </ListLayout>
  );
};
