export const required = () => {
  return {
    required: "必須項目です",
  };
};

export const maxLength = (length = 0) => {
  return {
    maxLength: {
      value: length,
      message: `${length}文字以下にしてください`,
    },
  };
};

export const max = (num = 0) => {
  return {
    max: {
      value: num,
      message: `${num}以下を指定してください`,
    },
  };
};
export const min = (num = 0) => {
  return {
    min: {
      value: num,
      message: `${num}以上を指定してください`,
    },
  };
};

export const isNumber = () => {
  return {
    pattern: {
      value: /^-?\d*$/,
      message: "半角整数のみ入力可能です",
    },
  }
}
export const isFloat = () => {
  return {
    pattern: {
      value: /^\d+(\.\d+)?$/,
      message: "半角実数のみ入力可能です",
    },
  }
}

export const isZipcode = () => {
  return {
    pattern: {
      value: /^[0-9]{3}-?[0-9]{4}$/,
      message: "半角で000-0000か、0000000の形式のみ入力可能です",
    },
  }
}

export const isEmail = () => {
  return {
    pattern: {
      value: /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/,
      message: "emailのみ入力可能です",
    },
  }
}