import { Box, InputAdornment, Stack } from "@mui/material"
import PropTypes from "prop-types";
import React from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { SearchIcon } from "../atoms/SearchIcon"
import { SelectForm } from "../atoms/SelectForm";
import { TextField } from "../atoms/TextField"


export const PropertyTableSearchForms = (props) => {

  const {choices, doSearch, direction, isReset, resetedAction, defaultValues} = props

  const {
    control,
    reset,
    setValue,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
  });
  React.useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      setValue(key, defaultValues[key])
    })
  }, [])

  React.useEffect(() => {
    if (isReset) {
      reset()
      resetedAction()
    }
  }, [isReset])

  const handleSearch = (event) => {
    doSearch({ [event.target.name] : event.target.value })
  }

  const rules = {
    property_name: {
      onBlur: isMobile && handleSearch,
    },
    companypayeebroker_name: {
      onBlur: isMobile && handleSearch,
    },
    property_status: {
      onBlur: handleSearch,
    },
  }

  const onKeyDown = (event) => {
    if (!isBrowser) {
      return
    }
    if (event.key === 'Enter') {
      handleSearch(event)
    }
  }

  return (
    <Stack direction={direction} spacing={1}>
      <TextField
        control={control}
        name="property_name"
        placeholder="物件名で検索"
        fullWidth={false}
        onKeyDown={onKeyDown}
        rules={rules.property_name}
        type="search"
        InputProps={{
          startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
        }}
      />

      <TextField
        control={control}
        name="companypayeebroker_name"
        placeholder="仲介名で検索"
        fullWidth={false}
        onKeyDown={onKeyDown}
        rules={rules.companypayeebroker_name}
        type="search"
        InputProps={{
          startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
        }}
      />

      <Box sx={{p: 0, m: 0, width: direction === "row" ? "200px" : "auto"}}>
        <SelectForm
          control={control}
          name="property_status"
          rules={rules.property_status}
          choices={choices.statuses ?? []}
          label="ステータスで検索"
          isLabelInside={true}
          setValue={setValue}
        />
      </Box>
    </Stack>
  )
}

PropertyTableSearchForms.propTypes = {
  doSearch: PropTypes.func.isRequired,
  direction: PropTypes.string,
  isReset: PropTypes.bool,
  resetedAction: PropTypes.func,
  choices: PropTypes.object.isRequired,
  defaultValues: PropTypes.object,
}
PropertyTableSearchForms.defaultProps = {
  defaultValues: {},
}