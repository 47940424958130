import { Stack, Typography } from "@mui/material"
import PropTypes from "prop-types";
import React from "react";
import { LeftArrowIcon } from "../atoms/LeftArrowIcon"
import { Dialog } from "../molecules/Dialog"

export const PropertyEditFormDialog = (props) => {
  const {isOpen, title, content, actions, handleClose, openedAction, contentSx} = props

  React.useEffect(() => {
    if (isOpen) {
      openedAction()
    }
  }, [isOpen])

  return (
      <Dialog
        isOpen={isOpen}
        titleSx={{width: "100%"}}
        title={
          <Stack
            spacing={1}
            direction="row"
            sx={{width: "100%"}}
          >
            <LeftArrowIcon color="blue2" onClick={handleClose}/>
            <Typography variant="subtitle1" sx={{flexGrow: 1}}>
              {title}
            </Typography>
          </Stack>
        }
        content={content}
        contentSx={contentSx}
        actions={actions}
      />
  )
}

PropertyEditFormDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
  content: PropTypes.object.isRequired,
  actions: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  openedAction: PropTypes.func,
  contentSx: PropTypes.object,
}
PropertyEditFormDialog.defaultProps = {
  openedAction: () => {},
}