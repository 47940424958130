import { Toolbar as MUIToolbar } from '@mui/material';
import PropTypes from "prop-types";

export const Toolbar = ({ sx, ...props }) => {

  return (
    <MUIToolbar
      sx={sx}
    >
      {props.children}
    </MUIToolbar>
  );
};

Toolbar.propTypes = {
  children: PropTypes.any,
  sx: PropTypes.object,
}
Toolbar.defaultProps = {
  sx: {
    px: {xs: 0},
    mb: 1,
  },
}