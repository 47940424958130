import { ThemeProvider, createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

export const palette = {
  palette: {
    text: {
      disabled: "#000000"
    },
    black: {
      main: "#000000",
      right: "#2c2c2c",
      dark: "#000000",
      contrastText: "#ffffff",
    },
    black2: {
      main: "#24252E",
      right: "#4c4d57",
      dark: "#000004",
      contrastText: "#ffffff",
    },
    black3: {
      main: "#3A3A47",
      right: "#646472",
      dark: "#141420",
      contrastText: "#ffffff",
    },
    white: {
      main: "#ffffff",
      right: "#ffffff",
      dark: "#cccccc",
      contrastText: "#000000",
    },
    pink: {
      main: "#f75b60",
      right: "#ff8e8d",
      dark: "#be2336",
      contrastText: "#ffffff",
    },
    blue: {
      main: "#498fff",
      right: "#87bfff",
      dark: "#0062cb",
      contrastText: "#ffffff",
    },
    blue2: {
      main: "#0A67D1",
      right: "#5f94ff",
      dark: "#003e9f",
      contrastText: "#ffffff",
    },
    emeraldGreen: {
      main: "#36a094",
      right: "#6cd2c4",
      dark: "#007166",
      contrastText: "#ffffff",
    },
    green: {
      main: "#40a42b",
      right: "#75d65b",
      dark: "#007400",
      contrastText: "#ffffff",
    },
    darkGreen: {
      main: "#217b00",
      right: "#58ab39",
      dark: "#004e00",
      contrastText: "#ffffff",
    },
    gray: {
      main: "#f0f0f5",
      right: "#ffffff",
      dark: "#bebec2",
      contrastText: "#000000",
    },
    gray2: {
      main: "#6A7685",
      right: "#98a5b5",
      dark: "#3f4a58",
      contrastText: "#ffffff",
    },
    gray3: {
      main: "#d3d4dd",
      right: "#ffffff",
      dark: "#d3d4dd",
      contrastText: "#000000",
    },
    gray4: {
      main: "#E8EBEF",
      right: "#ffffff",
      dark: "#b6b9bd",
      contrastText: "#000000",
    },
    orange: {
      main: "#e76f00",
      right: "#ff9f3f",
      dark: "#ae4100",
      contrastText: "#000000",
    },
    red: {
      main: "#d01e29",
      right: "#ff5b53",
      dark: "#970000",
      contrastText: "#ffffff",
    },
    darkRed: {
      main: "#790c14",
      right: "#ae413a",
      dark: "#480000",
      contrastText: "#ffffff",
    },
    brown: {
      main: "#b58b00",
      right: "#ebbb41",
      dark: "#815e00",
      contrastText: "#ffffff",
    },
    yellow: {
      main: "#faff9d",
      right: "#ffffcf",
      dark: "#c6cc6d",
      contrastText: "#000000",
    },
    purple: {
      main: "#9136A0",
      right: "#9136A0",
      dark: "#9136A0",
      contrastText: "#ffffff",
    },
    bluePurple: {
      main: "#3E36A0",
      right: "#3E36A0",
      dark: "#3E36A0",
      contrastText: "#ffffff",
    },
    defaultTextFieldFocusedBorder: {
      main: "#1976d2",
    }
  },
}
export const customTheme = createTheme({
  ...palette,
  typography: {
    fontSize: 12,
    button: {
      textTransform: "none",
      fontWeight: "bold",
    },
    subtitle1: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: "bold",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
          border: "1px solid #dedfe6",
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingTop: "50px",
          paddingBottom: "15px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          height: "100%",
          alignItems: "center",
          display: "flex",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: 'outlined', disabled: false },
          style: {
            backgroundColor: palette.palette.white.main,
            color: palette.palette.black.main,
          },
        },
        {
          props: { variant: 'outlined', disabled: true },
          style: {
            backgroundColor: palette.palette.gray.main,
            color: palette.palette.black.main,
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined', disabled: false },
          style: {
            backgroundColor: palette.palette.white.main,
            color: palette.palette.black.main,
          },
        },
        {
          props: { variant: 'outlined', disabled: true },
          style: {
            backgroundColor: palette.palette.gray.main,
            color: palette.palette.black.main,
          },
        },
        {
          props: { variant: 'outlined', disabled: false, color: "yellow" },
          style: {
            '& .MuiOutlinedInput-root': {
              backgroundColor: palette.palette.yellow.main,
              '&.Mui-focused:not(.Mui-error) fieldset': {
                borderColor: palette.palette.defaultTextFieldFocusedBorder.main,
              },
            },
            '& .MuiInputLabel-root.Mui-focused:not(.Mui-error)': {
              color: palette.palette.defaultTextFieldFocusedBorder.main,
            },
          },
        },
        {
          props: { variant: 'outlined', disabled: true, color: "yellow" },
          style: {
            '& .MuiOutlinedInput-root': {
              backgroundColor: palette.palette.yellow.main,
              '&.Mui-focused:not(.Mui-error) fieldset': {
                borderColor: palette.palette.defaultTextFieldFocusedBorder.main,
              },
              "&:hover": {
                backgroundColor: palette.palette.gray.main,
              },
            },
            '& .MuiInputLabel-root.Mui-focused:not(.Mui-error)': {
              color: palette.palette.defaultTextFieldFocusedBorder.main,
            },
          },
        },
      ],
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: isMobile ? palette.palette.gray4.main : palette.palette.white.main,
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'inputTargets' },
          style: {
            backgroundColor: palette.palette.gray.main,
            borderRadius: "16px",
            padding: "10px"
          },
        },
      ],
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&$selected": {
            backgroundColor: "red",
            "&:hover": {
              backgroundColor: "orange",
            },
          },
        },
        button: {
          "&:hover": {
            backgroundColor: "yellow",
          },
        },
      },
    },
  },
});

export const CustomThemeProvider = (props) => {
  return <ThemeProvider theme={customTheme}>{props.children}</ThemeProvider>;
};

CustomThemeProvider.propTypes = {
  children: PropTypes.any,
}