import { Stack, Typography } from "@mui/material";
import { downloadExcel } from "../../api/Properties";
import PropTypes from "prop-types";
import { SelectSheetDialog } from "./SelectSheetDialog";
import { FileIcon } from "../atoms/FileIcon";
import { excelDownload } from "../../utils/FileUtil";

export const ExcelDialog = (props) => {
  return (
    <SelectSheetDialog
      open={props.open}
      title={
        <>
          <FileIcon color="black" sx={{ mr: 1 }} />
          Excel出力
        </>
      }
      submitButtonTitle={"ダウンロード"}
      submitButtonColor={"green"}
      causeCanNotSelectSheet={props.causeCanNotSelectSheet}
      property_id={props.property_id ?? 0}
      choices={props.choices ?? []}
      onClose={props.onClose}
      api={downloadExcel}
      description={
        <Stack direction="row" sx={{ mb: 1 }}>
          <Typography variant="subtitle1" sx={{ color: "red.main" }} component="span">＊</Typography>
          <Typography variant="subtitle1" component="span">ダウンロードしたいシートを選んでください：</Typography>
        </Stack>
      }
      apiSuccessAction={(response) => {
        excelDownload(response)
      }}
    />
  );
};

ExcelDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  property_id: PropTypes.number.isRequired,
  choices: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  causeCanNotSelectSheet: PropTypes.object,
};

ExcelDialog.defaultProps = {
  causeCanNotSelectSheet: {},
};