import PropTypes from "prop-types";
import { AddIcon } from "./AddIcon";
import { IconButton } from "@mui/material";

export const AddButton = (props) => {
  const { sx, disabled, onClick, color, ...buttonProps } = props;
  const newColor = disabled ? "gray" : color
  return (
    <IconButton
      sx={{
        minWidth: "26px",
        minHeight: "26px",
        p: "2px",
        border: "1.2px solid",
        borderRadius: 2,
        ml: 2,
        ...sx,
      }}
      variant="outlined"
      onClick={disabled ? null : onClick}
      color={newColor}
      {...buttonProps}
    >
      <AddIcon color={newColor}/>
    </IconButton>
  );
};

AddButton.defaultProps = {
  sx: {},
  color: "darkGreen",
  disabled: false,
};

AddButton.propTypes = {
  sx: PropTypes.object,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
