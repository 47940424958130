import { FormControl as MUIFormControl } from "@mui/material";

export const FormControl = (props) => {
  return <MUIFormControl {...props} />;
};

FormControl.defaultProps = {
  size: "small",
  fullWidth: true
};
