import * as React from "react";
import PropTypes from "prop-types";
import { TextField } from "../atoms/TextField";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import { Button } from "../atoms/Button";
import { AddButton } from "../atoms/AddButton";
import { PropertyShareInputs } from "../molecules/PropertyShareInputs";
import { FormControl } from "../atoms/FormControl";
import { Container } from "../atoms/Container";
import { UserContext } from "../../Context";
import { saveUserShares } from "../../api/UserShare";
import { ApiErrorHandler } from "../../api/ApiErrorHandler";
import { Snackbar } from "../atoms/Snackbar";
import { isMobile } from "react-device-detect";
import { Dialog } from "../molecules/Dialog";

export const UserSettingsForm = React.memo(function userSettingsForm(props) {
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const userShare = props.userShare;
  const choices = props.choices;
  const isInitializing = props.isInitializing;
  const user = React.useContext(UserContext);

  React.useEffect(() => {
    setValue("name", user.username ?? "")
    setValue("email", user.email ?? "")
  }, [user]);

  React.useEffect(() => {
    const itemData = []
    userShare.map((item) => {
      const data = {
        id: item.id,
        shared_user: item.shared_user,
        permission: item.permission,
      };
      itemData.push(data)
    });
    shareReplace(itemData);
  }, [userShare]);

  const canSubmit = !isInitializing && !isSubmitting;

  const createShareForm = () => {
    const data = {
      id: undefined,
      shared_user: undefined,
      permission: undefined,
    };
    shareAppend(data);
  };
  const {
    control,
    register,
    handleSubmit,
    setValue,
    methods,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
  });

  const {
    fields: shareFields,
    append: shareAppend,
    replace: shareReplace,
    remove: shareRemove,
  } = useFieldArray({ control, name: "share" });

  const submitAction = (data) => {
    setIsSubmitting(true);
    saveUserShares(data)
      .then(() => {
        setMessage("保存しました");
        setSeverity("success");
        props.submittedAction();
      })
      .catch((error) => {
        ApiErrorHandler(error);
        setMessage("保存に失敗しました");
        setSeverity("error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const formsProps = {
    control: control,
    shareFields: shareFields,
    errors: errors,
    createShareForm: createShareForm,
    shareRemove: shareRemove,
    setValue: setValue,
    choices: choices,
    register: register,
  }

  return (
    <>
      {isMobile
        ?
          <Dialog
            isOpen={true}
            title="ユーザー設定"
            handleClose={() => history.back()}
            content={
              <Forms {...formsProps} />
            }
            actions={
              <>
                {
                  isSubmitting
                  ?
                    <Button color="gray" disabled={true} fullSize={true}>
                      保存中
                    </Button>
                  :
                    <>
                      <Button color="gray" onClick={() => history.back()} disabled={!canSubmit} fullSize={true}>
                        キャンセル
                      </Button>
                      <Button color="green" type="submit" onClick={handleSubmit(submitAction)} disabled={!canSubmit} fullSize={true}>
                        保存
                      </Button>
                    </>
                }
              </>
            }
          />
        :
          <Container maxWidth="xs">
            <Snackbar
              closeAction={() => {
                setMessage("");
              }}
              message={message}
              severity={severity}
            />
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submitAction)}>
                <Forms {...formsProps} />
                <Box>
                  <Button
                    color="green"
                    type="submit"
                    sx={{ marginTop: 3 }}
                    fullWidth={true}
                    disabled={!canSubmit}
                  >
                    保存
                  </Button>
                </Box>
              </form>
            </FormProvider>
          </Container>
      }
    </>
  );
});

UserSettingsForm.propTypes = {
  userShare: PropTypes.array.isRequired,
  choices: PropTypes.object.isRequired,
  isInitializing: PropTypes.bool.isRequired,
  submittedAction: PropTypes.func.isRequired,
}

const Forms = (props) => {
  const {control, errors, createShareForm, shareRemove, setValue, choices, register, shareFields} = props
  return (
    <>
      <Box>
        <TextField
          control={control}
          label={
            <Stack direction="row" sx={{mb: 1}}>
              <Typography>名前</Typography>
              <Typography variant="subtitle1" sx={{color: "red.main"}}>＊</Typography>
            </Stack>
          }
          name="name"
          disabled={true}
        />
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <TextField
          control={control}
          label={
            <Stack direction="row" sx={{mb: 1}}>
              <Typography>メールアドレス</Typography>
              <Typography variant="subtitle1" sx={{color: "red.main"}}>＊</Typography>
            </Stack>
          }
          name="email"
          disabled={true}
        />
      </Box>

      <Box sx={{ marginTop: 3 }}>
        <FormControl error={errors.share ? true : false} fullWidth={true}>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent={isMobile ? "space-between" : undefined}>
              <Typography>
                物件共有
              </Typography>
              <AddButton onClick={createShareForm} />
            </Stack>

            {shareFields.map((item, index) => (
              <PropertyShareInputs
                errors={errors}
                register={register}
                index={index}
                control={control}
                handleRemove={() => shareRemove(index)}
                key={item.id}
                choices={choices}
                setValue={setValue}
              />
            ))}
          </Stack>
        </FormControl>
      </Box>
    </>
  )
}
Forms.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  createShareForm: PropTypes.func.isRequired,
  shareRemove: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  shareFields: PropTypes.array.isRequired,
  choices: PropTypes.object.isRequired,
}