import { backendClient, simpleClient } from "./Client";
import { BackendUrls, getAddressUrl } from "./Urls";


export const getProperties = (params) => {
  return backendClient().get(BackendUrls.properties, { params })
}

export const updateProperty = (id, params) => {
  const url = BackendUrls.property.replace(":id", id)
  return backendClient().put(url, params)
}

export const getProperty = (id, params) => {
  const url = BackendUrls.property.replace(":id", id)
  return backendClient().get(url, { params })
}

export const createProperty = (params) => {
  return backendClient().post(BackendUrls.properties, params)
}

export const deleteHasProperties = (params) => {
  return backendClient().post(BackendUrls.deleteHasProperties, params)
}
export const copyProperty = (params) => {
  return backendClient().post(BackendUrls.copyProperty, params)
}

export const saveChild = (id, params) => {
  const url = BackendUrls.saveChild.replace(":id", id)
  return backendClient().post(url, params)
}
export const deleteChild = (id, params) => {
  const url = BackendUrls.deleteChild.replace(":id", id)
  return backendClient().post(url, params)
}

export const downloadPdf = (id, params) => {
  const url = BackendUrls.downloadPdf.replace(":id", id)
  return backendClient({ responseType: "blob" }).post(url, params)
}

export const sendEmailWithPdf = (id, params) => {
  const url = BackendUrls.sendEmailWithPdf.replace(":id", id)
  return backendClient().post(url, params)
}

export const getAddress = (zipcode) => {
  const params = {
    params: { zip: zipcode },
    headers: {
      "x-api-key": process.env.REACT_APP_ADDRESS_API_X_API_KEY,
      "x-apigw-api-id": process.env.REACT_APP_ADDRESS_API_X_APIGW_API_ID,
    }
  }
  return simpleClient().get(getAddressUrl, params)
}

export const downloadExcel = (id, params) => {
  const url = BackendUrls.downloadExcel.replace(":id", id)
  return backendClient({ responseType: "blob" }).post(url, params)
}

export const overwriteSalesPlanDetail = (id, params) => {
  const url = BackendUrls.overwriteSalesPlanDetail.replace(":id", id)
  return backendClient().post(url, params)
}

export const uploadBlockRelatedDiagram = (id, params) => {
  const url = BackendUrls.uploadBlockRelatedDiagram.replace(":id", id)
  return backendClient().post(url, params)
}

export const downloadBlockRelatedDiagram = (id, params) => {
  const url = BackendUrls.downloadBlockRelatedDiagram.replace(":id", id)
  return backendClient({ responseType: "blob" }).post(url, params)
}

export const applyEstimate = (id) => {
  const url = BackendUrls.applyEstimate.replace(":id", id)
  return backendClient().post(url)
}

export const constructionCooperation = (id, params) => {
  const url = BackendUrls.constructionCooperation.replace(":id", id)
  return backendClient().post(url, params)
}

export const constructionCooperationConfirm = (id) => {
  const url = BackendUrls.constructionCooperationConfirm.replace(":id", id)
  return backendClient().post(url)
}

export const getCompanyPayeeBrokerNameChoices = (params) => {
  const url = BackendUrls.getCompanyPayeeBrokerNameChoices
  return backendClient().post(url, params)
}