export const BackendUrls = {
  login: "/login/",
  logout: "/logout/",

  properties: "/properties/",
  property: "/properties/:id/",
  updateProperty: "/properties/:id/",
  deleteHasProperties: "/properties/delete/possession/",
  copyProperty: "/properties/copy/",
  overwriteSalesPlanDetail: "/properties/overwrite/salesplanddetail/:id/",

  downloadExcel: "/properties/download/excel/:id/",
  downloadPdf: "/properties/download/pdf/:id/",
  sendEmailWithPdf: "/properties/email/send/pdf/:id/",

  uploadBlockRelatedDiagram: "/properties/upload/block_related_diagram/:id/",
  downloadBlockRelatedDiagram: "/properties/download/block_related_diagram/:id/",
  applyEstimate: "/properties/apply/estimate/:id/",
  constructionCooperation: "/properties/construction/cooperation/:id/",
  constructionCooperationConfirm: "/properties/construction/cooperation/:id/confirm/",

  saveChild: "/properties/save/child/:id/",
  deleteChild: "/properties/delete/child/:id/",

  getCompanyPayeeBrokerNameChoices: "/properties/choices/company_payee_broker_name/",

  userShare: "/user/share/",
  userShareSave: "/user/share/save/",

  propertyShareSave: "/property/share/save/",

  adminFormulas: "/admin/formulas/",
  adminFormulasByKey: "/admin/formulas/:key/",
  adminFormulaConfirm: "/admin/formulas/confirm/",
  adminFormulaExec: "/admin/formulas/exec/",
  adminPropertyShare: "/admin/property/share/",
  adminPropertyShareBulkShare: "/admin/property/share/bulk_share/",

};

export const getAddressUrl = process.env.REACT_APP_ADDRESS_API_ENDPOINT