import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { AddButton } from "../atoms/AddButton";
import { DeleteButton } from "../atoms/DeleteButton";
import { HiddenInput } from "../atoms/HiddenInput";
import { isMobile } from "react-device-detect";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitPrivateRoadAgreement } from "../../utils/CustomHook";
import { onBlurCommonAction, removeChild, saveChildForPropertyEdit } from "../../utils/PropertyEdit";

export const PrivateRoadAgreementForms = React.memo(
  function privateRoadAgreementForms({
    property,
    initialized,
    privateroadagreement_set,
    setOriginPropertyData,
    contractterm,
    toggleViewport,
    formPaperVariant,
    formPaperElevation,
    ...props
  }) {
    const [isLoading, setIsLoading] = React.useState(false)
    const loadingComplete = React.useCallback(() => {
      setIsLoading(false)
    }, [])

    const [isOpenModal, setIsOpenModal] = React.useState(false)
    const [propertyId, setPropertyId] = React.useState(null)

    const [isFocused, setFocusInputName] = useFocusInput()
    const handleFocus = React.useCallback((name) => {
      setFocusInputName(name)
    }, [])

    const {
      control,
      formState: { errors },
      getValues,
      methods,
      setValue,
      setError,
      clearErrors,
      trigger,
    } = useForm({
      shouldFocusError: false,
      criteriaMode: "all",
      defaultValues: "",
      mode: "onChange",
    });

    const [privateRoadAgreementFields, privateRoadAgreementAppend, privateRoadAgreementRemove] = useInitPrivateRoadAgreement(privateroadagreement_set, setValue, isFocused, control, trigger)

    React.useEffect(() => {
      setPropertyId(property.id)
    }, [property])

    React.useEffect(() => {
      if (!privateroadagreement_set) {
        return;
      }
      initialized();
    }, [privateroadagreement_set]);

    const createPrivateRoadAgreement = () => {
      privateRoadAgreementAppend();
    };

    const saveCommonParams = React.useMemo(() => [
      errors, propertyId, clearErrors, setOriginPropertyData, setError
    ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

    const removeChildCommonParams = React.useMemo(() => [
      propertyId, getValues, setOriginPropertyData,
    ], [propertyId, getValues, setOriginPropertyData])

    const onBlurForPrivateRoadAgreement = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "privateroadagreement_set", null
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
    }, [propertyId]);

    const handleRemoveForPrivateRoadAgreement = React.useCallback((index) => {
      removeChild(...removeChildCommonParams, privateRoadAgreementRemove, index, "privateroadagreement_set", null, "property")
    }, [propertyId]);

    const rules = React.useMemo(() => ({
      privateroadagreement_set: {
        target_brush: {
          onBlur: onBlurForPrivateRoadAgreement,
        },
      },
    }), [onBlurForPrivateRoadAgreement]);

    const title = `私道承諾${contractterm.private_road_approval_form_display ? "（" + contractterm.private_road_approval_form_display + "）" : ""}`

    const isCreatePrivateRoadAgreement = props.disabled || property.MAX_ROW_PRIVATE_ROAD_AGREEMENT <= privateRoadAgreementFields.length

    const propertyEditFormDialogCloseAction = React.useCallback(() => {
      toggleViewport()
    }, [])
    const formAreaClickedAction = React.useCallback((additionalProcess) => {
      if (!isMobile) {
        return
      }
      if (additionalProcess) {
        additionalProcess()
      }
      setIsLoading(true)
      toggleViewport()
    }, [])

    const commonInputProps = React.useMemo(() => ({
      control: control,
      onFocus: handleFocus,
    }), [control, handleFocus])

    return (
      <PropertyEditForms
        title={title}
      >
        <LoadingProgress open={isLoading} useCircular={false} />
        <FormProvider {...methods}>
          <PropertyEditFormDialog
            isOpen={isOpenModal}
            handleClose={() => {
              setIsOpenModal(false)
              propertyEditFormDialogCloseAction()
            }}
            openedAction={loadingComplete}
            title={title}
            content={
              <PrivateRoadAgreementForm
                commonInputProps={commonInputProps}
                rules={rules}
                disabled={props.disabled}
                isModal={true}
                handleRemoveForPrivateRoadAgreement={handleRemoveForPrivateRoadAgreement}
                privateRoadAgreementFields={privateRoadAgreementFields}
                isCreatePrivateRoadAgreement={isCreatePrivateRoadAgreement}
                createPrivateRoadAgreement={createPrivateRoadAgreement}
              />
            }
          />
          <form>
            <Grid container direction="row" spacing={1}>

              <Grid item xs={12}>
                <Paper
                  elevation={formPaperElevation}
                  variant={formPaperVariant}
                  onClick={() => formAreaClickedAction(() => setIsOpenModal(true))}
                >
                  <PrivateRoadAgreementForm
                    commonInputProps={commonInputProps}
                    rules={rules}
                    disabled={props.disabled}
                    isModal={false}
                    handleRemoveForPrivateRoadAgreement={handleRemoveForPrivateRoadAgreement}
                    privateRoadAgreementFields={privateRoadAgreementFields}
                    isCreatePrivateRoadAgreement={isCreatePrivateRoadAgreement}
                    createPrivateRoadAgreement={createPrivateRoadAgreement}
                  />
                </Paper>
              </Grid>

            </Grid>
          </form>
        </FormProvider>
      </PropertyEditForms>
    );
  }
);

PrivateRoadAgreementForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  privateroadagreement_set: PropTypes.array.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  contractterm: PropTypes.object.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

PrivateRoadAgreementForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const PrivateRoadAgreementForm = React.memo(function privateRoadAgreementForm(props) {
  const {commonInputProps, rules, isModal, handleRemoveForPrivateRoadAgreement, privateRoadAgreementFields, isCreatePrivateRoadAgreement, createPrivateRoadAgreement} = props
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={4}>
        <Typography align="center">対象筆</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography align="center" variant="subtitle1">
          合計 = {privateRoadAgreementFields.length}筆
          <AddButton
            onClick={createPrivateRoadAgreement}
            disabled={isCreatePrivateRoadAgreement}
          />
        </Typography>
      </Grid>
      <Grid item xs={4} />

      {privateRoadAgreementFields.map((item, index) => (
        <React.Fragment key={item.id}>
          <Grid item xs={isModal ? 11 : 3} key={index}>
            <HiddenInput
              {...commonInputProps}
              name={`privateroadagreement_set.${index}.id`}
            />
            <TextField
              {...commonInputProps}
              name={`privateroadagreement_set.${index}.target_brush`}
              rules={rules.privateroadagreement_set.target_brush}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={1}>
            <DeleteButton
              onClick={() => handleRemoveForPrivateRoadAgreement(index)}
              disabled={props.disabled}
            />
          </Grid>
        </React.Fragment>
      ))}
      {isModal &&
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", mt: 1 }}
          onClick={!(isCreatePrivateRoadAgreement) ? createPrivateRoadAgreement : undefined}
        >
          <Typography sx={{ color: (isCreatePrivateRoadAgreement) ? "gray.main" : "darkGreen.main" }}>
            追加
          </Typography>
          <AddButton disabled={isCreatePrivateRoadAgreement} sx={{ ml: 1 }} />
        </Stack>
      }
    </Grid>
  )
})
PrivateRoadAgreementForm.propTypes = {
  commonInputProps: PropTypes.any.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  privateRoadAgreementFields: PropTypes.array.isRequired,
  handleRemoveForPrivateRoadAgreement: PropTypes.func.isRequired,
  isCreatePrivateRoadAgreement: PropTypes.bool.isRequired,
  createPrivateRoadAgreement: PropTypes.func.isRequired,
};