import React from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Grid, Paper } from "@mui/material";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { isMobile } from "react-device-detect";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useLifeLineGarbageWalkwayEtc } from "../../utils/CustomHook";
import { onBlurCommonAction, saveChildForPropertyEdit } from "../../utils/PropertyEdit";
import { SelectForm } from "../atoms/SelectForm";
import { PropertyEditForms } from "../molecules/PropertyEditForms";


export const LifeLineGarbageWalkwayEtcForms = React.memo(
  function lifeLineGarbageWalkwayEtcForms({
    property,
    initialized,
    lifelinegarbagewalkwayetc,
    setOriginPropertyData,
    toggleViewport,
    formPaperVariant,
    formPaperElevation,
    ...props
  }) {

    const [choices, setChoices] = React.useState({});
    React.useEffect(() => {
      setChoices(props.choices);
    }, [props.choices]);

    const [isLoading, setIsLoading] = React.useState(false)
    const loadingComplete = React.useCallback(() => {
      setIsLoading(false)
    }, [])

    const [isOpenModal, setIsOpenModal] = React.useState(false)
    const [propertyId, setPropertyId] = React.useState(null)

    const [isFocused, setFocusInputName] = useFocusInput()
    const handleFocus = React.useCallback((name) => {
      setFocusInputName(name)
    }, [])

    const {
      control,
      formState: { errors },
      setValue,
      methods,
      setError,
      clearErrors,
    } = useForm({
      shouldFocusError: false,
      criteriaMode: "all",
      defaultValues: "",
      mode: "onChange",
    });

    useLifeLineGarbageWalkwayEtc(lifelinegarbagewalkwayetc, setValue, isFocused)

    React.useEffect(() => {
      setPropertyId(property.id)
    }, [property])

    React.useEffect(() => {
      if (!lifelinegarbagewalkwayetc) {
        return;
      }
      initialized();
    }, [lifelinegarbagewalkwayetc]);

    const saveCommonParams = React.useMemo(() => [
      errors, propertyId, clearErrors, setOriginPropertyData, setError
    ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

    const onBlurForLifeLineGarbageWalkwayEtc = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "lifelinegarbagewalkwayetc",
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
    }, [propertyId]);

    const rules = React.useMemo(() => ({
      lifelinegarbagewalkwayetc: {
        clean_water: {
          onBlur: onBlurForLifeLineGarbageWalkwayEtc,
        },
        sewage_water: {
          onBlur: onBlurForLifeLineGarbageWalkwayEtc,
        },
        rainwater_processing_method: {
          onBlur: onBlurForLifeLineGarbageWalkwayEtc,
        },
        gas: {
          onBlur: onBlurForLifeLineGarbageWalkwayEtc,
        },
        electricity: {
          onBlur: onBlurForLifeLineGarbageWalkwayEtc,
        },
        garbage_storage: {
          onBlur: onBlurForLifeLineGarbageWalkwayEtc,
        },
        walkway_off_lower: {
          onBlur: onBlurForLifeLineGarbageWalkwayEtc,
        },
        sign_removal_new_found: {
          onBlur: onBlurForLifeLineGarbageWalkwayEtc,
        },
        power_pole_relocation: {
          onBlur: onBlurForLifeLineGarbageWalkwayEtc,
        },
        order_vendor_up_and_down_water: {
          onBlur: onBlurForLifeLineGarbageWalkwayEtc,
        },
        order_vendor_gas: {
          onBlur: onBlurForLifeLineGarbageWalkwayEtc,
        },
        memo: {
          onBlur: onBlurForLifeLineGarbageWalkwayEtc,
        },
      },
    }), [onBlurForLifeLineGarbageWalkwayEtc]);

    const propertyEditFormDialogCloseAction = React.useCallback(() => {
      toggleViewport()
    }, [])

    const formAreaClickedAction = React.useCallback((additionalProcess) => {
      if (!isMobile) {
        return
      }
      if (additionalProcess) {
        additionalProcess()
      }
      setIsLoading(true)
      toggleViewport()
    }, [])

    const commonInputProps = React.useMemo(() => ({
      control: control,
      onFocus: handleFocus,
    }), [control, handleFocus])

    const pageTitle = "ライフライン・ごみ・歩道 etc."
    return (
      <PropertyEditForms title={pageTitle} >
        <LoadingProgress open={isLoading} useCircular={false} />
        <FormProvider {...methods}>
          <PropertyEditFormDialog
            isOpen={isOpenModal}
            handleClose={() => {
              setIsOpenModal(false)
              propertyEditFormDialogCloseAction()
            }}
            title={pageTitle}
            openedAction={loadingComplete}
            content={
              <Forms
                commonInputProps={commonInputProps}
                rules={rules}
                isModal={true}
                disabled={props.disabled}
                setValue={setValue}
                choices={choices}
              />
            }
          />
          <form>
            <Paper
              elevation={formPaperElevation}
              variant={formPaperVariant}
              onClick={() => formAreaClickedAction(() => setIsOpenModal(true))}
            >
              <Forms
                commonInputProps={commonInputProps}
                rules={rules}
                isModal={false}
                disabled={props.disabled}
                setValue={setValue}
                choices={choices}
              />
            </Paper>
          </form>
        </FormProvider>
      </PropertyEditForms>
    );
  }
);

LifeLineGarbageWalkwayEtcForms.propTypes = {
  property: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  lifelinegarbagewalkwayetc: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
  choices: PropTypes.object.isRequired,
};

LifeLineGarbageWalkwayEtcForms.defaultProps = {
  property: {},
  disabled: false,
};


const Forms = React.memo(function forms(props) {
  const { commonInputProps, rules, isModal, disabled, choices, setValue } = props

  const columns = 30
  const formTypeText = "text"
  const formTypeSelect = "select"
  const formList = React.useMemo(() => [
    {
      formType: formTypeSelect,
      label: "上水",
      property_name: "clean_water",
      pcWidth: 6,
      choices: choices.clean_water,
    },
    {
      formType: formTypeSelect,
      label: "下水",
      property_name: "sewage_water",
      pcWidth: 6,
      choices: choices.sewage_water,
    },
    {
      formType: formTypeText,
      label: "雨水処理方法",
      property_name: "rainwater_processing_method",
      pcWidth: 6,
    },
    {
      formType: formTypeSelect,
      label: "ガス",
      property_name: "gas",
      pcWidth: 6,
      choices: choices.gas,
    },
    {
      formType: formTypeSelect,
      label: "電気",
      property_name: "electricity",
      pcWidth: 6,
      choices: choices.electricity,
    },
    {
      formType: formTypeSelect,
      label: "ごみ置場",
      property_name: "garbage_storage",
      pcWidth: 6,
      choices: choices.garbage_storage,
    },
    {
      formType: formTypeSelect,
      label: "歩道切下",
      property_name: "walkway_off_lower",
      pcWidth: 6,
      choices: choices.lifelinegarbagewalkwayetc_availability,
    },
    {
      formType: formTypeSelect,
      label: "標識撤去・新設",
      property_name: "sign_removal_new_found",
      pcWidth: 6,
      choices: choices.lifelinegarbagewalkwayetc_availability,
    },
    {
      formType: formTypeSelect,
      label: "電柱移設",
      property_name: "power_pole_relocation",
      pcWidth: 6,
      choices: choices.power_pole_relocation,
    },
    {
      formType: null,
      pcWidth: 6,
    },
    {
      formType: formTypeText,
      label: "発注業者（上下水）",
      property_name: "order_vendor_up_and_down_water",
      pcWidth: 6,
    },
    {
      formType: formTypeText,
      label: "発注業者（ガス）",
      property_name: "order_vendor_gas",
      pcWidth: 6,
    },
  ], [choices])

  return (
    <Grid container direction="row" spacing={1} columns={columns} alignItems="flex-end">
      {formList.map((item, index) => (
        <Grid key={index} item xs={isModal ? columns : item.pcWidth}>
          {item.formType === formTypeText &&
            <TextField
              {...commonInputProps}
              name={`lifelinegarbagewalkwayetc.${item.property_name}`}
              rules={rules.lifelinegarbagewalkwayetc[item.property_name]}
              label={item.label}
              disabled={disabled}
              type={item.type}
            />
          }
          {item.formType === formTypeSelect &&
            <SelectForm
              {...commonInputProps}
              name={`lifelinegarbagewalkwayetc.${item.property_name}`}
              rules={rules.lifelinegarbagewalkwayetc[item.property_name]}
              label={item.label}
              choices={item.choices}
              disabled={disabled}
              setValue={setValue}
            />
          }
        </Grid>
      ))}

      <Grid item xs={columns}>
        <TextField
          {...commonInputProps}
          name={"lifelinegarbagewalkwayetc.memo"}
          rules={rules.lifelinegarbagewalkwayetc.memo}
          label="メモ"
          multiline
          rows={isModal ? 20 : 5}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
})

Forms.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
}