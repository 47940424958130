import * as React from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import { Button } from "../atoms/Button";
import { AddButton } from "../atoms/AddButton";
import { PropertyShareInputs } from "../molecules/PropertyShareInputs";
import { FormControl } from "../atoms/FormControl";
import { ApiErrorHandler } from "../../api/ApiErrorHandler";
import { Snackbar } from "../atoms/Snackbar";
import { Dialog } from "../molecules/Dialog";
import { savePropertyShares } from "../../api/PropertyShare";
import { ShareIcon } from "../atoms/ShareIcon";
import { UserContext } from "../../Context";
import { isMobile } from "react-device-detect";

export const PropertyShareDialog = function propertyShareDialog(props) {
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [propertyShareData, setPropertyShareData] = React.useState([])
  const choices = props.choices;
  const user = React.useContext(UserContext);
  React.useEffect(() => {
    setPropertyShareData(props.propertyShare)
  }, [props.propertyShare]);

  React.useEffect(() => {
    setValue("property_id", props.property_id);
  }, [props.property_id]);

  React.useEffect(() => {
    const itemData = [];
    propertyShareData.map((item) => {
      const data = {
        shared_user: item.shared_user,
        permission: item.permission,
      };
      if (item.shared_user != user.id) {
        itemData.push(data);
      }
    });
    shareReplace(itemData);
  }, [propertyShareData]);

  const canSubmit = () => {
    return !isSubmitting;
  };

  const createShareForm = () => {
    const data = {
      shared_user: undefined,
      permission: undefined,
    };
    shareAppend(data);
  };
  const {
    control,
    register,
    handleSubmit,
    setValue,
    methods,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
  });

  const {
    fields: shareFields,
    append: shareAppend,
    replace: shareReplace,
    remove: shareRemove,
  } = useFieldArray({ control, name: "share" });

  const submitAction = (data) => {
    setIsSubmitting(true);
    savePropertyShares(data)
      .then((response) => {
        setMessage("保存しました");
        setSeverity("success");
        props.onClose();

        setPropertyShareData(response.data)
      })
      .catch((error) => {
        ApiErrorHandler(error);
        setMessage("保存に失敗しました");
        setSeverity("error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Snackbar
        closeAction={() => {
          setMessage("");
        }}
        message={message}
        severity={severity}
      />
      <Dialog
        isOpen={props.open}
        title={
          <Stack direction="row" alignItems="center" spacing={1}>
            <ShareIcon color="black" />
            <div>
              共有設定
            </div>
            <AddButton onClick={createShareForm} />
          </Stack>
        }
        handleClose={props.onClose}
        content={
          <>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submitAction)}>
                <Box sx={{ marginTop: 3 }}>
                  <FormControl
                    error={errors.share ? true : false}
                    fullWidth={true}
                  >
                    <Typography variant="subtitle1">
                      プラスのボタンをクリックして共有相手を追加してください。
                    </Typography>
                    {shareFields.map((item, index) => (
                      <PropertyShareInputs
                        errors={errors}
                        register={register}
                        index={index}
                        control={control}
                        handleRemove={() => shareRemove(index)}
                        key={item.id}
                        choices={choices}
                        setValue={setValue}
                      />
                    ))}
                  </FormControl>
                </Box>
              </form>
            </FormProvider>
          </>
        }
        actions={
          <>
            <Button
              color={isMobile ? "gray" : "white"}
              onClick={props.onClose}
              disabled={!canSubmit()}
              fullSize={isMobile}
            >
              キャンセル
            </Button>
            <Button
              color="blue"
              type="submit"
              onClick={handleSubmit(submitAction)}
              disabled={!canSubmit()}
              fullSize={isMobile}
            >
              保存
            </Button>
          </>
        }
      />
    </>
  );
};

PropertyShareDialog.propTypes = {
  property_id: PropTypes.number.isRequired,
  propertyShare: PropTypes.array.isRequired,
  choices: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

PropertyShareDialog.defaultProps = {
  property: {},
};
