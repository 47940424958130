import * as React from "react";
import PropTypes from "prop-types";
import { Dialog } from "../molecules/Dialog";
import { Button } from "../atoms/Button";
import { AddIcon } from "../atoms/AddIcon";
import { Snackbar } from "../atoms/Snackbar";
import { TextField } from "../atoms/TextField";
import { useForm } from "react-hook-form";
import {
  FormControlLabel,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { RadioGroup } from "../atoms/RadioGruop";
import * as validationRules from "../../utils/ValidationRules";
import { createProperty } from "../../api/Properties";
import { isMobile } from "react-device-detect";

export const CreatePropertyDialog = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {
    control,
    handleSubmit,
    reset,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
  });

  React.useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleClose = () => {
    props.handleClose();
  };
  const submitAction = (data) => {
    setIsSubmitting(true);
    let { area_grade, ...requestData } = data;
    area_grade = area_grade.split("_");
    requestData = {
      ...requestData,
      area_code: Number(area_grade[0]),
      grade: Number(area_grade[1]),
    };

    createProperty(requestData)
      .then((response) => {
        props.dataReload(response.data)
        setMessage("作成しました");
        setSeverity("success");
        handleClose();
        reset();
      })
      .catch(() => {
        setMessage("作成に失敗しました");
        setSeverity("error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const rules = {
    name: {
      ...validationRules.required(),
      ...validationRules.maxLength(200),
    },
    area_grade: {
      ...validationRules.required(),
    },
  };

  return (
    <>
      <Snackbar
        closeAction={() => {
          setMessage("");
        }}
        message={message}
        severity={severity}
      />
        <Dialog
          isOpen={isOpen}
          title={
            <>
              <AddIcon color="black" sx={{mr: 1}} />
              新規作成
            </>
          }
          content={
            <Stack spacing={2}>
              <TextField
                control={control}
                label={
                  <Stack direction="row" sx={{mb: 1}}>
                    <Typography variant="subtitle1" sx={{color: "red.main"}}>＊</Typography>
                    <Typography variant="subtitle1">物件名：</Typography>
                  </Stack>
                }
                name="name"
                rules={rules.name}
              />

              <RadioGroup
                control={control}
                label={
                  <Stack direction="row">
                    <Typography variant="subtitle1" sx={{color: "red.main"}}>＊</Typography>
                    <Typography variant="subtitle1">エリアとPS/LSを選んでください：</Typography>
                  </Stack>
                }
                name="area_grade"
                rules={rules.area_grade}
                labelSx={{fontWeight: "bold", mb: 0.5}}
              >
                <Stack direction={isMobile ? "column" : "row"} justifyContent="space-evenly" alignItems="baseline" sx={{mt: 1}}>
                  {props.choices.area_code_and_grade_group?.map(
                    (item, index) => {
                      return (
                        <Stack
                          key={index}
                          sx={{width: "100%"}}
                          direction={isMobile ? "row" : "column"}
                          alignItems="center"
                          spacing={isMobile ? 2 : 0}
                        >
                          <Typography sx={{minWidth: "70px"}}>{item.areacode.label}</Typography>
                          <Stack direction={isMobile ? "row" : "column"} spacing={isMobile ? 5 : 0}>
                            {item.grades.map((grade, gradeIndex) => {
                              return (
                                <FormControlLabel
                                  value={`${item.areacode.value}_${grade.value}`}
                                  label={grade.label}
                                  control={<Radio />}
                                  key={gradeIndex}
                                />
                              );
                            })}
                          </Stack>
                        </Stack>
                      );
                    }
                  )}
                </Stack>
              </RadioGroup>
            </Stack>
          }
          actions={
            <>
              <Button color={isMobile ? "gray" : "white"} onClick={handleClose} disabled={isSubmitting} fullSize={isMobile}>
                キャンセル
              </Button>
              <Button color="green" onClick={handleSubmit(submitAction)} disabled={isSubmitting} fullSize={isMobile}>
                作成
              </Button>
            </>
          }
          handleClose={handleClose}
        />
    </>
  );
};

CreatePropertyDialog.propTypes = {
  choices: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dataReload: PropTypes.func
};

CreatePropertyDialog.defaultProps = {
  dataReload: () => { }
};