/**
 * 数字を丸付きの数字に変換する
 * 丸付き数字は50までしかないので、51以降はそのまま返す
 * @param {Number} num 
 * @returns {String}
 */
export function toCircled(num) {
  if (num > 50) {
    return num
  }
  if (num <= 20) {
    const base = '①'.charCodeAt(0);
    return String.fromCharCode(base + num - 1);
  }
  if (num <= 35) {
    const base = '㉑'.charCodeAt(0);
    return String.fromCharCode(base + num - 21);
  }
  const base = '㊱'.charCodeAt(0);
  return String.fromCharCode(base + num - 36);
}

export function toAlphabet(num) {
  if (num <= 26) {
    return String.fromCharCode(num + 64);
  } else if (num % 26 == 0) {
    return toAlphabet(Math.floor(num/ 26) - 1) + String.fromCharCode(90);
  } else {
    return toAlphabet(Math.floor(num/ 26)) + String.fromCharCode(64 + num % 26);
  }
}

/**
 * 数字を抽出する
 */
export function extractingNumber(str) {
  if (!str) {
    return null
  }
  const num = String(str).replace(/[^0-9]/g, '');
  return Number(num)
}