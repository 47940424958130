import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import {ReactComponent as icon} from "../../assets/images/Mail.svg";

export const MailIcon = (props) => {
  return (
    <SvgIcon
      component={icon}
      viewBox="0 0 20 16"
      {...props}
    />
  );
};

MailIcon.defaultProps = {
  color: "white"
};

MailIcon.propTypes = {
  color: PropTypes.string,
}