import { ApiErrorHandler } from "../api/ApiErrorHandler";
import {
  deleteChild,
  saveChild,
  updateProperty,
  overwriteSalesPlanDetail as overwriteSalesPlanDetailApi,
  uploadBlockRelatedDiagram,
  applyEstimate as applyEstimateApi,
  constructionCooperation as constructionCooperationApi,
  getCompanyPayeeBrokerNameChoices as getCompanyPayeeBrokerNameChoicesApi,
} from "../api/Properties";
import { getNested } from "./ObjectUtil";

export const onBlurCommonAction = (setFocusInputName) => {
  setFocusInputName("")
}

const execSave = (saveAction, data, name, propertyId, clearErrors, setOriginPropertyData, setError, successCallBack = null, finallyCallBack = null) => {
  saveAction(propertyId, data)
    .then((response) => {
      if (name && clearErrors) {
        clearErrors(name);
      }
      setOriginPropertyData(response.data);
      if (successCallBack) {
        successCallBack(response)
      }
    })
    .catch((error) => {
      if (name) {
        setError(name, { type: "api", message: "更新に失敗しました。" });
      }
      ApiErrorHandler(error);
    })
    .finally(() => {
      if (finallyCallBack) {
        finallyCallBack()
      }
    });
}

export const createSalesPlan = (propertyId, clearErrors, setOriginPropertyData, setError, successCallBack) => {
  const data = {
    values: {},
    key_name: "sales_plan",
    info: {
      name: "salesplan",
      child: {
        name: "salesplandetail_set",
      },
    },
  };
  execSave(saveChild, data, null, propertyId, clearErrors, setOriginPropertyData, setError, successCallBack)
}

export const createBuildingPlanningInformationDetail = (propertyId, clearErrors, setOriginPropertyData, setError, successCallBack) => {
  const data = {
    values: {},
    key_name: "property",
    info: {
      name: "buildingplanninginformationdetail_set",
    },
  };
  execSave(saveChild, data, null, propertyId, clearErrors, setOriginPropertyData, setError, successCallBack)
}

const createDataForSaveChild = (name, value, errors, key_name, info_name, child_name = null, getValues = null, isReset = false) => {
  const names = name.split(".");
  const error = getNested(errors, names);
  if (error && error.type !== "api") {
    return null;
  }
  const column_name = names[names.length - 1];
  var data = {}
  if (child_name && getValues) {
    const id_name = names.slice(0, -1).join(".") + ".id";
    const id = getValues(id_name);
    data = {
      values: {
        [column_name]: value,
      },
      key_name: key_name,
      info: {
        name: info_name,
        child: {
          name: child_name,
          id: id,
        },
      },
    };
    if (child_name === "businessbalanceroadsidelandpricesratio_set") {
      const year_name = names.slice(0, -1).join(".") + ".year";
      const year = getValues(year_name);
      data["values"]["year"] = year
    } else if (child_name === "contractamountdetail_set" || child_name === "settlementconfirmationcontractterm_set") {
      const type = getValues(names.slice(0, -1).join(".") + ".type");
      data["values"]["type"] = type
    }
  } else {
    data = {
      values: {
        [column_name]: value,
      },
      key_name: key_name,
      info: {
        name: info_name,
      },
    };
    if (getValues) {
      const id_name = names.slice(0, -1).join(".") + ".id";
      const id = getValues(id_name);
      data.info["id"] = id
    }
  }
  if (isReset) {
    data.values["is_dirty_" + column_name] = false
  }
  return {
    data: data,
    name: name,
  }
}

export const saveChildForPropertyEdit = (e, errors, propertyId, clearErrors, setOriginPropertyData, setError, key_name, info_name, child_name = null, getValues = null) => {
  const name = e.target.name
  const value = e.target.value
  const data = createDataForSaveChild(name, value, errors, key_name, info_name, child_name, getValues)
  if (data) {
    execSave(saveChild, data.data, data.name, propertyId, clearErrors, setOriginPropertyData, setError)
  }
};

export const saveBlockRelatedDiagram = (file, errors, propertyId, clearErrors, setOriginPropertyData, setError, finallyCallBack) => {
  const name = "planningrequest.block_related_diagram_google_drive_id"
  const data = new FormData()
  data.append("file", file)
  execSave(uploadBlockRelatedDiagram, data, name, propertyId, clearErrors, setOriginPropertyData, setError, null, finallyCallBack)
};

export const resetColumnForPropertyEdit = (name, errors, propertyId, clearErrors, setOriginPropertyData, setError, key_name, info_name, child_name = null, getValues = null) => {
  const value = ""
  const isReset = true
  const data = createDataForSaveChild(name, value, errors, key_name, info_name, child_name, getValues, isReset)
  if (data) {
    execSave(saveChild, data.data, data.name, propertyId, clearErrors, setOriginPropertyData, setError)
  }
};

export const saveTransferTargets = (e, errors, propertyId, clearErrors, setOriginPropertyData, setError, getValues) => {
  const name = e.target.name;
  const names = e.target.name.split(".");

  const error = getNested(errors, names);
  if (error && error.type !== "api") {
    return;
  }

  const value = getValues("settlementcontact.transfer_targets");
  let sendValues = [];
  value.map((row) => {
    let transfer_data = [];
    row.transfer_data.map((item) => {
      transfer_data.push({
        id: item.id,
        checked: item?.checked ? true : false,
      });
    });
    sendValues.push({
      same_transfer_target: row.same_transfer_target,
      transfer_type: row.transfer_type,
      transfer_data: transfer_data,
    });
  });
  const data = {
    values: {
      transfer_targets: { data: sendValues },
    },
    key_name: "property",
    info: {
      name: "settlementcontact",
    },
  };
  execSave(saveChild, data, name, propertyId, clearErrors, setOriginPropertyData, setError)
};

export const saveProperty = (e, errors, propertyId, clearErrors, setOriginPropertyData, setError) => {
  const name = e.target.name;
  const column_name = name;
  if (errors[column_name] && errors[column_name].type !== "api") {
    return;
  }
  const data = {
    [column_name]: e.target.value,
  };
  execSave(updateProperty, data, name, propertyId, clearErrors, setOriginPropertyData, setError)
}

export const removeChild = (propertyId, getValues, setOriginPropertyData, fieldRemove, index, infoName, childName = null, keyName = null) => {
  const names = [infoName, childName, index, "id"]
  const idName = names.filter(v => v != null).join(".")
  const id = getValues(idName);

  const data = {
    info: {
      name: infoName,
    },
  };
  if (childName) {
    data.info["child"] = {
      name: childName,
      id: id,
    }
  } else {
    data.info["id"] = id
  }
  if (keyName) {
    data["key_name"] = keyName
  }
  if (id) {
    deleteChild(propertyId, data)
      .then((response) => {
        setOriginPropertyData(response.data);
      })
      .catch((error) => {
        ApiErrorHandler(error);
      });
  } else {
    fieldRemove(index);
  }
}

export const overwriteSalesPlanDetail = (propertyId, destSalesPlanDetailId, setOriginPropertyData) => {
  const data = {
    dest_salesplanddetail_id: destSalesPlanDetailId,
  };
  overwriteSalesPlanDetailApi(propertyId, data)
    .then((response) => {
      setOriginPropertyData(response.data);
    })
    .catch((error) => {
      ApiErrorHandler(error);
    });
}

export const applyEstimate = (propertyId, setOriginPropertyData, successCallBack = null, errorCallBack = null, finallyCallBack = null) => {
  applyEstimateApi(propertyId)
    .then((response) => {
      setOriginPropertyData(response.data);
      if (successCallBack) {
        successCallBack()
      }
    })
    .catch((error) => {
      ApiErrorHandler(error);
      if (errorCallBack) {
        errorCallBack()
      }
    })
    .finally(() => {
      if (finallyCallBack) {
        finallyCallBack()
      }
    });
}

export const constructionCooperation = (propertyId, params, successCallBack = null, errorCallBack = null, finallyCallBack = null) => {
  constructionCooperationApi(propertyId, params)
    .then(() => {
      if (successCallBack) {
        successCallBack()
      }
    })
    .catch((error) => {
      ApiErrorHandler(error);
      if (errorCallBack) {
        errorCallBack()
      }
    })
    .finally(() => {
      if (finallyCallBack) {
        finallyCallBack()
      }
    });
}

export const getCompanyPayeeBrokerNameChoices = (params, successCallBack = null, errorCallBack = null, finallyCallBack = null) => {
  getCompanyPayeeBrokerNameChoicesApi(params)
    .then((response) => {
      if (successCallBack) {
        successCallBack(response)
      }
    })
    .catch((error) => {
      ApiErrorHandler(error);
      if (errorCallBack) {
        errorCallBack()
      }
    })
    .finally(() => {
      if (finallyCallBack) {
        finallyCallBack()
      }
    });
}
