import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from "prop-types";
import { CustomHelmetProvider } from "../organisms/CustomHelmetProvider";
import { CustomThemeProvider } from "../organisms/CustomThemeProvider";
import { Header } from "../organisms/Header";
import EnhancedTable from "../organisms/EnhancedTable";
import { Container } from "../atoms/Container";
import { UserContext } from "../../Context";
import Box from "@mui/material/Box";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { Snackbar } from "../atoms/Snackbar";
import { BrowserView, isBrowser, MobileView } from 'react-device-detect';
import { Button } from "../atoms/Button";
import { Stack, Typography } from "@mui/material";
import { CloseIcon } from "../atoms/CloseIcon";

export const ListLayout = (props) => {
  const containerSx = isBrowser ? {mt: 1, p:0} : {m:0, p:0}

  const deleteSearchWord = (key) => {
    const searchWords = { ...props.searchWords }
    searchWords[key] = null
    props.doSearch(searchWords)
  }

  return (
    <UserContext.Provider value={props.user}>
      <CustomThemeProvider>
        <CustomHelmetProvider pageTitle={props.pageTitle}>
          <CssBaseline />
          <Header />
          <LoadingProgress open={props.loadingOpen} />
          <Snackbar
            closeAction={props.snackBarCloseAction}
            message={props.snackBarMessage}
            severity={props.severity}
          />

            <Container
              sx={containerSx}
            >
              <Box sx={{
                width: "100%"}}>
                <BrowserView>
                  {props.tableToolbar}
                </BrowserView>

                <MobileView>
                  {Object.keys(props.searchWords).length > 0 &&
                    <Stack direction="row" sx={{py: 1, pl: 1}} alignItems="center" spacing={1}>
                      <Typography>検索：</Typography>
                      {Object.keys(props.searchWords).map((key) => {
                        let label = ""
                        if (key === "property_status") {
                          props.choices.statuses.forEach((item) => {
                            if (item.value === props.searchWords[key]) {
                              label = item.label
                            }
                          })
                        } else {
                          label = props.searchWords[key]
                        }
                        return (
                          <Button
                            roundedCorners={true}
                            key={key}
                            color="gray"
                            sx={{ border: "1px solid #0A67D1", py: 0.5 }}
                            onClick={() => deleteSearchWord(key)}
                          >
                            <Stack direction="row" alignItems="center">
                              <Typography sx={{color: "blue2.main", mr: 1}}>
                                {label}
                              </Typography>
                              <CloseIcon sx={{fontSize: "0.7rem"}} />
                            </Stack>
                          </Button>
                        )
                      })}
                    </Stack>
                  }
                </MobileView>

                <EnhancedTable
                  rows={props.rows}
                  includeCheckBox={props.tableCheckBox}
                  headCells={props.headCells}
                  tableName={props.tableName}
                  handleChangePage={props.handleChangePage}
                  handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                  page={props.page}
                  lastPage={props.lastPage}
                  rowsPerPage={props.rowsPerPage}
                  itemCount={props.itemCount}
                  rowsPerPageOptions={props.rowsPerPageOptions}
                  handleRequestSort={props.handleRequestSort}
                  order={props.order}
                  orderBy={props.orderBy}
                  tableButtons={props.tableButtons}
                  checkBoxClickedAction={props.tableCheckBoxClickedAction}
                  usePagination={isBrowser}
                />
                <MobileView>
                  {props.tableToolbar}
                </MobileView>
              </Box>
              {props.children}
            </Container>

        </CustomHelmetProvider>
      </CustomThemeProvider>
    </UserContext.Provider>
  );
};

ListLayout.propTypes = {
  doSearch: PropTypes.func,
  rows: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  tableName: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.any,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  itemCount: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  tableToolbar: PropTypes.object,
  tableButtons: PropTypes.array,
  loadingOpen: PropTypes.bool.isRequired,
  snackBarCloseAction: PropTypes.func,
  snackBarMessage: PropTypes.string,
  severity: PropTypes.string,
  tableCheckBoxClickedAction: PropTypes.func,
  tableCheckBox: PropTypes.bool,
  searchWords: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
};

ListLayout.defaultProps = {
  tableButtons: [],
  loadingOpen: false,
  tableCheckBox: true,
};