import { backendClient } from "./Client";
import { BackendUrls } from "./Urls";


export const backendLogin = (params) => {
  return backendClient().post(BackendUrls.login, params)
}

export const backendLogout = (params) => {
  return backendClient().get(BackendUrls.logout, params)
}
