import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import {ReactComponent as icon} from "../../assets/images/DownArrow.svg";

export const UpArrowIcon = (props) => {
  return (
    <SvgIcon
      component={icon}
      {...props}
      viewBox="0 0 17 10"
      sx={{ transform: "scale(1, -1)", ...props.sx}}
    />
  );
};

UpArrowIcon.defaultProps = {
  color: "black3",
};

UpArrowIcon.propTypes = {
  color: PropTypes.string,
  sx: PropTypes.object,
}