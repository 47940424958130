import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { downloadPdf } from "../../api/Properties";
import { pdfDownload } from "../../utils/FileUtil";
import { PrinterIcon } from "../atoms/PrinterIcon";
import { SelectSheetDialog } from "./SelectSheetDialog";

export const PrintDialog = (props) => {
  return (
    <SelectSheetDialog
      open={props.open}
      title={
        <>
          <PrinterIcon color="black" sx={{ mr: 1 }} />
          シート印刷
        </>
      }
      submitButtonTitle={"印刷"}
      submitButtonColor={"emeraldGreen"}
      property_id={props.property_id ?? 0}
      causeCanNotSelectSheet={props.causeCanNotSelectSheet}
      choices={props.choices ?? []}
      onClose={props.onClose}
      api={downloadPdf}
      apiSuccessAction={(response) => {
        pdfDownload(response)
      }}
      description={
        <Stack direction="row" sx={{ mb: 1 }}>
          <Typography variant="subtitle1" sx={{ color: "red.main" }} component="span">＊</Typography>
          <Typography variant="subtitle1" component="span">印刷したいシートを選んでください：</Typography>
        </Stack>
      }
    />
  );
};

PrintDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  property_id: PropTypes.number.isRequired,
  choices: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  causeCanNotSelectSheet: PropTypes.object,
};

PrintDialog.defaultProps = {
  causeCanNotSelectSheet: {},
};