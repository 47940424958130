import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { isBrowser } from "react-device-detect";
import { UpArrowIcon } from "../atoms/UpArrowIcon";

export const PropertyEditForms = (props) => {

  const expandIcon = () => {
    if (isBrowser) {
      return (
        <UpArrowIcon/>
      )
    } else {
      return (
        <Box sx={{
          bgcolor: "black.main",
          p: 0.5,
          borderRadius: "8px",
          display: "flex",
        }}>
          <UpArrowIcon color="white" sx={{
            justifyContent: "center", fontSize: "0.9rem"
          }} />
        </Box>
      )
    }
  }
  return (
    <Accordion
      defaultExpanded={true}
      sx={{boxShadow:"none"}}
      disableGutters={true}
    >
      <AccordionSummary
        expandIcon={expandIcon()}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          flexDirection: 'row-reverse',
          bgcolor: isBrowser ? 'gray.main' : "white.main",
        }}
      >
        <Typography variant={isBrowser ? undefined : "subtitle1"} sx={{px: 2}}>{props.title}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        {props.children}
      </AccordionDetails>
    </Accordion>
  )

}

PropertyEditForms.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
};
