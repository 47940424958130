import { IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { CopyIcon } from "./CopyIcon";
import { Tooltip } from "./Tooltip";

export const CopyButton = (props) => {
  const { title, sx, disabled, onClick, ...buttonProps } = props;
  return (
    <Tooltip title={title}>
      <IconButton
        sx={{
          minWidth: "40px",
          px: 0,
          border: "1.2px solid",
          borderRadius: 2,
          borderColor: "#40A42B",
          ...sx,
        }}
        onClick={disabled ? null : onClick}
        {...buttonProps}
      >
        <CopyIcon/>
      </IconButton>
    </Tooltip>
  );
};

CopyButton.defaultProps = {
  sx: {},
  title: "",
};

CopyButton.propTypes = {
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};
