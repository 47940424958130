import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import {ReactComponent as icon} from "../../assets/images/DownArrow.svg";

export const DownArrowIcon = (props) => {
  return (
    <SvgIcon
      component={icon}
      {...props}
      viewBox="0 0 17 10"
    />
  );
};


DownArrowIcon.defaultProps = {
  color: "black3"
};

DownArrowIcon.propTypes = {
  color: PropTypes.string,
}