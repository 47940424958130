import { HelmetProvider } from "react-helmet-async";
import PropTypes from "prop-types";
import { CustomHelmet } from "../molecules/CustomHelmet";

export const CustomHelmetProvider = (props) => {
  return (
    <HelmetProvider>
      <CustomHelmet
        pageTitle={props.pageTitle}
        scripts={props.scripts}
        viewport={props.viewport}
      />
      {props.children}
    </HelmetProvider>
  );
};

CustomHelmetProvider.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  viewport: PropTypes.string,
  scripts: PropTypes.array,
  children: PropTypes.any,
}