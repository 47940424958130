import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import {ReactComponent as icon} from "../../assets/images/Close.svg";

export const CloseIcon = (props) => {
  return (
    <SvgIcon
      component={icon}
      viewBox="0 0 9 9"
      {...props}
    />
  );
};

CloseIcon.defaultProps = {
  color: "blue2"
};

CloseIcon.propTypes = {
  color: PropTypes.string,
}