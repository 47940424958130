import PropTypes from "prop-types";
import { Container } from "../atoms/Container"
import { palette } from "../organisms/CustomThemeProvider"

export const DialogSubContent = (props) => {
  const {title, content} = props
  return (
    <>
      <Container sx={{bgcolor: palette.palette.gray.main, paddingTop: 0.3, paddingBottom: 0.3, pl: 3, minHeight: "50px", display: "flex", alignItems: "center"}}>
        {title}
      </Container>
      <Container sx={{px: 3, py: 2}}>
        {content}
      </Container>
    </>
  )
}

DialogSubContent.propTypes = {
  title: PropTypes.any.isRequired,
  content: PropTypes.object.isRequired,
}