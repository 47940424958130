import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import {ReactComponent as icon} from "../../assets/images/Search.svg";

export const SearchIcon = (props) => {
  return (
    <SvgIcon
      component={icon}
      viewBox="0 0 26 26"
      {...props}
    />
  );
};

SearchIcon.defaultProps = {
  color: "black2"
};

SearchIcon.propTypes = {
  color: PropTypes.string,
}