import PropTypes from "prop-types";
import LockResetIcon from '@mui/icons-material/LockReset';

export const ResetIcon = (props) => {
  return (
    <LockResetIcon
      {...props}
    />
  );
};

ResetIcon.defaultProps = {
  color: "black"
};

ResetIcon.propTypes = {
  color: PropTypes.string,
}