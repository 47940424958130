import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Divider, FormLabel, Grid, InputAdornment, Paper, Stack, Typography } from "@mui/material";
import { DatePicker } from "../atoms/DatePicker";
import { SelectForm } from "../atoms/SelectForm";
import { CheckBox } from "../atoms/CheckBox";
import { HiddenInput } from "../atoms/HiddenInput";
import { toCircled } from "../../utils/NumberUtils";
import { isMobile } from "react-device-detect";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { DialogSubContent } from "../molecules/DialogSubContent";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitCompanyPayeeBroker, useInitContractContactLandowner, useInitContractContactSeller, useInitContractTerm, useInitJudicialScrivener, useInitOutsourcingCompany, useInitPropertyOverview } from "../../utils/CustomHook";
import { onBlurCommonAction, saveChildForPropertyEdit, saveTransferTargets } from "../../utils/PropertyEdit";

export const SettlementContactForms = React.memo(
  function settlementContactForms({
    property,
    initialized,
    companypayeebroker,
    outsourcingcompany,
    judicialscrivener,
    contractcontactseller_set,
    contractcontactlandowner_set,
    settlementcontact,
    setOriginPropertyData,
    contractterm,
    propertyoverview,
    toggleViewport,
    formPaperVariant,
    formPaperElevation,
    ...props
  }) {
    const [isLoading, setIsLoading] = React.useState(false)
    const loadingComplete = React.useCallback(() => {
      setIsLoading(false)
    }, [])

    const [isOpenSettlementFormModal, setIsOpenSettlementFormModal] = React.useState(false)
    const [isOpenAreaFormModal, setIsOpenAreaFormModal] = React.useState(false)
    const [isOpenBreakdownFormsModal, setIsOpenBreakdownFormsModal] = React.useState(false)
    const [isOpenPaymentMethodFormsModal, setIsOpenPaymentMethodFormsModal] = React.useState(false)

    const [choices, setChoices] = React.useState({});
    const [propertyId, setPropertyId] = React.useState(null)

    const [settlementContactsDenominationSet, setSettlementContactsDenominationSet] = React.useState([])

    const [isFocused, setFocusInputName] = useFocusInput()
    const handleFocus = React.useCallback((name) => {
      setFocusInputName(name)
    }, [])

    React.useEffect(() => {
      setPropertyId(property.id)
    }, [property])

    const {
      control,
      formState: { errors },
      setValue,
      methods,
      setError,
      getValues,
      clearErrors,
      trigger,
    } = useForm({
      shouldFocusError: false,
      criteriaMode: "all",
      defaultValues: "",
      mode: "onChange",
    });

    const {
      fields: settlementContactsDenominationFields,
      replace: settlementContactsDenominationReplace,
    } = useFieldArray({ control, name: "settlementcontactsdenomination_set" });

    React.useEffect(() => {
      trigger("settlementcontactsdenomination_set")
    }, [settlementContactsDenominationFields])

    const {
      fields: settlementContactTransferTargetsFields,
      replace: settlementContactTransferTargetsReplace,
    } = useFieldArray({ control, name: "settlementcontact.transfer_targets" });

    React.useEffect(() => {
      trigger("settlementcontact.transfer_targets")
    }, [settlementContactTransferTargetsFields])

    useInitCompanyPayeeBroker(companypayeebroker, setValue, isFocused, errors)
    useInitOutsourcingCompany(outsourcingcompany, setValue, isFocused)
    useInitJudicialScrivener(judicialscrivener, setValue, isFocused)
    useInitContractTerm(contractterm, setValue, isFocused)
    useInitPropertyOverview(propertyoverview, setValue, isFocused)
    const [contractContactSellerFields] = useInitContractContactSeller(contractcontactseller_set, setValue, isFocused, control, trigger)
    const [contractContactLandownerFields] = useInitContractContactLandowner(contractcontactlandowner_set, setValue, isFocused, control, trigger)

    React.useEffect(() => {
      setChoices(props.choices);
    }, [props.choices]);

    React.useEffect(() => {
      if (
        !(
          companypayeebroker &&
          outsourcingcompany &&
          judicialscrivener &&
          contractcontactseller_set &&
          contractcontactlandowner_set &&
          settlementcontact &&
          contractterm &&
          propertyoverview
        )
      ) {
        return;
      }

      const denominationSet = (settlementcontact.settlementcontactsdenomination_set ?? [])
        .sort((a, b) => a.display_order < b.display_order ? -1 : 1)
        .map((item, index) => { return { ...item, labelNumber: index + 1 } })

      denominationSet.map((denomination, index) => {
        Object.keys(denomination).forEach((key) => {
          if (Object.keys(denomination.values_or_default ?? {}).includes(key)) {
            denominationSet[index][key] = denomination.values_or_default[key]
          }
        })
      });

      Object.keys(settlementcontact).map((key) => {
        const name = `settlementcontact.${key}`
        if ((typeof settlementcontact[key] !== "object" || settlementcontact[key] === null) && !isFocused(name)) {
          if (Object.keys(settlementcontact.values_or_default ?? {}).includes(key)) {
            setValue(name, settlementcontact.values_or_default[key], { shouldValidate: true });
          } else {
            setValue(name, settlementcontact[key], { shouldValidate: true });
          }
        } else if (key === "transfer_targets") {
          const transferTargets = denominationSet
          const transferTargetsData = settlementcontact[key]?.data?.map(
            (item) => {
              item.transfer_data.map((data) => {
                transferTargets.map((target, nestIndex) => {
                  if (target.id === data.id) {
                    transferTargets[nestIndex].checked = data.checked;
                  }
                });
              });
              return {
                same_transfer_target: item.same_transfer_target,
                transfer_type: item.transfer_type,
                transfer_data: transferTargets,
              };
            }
          );
          const defaultTargets = [
            {
              same_transfer_target: "",
              transfer_type: "",
              transfer_data: transferTargets,
            },
          ];

          const settlementContactTransferTargetsData = transferTargetsData ?? defaultTargets
          if (settlementContactTransferTargetsFields.length) {
            settlementContactTransferTargetsData.forEach((item, index) => {
              Object.keys(item).forEach((key) => {
                const name = `settlementcontact.transfer_targets.${index}.${key}`
                if (!isFocused(name)) {
                  setValue(name, item[key], { shouldValidate: true });
                }
              })
            })
          } else {
            settlementContactTransferTargetsReplace(settlementContactTransferTargetsData);
          }
        }
      });

      if (settlementContactsDenominationFields.length) {
        denominationSet.forEach((item, index) => {
          Object.keys(item).forEach((key) => {
            const name = `settlementcontactsdenomination_set.${index}.${key}`
            if (!isFocused(name)) {
              setValue(name, item[key], { shouldValidate: true })
            }
          })
        })
      } else {
        settlementContactsDenominationReplace(denominationSet)
      }
      setSettlementContactsDenominationSet(denominationSet.map(obj => ({
        labelNumber: obj.labelNumber,
        is_first_seller: obj.is_first_seller,
        is_first_broker: obj.is_first_broker,
        is_first_business_commission: obj.is_first_business_commission,
        is_first_judicial_scrivener: obj.is_first_judicial_scrivener,
        is_first_landowner: obj.is_first_landowner,
      })))

      initialized();
    }, [
      companypayeebroker,
      outsourcingcompany,
      judicialscrivener,
      contractcontactseller_set,
      contractcontactlandowner_set,
      settlementcontact,
      contractterm,
      propertyoverview,
    ]);

    const saveCommonParams = React.useMemo(() => [
      errors, propertyId, clearErrors, setOriginPropertyData, setError
    ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

    const onBlurForSettlementContact = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "settlementcontact",
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
    }, [propertyId]);

    const onBlurForSettlementContactTransferTarget = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      saveTransferTargets(e, ...saveCommonParams, getValues)
    }, [propertyId]);

    const onBlurForContractContactLandowner = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "contractcontactlandowner_set", null
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
    }, [propertyId]);

    const onBlurForSettlementContactsDenomination = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "settlement_contact", "settlementcontact", "settlementcontactsdenomination_set"
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
    }, [propertyId]);

    const rules = React.useMemo(() => ({
      settlementcontact: {
        settlement_date: {
          onBlur: onBlurForSettlementContact,
        },
        settlement_time: {
          onBlur: onBlurForSettlementContact,
        },
        settlement_location: {
          onBlur: onBlurForSettlementContact,
        },
        settlement_application_progress_denominator: {
          onBlur: onBlurForSettlementContact,
        },
        settlement_application_progress_numerator: {
          onBlur: onBlurForSettlementContact,
        },
        settlement_approval_progress_denominator: {
          onBlur: onBlurForSettlementContact,
        },
        settlement_approval_progress_numerator: {
          onBlur: onBlurForSettlementContact,
        },
        budget_modifying_application_progress_denominator: {
          onBlur: onBlurForSettlementContact,
        },
        budget_modifying_application_progress_numerator: {
          onBlur: onBlurForSettlementContact,
        },
        budget_modifying_approval_progress_denominator: {
          onBlur: onBlurForSettlementContact,
        },
        budget_modifying_approval_progress_numerator: {
          onBlur: onBlurForSettlementContact,
        },
        expenses_payment_application_progress_denominator: {
          onBlur: onBlurForSettlementContact,
        },
        expenses_payment_application_progress_numerator: {
          onBlur: onBlurForSettlementContact,
        },
        expenses_payment_approval_progress_denominator: {
          onBlur: onBlurForSettlementContact,
        },
        expenses_payment_approval_progress_numerator: {
          onBlur: onBlurForSettlementContact,
        },
        building_enabled_area_check: {
          onChange: onBlurForSettlementContact,
        },
        building_enabled_area: {
          onBlur: onBlurForSettlementContact,
        },
        receipt_issuance: {
          onBlur: onBlurForSettlementContact,
        },
        receipt_amount: {
          onBlur: onBlurForSettlementContact,
        },
        actual_reimbursement: {
          onBlur: onBlurForSettlementContact,
        },
        increase_and_decrease_amount: {
          onBlur: onBlurForSettlementContact,
        },
        increase_or_decrease: {
          onBlur: onBlurForSettlementContact,
        },
        selling_price_change_after_contract: {
          onBlur: onBlurForSettlementContact,
        },
        selling_price_before_change: {
          onBlur: onBlurForSettlementContact,
        },
        selling_price_after_change: {
          onBlur: onBlurForSettlementContact,
        },
        seller_property_tax_and_city_planning_tax: {
          onBlur: onBlurForSettlementContact,
        },
        transfer_targets: {
          onBlur: onBlurForSettlementContactTransferTarget,
        },
        property_tax_and_city_planning_tax: {
          onBlur: onBlurForSettlementContact,
        },
        seller_land_rent_settlement: {
          onBlur: onBlurForSettlementContact,
        },
        seller_land_rent_burden_days: {
          onBlur: onBlurForSettlementContact,
        },
        broker_seller_other_payment_amount: {
          onBlur: onBlurForSettlementContact,
        },
        business_consignment_fee_amount: {
          onBlur: onBlurForSettlementContact,
        },
        business_consignment_target_other_payment_amount: {
          onBlur: onBlurForSettlementContact,
        },
        judicial_scrivener_registration_cost: {
          onBlur: onBlurForSettlementContact,
        },
        judicial_scrivener_registration_other_cost: {
          onBlur: onBlurForSettlementContact,
        },
        other_payment_name1: {
          onBlur: onBlurForSettlementContact,
        },
        other_payment_amount1: {
          onBlur: onBlurForSettlementContact,
        },
        other_payment_name2: {
          onBlur: onBlurForSettlementContact,
        },
        other_payment_amount2: {
          onBlur: onBlurForSettlementContact,
        },
        settlementcontactsdenomination_set: {
          type: {
            onBlur: onBlurForSettlementContactsDenomination,
          },
          payee: {
            onBlur: onBlurForSettlementContactsDenomination,
          },
          amount: {
            onBlur: onBlurForSettlementContactsDenomination,
          },
          payment_methods: {
            onBlur: onBlurForSettlementContactsDenomination,
          },
          transfer_name: {
            onBlur: onBlurForSettlementContactsDenomination,
          },
          exists_confirmation: {
            onChange: onBlurForSettlementContactsDenomination,
          },
          check_anti_social: {
            onChange: onBlurForSettlementContactsDenomination,
          },
        }
      },
      contractcontactlandowner_set: {
        comparison_of_current_address_and_registry: {
          onBlur: onBlurForContractContactLandowner,
        },
      },
    }), [onBlurForSettlementContact, onBlurForSettlementContactTransferTarget, onBlurForSettlementContactsDenomination, onBlurForContractContactLandowner]);

    const propertyEditFormDialogCloseAction = React.useCallback(() => {
      toggleViewport()
    }, [])
    const formAreaClickedAction = React.useCallback((additionalProcess) => {
      if (!isMobile) {
        return
      }
      if (additionalProcess) {
        additionalProcess()
      }
      setIsLoading(true)
      toggleViewport()
    }, [])

    const commonInputProps = React.useMemo(() => ({
      control: control,
      onFocus: handleFocus,
    }), [control, handleFocus])

    return (
      <PropertyEditForms title="決済連絡表">
        <LoadingProgress open={isLoading} useCircular={false} />
        <FormProvider {...methods}>
          <PropertyEditFormDialog
            isOpen={isOpenSettlementFormModal}
            handleClose={() => {
              setIsOpenSettlementFormModal(false)
              propertyEditFormDialogCloseAction()
            }}
            title="決済連絡表"
            openedAction={loadingComplete}
            content={
              <SettlementForm
                commonInputProps={commonInputProps}
                choices={choices}
                rules={rules}
                disabled={props.disabled}
                setValue={setValue}
                contractContactSellerFields={contractContactSellerFields}
                contractContactLandownerFields={contractContactLandownerFields}
                isModal={true}
              />
            }
          />
          <PropertyEditFormDialog
            isOpen={isOpenAreaFormModal}
            handleClose={() => {
              setIsOpenAreaFormModal(false)
              propertyEditFormDialogCloseAction()
            }}
            title="決済連絡表"
            openedAction={loadingComplete}
            content={
              <AreaForm
                commonInputProps={commonInputProps}
                choices={choices}
                rules={rules}
                disabled={props.disabled}
                setValue={setValue}
                isModal={true}
              />
            }
          />
          <PropertyEditFormDialog
            contentSx={{ px: 0, pt: 0 }}
            isOpen={isOpenBreakdownFormsModal}
            handleClose={() => {
              setIsOpenBreakdownFormsModal(false)
              propertyEditFormDialogCloseAction()
            }}
            title="<内訳>"
            openedAction={loadingComplete}
            content={
              <BreakdownForms
                commonInputProps={commonInputProps}
                rules={rules}
                disabled={props.disabled}
                isModal={true}
              />
            }
          />
          <PropertyEditFormDialog
            contentSx={{ px: 0, pt: 0 }}
            isOpen={isOpenPaymentMethodFormsModal}
            handleClose={() => {
              setIsOpenPaymentMethodFormsModal(false)
              propertyEditFormDialogCloseAction()
            }}
            title="<金種>"
            openedAction={loadingComplete}
            content={
              <PaymentMethodForms
                commonInputProps={commonInputProps}
                rules={rules}
                choices={choices}
                setValue={setValue}
                settlementContactsDenominationFields={settlementContactsDenominationSet}
                settlementContactTransferTargetsFields={settlementContactTransferTargetsFields}
                disabled={props.disabled}
                isModal={true}
                onBlurForSettlementContactTransferTarget={onBlurForSettlementContactTransferTarget}
              />
            }
          />

          <form>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} lg={7}>
                <Paper
                  elevation={formPaperElevation}
                  variant={formPaperVariant}
                  onClick={() => formAreaClickedAction(() => setIsOpenSettlementFormModal(true))}
                >
                  <SettlementForm
                    commonInputProps={commonInputProps}
                    choices={choices}
                    rules={rules}
                    disabled={props.disabled}
                    setValue={setValue}
                    contractContactSellerFields={contractContactSellerFields}
                    contractContactLandownerFields={contractContactLandownerFields}
                    isModal={false}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} lg={5}>
                <Paper
                  elevation={formPaperElevation}
                  variant={formPaperVariant}
                  onClick={() => formAreaClickedAction(() => setIsOpenAreaFormModal(true))}
                >
                  <AreaForm
                    commonInputProps={commonInputProps}
                    choices={choices}
                    rules={rules}
                    disabled={props.disabled}
                    setValue={setValue}
                    isModal={false}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12}/>
              <Grid item xs={12}>
                <Paper
                  elevation={formPaperElevation}
                  variant={formPaperVariant}
                  onClick={() => formAreaClickedAction(() => setIsOpenBreakdownFormsModal(true))}
                >
                  <Stack>
                    <Typography variant="subtitle1">{"<内訳>"}</Typography>
                    <BreakdownForms
                      commonInputProps={commonInputProps}
                      rules={rules}
                      disabled={props.disabled}
                      isModal={false}
                    />
                  </Stack>
                </Paper>
              </Grid>

              <Grid item xs={12} sx={{mt: 3}}>
                <Paper
                  elevation={formPaperElevation}
                  variant={formPaperVariant}
                  onClick={() => formAreaClickedAction(() => setIsOpenPaymentMethodFormsModal(true))}
                >
                  <Stack>
                    <Typography variant="subtitle1">{"<金種>"}</Typography>
                    <PaymentMethodForms
                      commonInputProps={commonInputProps}
                      rules={rules}
                      choices={choices}
                      setValue={setValue}
                      settlementContactsDenominationFields={settlementContactsDenominationSet}
                      settlementContactTransferTargetsFields={settlementContactTransferTargetsFields}
                      disabled={props.disabled}
                      isModal={false}
                      onBlurForSettlementContactTransferTarget={onBlurForSettlementContactTransferTarget}
                    />
                  </Stack>
                </Paper>
              </Grid>

            </Grid>

          </form>
        </FormProvider>
      </PropertyEditForms>
    );
  }
);

SettlementContactForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  companypayeebroker: PropTypes.object.isRequired,
  outsourcingcompany: PropTypes.object.isRequired,
  judicialscrivener: PropTypes.object.isRequired,
  contractterm: PropTypes.object.isRequired,
  propertyoverview: PropTypes.object.isRequired,
  contractcontactseller_set: PropTypes.array.isRequired,
  contractcontactlandowner_set: PropTypes.array.isRequired,
  settlementcontact: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

SettlementContactForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const TransferTargets = (props) => {
  const { index, commonInputProps, data, checkedOnBlur } = props;
  const control = commonInputProps.control
  const { fields, replace } = useFieldArray({
    control,
    name: `settlementcontact.transfer_targets.${index}.transfer_data`,
  });

  React.useEffect(() => {
    replace(data);
  }, [data]);

  const rules = {
    checked: {
      onChange: checkedOnBlur,
    },
  };

  return fields.map((item, itemIndex) => {
    return (
      <React.Fragment key={itemIndex}>
        <HiddenInput
          {...commonInputProps}
          name={`settlementcontact.transfer_targets.${index}.transfer_data.${itemIndex}.id`}
        />
        <CheckBox
          {...commonInputProps}
          name={`settlementcontact.transfer_targets.${index}.transfer_data.${itemIndex}.checked`}
          label={item.labelNumber}
          rules={rules.checked}
        />
      </React.Fragment>
    );
  });
};

TransferTargets.propTypes = {
  index: PropTypes.number.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  checkedOnBlur: PropTypes.func.isRequired,
};

const BreakdownLabel = (props) => {
  const { count, label } = props;
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12} sx={{ mt: 3, display: "flex" }}>
        <Typography
          variant="subtitle1"
        >
          {count}
          {"　"}
        </Typography>
        <Typography
          variant="subtitle1"
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};
BreakdownLabel.propTypes = {
  count: PropTypes.string,
  label: PropTypes.string.isRequired,
};

const BreakdownForm = (props) => {
  const {
    labelNumber,
    label,
    name,
    rules,
    labelVariant,
    textFieldInputProps,
    textFieldType,
    commonInputProps,
    isModal,
  } = props;
  return (
    <Grid container direction="row" spacing={1} alignItems="center">
      {isModal
        ?
        <>
          {labelNumber
          ?
            <>
              {typeof label == "object" &&
                <Grid item xs={12}>
                  {label}
                </Grid>
              }
              <Grid item xs={12}>
                <TextField
                  {...commonInputProps}
                  name={name}
                  rules={rules}
                  type={textFieldType ?? "number"}
                  disabled={rules && !props.disabled ? false : true}
                  InputProps={textFieldInputProps}
                  label={typeof label == "object" ? undefined : (labelNumber ?? "") + label}
                  labelPosition="top"
                />
              </Grid>
            </>
          :
            <>
              <Grid item xs={6}>
                <Stack direction="row-reverse">
                  <Typography> {label} </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...commonInputProps}
                  name={name}
                  rules={rules}
                  type={textFieldType ?? "number"}
                  disabled={rules && !props.disabled ? false : true}
                  InputProps={textFieldInputProps}
                />
              </Grid>
            </>
          }
        </>
        :
        <>
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
            {labelNumber && (
              <Typography
                sx={{ pl: 3, pr: 1 }}
                variant={labelVariant ?? "body1"}
                component="span"
              >
                {labelNumber}{" "}
              </Typography>
            )}
            <Typography
              sx={{
                pl: labelNumber ? 0 : labelVariant ? 3 : 5,
                textDecoration: labelVariant ? "underline" : "none",
                textUnderlineOffset: "0.1em",
              }}
              variant={labelVariant ?? "body1"}
              component="span"
            >
              {label}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              {...commonInputProps}
              name={name}
              rules={rules}
              type={textFieldType ?? "number"}
              disabled={rules && !props.disabled ? false : true}
              InputProps={textFieldInputProps}
            />
          </Grid>
        </>
      }
    </Grid>
  );
};

BreakdownForm.propTypes = {
  labelNumber: PropTypes.string,
  label: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  labelVariant: PropTypes.string,
  textFieldInputProps: PropTypes.object,
  textFieldType: PropTypes.object,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
};
BreakdownForm.defaultProps = {
  disabled: false,
};

const PaymentMethodForm = React.memo(function paymentMethodForm(props) {
  const { labelNumber, isFirstSeller, isFirstBroker, isFirstBusinessCommission, isFirstJudicialScrivener, isFirstLandowner, index, commonInputProps, rules, choices, setValue, isModal, labels} = props;

  const Form = () => (
    <Grid container direction="row" spacing={1} columns={isModal ? 12 : 20}>
      <Grid item xs={isModal ? 12 : 4}>
        <TextField
          {...commonInputProps}
          name={`settlementcontactsdenomination_set.${index}.payee`}
          rules={rules.settlementcontact.settlementcontactsdenomination_set.payee}
          disabled={props.disabled}
          label={isModal && labels.payee}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 6}>
        <TextField
          {...commonInputProps}
          name={`settlementcontactsdenomination_set.${index}.amount`}
          type="number"
          rules={rules.settlementcontact.settlementcontactsdenomination_set.amount}
          disabled={props.disabled || isFirstSeller || isFirstBroker || isFirstBusinessCommission || isFirstJudicialScrivener || isFirstLandowner}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
          label={isModal && labels.amount}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 4}>
        <SelectForm
          {...commonInputProps}
          name={`settlementcontactsdenomination_set.${index}.payment_methods`}
          rules={rules.settlementcontact.settlementcontactsdenomination_set.payment_methods}
          choices={choices.payment_methods}
          disabled={props.disabled}
          setValue={setValue}
          label={isModal && labels.payment_methods}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 4}>
        <TextField
          {...commonInputProps}
          name={`settlementcontactsdenomination_set.${index}.transfer_name`}
          rules={rules.settlementcontact.settlementcontactsdenomination_set.transfer_name}
          disabled={props.disabled}
          label={isModal && labels.transfer_name}
        />
      </Grid>
      <Grid item xs={isModal ? 6: 1}>
        <CheckBox
          {...commonInputProps}
          name={`settlementcontactsdenomination_set.${index}.exists_confirmation`}
          rules={rules.settlementcontact.settlementcontactsdenomination_set.exists_confirmation}
          disabled={props.disabled}
          label={isModal && labels.exists_confirmation}
          labelPlacement={isModal ? "end" : undefined}
        />
      </Grid>
      <Grid item xs={isModal ? 6: 1}>
        <CheckBox
          {...commonInputProps}
          name={`settlementcontactsdenomination_set.${index}.check_anti_social`}
          rules={rules.settlementcontact.settlementcontactsdenomination_set.check_anti_social}
          disabled={props.disabled}
          label={isModal && labels.check_anti_social}
          labelPlacement={isModal ? "end" : undefined}
        />
      </Grid>
    </Grid>
  )
  const TitleForm = () => (
    <SelectForm
      {...commonInputProps}
      name={`settlementcontactsdenomination_set.${index}.type`}
      rules={rules.settlementcontact.settlementcontactsdenomination_set.type}
      choices={choices.settlement_contacts_denomination_types}
      disabled={props.disabled || isFirstSeller}
      setValue={setValue}
      label={`${labelNumber}. `}
      labelPosition="left"
    />
  )

  return (
    <Grid container direction="row" spacing={1} columns={isModal ? 12 : 22}>
      <HiddenInput {...commonInputProps} name={`settlementcontactsdenomination_set.${index}.labelNumber`} />
      <HiddenInput {...commonInputProps} name={`settlementcontactsdenomination_set.${index}.id`} />
      {isModal
        ?
          <Grid item xs={12}>
            <DialogSubContent
              title={<TitleForm/>}
              content={<Form/>}
            />
          </Grid>
        :
        <>
          <Grid item xs={2}>
            <TitleForm/>
          </Grid>
          <Grid item xs={20}>
            <Form/>
          </Grid>
        </>
      }
    </Grid>
  );
});

PaymentMethodForm.propTypes = {
  index: PropTypes.number,
  commonInputProps: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  labelNumber: PropTypes.number.isRequired,
  isFirstSeller: PropTypes.bool.isRequired,
  isFirstBroker: PropTypes.bool.isRequired,
  isFirstBusinessCommission: PropTypes.bool.isRequired,
  isFirstJudicialScrivener: PropTypes.bool.isRequired,
  isFirstLandowner: PropTypes.bool.isRequired,
};

PaymentMethodForm.defaultProps = {
  disabled: false,
};

const SettlementForm = React.memo(function settlementForm(props) {

  const { choices, commonInputProps, rules, setValue, contractContactSellerFields, contractContactLandownerFields, isModal } = props;

  const landownerLabels = React.useMemo(() => ({
    landowner: "地主(底地権者)",
    currentAddressAndRegistryMatched: "現住所と登記簿一致",
  }), [])

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12} container direction="row" spacing={1} alignItems="flex-end">
        <Grid item xs={8}>
          <DatePicker
            {...commonInputProps}
            label="決済日時(予定)"
            name="settlementcontact.settlement_date"
            rules={rules.settlementcontact.settlement_date}
            defaultValue={null}
            disabled={props.disabled}
          />
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            {...commonInputProps}
            name="settlementcontact.settlement_time"
            rules={rules.settlementcontact.settlement_time}
            type="time"
            defaultValue={null}
            disabled={props.disabled}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label="決済場所"
          name="settlementcontact.settlement_location"
          rules={rules.settlementcontact.settlement_location}
          disabled={props.disabled}
        />
      </Grid>

      {contractContactSellerFields.length > 0 && (
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
              <Typography>売主名</Typography>
            </Grid>
            {contractContactSellerFields.map((item, index) => {
              const circleNum = toCircled(index + 1);
              return (
                <Grid item xs={isModal ? 12 : 3} key={index}>
                  <TextField
                    {...commonInputProps}
                    name={`contractcontactseller_set.${index}.name`}
                    disabled={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {circleNum}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label="当社支払・仲介業者"
          name="companypayeebroker.name"
          disabled={true}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label="業務委託先"
          name="outsourcingcompany.name"
          disabled={true}
        />
      </Grid>

      <Grid item xs={8}>
        <TextField
          {...commonInputProps}
          label="司法書士"
          name="judicialscrivener.name"
          disabled={true}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectForm
          {...commonInputProps}
          label="反社チェック"
          name="judicialscrivener.trading_confirmation"
          choices={choices?.trading_confirmation}
          disabled={true}
          setValue={setValue}
        />
      </Grid>

      {contractContactLandownerFields.length > 0 && 
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1}>
            {!isModal &&
              <>
                <Grid item xs={9}>
                  <Typography>{landownerLabels.landowner}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{landownerLabels.currentAddressAndRegistryMatched}</Typography>
                </Grid>
              </>
            }

            {contractContactLandownerFields.map((item, index) => {
              const circleNum = toCircled(index + 1);
              return (
                <Grid item xs={12} container direction="row" spacing={1} key={index} alignItems={isModal ? "flex-end" : undefined}>
                  <HiddenInput
                    {...commonInputProps}
                    name={`contractcontactlandowner_set.${index}.id`}
                  />
                  <Grid item xs={isModal ? 6 : 2}>
                    <TextField
                      {...commonInputProps}
                      name={`contractcontactlandowner_set.${index}.name`}
                      disabled={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {circleNum}
                          </InputAdornment>
                        ),
                      }}
                      label={isModal && landownerLabels.landowner}
                    />
                  </Grid>
                  <Grid item xs={isModal ? 6 : 2}>
                    <TextField
                      {...commonInputProps}
                      name={`contractcontactlandowner_set.${index}.zipcode`}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={isModal ? 12 : 5}>
                    <TextField
                      {...commonInputProps}
                      name={`contractcontactlandowner_set.${index}.address`}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={isModal ? 12 : 3}>
                    <SelectForm
                      {...commonInputProps}
                      name={`contractcontactlandowner_set.${index}.comparison_of_current_address_and_registry`}
                      rules={
                        rules.contractcontactlandowner_set
                          .comparison_of_current_address_and_registry
                      }
                      choices={
                        choices?.comparison_of_current_address_and_registry
                      }
                      disabled={props.disabled}
                      setValue={setValue}
                      label={isModal && landownerLabels.currentAddressAndRegistryMatched}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      }
    </Grid>
  )
})
SettlementForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  contractContactSellerFields: PropTypes.array.isRequired,
  contractContactLandownerFields: PropTypes.array.isRequired,
  isModal: PropTypes.bool.isRequired,
};

const AreaForm = React.memo(function areaForm(props) {
  const { choices, commonInputProps, rules, setValue, isModal } = props;
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label="売買形態"
          name="contractterm.trade_form"
          disabled={true}
        />
      </Grid>

      <Grid item xs={12}>
        <CheckBox
          {...commonInputProps}
          name="settlementcontact.building_enabled_area_check"
          label="建築有効面積"
          rules={
            rules.settlementcontact.building_enabled_area_check
          }
          labelPlacement="start"
          sx={{ justifyContent: "flex-end", margin: 0 }}
          disabled={props.disabled}
        />
      </Grid>

      <Grid item xs={12} container direction="row">
        {isModal &&
          <Grid item xs={12} container direction="row" spacing={1}>
            <Grid item xs={4}>
              <FormLabel>
                確定時
              </FormLabel>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>
                契約時
              </FormLabel>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        }
        <Grid item xs={12} container direction="row" spacing={1}>
          <Grid item xs={4}>
            <TextField
              {...commonInputProps}
              name="settlementcontact.building_enabled_area"
              type="float"
              rules={rules.settlementcontact.building_enabled_area}
              label={isModal ? "-" : "確定時"}
              labelPosition={isModal ? "right" : "left"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">㎡</InputAdornment>
                ),
              }}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...commonInputProps}
              name="propertyoverview.building_enabled_area"
              type="float"
              disabled={true}
              label={!isModal && "- 契約時"}
              labelPosition="left"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">㎡</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...commonInputProps}
              name="settlementcontact.building_enabled_area_difference"
              type="float"
              label="="
              labelPosition="left"
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">㎡</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} container direction="row">
        <Grid item xs={12} container direction="row" spacing={1}>
          <Grid item xs={6}>
            <FormLabel>領収書発行</FormLabel>
          </Grid>
          <Grid item xs={6}>
            <FormLabel>金額</FormLabel>
          </Grid>
        </Grid>
        <Grid item xs={12} container direction="row" spacing={1}>
          <Grid item xs={6}>
            <Stack direction="row">
              <SelectForm
                {...commonInputProps}
                name="settlementcontact.receipt_issuance"
                rules={rules.settlementcontact.receipt_issuance}
                choices={choices?.necessary}
                disabled={props.disabled}
                setValue={setValue}
                label="→"
                labelPosition="right"
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...commonInputProps}
              name="settlementcontact.receipt_amount"
              type="number"
              disabled={props.disabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          spacing={1}
          alignItems="flex-end"
        >
          <Grid item xs={isModal ? 6 : 3}>
            <SelectForm
              {...commonInputProps}
              label="実測精算"
              name="settlementcontact.actual_reimbursement"
              rules={rules.settlementcontact.actual_reimbursement}
              choices={choices?.availability}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 3}>
            <TextField
              {...commonInputProps}
              label="増減面積"
              name="settlementcontact.building_enabled_area_difference"
              type="float"
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">㎡</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 3}>
            <TextField
              {...commonInputProps}
              label="増減金額"
              name="settlementcontact.increase_and_decrease_amount"
              type="number"
              rules={
                rules.settlementcontact.increase_and_decrease_amount
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 3}>
            <SelectForm
              {...commonInputProps}
              name="settlementcontact.increase_or_decrease"
              rules={rules.settlementcontact.increase_or_decrease}
              choices={choices?.increase_or_decrease}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          spacing={1}
          alignItems="flex-end"
        >
          <Grid item xs={isModal ? 12 : 4}>
            <SelectForm
              {...commonInputProps}
              label="契約後売価変更"
              name="settlementcontact.selling_price_change_after_contract"
              rules={
                rules.settlementcontact
                  .selling_price_change_after_contract
              }
              choices={choices?.availability}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <TextField
              {...commonInputProps}
              name="settlementcontact.selling_price_before_change"
              type="number"
              rules={
                rules.settlementcontact.selling_price_before_change
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
              disabled={props.disabled}
              label="→"
              labelPosition="right"
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <TextField
              {...commonInputProps}
              name="settlementcontact.selling_price_after_change"
              type="number"
              rules={
                rules.settlementcontact.selling_price_after_change
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
              disabled={props.disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})
AreaForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
};

const BreakdownForms = React.memo(function breakdownForms(props) {

  const { commonInputProps, rules, isModal } = props;

  const itemXs = isModal ? 12 : 6
  const data = [
    {
      title: "売主様にお支払いする分",
      items: <Grid container direction="row" spacing={1}>
        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            labelNumber="(1)"
            label="残代金"
            name="settlementcontact.remaining_price_amount"
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            disabled={props.disabled}
            isModal={isModal}
          />
        </Grid>
        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            label="手付金"
            name="settlementcontact.total_earnest"
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            disabled={props.disabled}
            isModal={isModal}
          />
        </Grid>
        {!isModal && <Grid item xs={6} />}

        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            label="中間金"
            name="settlementcontact.total_intermediate_money"
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            disabled={props.disabled}
            isModal={isModal}
          />
        </Grid>
        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            labelNumber="(2)"
            label="固都税"
            name="settlementcontact.seller_property_tax_and_city_planning_tax"
            rules={
              rules.settlementcontact
                .seller_property_tax_and_city_planning_tax
            }
            disabled={props.disabled}
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            isModal={isModal}
          />
        </Grid>
        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            label="買主負担期間"
            name="settlementcontact.vendee_burden_period"
            type="number"
            textFieldInputProps={{
              endAdornment: (
                <InputAdornment position="end">日間</InputAdornment>
              ),
            }}
            disabled={props.disabled}
            isModal={isModal}
          />
        </Grid>
        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            labelNumber="(3)"
            label="地代精算等"
            name="settlementcontact.seller_land_rent_settlement"
            rules={rules.settlementcontact.seller_land_rent_settlement}
            disabled={props.disabled}
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            isModal={isModal}
          />
        </Grid>
        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            label="地代負担日数"
            name="settlementcontact.seller_land_rent_burden_days"
            rules={rules.settlementcontact.seller_land_rent_burden_days}
            disabled={props.disabled}
            textFieldInputProps={{
              endAdornment: (
                <InputAdornment position="end">日間</InputAdornment>
              ),
            }}
            isModal={isModal}
          />
        </Grid>
        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            label="小計："
            name="settlementcontact.total_pay_to_seller"
            type="number"
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            disabled={props.disabled}
            isModal={isModal}
          />
        </Grid>
      </Grid>
    },
    {
      title: "仲介業者様にお支払いする分",
      items: <Grid container direction="row" spacing={1}>
        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            labelNumber="(1)"
            label="仲介手数料"
            name="settlementcontact.remaining_price_intermediary_charge_amount"
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            disabled={props.disabled}
            isModal={isModal}
          />
        </Grid>

        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            label="契約時支払額"
            name="settlementcontact.total_remaining_price_intermediary_charge"
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            disabled={props.disabled}
            isModal={isModal}
          />
        </Grid>

        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            labelNumber="(2)"
            label="その他"
            name="settlementcontact.broker_seller_other_payment_amount"
            rules={
              rules.settlementcontact.broker_seller_other_payment_amount
            }
            disabled={props.disabled}
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            isModal={isModal}
          />
        </Grid>
        {!isModal && <Grid item xs={6} />}

        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            label="小計："
            name="settlementcontact.total_pay_to_broker"
            type="number"
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            disabled={props.disabled}
            isModal={isModal}
          />
        </Grid>
      </Grid>
    },
    {
      title: "業務委託先業者様にお支払いする分",
      items: <Grid container direction="row" spacing={1}>
        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            labelNumber="(1)"
            label="業務委託料"
            name="settlementcontact.business_consignment_fee_amount"
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            rules={rules.settlementcontact.business_consignment_fee_amount}
            disabled={props.disabled}
            isModal={isModal}
          />
        </Grid>
        {!isModal && <Grid item xs={6} />}

        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            labelNumber="(2)"
            label="その他"
            name="settlementcontact.business_consignment_target_other_payment_amount"
            rules={
              rules.settlementcontact
                .business_consignment_target_other_payment_amount
            }
            disabled={props.disabled}
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            isModal={isModal}
          />
        </Grid>
        {!isModal && <Grid item xs={6} />}

        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            label="小計："
            name="settlementcontact.total_pay_to_service_consignment_destination"
            type="number"
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            disabled={props.disabled}
            isModal={isModal}
          />

        </Grid>
      </Grid>
    },
    {
      title: "司法書士様にお支払いする分",
      items: <Grid container direction="row" spacing={1}>
        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            labelNumber="(1)"
            label="登記費用"
            name="settlementcontact.judicial_scrivener_registration_cost"
            rules={
              rules.settlementcontact.judicial_scrivener_registration_cost
            }
            disabled={props.disabled}
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            isModal={isModal}
          />
        </Grid>
        {!isModal && <Grid item xs={6} />}
        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            labelNumber="(2)"
            label="その他"
            name="settlementcontact.judicial_scrivener_registration_other_cost"
            rules={
              rules.settlementcontact.judicial_scrivener_registration_other_cost
            }
            disabled={props.disabled}
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            isModal={isModal}
          />
        </Grid>
        {!isModal && <Grid item xs={6} />}

        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            label="小計："
            name="settlementcontact.total_pay_to_solicitor"
            type="number"
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            disabled={props.disabled}
            isModal={isModal}
          />
        </Grid>
      </Grid>
    },
    {
      title: "その他",
      items: <Grid container direction="row" spacing={1}>
        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            labelNumber="(1)"
            label={
              <TextField
                {...commonInputProps}
                name="settlementcontact.other_payment_name1"
                rules={rules.settlementcontact.other_payment_name1}
                label={isModal ? "(1)" : undefined}
                labelPosition="left"
              />
            }
            name="settlementcontact.other_payment_amount1"
            rules={rules.settlementcontact.other_payment_amount1}
            disabled={props.disabled}
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            isModal={isModal}
          />
        </Grid>
        {!isModal && <Grid item xs={6} />}

        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            labelNumber="(2)"
            label={
              <TextField
                {...commonInputProps}
                name="settlementcontact.other_payment_name2"
                rules={rules.settlementcontact.other_payment_name2}
                label={isModal ? "(2)" : undefined}
                labelPosition="left"
              />
            }
            name="settlementcontact.other_payment_amount2"
            rules={rules.settlementcontact.other_payment_amount2}
            disabled={props.disabled}
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            isModal={isModal}
          />
        </Grid>
        {!isModal && <Grid item xs={6} />}

        <Grid item xs={itemXs}>
          <BreakdownForm
            commonInputProps={commonInputProps}
            label="小計："
            name="settlementcontact.total_pay_to_landowner"
            type="number"
            textFieldInputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            disabled={props.disabled}
            isModal={isModal}
          />
        </Grid>
      </Grid>
    }
  ]
  return (
    <Grid container direction="row" spacing={1}>
      {data.map((item, index) => (
        <Grid item xs={12} key={index}>
          {isModal
          ?
            <DialogSubContent
              title={toCircled(index + 1) + item.title}
              content={item.items}
            />
          :
            <Grid container direction="row" spacing={1} key={index}>
              <Grid item xs={12}>
                <BreakdownLabel count={toCircled(index + 1)} label={item.title} />
              </Grid>
              <Grid item xs={12}>
                {item.items}
              </Grid>
            </Grid>
          }
        </Grid>
      ))}
        {isModal
        ?
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Divider/>
              <TextField
                {...commonInputProps}
                name="settlementcontact.total_pay"
                rules={rules}
                type="text"
                InputProps={{
                  style: { fontWeight: "bold" },
                  startAdornment: (
                    <InputAdornment position="start">￥</InputAdornment>
                  ),
                }}
                label="合計："
                labelSx={{fontWeight: "bold"}}
                formControlProps={{ sx: { px: 2 } }}
                labelPosition="left"
                disabled={true}
              />
            </Stack>
          </Grid>
        :
          <Grid item xs={ 6}>
            <BreakdownForm
              commonInputProps={commonInputProps}
              label="合計："
              labelVariant="subtitle1"
              textFieldInputProps={{
                style: { fontWeight: "bold" },
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
              name="settlementcontact.total_pay"
              disabled={props.disabled}
              isModal={isModal}
            />
          </Grid>
        }
    </Grid>
  )
})
BreakdownForms.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
};

const PaymentMethodForms = React.memo(function paymentMethodForms(props) {
  const { commonInputProps, rules, choices, setValue, isModal, settlementContactsDenominationFields, settlementContactTransferTargetsFields, onBlurForSettlementContactTransferTarget } = props;

  const labels = React.useMemo(() => ({
    payee: "支払先",
    amount: "金額",
    payment_methods: "金種",
    transfer_name: "振込名義",
    exists_confirmation: "確認書",
    check_anti_social: "反社",
  }), [])

  return (
    <Stack>
      {!isModal &&
        <Grid container direction="row" spacing={1} columns={22}>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Typography align="center">{labels.payee}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="center">{labels.amount}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center">{labels.payment_methods}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center">{labels.transfer_name}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography align="center">{labels.exists_confirmation}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography align="center">{labels.check_anti_social}</Typography>
          </Grid>
        </Grid>
      }

      {settlementContactsDenominationFields.map((item, index) => {
        return (
          <PaymentMethodForm
            commonInputProps={commonInputProps}
            rules={rules}
            choices={choices}
            key={index}
            labelNumber={item.labelNumber}
            isFirstSeller={item.is_first_seller}
            isFirstBroker={item.is_first_broker}
            isFirstBusinessCommission={item.is_first_business_commission}
            isFirstJudicialScrivener={item.is_first_judicial_scrivener}
            isFirstLandowner={item.is_first_landowner}
            index={index}
            disabled={props.disabled}
            setValue={setValue}
            labels={labels}
            isModal={isModal}
          />
        )
      })}

      <Grid container direction="row" spacing={1} columns={22}>
        {isModal
          ?
            <Grid item xs={22}>
              <Stack spacing={2}>
                <Divider/>
                <TextField
                  {...commonInputProps}
                  name="settlementcontact.total_pay_denomination"
                  type="number"
                  disabled={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">￥</InputAdornment>
                    ),
                  }}
                  label="合計："
                  labelPosition="left"
                  formControlProps={{ sx: { px: 2 } }}
                  labelSx={{fontWeight: "bold"}}
                />
                <Divider/>
              </Stack>
            </Grid>
          :
            <>
              <Grid item xs={2} />
              <Grid item xs={4}>
                <Stack direction="row-reverse" alignItems="center" sx={{height: "100%"}}>
                  <Typography variant="subtitle1" align="center">
                    合計：
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...commonInputProps}
                name="settlementcontact.total_pay_denomination"
                  type="number"
                  disabled={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">￥</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
        }
      </Grid>

      <Grid container direction="row" spacing={1} sx={isModal ? {mt: 1, px: 2} : {mt: 1}}>
        {settlementContactTransferTargetsFields.map(
          (target, index) => {
            return (
              <Grid
                item
                xs={12}
                key={index}
                container
                direction="row"
                spacing={1}
                justifyContent="center"
              >
                {!isModal &&
                  <Grid item xs="auto">
                    <Typography>
                      → 同じ振込先が複数{" "}
                    </Typography>
                  </Grid>
                }
                <Grid item xs={isModal ? 12 : "auto"}>
                  <SelectForm
                    {...commonInputProps}
                    name={`settlementcontact.transfer_targets.${index}.same_transfer_target`}
                    rules={
                      rules.settlementcontact.transfer_targets
                    }
                    choices={choices?.availability}
                    setValue={setValue}
                    label={isModal && "→ 同じ振込先が複数 "}
                    labelPosition={isModal ? "left" : undefined}
                  />
                </Grid>
                {!isModal &&
                  <Grid item xs={1}>
                    <Typography>(金種)番号</Typography>
                  </Grid>
                }
                <Grid item xs={isModal ? 12 : "auto"} sx={{ display: "flex" }}>
                  <TransferTargets
                    commonInputProps={commonInputProps}
                    index={index}
                    data={target.transfer_data}
                    checkedOnBlur={
                      onBlurForSettlementContactTransferTarget
                    }
                    label={isModal && "(金種)番号"}
                  />
                </Grid>
                <Grid item xs={isModal ? 12 : 2} sx={isModal ? {} : { ml: 3 }}>
                  <SelectForm
                    {...commonInputProps}
                    name={`settlementcontact.transfer_targets.${index}.transfer_type`}
                    rules={
                      rules.settlementcontact.transfer_targets
                    }
                    choices={choices?.transfer_targets}
                    setValue={setValue}
                  />
                </Grid>
              </Grid>
            );
          }
        )}
      </Grid>
    </Stack>
  )
})
PaymentMethodForms.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  settlementContactsDenominationFields: PropTypes.array.isRequired,
  settlementContactTransferTargetsFields: PropTypes.array.isRequired,
  onBlurForSettlementContactTransferTarget: PropTypes.func.isRequired,
};