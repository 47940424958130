import { Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TextField } from "../atoms/TextField";
import { XText } from "../atoms/XText";
import { DialogSubContent } from "./DialogSubContent";

export const CostBuildingRelatedCostForm = (props) => {

  const getContent = () => {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item xs={props.isModal ? 12 : 4}>
          <TextField {...props.amountParams} />
        </Grid>
        <Grid item xs={props.isModal ? 6 : 4}>
          <TextField {...props.unitPriceParams} />
        </Grid>
        <Grid item xs={props.isModal ? 6 : 4}>
          <Grid
            container
            direction="row"
            spacing={1}
          >
            <Grid item xs={2}>
              <XText/>
            </Grid>
            <Grid item xs={10}>
              <TextField {...props.quantityParams} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return (
    <>
      {props.isModal
        ?
          <DialogSubContent
            title={
              <Stack direction="row" spacing={2}>
                {props.number && <Typography>{`(${props.number})`}</Typography> }
                <Typography>
                  {props.title}
                </Typography>
              </Stack>
            }
            content={getContent()}
          />
        :
          <>
            <Grid item xs={1}>
              <Typography>
                {props.number && `(${props.number})`}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {getContent()}
            </Grid>
          </>
      }
    </>
  );
};

CostBuildingRelatedCostForm.propTypes = {
  number: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  amountParams: PropTypes.object.isRequired,
  unitPriceParams: PropTypes.object.isRequired,
  quantityParams: PropTypes.object.isRequired,
  isModal: PropTypes.bool.isRequired,
}