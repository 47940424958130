import * as React from "react";
import PropTypes from "prop-types";
import { Dialog } from "../molecules/Dialog";
import { Button } from "../atoms/Button";
import { Snackbar } from "../atoms/Snackbar";
import { useForm } from "react-hook-form";
import {
  Box,
  Stack,
  FormHelperText,
  Portal,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { TextField } from "../atoms/TextField";
import { constructionCooperationConfirm } from "../../api/Properties";
import { constructionCooperation } from "../../utils/PropertyEdit";
import * as validationRules from "../../utils/ValidationRules";


export const ConstructionCooperationDialog = ({ property_id, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [autoHideDuration, setAutoHideDuration] = React.useState(undefined);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isInitializing, setIsInitializing] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);

  const {
    control,
    handleSubmit,
    setValue,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange",
  });

  React.useEffect(() => {
    if (isInitializing) {
      setMessage("読み込み中")
      setSeverity("info")
      setAutoHideDuration(null)
    } else {
      setMessage("")
      setAutoHideDuration(undefined)
    }
  }, [isInitializing])

  React.useEffect(() => {
    if (open) {
      setIsInitializing(true)
      constructionCooperationConfirm(property_id)
        .then((response) => {
          setValue("body", response.data.replaced_body)
          setValue("subject", response.data.replaced_subject)
        })
        .catch((error) => {
          setErrorMessage(error.response.data?.error_message ?? "エラーが発生しました")
        })
        .finally(() => {
          setIsInitializing(false)
        })
    } else if (!open) {
      setErrorMessage("")
      setValue("body", "")
      setValue("subject", "")
    }
  }, [open]);

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onClose = () => {
    props.onClose();
  };

  const submitAction = (data) => {
    setIsSubmitting(true);
    const successCallBack = () => {
      setMessage("成功しました");
      setSeverity("success");
      onClose();
    }
    const errorCallBack = () => {
      setMessage("失敗しました");
      setSeverity("error");
    }
    const finallyCallBack = () => {
      setIsSubmitting(false)
    }
    constructionCooperation(property_id, data, successCallBack, errorCallBack, finallyCallBack)
  };

  return (
    <>
      <Portal>
        <Snackbar
          closeAction={() => {
            setMessage("");
          }}
          message={message}
          severity={severity}
          autoHideDuration={autoHideDuration}
        />
      </Portal>
      <Dialog
        isOpen={open}
        title="通知用メール設定"
        handleClose={onClose}
        content={
          <>
            <Box>
              <Stack spacing={2}>
                <FormHelperText error >
                  {errorMessage}
                </FormHelperText>
                <TextField
                  multiline
                  label="件名"
                  control={control}
                  name="subject"
                  disabled={isInitializing || isSubmitting}
                  rules={{ ...validationRules.required() }}
                />
                <TextField
                  label="本文"
                  control={control}
                  name="body"
                  disabled={isInitializing || isSubmitting}
                  multiline
                  rows={20}
                  inputProps={{ sx: { textWrap: "nowrap" } }}
                  rules={{ ...validationRules.required() }}
                />
              </Stack>
            </Box>
          </>
        }
        actions={
          <>
            <Button color={isMobile ? "gray" : "white"} onClick={onClose} disabled={isSubmitting || isInitializing} fullSize={isMobile}>
              キャンセル
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(submitAction)}
              disabled={isSubmitting || isInitializing || errorMessage !== ""}
              fullSize={isMobile}
              isLoading={isSubmitting}
            >
              連携
            </Button>
          </>
        }
      />
    </>
  );
};

ConstructionCooperationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  property_id: PropTypes.number.isRequired,
};

ConstructionCooperationDialog.defaultProps = {
}