import React from "react";
import { getAdminFormulas } from "../../../api/Admin";
import { ApiErrorHandler } from "../../../api/ApiErrorHandler";
import { TableToolbar } from "../../molecules/TableToolbar";
import { AdminListLayout } from "../../templates/AdminListLayout";
import { FrontendUrls } from "../../Urls";

export const Formulas = () => {
  const [user, setUser] = React.useState({});
  const [page, setPage] = React.useReducer((state, newState) => newState, 1);
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([10]);
  const [itemCount, setItemCount] = React.useState(0);
  const [lastPage, setLastPage] = React.useState(1);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [forceUseEffect, setForceUseEffect] = React.useState(0);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (newOrderBy, newOrder) => {
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  };
  const headCells = [
    {
      id: "key",
      label: "キー",
      link: FrontendUrls.adminFormulasByKey,
      link_params: [
        "key",
      ],
    },
    {
      id: "name",
      label: "名前",
    },
    {
      id: "created_user",
      label: "作成者",
    },
  ];

  const callUseEffect = () => {
    setForceUseEffect(forceUseEffect + 1);
  };
  const dataReload = () => {
    callUseEffect()
  }
  const handleChangeRowsPerPage = (page_size) => {
    setRowsPerPage(page_size);
    setPage(1);
  };

  React.useEffect(() => {
    let ordering = "-id";
    if (order && orderBy) {
      if (order == "asc") {
        ordering = orderBy;
      } else if (order == "desc") {
        ordering = "-" + orderBy;
      }
    }
    const params = {
      page: page,
      page_size: rowsPerPage,
      ordering: ordering,
    };
    setLoadingOpen(true);
    getAdminFormulas(params)
      .then((response) => {
        setUser(response.data.user);
        const rowData = []
        response.data.results.map((item) => {
          rowData.push({
            id: item.id,
            name: item.name,
            key: item.key,
            created_user: item.created_user?.username ?? "",
          })
        })
        setRowData(rowData)
        setRowsPerPage(response.data.page_size);
        setRowsPerPageOptions(response.data.page_sizes);
        setLastPage(response.data.last_page);
        setItemCount(response.data.count);
      })
      .catch((error) => {
        ApiErrorHandler(error);
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  }, [page, rowsPerPage, order, orderBy, forceUseEffect]);

  return (
    <AdminListLayout
      pageTitle="計算式一覧"
      user={user}
      page={page}
      lastPage={lastPage}
      loadingOpen={loadingOpen}
      headCells={headCells}
      rows={rowData}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      itemCount={itemCount}
      order={order}
      orderBy={orderBy}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      dataReload={dataReload}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      tableToolbar={
        <TableToolbar title="計算式一覧">
        </TableToolbar>
      }
    />
  );
};