import * as React from "react";
import PropTypes from "prop-types";
import { TextField } from "./TextField";

export const HiddenInput = React.forwardRef((props, ref) => {
  // const { sx, ...hiddenProps } = props;
  // sx.display = "none";
  return <TextField hidden={true} {...props} ref={ref} />;
});

HiddenInput.displayName = "HiddenInput";
HiddenInput.propTypes = {
  // sx: PropTypes.object,
  formControlProps: PropTypes.object,
}

HiddenInput.defaultProps = {
  // sx: {},
  formControlProps: {}
};
