import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from "prop-types";
import { CustomHelmetProvider } from "../organisms/CustomHelmetProvider";
import { CustomThemeProvider } from "../organisms/CustomThemeProvider";
import { Header } from "../organisms/Header";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { UserContext, ViewportContext } from "../../Context";
import { Container } from "../atoms/Container";
import { PropertyEditToolbar } from "../organisms/PropertyEditToolbar";
import { Breadcrumbs } from "../atoms/Breadcrumbs";
import { Snackbar } from "../atoms/Snackbar";
import { Box } from "@mui/system";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { PropertyEditStatus } from "../molecules/PropertyEditStatus";
import { AppBar, Link, Stack, Toolbar, Typography } from "@mui/material";
import { LeftArrowIcon } from "../atoms/LeftArrowIcon";
import { FrontendUrls } from "../Urls";
import { PropertyEditMenus } from "../molecules/PropertyEditMenus";

export const PropertyEditLayout = React.memo(function propertyEditLayout({property, choices, ...props }) {

  return (
    <UserContext.Provider value={props.user}>
      <ViewportContext.Provider value={props.viewport}>
        <CustomThemeProvider>
          <CustomHelmetProvider pageTitle={props.pageTitle} viewport={props.viewport}>
            <CssBaseline />
            <LoadingProgress open={props.loadingOpen} />
            <Snackbar
              closeAction={() => {
                props.snackbarCloseAction()
              }}
              message={props.snackbarMessage}
              severity={props.severity}
            />

              <MobileView>
                <Box sx={{ flexGrow: 1 }}>
                  <AppBar color="white">
                    <Box sx={{backgroundColor: props.isEdit ? "brown.main" : "gray2.main"}}>
                      <Typography textAlign="center" justifyContent="center" sx={{color: "white.main"}}>
                        {props.isEdit ? "編集中" : "閲覧中"}
                      </Typography>
                    </Box>
                    <Toolbar>
                      <Link variant="text" href={FrontendUrls.properties} sx={{mr: 2}}>
                        <LeftArrowIcon color="blue2"/>
                      </Link>
                      <PropertyEditStatus
                        property={property}
                        choices={choices}
                        pageChangedAction={props.pageChangedAction}
                        apiErrorAction={props.apiErrorAction}
                        showEditInfo
                      />
                    </Toolbar>
                  </AppBar>
                  <Toolbar />
                </Box>
              </MobileView>

              <BrowserView>
                <Header
                  cost_price={property?.businessbalance?.cost_price}
                  gross_profit_per_building={property?.businessbalance?.gross_profit_per_building}
                  showEditInfo
                />
              </BrowserView>

            <Container maxWidth={false} sx={{mt: 3, pt: 1}}>
              <BrowserView>
                <PropertyEditToolbar
                  property={property}
                  choices={choices}
                  pageChangedAction={props.pageChangedAction}
                  apiErrorAction={props.apiErrorAction}
                />
              </BrowserView>
              <Box sx={{pt: isMobile ? 0 : 4, px: 1}}>
                <Stack direction="row" justifyContent="space-between">
                  <Breadcrumbs items={props.pages}/>
                  <MobileView>
                    <PropertyEditMenus
                      property={property}
                      choices={choices}
                      toggleViewport={props.toggleViewport}
                    />
                  </MobileView>
                </Stack>
              </Box>
              <Box sx={{py: 4, px: 1}}>
                {props.children}
              </Box>
            </Container>
          </CustomHelmetProvider>
        </CustomThemeProvider>
      </ViewportContext.Provider>
    </UserContext.Provider>
  );
});

PropertyEditLayout.propTypes = {
  user: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  property: PropTypes.object.isRequired,
  loadingOpen: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.any,
  choices: PropTypes.object.isRequired,
  pageChangedAction: PropTypes.func,
  snackbarMessage: PropTypes.string,
  severity: PropTypes.string,
  snackbarCloseAction: PropTypes.func,
  apiErrorAction: PropTypes.func,
  isEdit: PropTypes.bool.isRequired,
  viewport: PropTypes.string.isRequired,
  toggleViewport: PropTypes.func.isRequired,
};

PropertyEditLayout.defaultProps = {
  user: {},
  property: {},
  loadingOpen: false,
  pageTitle: "",
  choices: {},
  statusUpdatedAction: () => {},
  severity: "error",
  snackbarCloseAction: () => {},
  snackbarMessage: "",
};
