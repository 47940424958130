import { backendClient } from "./Client";
import { BackendUrls } from "./Urls";

export const getUserShares = (params) => {
  return backendClient().get(BackendUrls.userShare, { params });
};

export const saveUserShares = (params) => {
  return backendClient().post(BackendUrls.userShareSave, params);
};
