import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from "prop-types";
import { Container } from "../atoms/Container";
import { CustomHelmetProvider } from "../organisms/CustomHelmetProvider";
import { CustomThemeProvider } from "../organisms/CustomThemeProvider";
import { Header } from "../organisms/Header";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { UserContext } from "../../Context";
import { Stack } from "@mui/material";
import { isBrowser } from "react-device-detect";

export const AdminLayout = (props) => {

  return (
    <UserContext.Provider value={props.user}>
      <CustomThemeProvider>
        <CustomHelmetProvider pageTitle={props.pageTitle}>
          <CssBaseline />
          <Header/>
          <LoadingProgress open={props.loadingOpen} />
          <Container sx={isBrowser ? {mt: 1, p:0} : {m:0, p:0}}>
            <Stack spacing={1} direction="row">
              <Container sx={{p: 0, m: 0}}>
                {props.user.is_accessible_admin && props.children}
              </Container>
            </Stack>
          </Container>
        </CustomHelmetProvider>
      </CustomThemeProvider>
    </UserContext.Provider>
  );
};

AdminLayout.propTypes = {
  user: PropTypes.object.isRequired,
  loadingOpen: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.any,
  selectedMenuId: PropTypes.number,
};
