import * as React from "react";
import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableCell from "../atoms/TableCell";
import { Stack, TableHead, Typography } from "@mui/material";
import { CheckBox } from "../atoms/CheckBox";
import { DownArrowIcon } from "../atoms/DownArrowIcon";
import { UpArrowIcon } from "../atoms/UpArrowIcon";

export function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    headCells,
    control,
  } = props;
  const createSortHandler = (orderBy, order) => (event) => {
    onRequestSort(event, orderBy, order);
  };

  const rules = {
    header_row: {
      onChange: onSelectAllClick
    }
  }

  return (
    <TableHead>
      <TableRow>
        {props.includeCheckBox &&
          <TableCell padding="checkbox">
            <CheckBox
              control={control}
              color="primary"
              name="header_row"
              rules={rules.header_row}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        }
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center" py={1} spacing={1}>
              <Typography variant="subtitle1" noWrap>
                {headCell.label}
              </Typography>
              <Stack>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction="asc"
                  onClick={createSortHandler(headCell.id, "asc")}
                  IconComponent={() => <UpArrowIcon sx={{fontSize: "0.7rem"}} />}
                />
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction="desc"
                  onClick={createSortHandler(headCell.id, "desc")}
                  IconComponent={() => <DownArrowIcon sx={{fontSize: "0.7rem"}}/>}
                />
              </Stack>
            </Stack>
          </TableCell>
        ))}
        {props.tableButtons.map((item, index) => {
          return (
            <TableCell key={index} padding="none" />
          )
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
  tableButtons: PropTypes.array,
  control: PropTypes.object.isRequired,
  includeCheckBox: PropTypes.bool,
};

EnhancedTableHead.defaultProps = {
  tableButtons: [],
};