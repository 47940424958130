export const setNested = (path, value, obj, separeter=".") => {
  let schema = obj;
  const pathSplited = path.split(separeter);
  let len = pathSplited.length;
  for (let i = 0; i < len - 1; i++) {
    var elem = pathSplited[i];
    if (!schema[elem]) {
      schema[elem] = {}
    }
    schema = schema[elem];
  }

  schema[pathSplited[len - 1]] = value;
  return obj
}

export const getNested = (data, keys) => {
  return keys.reduce((current, key) => {
    return current?.[key]
  }, data)
}

export const objectFilter = (obj, filterFunc) => {
  const filtered = Object.keys(obj).filter(filterFunc)
  if (!filtered.length) {
    return []
  }

  let result = {}
  filtered.forEach((value) => {
    result[value] = obj[value]
  })
  return result
}