import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Grid, InputAdornment, Paper, Typography } from "@mui/material";
import { SelectForm } from "../atoms/SelectForm";
import { DatePicker } from "../atoms/DatePicker";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { isMobile } from "react-device-detect";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitOutsourcingReport, useInitOutsourcingReportPaymentInformation } from "../../utils/CustomHook";
import { onBlurCommonAction, saveChildForPropertyEdit } from "../../utils/PropertyEdit";

export const OutsourcingReportForms = React.memo(
  function outsourcingReportForms({
    property,
    initialized,
    outsourcingreport,
    setOriginPropertyData,
    toggleViewport,
    formPaperVariant,
    formPaperElevation,
    ...props
  }) {
    const [isLoading, setIsLoading] = React.useState(false)
    const loadingComplete = React.useCallback(() => {
      setIsLoading(false)
    }, [])

    const [choices, setChoices] = React.useState({});
    const [isOpenOutsourcingForm, setIsOpenOutsourcingForm] = React.useState(false)
    const [isOpenReasonForm, setIsOpenReasonForm] = React.useState(false)
    const [propertyId, setPropertyId] = React.useState(null)

    const [isFocused, setFocusInputName] = useFocusInput()
    const handleFocus = React.useCallback((name) => {
      setFocusInputName(name)
    }, [])

    const {
      control,
      formState: { errors },
      setValue,
      methods,
      setError,
      getValues,
      clearErrors,
      trigger,
    } = useForm({
      shouldFocusError: false,
      criteriaMode: "all",
      defaultValues: "",
      mode: "onChange",
    });

    useInitOutsourcingReport(outsourcingreport, setValue, isFocused)
    const outsourcingReportPaymentInformationFields = useInitOutsourcingReportPaymentInformation(outsourcingreport.outsourcingreportpaymentinformation_set, setValue, isFocused, control, trigger)

    React.useEffect(() => {
      setChoices(props.choices);
    }, [props.choices]);

    React.useEffect(() => {
      if (!(property && outsourcingreport)) {
        return;
      }
      setPropertyId(property.id)
      if (!isFocused("property.owner_user_name")) {
        setValue("property.owner_user_name", property.owner_user_name, { shouldValidate: true });
      }
      initialized();
    }, [property, outsourcingreport]);

    const saveCommonParams = React.useMemo(() => [
      errors, propertyId, clearErrors, setOriginPropertyData, setError
    ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

    const onBlurForOutsourcingReport = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "property", "outsourcingreport",
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
    }, [propertyId]);

    const onBlurForOutsourcingReportPaymentInformation = React.useCallback((e) => {
      onBlurCommonAction(setFocusInputName)
      const saveChildKeys = [
        "outsourcing_report", "outsourcingreport", "outsourcingreportpaymentinformation_set"
      ]
      saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
    }, [propertyId]);

    const rules = React.useMemo(() => ({
      outsourcingreport: {
        department: {
          onBlur: onBlurForOutsourcingReport,
        },
        outsourcing_contract_background: {
          onBlur: onBlurForOutsourcingReport,
        },
        outsourcing_contract_background_body: {
          onBlur: onBlurForOutsourcingReport,
        },
        outsourcingreportpaymentinformation_set: {
          date_of_payment: {
            onBlur: onBlurForOutsourcingReportPaymentInformation,
          },
          payment_amount: {
            onBlur: onBlurForOutsourcingReportPaymentInformation,
          },
        },
      },
    }), [onBlurForOutsourcingReport, onBlurForOutsourcingReportPaymentInformation]);

    const propertyEditFormDialogCloseAction = React.useCallback(() => {
      toggleViewport()
    }, [])

    const formAreaClickedAction = React.useCallback((additionalProcess) => {
      if (!isMobile) {
        return
      }
      if (additionalProcess) {
        additionalProcess()
      }
      setIsLoading(true)
      toggleViewport()
    }, [])

    const commonInputProps = React.useMemo(() => ({
      control: control,
      onFocus: handleFocus,
    }), [control, handleFocus])

    return (
      <PropertyEditForms title="業務委託報告書">
        <LoadingProgress open={isLoading} useCircular={false} />
        <FormProvider {...methods}>
          <PropertyEditFormDialog
            isOpen={isOpenOutsourcingForm}
            handleClose={() => {
              setIsOpenOutsourcingForm(false)
              propertyEditFormDialogCloseAction()
            }}
            title="業務委託報告書"
            openedAction={loadingComplete}
            content={
              <OutsourcingReportForm
                commonInputProps={commonInputProps}
                choices={choices}
                setValue={setValue}
                rules={rules}
                outsourcingReportPaymentInformationFields={outsourcingReportPaymentInformationFields}
                disabled={props.disabled}
              />
            }
          />
          <PropertyEditFormDialog
            isOpen={isOpenReasonForm}
            handleClose={() => {
              setIsOpenReasonForm(false)
              propertyEditFormDialogCloseAction()
            }}
            title="業務委託契約締結の経緯・理由"
            openedAction={loadingComplete}
            content={
              <ReasonForm
                commonInputProps={commonInputProps}
                choices={choices}
                setValue={setValue}
                rules={rules}
                disabled={props.disabled}
              />
            }
          />
          <form>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <Paper
                  elevation={formPaperElevation}
                  variant={formPaperVariant}
                  onClick={() => formAreaClickedAction(() => setIsOpenOutsourcingForm(true))}
                >
                  <OutsourcingReportForm
                    commonInputProps={commonInputProps}
                    choices={choices}
                    setValue={setValue}
                    rules={rules}
                    outsourcingReportPaymentInformationFields={outsourcingReportPaymentInformationFields}
                    disabled={props.disabled}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper
                  elevation={formPaperElevation}
                  variant={formPaperVariant}
                  onClick={() => formAreaClickedAction(() => setIsOpenReasonForm(true))}
                >
                  <ReasonForm
                    commonInputProps={commonInputProps}
                    choices={choices}
                    setValue={setValue}
                    rules={rules}
                    disabled={props.disabled}
                  />
                </Paper>
              </Grid>

            </Grid>
          </form>
        </FormProvider>
      </PropertyEditForms>
    );
  }
);

OutsourcingReportForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  outsourcingreport: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

OutsourcingReportForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const OutsourcingReportForm = React.memo(function outsourcingReportForm(props) {
  const {commonInputProps, choices, setValue, rules, outsourcingReportPaymentInformationFields} = props
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12} md={8}>
        <SelectForm
          {...commonInputProps}
          label="所属部署"
          name="outsourcingreport.department"
          rules={rules.outsourcingreport.department}
          choices={choices?.department}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          {...commonInputProps}
          label="担当者"
          name="outsourcingreport.business_representative"
          disabled={true}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DatePicker
          {...commonInputProps}
          label="契約日"
          name="outsourcingreport.contract_at"
          disabled={true}
          defaultValue={null}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          {...commonInputProps}
          label="決済日"
          name="outsourcingreport.settlement_at"
          disabled={true}
          defaultValue={null}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          {...commonInputProps}
          label="売買代金"
          name="outsourcingreport.trade_price"
          type="number"
          disabled={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          {...commonInputProps}
          label="仲介手数料"
          name="outsourcingreport.purchase_intermediary_charge"
          type="number"
          disabled={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label="業務委託先"
          name="outsourcingreport.outsourcing_company_name"
          disabled={true}
        />
      </Grid>

      {outsourcingReportPaymentInformationFields.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Grid item xs={12} md={6}>
              <DatePicker
                {...commonInputProps}
                label={`支払日${index + 1}`}
                name={`outsourcingreport.outsourcingreportpaymentinformation_set.${index}.date_of_payment`}
                rules={
                  rules.outsourcingreport
                    .outsourcingreportpaymentinformation_set
                    .date_of_payment
                }
                defaultValue={null}
                disabled={props.disabled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...commonInputProps}
                label={`支払額${index + 1}`}
                name={`outsourcingreport.outsourcingreportpaymentinformation_set.${index}.payment_amount`}
                rules={
                  rules.outsourcingreport
                    .outsourcingreportpaymentinformation_set
                    .payment_amount
                }
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">￥</InputAdornment>
                  ),
                }}
                disabled={props.disabled}
              />
            </Grid>
          </React.Fragment>
        );
      })}

      <Grid item xs={12} md={6}>
        <TextField
          {...commonInputProps}
          label="売買価格に対する割合"
          name="outsourcingreport.rate_for_trade_price"
          type="number"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">%</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          {...commonInputProps}
          label="合計"
          name="outsourcingreport.total_amount"
          type="number"
          disabled={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
})
OutsourcingReportForm.propTypes = {
  outsourcingReportPaymentInformationFields: PropTypes.array.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
}

const ReasonForm = React.memo(function reasonForm(props) {
  const {commonInputProps, choices, setValue, rules} = props
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          業務委託契約締結の経緯・理由
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <SelectForm
          {...commonInputProps}
          name="outsourcingreport.outsourcing_contract_background"
          rules={
            rules.outsourcingreport
              .outsourcing_contract_background
          }
          choices={choices?.outsourcing_contract_background}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          name="outsourcingreport.outsourcing_contract_background_body"
          rules={ rules.outsourcingreport.outsourcing_contract_background_body }
          multiline
          rows={5}
          disabled={props.disabled}
        />
      </Grid>
    </Grid>
  )
})
ReasonForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
}