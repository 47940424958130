import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Divider, Grid, InputAdornment, Paper, Stack, Typography } from "@mui/material";
import { DatePicker } from "../atoms/DatePicker";
import { FormControl } from "../atoms/FormControl";
import { FormLabel } from "../atoms/FormLabel";
import { AddButton } from "../atoms/AddButton";
import { DeleteButton } from "../atoms/DeleteButton";
import { HiddenInput } from "../atoms/HiddenInput";
import { SelectForm } from "../atoms/SelectForm";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { isMobile } from "react-device-detect";
import { DialogSubContent } from "../molecules/DialogSubContent";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitCompanyPayeeBroker, useInitContractContact, useInitContractContactLandowner, useInitContractContactSeller, useInitJudicialScrivener, useInitOtherBroker, useInitOutsourcingCompany, useCompanyPayeeBrokerName } from "../../utils/CustomHook";
import { onBlurCommonAction, removeChild, saveChildForPropertyEdit } from "../../utils/PropertyEdit";
import { getAddress } from "../../api/Properties";
import * as validationRules from "../../utils/ValidationRules";
import * as numberUtils from "../../utils/NumberUtils";

export const ContractContactForms = React.memo(function contractContactForms({
  property,
  initialized,
  contractcontact,
  contractcontactseller_set,
  contractcontactlandowner_set,
  companypayeebroker,
  otherbroker,
  outsourcingcompany,
  judicialscrivener,
  setOriginPropertyData,
  toggleViewport,
  formPaperVariant,
  formPaperElevation,
  ...props
}) {

  const [isLoading, setIsLoading] = React.useState(false)
  const loadingComplete = React.useCallback(() => {
    setIsLoading(false)
  }, [])

  const [isOpenContractModal, setIsOpenContractModal] = React.useState(false)
  const [isOpenSellerModal, setIsOpenSellerModal] = React.useState(false)
  const [isOpenLandOwnerModal, setIsOpenLandOwnerModal] = React.useState(false)
  const [isFormerSellerEtcFormModal, setFormerSellerEtcFormModal] = React.useState(false)
  const [isOpenRelatedVendorModal, setIsOpenRelatedVendorModal] = React.useState(false)

  const [choices, setChoices] = React.useState({});

  const [propertyId, setPropertyId] = React.useState(null)

  const [isFocused, setFocusInputName] = useFocusInput()

  const [addresses, setAddresses] = React.useState({})
  const getAddressAndZipcodeNames = React.useCallback((index) => {
    const indexText = (index !== null && index !== "" && !isNaN(Number(index))) ? "." + index : ""
    return [
      {
        address: "contractcontact.former_seller_address",
        zipcode: "contractcontact.former_seller_zipcode",
      },
      {
        address: "contractcontact.agent_or_guardian_address",
        zipcode: "contractcontact.agent_or_guardian_zipcode",
      },
      {
        address: `contractcontactseller_set${indexText}.address`,
        zipcode: `contractcontactseller_set${indexText}.zipcode`,
      },
      {
        address: `contractcontactlandowner_set${indexText}.address`,
        zipcode: `contractcontactlandowner_set${indexText}.zipcode`,
      },
      {
        address: `companypayeebroker.address`,
        zipcode: `companypayeebroker.zipcode`,
      },
      {
        address: `otherbroker.address`,
        zipcode: `otherbroker.zipcode`,
      },
      {
        address: `outsourcingcompany.address`,
        zipcode: `outsourcingcompany.zipcode`,
      },
      {
        address: `judicialscrivener.address`,
        zipcode: `judicialscrivener.zipcode`,
      },
    ]
  }, [])

  const [isAddressLoading, setIsAddressLoading] = React.useState(false)
  const setAddressesIfNeeded = React.useCallback((name) => {
    const index = name.split(".")[1]
    const zipcodeAndAddressName = getAddressAndZipcodeNames(index).find(x => x.address === name)
    const zipcodeName = zipcodeAndAddressName?.zipcode
    if (!zipcodeName) {
      return
    }
    const zipcodeNum = numberUtils.extractingNumber(getValues(zipcodeName))
    if (typeof zipcodeNum !== "number" || zipcodeNum.toString().length !== 7) {
      return
    }
    const addressesKey = zipcodeAndAddressName.address
    if (addresses[addressesKey]?.zipcode === zipcodeNum) {
      return
    }
    setIsAddressLoading(true)
    getAddress(zipcodeNum)
      .then((response) => {
        const newData = response.data.map(x => (x.prefecture_name ?? "") + (x.municipality_name ?? "") + (x.section_of_village_name ?? "") + (x.street_name ?? ""))
        const newUniqueData = [...new Set(newData)].map(x => ({ label: x, value: x }))
        const newAddress = {
          ...addresses,
          [addressesKey]: {
            data: newUniqueData,
            zipcode: zipcodeNum,
          }
        }
        setAddresses(newAddress)
      })
      .finally(() => {
        setIsAddressLoading(false)
      })

  }, [addresses])

  const handleFocus = React.useCallback((name) => {
    setFocusInputName(name)
    setAddressesIfNeeded(name)
  }, [setAddressesIfNeeded])

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    methods,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange",
  });

  useInitContractContact(contractcontact, setValue, isFocused)

  const [contractContactSellerFields, contractContactSellerAppend, contractContactSellerRemove] = useInitContractContactSeller(contractcontactseller_set, setValue, isFocused, control, trigger)

  const [contractContactLandownerFields, contractContactLandownerAppend, contractContactLandownerRemove] = useInitContractContactLandowner(contractcontactlandowner_set, setValue, isFocused, control, trigger)

  useInitCompanyPayeeBroker(companypayeebroker, setValue, isFocused, errors)
  useInitOtherBroker(otherbroker, setValue, isFocused)
  useInitOutsourcingCompany(outsourcingcompany, setValue, isFocused)
  useInitJudicialScrivener(judicialscrivener, setValue, isFocused)

  React.useEffect(() => {
    setChoices(props.choices);
  }, [props.choices]);

  React.useEffect(() => {
    if (
      !(
        contractcontact &&
        contractcontactseller_set &&
        contractcontactlandowner_set &&
        companypayeebroker &&
        otherbroker &&
        outsourcingcompany &&
        judicialscrivener &&
        property
      )
    ) {
      return;
    }
    setPropertyId(property.id)

    if (!isFocused("property.name")) {
      setValue("property.name", property.name, { shouldValidate: true });
    }

    initialized();
  }, [
    contractcontact,
    contractcontactseller_set,
    contractcontactlandowner_set,
    companypayeebroker,
    otherbroker,
    outsourcingcompany,
    judicialscrivener,
    property,
  ]);

  const createContractContactSeller = () => {
    contractContactSellerAppend();
  };

  const saveCommonParams = React.useMemo(() => [
    errors, propertyId, clearErrors, setOriginPropertyData, setError
  ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

  const removeChildCommonParams = React.useMemo(() => [
    propertyId, getValues, setOriginPropertyData,
  ], [propertyId, getValues, setOriginPropertyData])

  const onBlurForContractContactSeller = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "contractcontactseller_set", null
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId]);

  const handleRemoveForContractContactSeller = React.useCallback((index) => {
    removeChild(...removeChildCommonParams, contractContactSellerRemove, index, "contractcontactseller_set")
  }, [propertyId]);

  const createContractContactLandowner = () => {
    contractContactLandownerAppend();
  };
  const onBlurForContractContactLandowner = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "contractcontactlandowner_set", null
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId]);

  const handleRemoveForContractContactLandowner = React.useCallback((index) => {
    removeChild(...removeChildCommonParams, contractContactLandownerRemove, index, "contractcontactlandowner_set")
  }, [propertyId]);

  const onBlurForContractContact = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "contractcontact",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const onBlurForCompanyPayeeBroker = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "companypayeebroker",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const onBlurForOtherBroker = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "otherbroker",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const onBlurForOutsourcingCompany = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "outsourcingcompany",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const onBlurForJudicialScrivener = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "judicialscrivener",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const rules = React.useMemo(() => ({
    contractcontact: {
      contract_plan_time: {
        onBlur: onBlurForContractContact,
      },
      contract_location: {
        onBlur: onBlurForContractContact,
      },
      deposit: {
        onBlur: onBlurForContractContact,
      },
      intermediate_fee: {
        onBlur: onBlurForContractContact,
      },
      former_seller_name: {
        onBlur: onBlurForContractContact,
      },
      former_seller_zipcode: {
        ...validationRules.isZipcode(),
        onBlur: onBlurForContractContact,
      },
      former_seller_address: {
        onBlur: onBlurForContractContact,
      },
      former_seller_trading_confirmation: {
        onBlur: onBlurForContractContact,
      },
      former_seller_category: {
        onBlur: onBlurForContractContact,
      },
      agent_or_guardian_name: {
        onBlur: onBlurForContractContact,
      },
      agent_or_guardian_zipcode: {
        ...validationRules.isZipcode(),
        onBlur: onBlurForContractContact,
      },
      agent_or_guardian_address: {
        onBlur: onBlurForContractContact,
      },
      agent_or_guardian_trading_confirmation: {
        onBlur: onBlurForContractContact,
      },
      agent_or_guardian_category: {
        onBlur: onBlurForContractContact,
      },
    },
    contractcontactseller_set: {
      name: {
        onBlur: onBlurForContractContactSeller,
      },
      zipcode: {
        ...validationRules.isZipcode(),
        onBlur: onBlurForContractContactSeller,
      },
      address: {
        onBlur: onBlurForContractContactSeller,
      },
      trading_confirmation: {
        onBlur: onBlurForContractContactSeller,
      },
      category: {
        onBlur: onBlurForContractContactSeller,
      },
    },
    contractcontactlandowner_set: {
      name: {
        onBlur: onBlurForContractContactLandowner,
      },
      zipcode: {
        ...validationRules.isZipcode(),
        onBlur: onBlurForContractContactLandowner,
      },
      address: {
        onBlur: onBlurForContractContactLandowner,
      },
      trading_confirmation: {
        onBlur: onBlurForContractContactLandowner,
      },
      category: {
        onBlur: onBlurForContractContactLandowner,
      },
    },
    companypayeebroker: {
      name: {
        onBlur: onBlurForCompanyPayeeBroker,
      },
      zipcode: {
        ...validationRules.isZipcode(),
        onBlur: onBlurForCompanyPayeeBroker,
      },
      address: {
        onBlur: onBlurForCompanyPayeeBroker,
      },
      trading_confirmation: {
        onBlur: onBlurForCompanyPayeeBroker,
      },
      invoice_touroku_no: {
        onBlur: onBlurForCompanyPayeeBroker,
      },
    },
    otherbroker: {
      name: {
        onBlur: onBlurForOtherBroker,
      },
      zipcode: {
        ...validationRules.isZipcode(),
        onBlur: onBlurForOtherBroker,
      },
      address: {
        onBlur: onBlurForOtherBroker,
      },
      trading_confirmation: {
        onBlur: onBlurForOtherBroker,
      },
    },
    outsourcingcompany: {
      name: {
        onBlur: onBlurForOutsourcingCompany,
      },
      zipcode: {
        ...validationRules.isZipcode(),
        onBlur: onBlurForOutsourcingCompany,
      },
      address: {
        onBlur: onBlurForOutsourcingCompany,
      },
      trading_confirmation: {
        onBlur: onBlurForOutsourcingCompany,
      },
    },
    judicialscrivener: {
      name: {
        onBlur: onBlurForJudicialScrivener,
      },
      zipcode: {
        ...validationRules.isZipcode(),
        onBlur: onBlurForJudicialScrivener,
      },
      address: {
        onBlur: onBlurForJudicialScrivener,
      },
      trading_confirmation: {
        onBlur: onBlurForJudicialScrivener,
      },
    },
  }), [onBlurForContractContact, onBlurForContractContactSeller, onBlurForContractContactLandowner, onBlurForCompanyPayeeBroker, onBlurForOtherBroker, onBlurForOutsourcingCompany, onBlurForJudicialScrivener]);

  const sellerOwnerFormTitle = "売主名"
  const landOwnerFormTitle = "地主名(底地権者)"

  const isAddSellerDisabled = props.disabled || (property.MAX_ROW_CONTRACT_CONTACT_SELLER ?? 0) <= contractContactSellerFields.length
  const AddSellerButton = () => (
    <AddButton onClick={createContractContactSeller} disabled={isAddSellerDisabled} />
  )

  const isAddLandOwnerDisabled = props.disabled || (property.MAX_ROW_CONTRACT_CONTACT_LANDOWNER ?? 0) <= contractContactLandownerFields.length
  const AddLandOwnerButton = () => (
    <AddButton onClick={createContractContactLandowner} disabled={isAddLandOwnerDisabled} />
  )

  const propertyEditFormDialogCloseAction = React.useCallback(() => {
    toggleViewport()
  }, [])

  const formAreaClickedAction = React.useCallback((additionalProcess) => {
    if (!isMobile) {
      return
    }
    if (additionalProcess) {
      additionalProcess()
    }
    setIsLoading(true)
    toggleViewport()
  }, [])

  const commonInputProps = React.useMemo(() => ({
    control: control,
    onFocus: handleFocus,
  }), [control, handleFocus])

  return (
    <PropertyEditForms title="契約連絡表・契約日時・売主情報・仲介情報">
      <LoadingProgress open={isLoading} useCircular={false} />
      <FormProvider {...methods}>
        <PropertyEditFormDialog
          isOpen={isOpenContractModal}
          handleClose={() => {
            setIsOpenContractModal(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="契約日時"
          content={
            <ContractForm
              commonInputProps={commonInputProps}
              rules={rules}
              disabled={props.disabled}
              isModal={true}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenSellerModal}
          handleClose={() => {
            setIsOpenSellerModal(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title={
            <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
              {sellerOwnerFormTitle}
              <AddSellerButton />
            </Stack>
          }
          content={
            <>
              <OwnerForms
                commonInputProps={commonInputProps}
                rules={rules}
                choices={choices}
                parentProperty="contractcontactseller_set"
                removeAction={handleRemoveForContractContactSeller}
                fields={contractContactSellerFields}
                disabled={props.disabled}
                setValue={setValue}
                isModal={true}
                addresses={addresses}
                isAddressLoading={isAddressLoading}
              />
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", mt: 1 }}
                onClick={!(isAddSellerDisabled) ? createContractContactSeller : undefined}
              >
                <Typography sx={{ color: (isAddSellerDisabled) ? "gray.main" : "darkGreen.main" }}>
                  追加
                </Typography>
                <AddButton disabled={isAddSellerDisabled} sx={{ ml: 1 }} />
              </Stack>
            </>
          }
        />

        <PropertyEditFormDialog
          isOpen={isFormerSellerEtcFormModal}
          handleClose={() => {
            setFormerSellerEtcFormModal(false)
            propertyEditFormDialogCloseAction()
          }}
          title=""
          openedAction={loadingComplete}
          contentSx={{ px: 0, pt: 0 }}
          content={
            <FormerSellerEtcForm
              commonInputProps={commonInputProps}
              rules={rules}
              choices={choices}
              setValue={setValue}
              disabled={props.disabled}
              isModal={true}
              addresses={addresses}
              isAddressLoading={isAddressLoading}
            />
          }
        />

        <PropertyEditFormDialog
          isOpen={isOpenLandOwnerModal}
          handleClose={() => {
            setIsOpenLandOwnerModal(false)
            propertyEditFormDialogCloseAction()
          }}
          title={
            <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
              {landOwnerFormTitle}
              <AddLandOwnerButton />
            </Stack>
          }
          openedAction={loadingComplete}
          content={
            <>
              <OwnerForms
                commonInputProps={commonInputProps}
                rules={rules}
                choices={choices}
                parentProperty="contractcontactlandowner_set"
                removeAction={handleRemoveForContractContactLandowner}
                fields={contractContactLandownerFields}
                disabled={props.disabled}
                setValue={setValue}
                isModal={true}
                addresses={addresses}
                isAddressLoading={isAddressLoading}
              />
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", mt: 1 }}
                onClick={!(isAddLandOwnerDisabled) ? createContractContactLandowner : undefined}
              >
                <Typography sx={{ color: (isAddLandOwnerDisabled) ? "gray.main" : "darkGreen.main" }}>
                  追加
                </Typography>
                <AddButton disabled={isAddLandOwnerDisabled} sx={{ ml: 1 }} />
              </Stack>
            </>
          }
        />

        <PropertyEditFormDialog
          isOpen={isOpenRelatedVendorModal}
          handleClose={() => {
            setIsOpenRelatedVendorModal(false)
            propertyEditFormDialogCloseAction()
          }}
          title="買主名"
          openedAction={loadingComplete}
          contentSx={{ px: 0, pt: 0 }}
          content={
            <RelatedVendorForms
              commonInputProps={commonInputProps}
              rules={rules}
              choices={choices}
              setValue={setValue}
              disabled={props.disabled}
              isModal={true}
              addresses={addresses}
              isAddressLoading={isAddressLoading}
              getValues={getValues}
            />
          }
        />
        <form>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} lg={4}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenContractModal(true))}
              >
                <ContractForm
                  commonInputProps={commonInputProps}
                  rules={rules}
                  disabled={props.disabled}
                  isModal={false}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} lg={8}>
              <Grid container direction="row" spacing={1} alignItems="flex-end">
                <Grid item xs={12}>
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenSellerModal(true))}
                  >
                    <Stack direction="row">
                      <FormLabel>
                        <Typography variant="subtitle1">{sellerOwnerFormTitle}</Typography>
                      </FormLabel>
                      <AddSellerButton />
                    </Stack>
                    <OwnerForms
                      commonInputProps={commonInputProps}
                      rules={rules}
                      choices={choices}
                      parentProperty="contractcontactseller_set"
                      removeAction={handleRemoveForContractContactSeller}
                      fields={contractContactSellerFields}
                      disabled={props.disabled}
                      setValue={setValue}
                      isModal={false}
                      addresses={addresses}
                      isAddressLoading={isAddressLoading}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setFormerSellerEtcFormModal(true))}
                  >
                    <FormerSellerEtcForm
                      commonInputProps={commonInputProps}
                      rules={rules}
                      choices={choices}
                      setValue={setValue}
                      disabled={props.disabled}
                      isModal={false}
                      addresses={addresses}
                      isAddressLoading={isAddressLoading}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenLandOwnerModal(true))}
                  >
                    <Stack direction="row">
                      <FormLabel>
                        <Typography variant="subtitle1">{landOwnerFormTitle}</Typography>
                      </FormLabel>
                      <AddLandOwnerButton />
                    </Stack>
                    <OwnerForms
                      commonInputProps={commonInputProps}
                      rules={rules}
                      choices={choices}
                      parentProperty="contractcontactlandowner_set"
                      removeAction={handleRemoveForContractContactLandowner}
                      fields={contractContactLandownerFields}
                      disabled={props.disabled}
                      setValue={setValue}
                      isModal={false}
                      addresses={addresses}
                      isAddressLoading={isAddressLoading}
                    />
                  </Paper>
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenRelatedVendorModal(true))}
              >
                <RelatedVendorForms
                  commonInputProps={commonInputProps}
                  rules={rules}
                  choices={choices}
                  setValue={setValue}
                  disabled={props.disabled}
                  isModal={false}
                  addresses={addresses}
                  isAddressLoading={isAddressLoading}
                  getValues={getValues}
                />
              </Paper>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </PropertyEditForms>
  );
});

ContractContactForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  contractcontact: PropTypes.object.isRequired,
  contractcontactseller_set: PropTypes.array.isRequired,
  contractcontactlandowner_set: PropTypes.array.isRequired,
  companypayeebroker: PropTypes.object.isRequired,
  otherbroker: PropTypes.object.isRequired,
  outsourcingcompany: PropTypes.object.isRequired,
  judicialscrivener: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

ContractContactForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const ContractForm = React.memo(function contractForm(props) {
  const { commonInputProps, rules, isModal } = props
  return (
    <Grid container direction="row" spacing={1} alignItems="flex-end">
      <Grid item xs={isModal ? 12 : 3}>
        <TextField
          {...commonInputProps}
          label="物件名"
          name="contractcontact.prefecture_name"
          disabled={true}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 5}>
        <TextField
          {...commonInputProps}
          name="property.name"
          disabled={true}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 4}>
        <TextField
          label="都道府県"
          {...commonInputProps}
          name="contractcontact.prefecture_name"
          disabled={true}
        />
      </Grid>

      <Grid item xs={isModal ? 12 : 8}>
        <TextField
          label="所在地"
          {...commonInputProps}
          name="contractcontact.address"
          disabled={true}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 4}>
        <TextField
          label="種別"
          {...commonInputProps}
          name="contractcontact.type"
          disabled={true}
        />
      </Grid>

      <Grid item xs={7}>
        <DatePicker
          label="契約日時(予定)"
          {...commonInputProps}
          name="contractcontact.contract_at"
          type="date"
          defaultValue={null}
          disabled={true}
        />
      </Grid>
      <Grid item xs={5}>
        <DatePicker
          {...commonInputProps}
          name="contractcontact.contract_plan_time"
          type="time"
          defaultValue={null}
          rules={rules.contractcontact.contract_plan_time}
          disabled={props.disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="契約場所"
          {...commonInputProps}
          name="contractcontact.contract_location"
          rules={rules.contractcontact.contract_location}
          disabled={props.disabled}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="売買価格"
          {...commonInputProps}
          name="contractcontact.trade_price"
          type="number"
          disabled={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="手付金"
          {...commonInputProps}
          name="contractcontact.deposit"
          rules={rules.contractcontact.deposit}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
          disabled={props.disabled}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="仲介手数料"
          {...commonInputProps}
          name="contractcontact.purchase_intermediary_charge"
          type="number"
          disabled={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="業務委託料"
          {...commonInputProps}
          name="contractcontact.outsourcing_fee"
          type="number"
          disabled={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={isModal ? 12 : 8}>
        <DatePicker
          label="決済予定日(迄)"
          {...commonInputProps}
          name="contractcontact.settlement_expected_at"
          disabled={true}
          defaultValue={null}
        />
      </Grid>
      <Grid item xs={4} />

      <Grid item xs={12}>
        <TextField
          label="買主名"
          {...commonInputProps}
          name="contractcontact.vendee_name"
          disabled={true}
        />
      </Grid>
    </Grid>
  )
})

ContractForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
};

const OwnerForms = React.memo(function ownerForms(props) {
  const {
    parentProperty,
    removeAction,
    fields,
    commonInputProps,
    rules,
    choices,
    setValue,
    isModal,
    addresses,
    isAddressLoading,
  } = props;
  const labels = React.useMemo(() => ({
    name: "名前",
    address: "住所",
    trading_confirmation: "取引確認",
    category: "種別",
  }), [])
  const columns = isModal ? 12 : 16
  const deleteButtonXs = 1

  const Inputs = (inputsProps) => {
    const { index } = inputsProps
    return (
      <>
        <Grid item xs={isModal ? columns : 3}>
          <HiddenInput
            {...commonInputProps}
            name={`${parentProperty}.${index}.id`}
          />
          <TextField
            {...commonInputProps}
            name={`${parentProperty}.${index}.name`}
            label={isModal && labels.name}
            rules={rules[parentProperty].name}
            disabled={props.disabled}
          />
        </Grid>
        <Grid item xs={isModal ? 5 : 2}>
          <TextField
            {...commonInputProps}
            name={`${parentProperty}.${index}.zipcode`}
            label={isModal && labels.address}
            rules={rules[parentProperty].zipcode}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">〒</InputAdornment>
              ),
            }}
            disabled={props.disabled}
          />
        </Grid>
        <Grid item xs={isModal ? columns : 5}>
          <SelectForm
            {...commonInputProps}
            name={`${parentProperty}.${index}.address`}
            rules={rules[parentProperty].address}
            choices={addresses?.[`${parentProperty}.${index}.address`]?.data}
            disabled={props.disabled}
            setValue={setValue}
            freeSolo
            loading={isAddressLoading}
          />
        </Grid>
        <Grid item xs={isModal ? columns / 2 : 2}>
          <SelectForm
            {...commonInputProps}
            name={`${parentProperty}.${index}.trading_confirmation`}
            rules={rules[parentProperty].trading_confirmation}
            choices={choices?.trading_confirmation}
            label={isModal && labels.trading_confirmation}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={isModal ? columns / 2 : 3}>
          <SelectForm
            {...commonInputProps}
            name={`${parentProperty}.${index}.category`}
            rules={rules[parentProperty].category}
            choices={choices?.owner_category}
            label={isModal && labels.category}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
      </>
    )
  }
  return (
    <FormControl>

      <Grid container spacing={1} columns={columns} alignItems={isModal ? "center" : "flex-end"}>
        {(!isModal && fields.length > 0) && (
          <>
            <Grid item xs={3}>
              {labels.name}
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              {labels.address}
            </Grid>
            <Grid item xs={2}>
              {labels.trading_confirmation}
            </Grid>
            <Grid item xs={3}>
              {labels.category}
            </Grid>
            <Grid item xs={1} />
          </>
        )}
        {fields.map((item, index) => (
          <React.Fragment key={index}>
            {isModal
              ?
              <>
                <Grid container direction="row" spacing={1} columns={columns} item xs={columns - deleteButtonXs}>
                  <Inputs index={index} />
                </Grid>
                <Grid item xs={deleteButtonXs}>
                  <DeleteButton
                    onClick={() => removeAction(index)}
                    disabled={props.disabled}
                  />
                </Grid>
                <Grid item xs={columns}>
                  <Divider />
                </Grid>
              </>
              :
              <>
                <Inputs index={index} />
                <Grid item xs={deleteButtonXs}>
                  <DeleteButton
                    onClick={() => removeAction(index)}
                    disabled={props.disabled}
                  />
                </Grid>
              </>
            }
          </React.Fragment>
        ))}
      </Grid>
    </FormControl>
  );
});
OwnerForms.propTypes = {
  parentProperty: PropTypes.string.isRequired,
  removeAction: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
  addresses: PropTypes.object.isRequired,
  isAddressLoading: PropTypes.bool.isRequired,
};
OwnerForms.defaultProps = {
  disabled: false,
};

const FormerSellerEtcForm = React.memo(function formerSellerEtcForm(props) {
  const { commonInputProps, rules, choices, setValue, isModal, addresses, isAddressLoading } = props

  const labels = React.useMemo(() => ({
    name: "名前",
    address: "住所",
    trading_confirmation: "取引確認",
    category: "種別",
  }), [])

  const data = React.useMemo(() => ([
    {
      title: "元売主(三為契約)",
      nameName: "former_seller_name",
      zipCodeName: "former_seller_zipcode",
      addressName: "former_seller_address",
      tradingConfirmationName: "former_seller_trading_confirmation",
      categoryName: "former_seller_category",
    },
    {
      title: "代理人/後見人等",
      nameName: "agent_or_guardian_name",
      zipCodeName: "agent_or_guardian_zipcode",
      addressName: "agent_or_guardian_address",
      tradingConfirmationName: "agent_or_guardian_trading_confirmation",
      categoryName: "agent_or_guardian_category",
    },
  ]), [])
  const Inputs = (inputsProps) => {
    const { item } = inputsProps
    return (
      <>
        <Grid item xs={isModal ? 12 : 2}>
          <TextField
            {...commonInputProps}
            name={`contractcontact.${item.nameName}`}
            rules={rules.contractcontact[item.nameName]}
            label={isModal && labels.name}
            disabled={props.disabled}
          />
        </Grid>
        <Grid item xs={isModal ? 5 : 2}>
          <TextField
            {...commonInputProps}
            name={`contractcontact.${item.zipCodeName}`}
            rules={rules.contractcontact[item.zipCodeName]}
            label={isModal && labels.address}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">〒</InputAdornment>
              ),
            }}
            disabled={props.disabled}
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 3}>
          <SelectForm
            {...commonInputProps}
            name={`contractcontact.${item.addressName}`}
            rules={rules.contractcontact[item.addressName]}
            choices={addresses?.[`contractcontact.${item.addressName}`]?.data}
            disabled={props.disabled}
            setValue={setValue}
            freeSolo
            loading={isAddressLoading}
          />
        </Grid>
        <Grid item xs={isModal ? 6 : 1}>
          <SelectForm
            {...commonInputProps}
            name={`contractcontact.${item.tradingConfirmationName}`}
            rules={rules.contractcontact[item.tradingConfirmationName]}
            label={isModal && labels.trading_confirmation}
            choices={choices?.trading_confirmation}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={isModal ? 6 : 2}>
          <SelectForm
            {...commonInputProps}
            name={`contractcontact.${item.categoryName}`}
            rules={rules.contractcontact[item.categoryName]}
            label={isModal && labels.category}
            choices={choices?.owner_category}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
      </>
    )
  }
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={1}
        alignItems="flex-end"
      >
        {!isModal &&
          <>
            <Grid item xs={2} />
            <Grid item xs={2}>
              {labels.name}
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={3}>
              {labels.address}
            </Grid>
            <Grid item xs={1}>
              {labels.trading_confirmation}
            </Grid>
            <Grid item xs={2}>
              {labels.category}
            </Grid>
          </>
        }

        {data.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {isModal
                ?
                <Grid item xs={12}>
                  <DialogSubContent
                    title={item.title}
                    content={
                      <Grid container direction="row" spacing={1}>
                        <Inputs item={item} />
                      </Grid>
                    }
                  />
                </Grid>
                :
                <Grid item xs={12} container direction="row" spacing={1}>
                  <Grid item xs={2}>
                    <Typography>{item.title}</Typography>
                  </Grid>
                  <Inputs item={item} />
                </Grid>
              }
            </React.Fragment>
          )
        })}
      </Grid>
    </>
  )
})

FormerSellerEtcForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  addresses: PropTypes.object.isRequired,
  isAddressLoading: PropTypes.bool.isRequired,
};

const RelatedVendorForms = React.memo(function relatedVendorForms(props) {
  const { commonInputProps, rules, choices, setValue, isModal, addresses, isAddressLoading, getValues } = props

  const labels = React.useMemo(() => ({
    name: "名前",
    address: "住所",
    trading_confirmation: "取引確認",
    invoice_touroku_no: "インボイス番号",
  }), [])

  const data = React.useMemo(() => ([
    {
      title: "当社支払先・仲介業者",
      parentProperty: "companypayeebroker",
      nameDisabled: false,
      isCompanypayeebroker: true,
    },
    {
      title: "その他・仲介業者",
      parentProperty: "otherbroker",
      nameDisabled: false,
      isCompanypayeebroker: false,
    },
    {
      title: "業務委託先",
      parentProperty: "outsourcingcompany",
      nameDisabled: false,
      isCompanypayeebroker: false,
    },
    {
      title: "司法書士",
      parentProperty: "judicialscrivener",
      nameDisabled: true,
      isCompanypayeebroker: false,
    },
  ]), [])
  return (
    <Grid container direction="row" spacing={1}>
      {!isModal &&
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={3}>
              {labels.name}
            </Grid>
            <Grid item xs={12} md={1} />
            <Grid item xs={12} md={3}>
              {labels.address}
            </Grid>
            <Grid item xs={12} md={1}>
              {labels.trading_confirmation}
            </Grid>
            <Grid item xs={12} md={2}>
              {labels.invoice_touroku_no}
            </Grid>
          </Grid>
        </Grid>
      }
      {data.map((item, index) => {
        return (
          <Grid item xs={12} key={index}>
            {isModal
              ?
              <DialogSubContent
                title={item.title}
                content={
                  <Grid container direction="row" spacing={1}>
                    <RelatedVendorForm
                      commonInputProps={commonInputProps}
                      rules={rules}
                      choices={choices}
                      parentProperty={item.parentProperty}
                      nameDisabled={item.nameDisabled}
                      disabled={props.disabled}
                      setValue={setValue}
                      isModal={isModal}
                      labels={labels}
                      addresses={addresses}
                      isAddressLoading={isAddressLoading}
                      isCompanypayeebroker={item.isCompanypayeebroker}
                      getValues={getValues}
                    />
                  </Grid>
                }
              />
              :
              <Grid container direction="row" spacing={1}>
                <Grid item xs={2}>
                  <Typography>{item.title}</Typography>
                </Grid>
                <RelatedVendorForm
                  commonInputProps={commonInputProps}
                  rules={rules}
                  choices={choices}
                  parentProperty={item.parentProperty}
                  nameDisabled={item.nameDisabled}
                  disabled={props.disabled}
                  setValue={setValue}
                  isModal={isModal}
                  labels={labels}
                  addresses={addresses}
                  isAddressLoading={isAddressLoading}
                  isCompanypayeebroker={item.isCompanypayeebroker}
                  getValues={getValues}
                />
              </Grid>
            }
          </Grid>
        )
      })}
    </Grid>
  )
})
RelatedVendorForms.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  addresses: PropTypes.object.isRequired,
  isAddressLoading: PropTypes.bool.isRequired,
  getValues: PropTypes.func.isRequired,
};

const RelatedVendorForm = React.memo(function RelatedVendorForm(props) {
  const { parentProperty, nameDisabled, commonInputProps, rules, choices, setValue, isModal, labels, addresses, isAddressLoading, isCompanypayeebroker, getValues } =
    props;

  const [getCompanyPayeeBrokerNameChoices, companyPayeeBrokerNameChoices, companyPayeeBrokerNameIsLoading] = useCompanyPayeeBrokerName()
  const onChangeForCompanyPayeeBrokerName = React.useCallback((e) => {
    getCompanyPayeeBrokerNameChoices(e.target.value)
  }, []);

  return (
    <>
      <Grid item xs={isModal ? 12 : 3}>
        {isCompanypayeebroker ?
          <SelectForm
            {...commonInputProps}
            name={`${parentProperty}.name`}
            rules={rules[parentProperty].name}
            disabled={nameDisabled || props.disabled}
            label={isModal && labels.name}
            setValue={setValue}
            freeSolo
            onChange={onChangeForCompanyPayeeBrokerName}
            choices={companyPayeeBrokerNameChoices}
            loading={companyPayeeBrokerNameIsLoading}
            onFocus={(name) => {
              commonInputProps.onFocus(name)
              getCompanyPayeeBrokerNameChoices(getValues(name))
            }}
          />
          :
          <TextField
            {...commonInputProps}
            name={`${parentProperty}.name`}
            rules={rules[parentProperty].name}
            disabled={nameDisabled || props.disabled}
            label={isModal && labels.name}
          />
        }
      </Grid>
      <Grid item xs={isModal ? 5 : 1}>
        <TextField
          {...commonInputProps}
          name={`${parentProperty}.zipcode`}
          rules={rules[parentProperty].zipcode}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">〒</InputAdornment>
            ),
          }}
          disabled={props.disabled}
          label={isModal && labels.address}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 3}>
        <SelectForm
          {...commonInputProps}
          name={`${parentProperty}.address`}
          rules={rules[parentProperty].address}
          choices={addresses?.[`${parentProperty}.address`]?.data}
          disabled={props.disabled}
          setValue={setValue}
          freeSolo
          loading={isAddressLoading}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 1}>
        <SelectForm
          {...commonInputProps}
          name={`${parentProperty}.trading_confirmation`}
          rules={rules[parentProperty].trading_confirmation}
          choices={choices?.trading_confirmation}
          disabled={props.disabled}
          setValue={setValue}
          label={isModal && labels.trading_confirmation}
        />
      </Grid>
      {isCompanypayeebroker &&
        <Grid item xs={isModal ? 12 : 2}>
          <TextField
            {...commonInputProps}
            name={`${parentProperty}.invoice_touroku_no`}
            rules={rules[parentProperty].invoice_touroku_no}
            disabled={props.disabled}
            label={isModal && labels.invoice_touroku_no}
          />
        </Grid>
      }
    </>
  );
});

RelatedVendorForm.propTypes = {
  parentProperty: PropTypes.string.isRequired,
  nameDisabled: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  addresses: PropTypes.object.isRequired,
  isAddressLoading: PropTypes.bool.isRequired,
  getValues: PropTypes.func.isRequired,
  isCompanypayeebroker: PropTypes.bool.isRequired,
};
RelatedVendorForm.defaultProps = {
  disabled: false,
};
