import { backendClient } from "./Client";
import { BackendUrls } from "./Urls";

export const getAdminFormulas = (params) => {
  return backendClient().get(BackendUrls.adminFormulas, { params });
};

export const getAdminFormulasByKey = (key, params) => {
  const url = BackendUrls.adminFormulasByKey.replace(":key", key)
  return backendClient().get(url, { params });
};

export const confirmFormula = (params) => {
  return backendClient().post(BackendUrls.adminFormulaConfirm, { params });
};

export const execFormula = (params) => {
  return backendClient().post(BackendUrls.adminFormulaExec, { params });
};

export const createFormula = (params) => {
  return backendClient().post(BackendUrls.adminFormulas, { params });
};

export const getPropertyShare = () => {
  return backendClient().get(BackendUrls.adminPropertyShare);
};

export const propertyShareBulkShare = (params) => {
  return backendClient().post(BackendUrls.adminPropertyShareBulkShare, params);
};