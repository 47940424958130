import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import {ReactComponent as icon} from "../../assets/images/RightArrow.svg";

export const RightArrowIcon = (props) => {
  return (
    <SvgIcon
      component={icon}
      viewBox="0 0 11 17"
      {...props}
    />
  );
};


RightArrowIcon.defaultProps = {
  color: "black"
};

RightArrowIcon.propTypes = {
  color: PropTypes.string,
}